import { useCallback, useRef } from 'react';

import { Divider, Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { castToBoolean } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { GridItem, RadioGroupWithOptions } from '@ecp/components';
import { useGetConditionValues, useGetFields, useInitValues } from '@ecp/features/sales/form';
import { Button, Form } from '@ecp/features/sales/shared/components';
import {
  HOME_SECONDARY_INSURED_PERSON_REF,
  RENTERS_SECONDARY_INSURED_PERSON_REF,
  SECONDARY_INSURED_PERSON_REF,
} from '@ecp/features/sales/shared/constants';
import {
  deleteAnswers,
  deleteInquiryRef,
  getAnswers,
  getSapiTarget,
  useField,
  useForm,
} from '@ecp/features/sales/shared/store';
import { getLineOfBusinessOrder } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';

import { useStyles } from './SecondaryNamedInsuredForm.styles';
import { SniQuestionsAuto } from './SniQuestionsAuto';
import { SniQuestionsProperty } from './SniQuestionsProperty';

export const SecondaryNamedInsuredForm: React.FC<{ onNext: () => Promise<void> }> = ({
  onNext,
}) => {
  const { classes } = useStyles();
  const sniDriverQuestionsFlag = flagValues[FeatureFlags.SNI_DRIVER_QUESTIONS];
  const hasSecondaryNamedInsured = useField('static.hasSecondaryNamedInsured');
  const selectedProducts = useSelector(getLineOfBusinessOrder);

  const initValues = useRef({});
  const getFields = useGetFields();
  const getConditions = useGetConditionValues();
  const dispatch = useDispatch();

  const propertySNI = useField('person.addSecondaryInsured');
  const sapiTarget = useSelector(getSapiTarget);
  const isSAPIV4 = sapiTarget === 'v4';
  const allAnswers = useSelector(getAnswers);

  useInitValues({ [hasSecondaryNamedInsured.key]: false });
  const { isPatchFormInProgress, patchFormValues, validateForm } = useForm({
    initValues,
    fields: getFields(),
    conditions: getConditions(),
  });

  // For v4, if propertySNI use auto SNI person, no need to patch form values, so no new person is created in SAPI
  const shouldPatchFormValues = useCallback(() => {
    const autoSNI = allAnswers[SECONDARY_INSURED_PERSON_REF];
    const homeSNI = allAnswers[HOME_SECONDARY_INSURED_PERSON_REF];
    const rentersSNI = allAnswers[RENTERS_SECONDARY_INSURED_PERSON_REF];

    return (homeSNI && autoSNI !== homeSNI) || (rentersSNI && autoSNI !== rentersSNI);
  }, [allAnswers]);

  const handleSubmit = useCallback(async () => {
    try {
      if (propertySNI.value) {
        propertySNI.props.actionOnComplete(true);
      }
      if (!propertySNI.value) {
        propertySNI.props.actionOnComplete(false);
      }
    } finally {
      if (validateForm().isValid) {
        if (!isSAPIV4 || (isSAPIV4 && shouldPatchFormValues())) await patchFormValues();
        await onNext();
      }
    }
  }, [onNext, validateForm, propertySNI, isSAPIV4, patchFormValues, shouldPatchFormValues]);

  const handleSkip = useCallback(async () => {
    // this prevents autoSNI from being removed when "skip this step" is clicked and the Auto SNI questions are rendered on the additional drivers page.
    const autoKeys = sniDriverQuestionsFlag ? '' : SECONDARY_INSURED_PERSON_REF;

    const keysToDelete = [
      autoKeys,
      HOME_SECONDARY_INSURED_PERSON_REF,
      RENTERS_SECONDARY_INSURED_PERSON_REF,
      'static.personPropertySNI.dob',
    ];
    const autoSNI = allAnswers[autoKeys];
    const homeSNI = allAnswers[HOME_SECONDARY_INSURED_PERSON_REF];
    const rentersSNI = allAnswers[RENTERS_SECONDARY_INSURED_PERSON_REF];
    try {
      if (propertySNI.props.value) {
        propertySNI.props.actionOnComplete(false);
      }

      // TODO: Review refs getting passed in here
      // This only delete secondary insured person for home, renters products, not auto, as the additional driver might still exists
      if (isSAPIV4) {
        if (homeSNI && autoSNI !== homeSNI) {
          await dispatch(deleteInquiryRef(homeSNI));
        }
        if (rentersSNI && autoSNI !== rentersSNI) {
          await dispatch(deleteInquiryRef(rentersSNI));
        }
      } else {
        await dispatch(deleteAnswers({ keys: keysToDelete }));
        await dispatch(deleteAnswers({ ref: 'personPropertySNI' }));
      }
    } finally {
      validateForm();
      await onNext();
    }
  }, [
    onNext,
    dispatch,
    validateForm,
    propertySNI.props,
    isSAPIV4,
    allAnswers,
    sniDriverQuestionsFlag,
  ]);

  const handleContinue = useCallback(async () => {
    if (hasSecondaryNamedInsured.value) {
      handleSubmit();
    } else {
      handleSkip();
    }
  }, [handleSkip, handleSubmit, hasSecondaryNamedInsured.value]);

  const hasAuto = selectedProducts.includes(LineOfBusiness.AUTO);
  const showAuto = hasAuto && (!sniDriverQuestionsFlag || selectedProducts.length === 1);
  const propertyProduct = selectedProducts.includes(LineOfBusiness.RENTERS)
    ? LineOfBusiness.RENTERS
    : selectedProducts.includes(LineOfBusiness.HOME)
    ? LineOfBusiness.HOME
    : null;

  return (
    <div className={classes.root}>
      <Form showBackdrop={isPatchFormInProgress}>
        <Grid container>
          <GridItem topSpacing='lg' xs={12}>
            <RadioGroupWithOptions
              {...hasSecondaryNamedInsured.props}
              label='Add SNI to your policy? (optional)'
              variant='yesNoButton'
              trackingName='PriorInsurance'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>

          {castToBoolean(hasSecondaryNamedInsured.value) && (
            <GridItem xs={12}>
              {showAuto && <SniQuestionsAuto />}
              {!!propertyProduct && (
                <SniQuestionsProperty
                  canCopyFromAuto={hasAuto && !sniDriverQuestionsFlag}
                  selectedProduct={propertyProduct}
                />
              )}
            </GridItem>
          )}
        </Grid>

        <Divider className={classes.divider} />
        <GridItem topSpacing='lg' xs={12}>
          <Button
            className={classes.next}
            variant='primary'
            onClick={handleContinue}
            trackingName='sni_details_continue'
            trackingLabel={GoogleAnalyticsLabels.CONTINUE}
            analyticsElement='sniDetailsPage.continueButton'
          >
            Continue
          </Button>
        </GridItem>
      </Form>
    </div>
  );
};
