import type { AllConfigs } from '../../types.js';
import { productPageFlow as productPageFlowBase } from './metadata.js';

export const productPageFlow: AllConfigs = [
  ...productPageFlowBase,
  {
    executionOrder: 340,
    startEffectiveDate: '01/01/2021',
    description: 'Consumer Monoline Renters Product Flow for Zillow',
    criteria: {
      baseExp: ['Renters'],
      flow: ['', 'FLOW.SHORT', 'FLOW.LONG'],
      product: ['homesite.renters'],
      expId: ['2844', '2845'],
    },
    deltas: [
      {
        operation: 'remove',
        target: 'menuItems',
        value: [''],
      },
      {
        operation: 'remove',
        target: 'navigationOrder',
        value: [''],
      },
      {
        operation: 'remove',
        target: 'byPageId',
        value: 'discounts',
      },
    ],
  },
  {
    executionOrder: 360,
    startEffectiveDate: '01/01/2021',
    description: 'Renters - Renters: Base menu structure with discounts removed for 1263 expid',
    criteria: {
      baseExp: ['Agent'],
      channel: ['CallCenter'],
      flow: ['', 'FLOW.SHORT', 'FLOW.LONG'],
      product: ['homesite.renters'],
      expId: ['1263'],
    },
    config: {
      menuItems: [
        {
          text: 'Renters',
          pageId: 'propertydetails',
          path: '/quote/renters/propertyDetails',
          subMenuItems: [
            {
              text: 'Rental property details',
              pageId: 'propertyDetails',
              path: '/quote/renters/propertyDetails',
            },
          ],
        },
      ],
      navigationOrder: ['quoteSummary'],
      byPageId: {
        quoteSummary: {
          path: '/quote/quotes',
          pageTitle: 'Quote Summary',
        },
        propertyDetails: {
          path: '/quote/renters/propertyDetails',
          pageTitle: 'Rental property details',
        },
      },
    },
  },
];
