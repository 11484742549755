import { type MouseEventHandler, useCallback, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import type { SelectProps } from '@ecp/components';
import { GridItem, Select } from '@ecp/components';
import { useAddDriver } from '@ecp/features/sales/quotes/auto';
import { TextField } from '@ecp/features/sales/shared/components';
import { Link } from '@ecp/features/sales/shared/components';
import {
  getNonPniDrivers,
  isAnyApiInProgress,
  navigateToDriver,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { useLoading } from '@ecp/features/sales/shared/utils/web';
import { IconCardAuto } from '@ecp/themes/base';

import { useStyles } from './SecondaryNamedInsuredForm.styles';

export const SniQuestionsAuto: React.FC = () => {
  const [isNotApplicable, setIsNotApplicable] = useState(false);

  const { classes } = useStyles();
  const dispatch = useDispatch();
  const shouldWait = useSelector(isAnyApiInProgress);
  const { withLoading } = useLoading(shouldWait);
  const addDriver = useAddDriver();

  const drivers = useSelector(getNonPniDrivers);

  const secondaryInsuredPersonRef = useField('secondaryInsured.person.ref');
  const autoSniFieldValue = useField(`secondaryInsured.person.ref`).props.value?.trim();
  const useAutoField = useFieldWithPrefix(autoSniFieldValue, 'person.<id>');

  const driverFirstName = useAutoField('firstName');
  const driverLastName = useAutoField('lastName');
  const driverDOB = useField(`${autoSniFieldValue}.dob`);
  const staticDriverDateOfBirth = useField(`static.${autoSniFieldValue}.dob`);
  const sniDriverDateOfBirth =
    staticDriverDateOfBirth && staticDriverDateOfBirth.props.value !== undefined
      ? staticDriverDateOfBirth
      : driverDOB;

  const handleDriverObjActionOnChange: NonNullable<SelectProps['actionOnChange']> = useCallback(
    (value) => {
      setIsNotApplicable(value === ' ');
      secondaryInsuredPersonRef.props.actionOnComplete(value);
    },
    [secondaryInsuredPersonRef.props],
  );

  const navigateToDriverPage: MouseEventHandler<HTMLSpanElement> = withLoading(async () => {
    const newDriver = addDriver();
    const { driverRef } = newDriver;
    await newDriver.done;

    trackClick({ action: 'add_driver_link', label: driverRef });

    await dispatch(
      navigateToDriver({
        ref: driverRef,
        replace: false,
        referencePage: 'profileAddSni',
      }),
    );
  });

  if (!drivers) return null;

  const driverObj = drivers.map((driver) => ({
    label: `${driver.firstName} ${driver.lastName} ${
      isMasked(driver.dateOfBirth) ? driver.dateOfBirth : formatDate(driver.dateOfBirth)
    }`,
    value: driver.personRef,
  }));
  driverObj.push({
    label: 'Not applicable',
    value: ' ',
  });

  return (
    <Grid container>
      <GridItem className={classes.questionLabel} topSpacing='lg' xs={12}>
        <Box>
          <div className={classes.labelIcon}>
            <IconCardAuto />
          </div>
        </Box>
        <Typography variant='body4'>
          Who would you like to add to your auto policy?
          <span className={classes.optional}>(optional)</span>
        </Typography>
      </GridItem>

      <GridItem xs={12} topSpacing={10}>
        <span className={classes.questionSubLabel}>
          The SNI must be listed as a driver on the policy.
        </span>
        <Link
          component='button'
          onClick={navigateToDriverPage}
          trackingName='add_new_driver_link'
          trackingLabel='add_new_driver'
        >
          Add a new driver
        </Link>
      </GridItem>

      <Grid container>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <Select
            {...secondaryInsuredPersonRef.props}
            id='FirstName'
            label='First name'
            actionOnChange={handleDriverObjActionOnChange}
            options={driverObj}
            displayEmpty
            // eslint-disable-next-line react/jsx-no-bind
            renderValue={(value) =>
              isNotApplicable ? 'Not applicable' : driverFirstName.props.value
            }
            className={classes.select}
            trackingName='auto_sni_first_name_selection'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
          />
        </GridItem>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
          <TextField
            id='SecondaryInsuredLastName'
            label='Last name'
            data-testid='lastName'
            value={driverLastName.props.value}
            disabled
            ariaLabel='Secondary insured last name'
            trackingName='auto_sni_last_name_selection'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
          />
        </GridItem>
        <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
          <TextField
            id='SecondaryInsuredDob'
            label='Date of birth'
            data-testid='dob'
            value={
              isMasked(sniDriverDateOfBirth.props.value)
                ? sniDriverDateOfBirth.props.value
                : formatDate(sniDriverDateOfBirth.props.value)
            }
            disabled
            ariaLabel='Secondary insured dob'
            trackingName='auto_sni_dob'
            trackingLabel={GoogleAnalyticsLabels.REDACTED}
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};
