import type { Questions } from '@ecp/types';

import { STATIC_QUESTIONS as STATIC_QUESTIONS_BASE } from './structure.js';

export * from './structure.js';

/** Includes either agent or consumer questions, depending on the experience. */
export const STATIC_QUESTIONS: Questions = {
  ...STATIC_QUESTIONS_BASE,
  'static.driver.<id>.discount.courseCompletionCertificationDate': {
    answerType: 'String',
    questionType: 'optional',
    businessType: ['auto'],
    required: '{"@driver.<id>.discount.defensiveDriver":{"$eq":true}}',
  },
  'static.exteriorWall': {
    answerType: 'String',
    questionType: 'required',
    businessType: ['auto', 'home'],
    required: 'true',
    criteria: [
      {
        $not: {
          $and: [
            {
              $and: [
                {
                  $exists: '@property.styleOfHome',
                },
                {
                  '@property.styleOfHome': {
                    $eq: 'STYLE_OF_HOME.CONCRETE',
                  },
                },
              ],
            },
            {
              $in: [
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDBRICK',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDLOG',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDSTONE',
              ],
            },
          ],
        },
        error: 'The exteriror wall finish is not valid with the construction type selected.',
      },
      {
        $not: {
          $and: [
            {
              $and: [
                {
                  $exists: '@property.styleOfHome',
                },
                {
                  '@property.styleOfHome': {
                    $eq: 'STYLE_OF_HOME.FRAME',
                  },
                },
              ],
            },
            {
              $in: [
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDBRICK',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDLOG',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CONCBLK',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDSTONE',
              ],
            },
          ],
        },
        error: 'The exteriror wall finish is not valid with the construction type selected.',
      },
      {
        $not: {
          $and: [
            {
              $and: [
                {
                  $exists: '@property.styleOfHome',
                },
                {
                  '@property.styleOfHome': {
                    $eq: 'STYLE_OF_HOME.MASONRY',
                  },
                },
              ],
            },
            {
              $in: [
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.BRICKVEN',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SIDINGLOG',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDLOG',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CONCBLK',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STONEVEN',
              ],
            },
          ],
        },
        error: 'The exteriror wall finish is not valid with the construction type selected.',
      },
      {
        $not: {
          $and: [
            {
              $and: [
                {
                  $exists: '@property.styleOfHome',
                },
                {
                  '@property.styleOfHome': {
                    $eq: 'STYLE_OF_HOME.STEEL',
                  },
                },
              ],
            },
            {
              $in: [
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDBRICK',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDLOG',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDSTONE',
              ],
            },
          ],
        },
        error: 'The exteriror wall finish is not valid with the construction type selected.',
      },
      {
        $not: {
          $and: [
            {
              $and: [
                {
                  $exists: '@property.styleOfHome',
                },
                {
                  '@property.styleOfHome': {
                    $eq: 'STYLE_OF_HOME.LOG',
                  },
                },
              ],
            },
            {
              $in: [
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.OTHER',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDBRICK',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.BRICKVEN',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SIDINGLOG',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CONCBLK',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDSTONE',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STONEVEN',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STUCCO',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.WOOD_SIDING',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SIDCEMENT',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SIDHARDBOR',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.EIFS',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.EARTH',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SIDEALUMSTEEL',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SIDEVINYL',
              ],
            },
          ],
        },
        error: 'The exteriror wall finish is not valid with the construction type selected.',
      },
      {
        $not: {
          $and: [
            {
              $and: [
                {
                  $in: [
                    'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDBRICK',
                    'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.BRICKVEN',
                    'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SIDINGLOG',
                    'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDLOG',
                    'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CONCBLK',
                    'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDSTONE',
                    'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STONEVEN',
                    'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.EIFS',
                    'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.EARTH',
                  ],
                },
                {
                  $exists: '@property.styleOfHome',
                },
                {
                  '@property.styleOfHome': {
                    $eq: 'STYLE_OF_HOME.ADOBE_BLOCK',
                  },
                },
              ],
            },
          ],
        },
        error: 'The exteriror wall finish is not valid with the construction type selected.',
      },
      {
        $not: {
          $and: [
            {
              $in: [
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDBRICK',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDLOG',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CONCBLK',
                'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.SOLIDSTONE',
              ],
            },
            {
              $and: [
                {
                  $exists: '@property.styleOfHome',
                },
                {
                  '@property.styleOfHome': {
                    $eq: 'STYLE_OF_HOME.OTHER_EXT',
                  },
                },
              ],
            },
          ],
        },
        error: 'The exteriror wall finish is not valid with the construction type selected.',
      },
    ],
    dependentQuestionKeys: ['property.styleOfHome'],
  },
  // Start - AMFAM Advance Delta section
  'static.delta.coverageOption.amfam-adv.auto.ubiProgramEmail': {
    answerType: 'String',
    businessType: ['auto'],
    criteria: [],
    defaultValue: null,
    required: 'true',
  },
  'static.delta.coverageOption.amfam-adv.auto.ubiProgramPhone': {
    answerType: 'String',
    businessType: ['auto'],
    criteria: [],
    required: '{"@discount.auto.ubiProgram.type":{"$eq": "MILES_MY_WAY"}}',
  },
  'static.delta.driver.#DRIVER#.ubiProgramPhone': {
    answerType: 'String',
    businessType: ['auto'],
    criteria: [],
    required:
      '{"$and":[{"@discount.auto.ubiProgram.type":{"$eq": "DRIVE_MY_WAY"}},{"delta.driver.#DRIVER#.ubiProgramOptIn":{"$eq":"true"}}]}',
  },
  'static.delta.coverageOption.amfam-adv.auto.paperlessEmail': {
    answerType: 'String',
    businessType: ['auto'],
    criteria: [],
    defaultValue: null,
    required: 'true',
  },
  'static.delta.hasThirdPartyInterestInd': {
    answerType: 'Boolean',
    businessType: ['auto'],
    description: 'Policies have third-party interests that need to be included',
    defaultValue: 'false',
    required: 'true',
  },
  // END - AMFAM Advance Delta section

  // Start - AMFAM Advance Signature (Remote and Inperson)
  'static.delta.coverageOption.amfam-adv.renters.paperlessEmail': {
    answerType: 'String',
    businessType: ['auto'],
    criteria: [],
    defaultValue: null,
    required: 'true',
  },
  'static.checkout.auto.signatureMethod': {
    answerType: 'String',
    businessType: ['auto'],
    questionType: 'required',
    required: 'true',
  },
  'static.checkout.renters.signatureMethod': {
    answerType: 'String',
    businessType: ['renters'],
    questionType: 'required',
    required: 'true',
  },
  'static.checkout.home.signatureMethod': {
    answerType: 'String',
    businessType: ['home'],
    questionType: 'required',
    required: 'true',
  },
  'static.person.<id>.checkout.auto.remoteClick2Sign.email': {
    answerType: 'String',
    businessType: ['auto'],
    questionType: 'required',
    required: 'true',
  },
  'static.person.<id>.checkout.auto.remoteClick2Sign.newEmail': {
    answerType: 'String',
    businessType: ['auto'],
    questionType: 'required',
    criteria: [
      {
        error: 'Invalid email',
        $regex: '^[\\w\\d\\-\\&\\’\\/\\.\\+\\_\\@]+@[\\w\\d\\-\\&\\’\\/\\.\\+\\_\\@]+\\.[\\w]+$',
      },
    ],
    required: 'true',
  },
  'static.person.<id>.checkout.home.remoteClick2Sign.email': {
    answerType: 'String',
    businessType: ['home'],
    questionType: 'required',
    required: 'true',
  },
  'static.person.<id>.checkout.home.remoteClick2Sign.newEmail': {
    answerType: 'String',
    businessType: ['home'],
    questionType: 'required',
    criteria: [
      {
        error: 'Invalid email',
        $regex: '^[\\w\\d\\-\\&\\’\\/\\.\\+\\_\\@]+@[\\w\\d\\-\\&\\’\\/\\.\\+\\_\\@]+\\.[\\w]+$',
      },
    ],
    required: 'true',
  },
  'static.person.<id>.checkout.renters.remoteClick2Sign.email': {
    answerType: 'String',
    businessType: ['renters'],
    questionType: 'required',
    required: 'true',
  },
  'static.person.<id>.checkout.renters.remoteClick2Sign.newEmail': {
    answerType: 'String',
    businessType: ['renters'],
    questionType: 'required',
    criteria: [
      {
        error: 'Invalid email',
        $regex: '^[\\w\\d\\-\\&\\’\\/\\.\\+\\_\\@]+@[\\w\\d\\-\\&\\’\\/\\.\\+\\_\\@]+\\.[\\w]+$',
      },
    ],
    required: 'true',
  },
  'static.person.<id>.checkout.auto.inPersonClick2Sign.envelopeId': {
    answerType: 'String',
    businessType: ['auto'],
    questionType: 'required',
    required: 'true',
  },
  'static.person.<id>.checkout.auto.inPersonClick2Sign.recipientId': {
    answerType: 'String',
    businessType: ['auto'],
    questionType: 'required',
    required: 'true',
  },
  'static.person.<id>.checkout.home.inPersonClick2Sign.envelopeId': {
    answerType: 'String',
    businessType: ['home'],
    questionType: 'required',
    required: 'true',
  },
  'static.person.<id>.checkout.home.inPersonClick2Sign.recipientId': {
    answerType: 'String',
    businessType: ['home'],
    questionType: 'required',
    required: 'true',
  },
  'static.person.<id>.checkout.renters.inPersonClick2Sign.envelopeId': {
    answerType: 'String',
    businessType: ['renters'],
    questionType: 'required',
    required: 'true',
  },
  'static.person.<id>.checkout.renters.inPersonClick2Sign.recipientId': {
    answerType: 'String',
    businessType: ['renters'],
    questionType: 'required',
    required: 'true',
  },
  // END - AMFAM Advance Signature (Remote and Inperson)

  // START - AMFAM Advance Producer details for Email
  'static.primaryInsuredAddress': {
    answerType: 'List',
    questionType: 'optional',
    businessType: ['auto', 'home', 'renters'],
    required: 'true',
  },
  'static.emailQuotes.from': {
    answerType: 'String',
    questionType: 'required',
    required: 'true',
  },
  'static.emailQuotes.cc': {
    answerType: 'String',
    questionType: 'required',
    required: 'false',
  },
  'static.emailQuotes.message': {
    answerType: 'String',
    questionType: 'required',
    required: 'true',
  },
  'static.emailQuotes.agencyName': {
    answerType: 'String',
    questionType: 'required',
    required: 'true',
  },
  'static.emailQuotes.agencyAddress': {
    answerType: 'String',
    questionType: 'required',
    required: 'true',
  },
  'static.emailQuotes.agencyPhone': {
    answerType: 'String',
    questionType: 'required',
    required: 'true',
  },
  'static.emailQuotes.agencyAddress.ref': {
    answerType: 'List',
    businessType: ['auto', 'home', 'renters'],
    criteria: [
      {
        $regex: '^addresses\\.\\d[a-zA-Z]\\w{6}$',
        error: 'Invalid reference id',
      },
    ],
    description: '',
    required: 'false',
  },
  // END - AMFAM Advance Producer details for Email
};
