import { castAnswerType, emptyArray, isEqual, unique } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import type { AnswerSet, QuestionSet } from '@ecp/utils/validator';
import { evaluateQuestionSet, validateAnswer } from '@ecp/utils/validator';

import { getPostBindAnswers } from '@ecp/features/sales/checkout';
import { emptyQuestion } from '@ecp/features/sales/shared/constants';
import type { AppDispatch, RootStore, ThunkAction } from '@ecp/features/sales/shared/store/types';
import type { Answers, AnswerValue, CoveragesFields } from '@ecp/features/sales/shared/types';
import { isField } from '@ecp/features/sales/shared/utils/web';
import type { Field, Fields, Question } from '@ecp/types';

import { getQuestion } from '../inquiry';
import { isValueMasked } from '../inquiry/thunks/predicates';
import { errorExists } from '../inquiry/util/errorUtil';
import { setFormErrorsChanged, setFormErrorsChangedByField } from './actions';
import { getAllValues, getErrors, getField, getFieldValue } from './selectors';

interface ValidateFieldParams {
  key: string;
  question: Question;
  value: AnswerValue;
  dependentQuestionKeys?: string[];
}

interface ValidateQuestionParams {
  question: Question;
  value: AnswerValue;
  questionKey: string;
}

interface ValidateFieldsParams {
  fields: Fields;
  excludedFields: Field[];
  requiredOverrideFields: Field[];
}

export const getShownFields =
  ({ fields }: { fields: Fields | CoveragesFields }): ThunkAction<Fields> =>
  (...[, getState]) => {
    // Generate QuestionSet for fields
    const questionSet: QuestionSet = {};
    Object.values(fields).forEach((field) => {
      if (isField(field)) {
        const { question: questionOriginal, key } = field;
        const question = { ...questionOriginal };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const validatorQuestion: any = { ...question };
        validatorQuestion.type = validatorQuestion.answerType || 'String';
        questionSet[key] = validatorQuestion;
      }
    });

    // Evaluate question set to get the hide node value
    const evaluatedQuestionSet = evaluateQuestionSet(
      questionSet,
      getAllValues(getState()) as AnswerSet,
    );

    // Get the keys for questions that should be shown
    const questionKeysToShow = Object.keys(evaluatedQuestionSet).reduce((acc, key) => {
      if (!evaluatedQuestionSet[key].hide) acc.push(key);

      return acc;
    }, [] as string[]);

    // Filter the fields to get only shown
    const shownFields = fields
      ? Object.keys(fields)
          .filter((fieldName) => questionKeysToShow.includes(fields[fieldName]?.key as string))
          .reduce((obj, key) => {
            obj[key] = fields[key];

            return obj;
          }, {} as Fields | CoveragesFields)
      : {};

    return shownFields;
  };

export const getValidationErrors = ({
  question,
  valueProp,
  allValues,
  inquiryValidationError,
  questionKey,
}: {
  question: Question;
  valueProp: AnswerValue;
  allValues: Answers;
  inquiryValidationError?: string[];
  questionKey: string;
}): string[] => {
  // Skip validation for the question that doesn't exist in the SAPI inquiry object or our static question set
  // TODO This actually can be converted into referential comparison via `===`
  // as emptyQuestion is now a frozen object and all empty questions simply hold a reference to it.
  // This change however needs to be thoroughly tested.
  if (isEqual(question, emptyQuestion)) return emptyArray as unknown as string[];

  const value = typeof valueProp === 'string' ? valueProp.trim() : valueProp;
  if (isValueMasked(questionKey, { [questionKey]: value })) {
    return emptyArray as unknown as string[];
  }

  // TODO hopefully validator and SAPI will sync and use same version of question, but until then, we need to convert
  // For typings (not exported) check sapi-validator library
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const validatorQuestion: any = { ...question };
  validatorQuestion.type = validatorQuestion.answerType || 'String';

  if (validatorQuestion.required === 'true' || validatorQuestion.required === true) {
    validatorQuestion.required = true;
  } else if (validatorQuestion.required === 'false' || validatorQuestion.required === false) {
    validatorQuestion.required = false;
  } else if (typeof validatorQuestion.required === 'string') {
    try {
      validatorQuestion.required = JSON.parse(validatorQuestion.required);
    } catch (error) {
      const e = error as Error;

      datadogLog({
        logType: 'info',
        message: `encountered thunk error - ${e?.message}`,
        context: {
          logOrigin: 'libs/features/sales/shared/store/lib/src/form/thunks.ts',
          functionOrigin: 'getValidationErrors',
        },
        error: e,
      });
      validatorQuestion.required = false;
    }
  } else if (typeof validatorQuestion.required !== 'object') {
    validatorQuestion.required = false;
  }

  if (validatorQuestion.type === 'Reference' || validatorQuestion.type === 'Currency') {
    validatorQuestion.type = 'String';
  }
  delete validatorQuestion.answerType;
  delete validatorQuestion.questionType;

  // TODO value should not be undefined or null, typecasting for now
  const validationErrors: string[] = validateAnswer(
    validatorQuestion,
    value as string,
    allValues as AnswerSet,
  );
  const errors = validationErrors.map(
    (error) => error || `${question.label || 'field'} is invalid`,
  );
  // For field validation we need to combine both errors via sapi validator as well as errors we have stored
  // from our previous PATCH call of answers.
  if (inquiryValidationError && errorExists(questionKey)) {
    return [...errors, ...inquiryValidationError];
  }

  return errors;
};

export const validateQuestion =
  ({ question, value, questionKey }: ValidateQuestionParams): ThunkAction<boolean> =>
  (...[, getState]) => {
    const errors = getValidationErrors({
      question,
      valueProp: value,
      allValues: getAllValues(getState()),
      questionKey,
    });

    return errors.length === 0;
  };

export const validatePostBindField =
  ({ key, question, value }: ValidateFieldParams): ThunkAction<string[]> =>
  (dispatch, getState) => {
    const errors = getValidationErrors({
      question,
      valueProp: value,
      allValues: getPostBindAnswers(getState()),
      questionKey: key,
    });
    dispatch(setFormErrorsChangedByField({ key, errors }));

    return errors;
  };

export const evaluateValidationErrors = ({
  question,
  value,
  allValues,
  state,
  dispatch,
  inquiryValidationError,
  questionKey,
}: {
  question: Question;
  value: AnswerValue;
  allValues: Answers;
  state: RootStore;
  dispatch: AppDispatch;
  inquiryValidationError?: string[];
  questionKey: string;
}): string[] => {
  const errors = getValidationErrors({
    question,
    valueProp: value,
    allValues,
    inquiryValidationError,
    questionKey,
  }).map((error) => {
    if (error) {
      const keys = error.match(/@[\w\d.]+[\w\d]/g);
      if (keys) {
        return keys.reduce((newError, element) => {
          const refField = getField(state, {
            key: element.substr(1),
            dispatch,
          });
          if (refField?.question) {
            const replacementElement = refField.question.label || refField.question.description;
            const newErr = replacementElement
              ? newError.replace(element, replacementElement)
              : newError;

            return newErr;
          }

          return newError;
        }, error);
      }

      return error;
    }

    return error;
  });

  return errors;
};

export const validateFields =
  (props: ValidateFieldsParams): ThunkAction<Array<{ key: string; errors: string[] }>> =>
  (dispatch, getState) => {
    const { excludedFields, fields: fieldsProp, requiredOverrideFields } = props;
    const state = getState();
    const allValues = getAllValues(state);
    const validateForm = (fields: Fields): { key: string; errors: string[] }[] => {
      const allFieldErrors: { key: string; errors: string[] }[] = [];
      const inquiryValidationError = getErrors(state);

      Object.values(fields).forEach((field) => {
        if (isField(field)) {
          const { question: questionOriginal, key } = field;
          const value = allValues[key];
          const question = { ...questionOriginal };
          if (!excludedFields.find((excludedField) => excludedField.key === field.key)) {
            if (requiredOverrideFields.find((overrideField) => overrideField.key === field.key)) {
              question.required = 'true';
            }
            const errors = evaluateValidationErrors({
              question,
              value,
              allValues,
              state,
              dispatch,
              inquiryValidationError: inquiryValidationError[field.key],
              questionKey: field.key,
            });

            let combinedErrors = [...errors];

            // Making sure that we only accumulate errors for the
            // auto delta vin or license number field to avoid cross-form validation issues
            // !TODO This hardcoded conditional should be removed in favor of passing a conditional prop
            // FIXME: it looks like this conditional keeps growing. fix the problem instead!
            if (
              /\bdelta.+(vin|license.number)|INTERIOR_WALL|FLOOR\.TYPE|milesMyWayParticipant|ubiProgramPhone|businessOnPremises/.test(
                key,
              )
            ) {
              combinedErrors = [...combinedErrors, ...field.errors];
            }

            if (combinedErrors.length) {
              allFieldErrors.push({ key, errors: combinedErrors });
            }
          }
        } else {
          allFieldErrors.push(...validateForm(field as Fields));
        }
      });

      return allFieldErrors;
    };

    const allErrors = validateForm(fieldsProp);

    if (allErrors.length > 0) {
      /**
       * I want to be able to facet off of specific validation errors
       * and only those validation errors. This will basically return
       * an array of unique validation errors i.e. ['Required field', 'Invalid Selection']
       *
       * If there is ever a validation error that we want to create an alert on
       * this will allow us to do it easily.
       */
      const validationErrorsArray = allErrors
        .map((err) => err.errors)
        .reduce((curr, acc) => {
          curr.push(...acc);

          return curr;
        }, []);
      const validationErrors = unique(validationErrorsArray);
      /**
       * While it's entirely possible to pass allErrors as a straight array
       * and facet of of that, it's more useful to create an object because
       * we can create better/more specific facets.
       *
       * For example, if we want to watch a specific field, we will be able
       * to do so and see what Validation errors we get for that field.
       */
      datadogLog({
        logType: 'warn',
        message: 'Encountered form validation errors',
        context: {
          logOrigin: 'libs/features/sales/shared/store/lib/src/form/thunks.ts',
          functionOrigin: 'validateFields',
          validationErrors,
          validationFieldErrors: allErrors.reduce((acc, curr) => {
            /**
             * This will take out the dot notation on the key as well as changing the ref to a
             * more generic <id>. Let me give an example: driver.4ru2sm25.license.number
             *
             * This will mess up the faceting in DD because it will create a facet for the key
             * for that exact ref. Since refs are always unique it makes it pointless as well will
             * never get that facet again.
             *
             * Replacing the . with - just makes it easier to read in the DD dashboard, but is not
             * entirely necessary.
             */
            const fieldKey = curr.key
              .replace(/[.!?\\-]/gi, '-')
              .replace(/\d[a-zA-Z]\w{6}/gi, '<id>');
            acc[fieldKey] = curr.errors.join(',');

            return acc;
          }, {} as Record<string, string>),
        },
      });
    }

    const errs = allErrors.reduce((acc, { key, errors }) => {
      acc[key] = errors;

      return acc;
    }, {} as Record<string, string[]>);
    dispatch(setFormErrorsChanged(errs));

    return allErrors;
  };

export const validateField =
  ({ key, question, value, dependentQuestionKeys }: ValidateFieldParams): ThunkAction<string[]> =>
  (dispatch, getState) => {
    const state = getState();
    const inquiryValidationError = getErrors(state);
    /**
     * For CSUI-609 the main issue was that we were calling validateField which did not do any key replacement which Midvale requires.
     * Splitting that logic out into it's own function and then using it when we validate a single field should fix this and any other
     * key replacement issues.
     */
    const errors = evaluateValidationErrors({
      question,
      value,
      allValues: getAllValues(state),
      state,
      dispatch,
      questionKey: key,
    });

    dispatch(setFormErrorsChangedByField({ key, errors }));
    if (dependentQuestionKeys) {
      dependentQuestionKeys.forEach((questionKey) => {
        const dependentQuestion = getQuestion(questionKey)(getState());
        const dependentValue = castAnswerType(
          getFieldValue(getState(), questionKey),
          dependentQuestion.answerType,
        );
        if (dependentValue) {
          const dependentQuestionErrors = getValidationErrors({
            question: dependentQuestion,
            valueProp: dependentValue,
            allValues: getAllValues(getState()),
            inquiryValidationError: inquiryValidationError[questionKey],
            questionKey,
          });
          dispatch(
            setFormErrorsChangedByField({
              key: questionKey,
              errors: dependentQuestionErrors,
            }),
          );
        }
      });
    }

    return errors;
  };
