import { useCallback } from 'react';

import { Button } from '@ecp/features/sales/shared/components';
import type { OfferStatusCode } from '@ecp/features/sales/shared/constants';
import { getOfferProductsSelected } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import { getProductNameFromProduct, type ProductName } from '@ecp/features/shared/product';
import { IconCardAuto, IconCardHome, IconCardHomeAuto, IconCardRent } from '@ecp/themes/base';

import { useStyles } from './QuoteRetrieveCardContainer.styles';

export const HeaderIcon = ({
  productName,
}: {
  productName: ProductName | 'bundle';
}): JSX.Element | null => {
  const { classes } = useStyles();
  switch (productName) {
    case 'auto':
      return <IconCardAuto className={classes.cardTitleIcon} />;
    case 'home':
      return <IconCardHome className={classes.cardTitleIcon} />;
    case 'renters':
      return <IconCardRent className={classes.rentersCardTitleIcon} />;
    case 'bundle':
      return <IconCardHomeAuto className={classes.cardTitleIcon} />;
    default:
      return null;
  }
};

export const QuoteRetrieveButton = ({
  offerStatusCode,
  handleBuy,
  isRecallInProgress,
}: {
  offerStatusCode?: OfferStatusCode;
  handleBuy: (trackingLabel: string, offerProductsSelected: Product[]) => Promise<void>;
  isRecallInProgress?: boolean;
}): JSX.Element => {
  const { cx, classes } = useStyles();
  const offerProductsSelected = useSelector(getOfferProductsSelected);
  const handleButton = useCallback(() => {
    handleBuy('quote_retrive_recall_button', offerProductsSelected);
  }, [handleBuy, offerProductsSelected]);

  switch (offerStatusCode) {
    case 'CNQ':
      return <div className={cx(classes.unavailableRecallButton, classes.cnqButton)}>CNQ</div>;
    case 'DNQ':
      return <div className={cx(classes.unavailableRecallButton, classes.dnqButton)}>DNQ</div>;
    case 'PURCHASED':
      return (
        <div className={cx(classes.unavailableRecallButton, classes.successButton)}>
          PURCHASED POLICY
        </div>
      );
    case 'EXPIRED':
      return (
        <div className={cx(classes.unavailableRecallButton, classes.expiredButton)}>
          EXPIRED QUOTE
        </div>
      );
    default:
      return (
        <Button
          variant='primary'
          className={classes.recallButton}
          onClick={handleButton}
          isProcessing={isRecallInProgress}
          type='button'
          data-testid='quoteRetriveRecallButton'
        >
          RECALL QUOTE
        </Button>
      );
  }
};

export const getContainterProductName = (cardProduct: Product | ProductName): ProductName =>
  cardProduct.includes('.')
    ? getProductNameFromProduct(cardProduct as Product)
    : (cardProduct as ProductName);
