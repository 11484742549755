import { Stack } from '@mui/material';

import { HoursList, PhoneLink } from '@ecp/components';
import { partner } from '@ecp/partners';

import { useStyles } from './SessionExpiryPromptContactInfo.styles';

export const SessionExpiryPromptContactInfo: React.FC<{ alignment: 'left' | 'center' }> = ({
  alignment,
}) => {
  const alignItems = alignment === 'left' ? 'flex-start' : 'center';
  const { classes } = useStyles();

  return (
    <Stack spacing={2} alignItems={alignItems} className={classes.root}>
      <Stack alignItems={alignItems}>
        <p>Reach our licensed insurance experts for help at </p>
        <PhoneLink withUnderlinedLinkStyle number={partner.shared.salesPhoneNumber} />
      </Stack>
      {partner.shared.salesPhoneHours && <HoursList weeklyHours={partner.shared.salesPhoneHours} />}
    </Stack>
  );
};
