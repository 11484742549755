export const getSessionPromptEventDetail = (
  isSessionIdle: boolean,
  offerExist: boolean,
): string => {
  if (!isSessionIdle) {
    return 'version: Warning';
  }

  return !offerExist ? 'version: expired_no_offer' : 'version: expired_with_offer';
};
