import { emptyObject } from '@ecp/utils/common';

import { emptyQuestion, ENTITY_REF_REGEX, REF_REGEX } from '@ecp/features/sales/shared/constants';
import type { Answers } from '@ecp/features/sales/shared/types';
import type { Question, Questions } from '@ecp/types';

import type { QuestionsState } from '../types';

// Create ref map that contains id to ref map with immediate relation reference to an answer key
// For example, an incident answer key can have only related driver ref
// input: 'incident.4jsfse3s.description' should generate a map like the following
// { 'incident.id': 'incident.4jsfse3s', 'driver.id': 'driver.5swr4sr3'}
export const createEntityRefMap = (key: string, answers: Answers): Record<string, string> => {
  const refRegex = /\d[a-zA-Z]\w{6}/;
  const selfRef = key.match(ENTITY_REF_REGEX)?.[0] || '';
  const selfRefKey = selfRef?.replace(refRegex, '<id>');
  const result: Record<string, string> = Object.keys(answers).reduce(
    (pre: Record<string, string>, cur: string) => {
      if (String(answers[cur]).includes(selfRef)) {
        const ref = cur.match(ENTITY_REF_REGEX)?.[0] || '';
        const refKey = ref.replace(refRegex, '<id>');
        pre[refKey] = ref;
      }

      return pre;
    },
    {},
  );

  result[selfRefKey] = selfRef;

  return result;
};

// update validation criteria and required criteria with correct ref for template question
export const translateTemplateQuestion = (
  key: string,
  question: Question,
  answers?: Answers,
): Question => {
  if (!!answers && !!question && ENTITY_REF_REGEX.test(key)) {
    const needSwapRequiredCriteria =
      question.required && /\.<id>/.test(JSON.stringify(question.required));
    const needSwapValidationCriteria =
      question.criteria && /\.<id>/.test(JSON.stringify(question.criteria));
    const needSwapHideCriteria = question.hide && /\.<id>/.test(JSON.stringify(question.hide));

    const refMap =
      needSwapRequiredCriteria || needSwapValidationCriteria || needSwapHideCriteria
        ? createEntityRefMap(key, answers)
        : {};

    if (needSwapRequiredCriteria) {
      const requiredString = JSON.stringify(question.required);
      const newRequiredCriteria = Object.keys(refMap).reduce((criteria: string, key: string) => {
        const updatedCriteria = criteria.replaceAll(key, refMap[key]);

        return updatedCriteria;
      }, requiredString);
      const newRequired = JSON.parse(newRequiredCriteria);
      question = { ...question, required: newRequired };
    }

    if (needSwapValidationCriteria) {
      const criteriaString = JSON.stringify(question.criteria);
      const newCriteriaString = Object.keys(refMap).reduce((criteria: string, key: string) => {
        const updatedCriteria = criteria.replaceAll(key, refMap[key]);

        return updatedCriteria;
      }, criteriaString);
      const newCriteria = JSON.parse(newCriteriaString);
      question = { ...question, criteria: newCriteria };
    }

    if (needSwapHideCriteria) {
      const criteriaString = JSON.stringify(question.hide);
      const newCriteriaString = Object.keys(refMap).reduce((criteria: string, key: string) => {
        const updatedCriteria = criteria.replaceAll(key, refMap[key]);

        return updatedCriteria;
      }, criteriaString);
      const newCriteria = JSON.parse(newCriteriaString);
      question = { ...question, hide: newCriteria };
    }
  }

  return question;
};

export const getQuestionNoDefault = (
  key: string,
  questions: QuestionsState,
  questionKeyProp?: string,
  answers?: Answers,
): Question | undefined => {
  const {
    sapi: sapiQuestions = emptyObject as Questions,
    static: staticQuestions = emptyObject as Questions,
  } = questions;
  const questionKey = questionKeyProp || key.replace(REF_REGEX, '.<id>.');

  // Favor template question over applied question
  const question =
    sapiQuestions[questionKey] ||
    sapiQuestions[key] ||
    staticQuestions[questionKey] ||
    staticQuestions[key];

  // update validation criteria and required criteria with correct ref
  const translatedQuestion = translateTemplateQuestion(key, question, answers);

  return translatedQuestion;
};

export const getQuestionWithDefault = (
  key: string,
  questions: QuestionsState,
  questionKeyProp?: string,
  answers?: Answers,
): Question => {
  const questionNoDefault = getQuestionNoDefault(key, questions, questionKeyProp, answers);

  return questionNoDefault || (emptyQuestion as Question);
};
