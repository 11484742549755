import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'QuoteRetrieveCardContainer' })((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[50],
    boxShadow: `0px 3px 5px ${theme.palette.shadow.primary}`,
    marginBottom: 40,
    '&:last-child': {
      marginBottom: 0,
    },
  },
  parentCard: {
    paddingBottom: 20,
  },
  parentCardHeader: {
    borderBottom: 0,
    [theme.breakpoints.down('md')]: {
      paddingBottom: 0,
    },
  },
  subCard: {
    boxShadow: 'none',
    margin: '0 16px 20px 16px',
  },
  defaultBorderColor: {
    border: `1px solid ${theme.palette.other.border}`,
  },
  warningBorderColor: {
    border: `1px solid ${theme.palette.warning.main}`,
  },
  successBorderColor: {
    border: `1px solid ${theme.palette.success.main}`,
  },
  errorBorderColor: {
    border: `1px solid ${theme.palette.error.main}`,
  },
  cardHeader: {
    borderBottom: `1px solid ${theme.palette.other.divider}`,
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 15,
    },
  },
  monolineCardTitleContainer: {
    ...theme.typography.h4,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 5,
    width: '100%',
    maxWidth: 'fit-content',
    padding: '10px 10px 0px 0px',
    marginBottom: 16,
    textTransform: 'none',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      display: 'block',
      padding: '10px 10px 0px',
      marginBottom: 10,
    },
  },
  subCardTitle: {
    backgroundColor: 'initial',
  },
  cardTitle: {
    ...theme.typography.h4,
    margin: '0px 10px 10px 0px',
    textTransform: 'none',
    display: 'flex',
    '& h3': {
      paddingTop: 10,
      [theme.breakpoints.down('md')]: {
        borderRight: 0,
      },
    },
  },
  parentCardTitle: {
    marginBottom: 0,
    [theme.breakpoints.down('md')]: {
      marginBottom: 5,
    },
    '& h3': {
      borderRight: 0,
      margin: 0,
    },
  },
  cardTitleIcon: {
    ...theme.mixins.setColorSvg(theme.palette.grey[900]),
    margin: '0px 10px',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
  },
  rentersCardTitleIcon: {
    ...theme.mixins.setColorSvg(theme.palette.grey[900]),
    [theme.breakpoints.down('md')]: {
      marginLeft: -10,
    },
  },
  cardHeaderTitle: {
    display: 'flex',
  },
  cardHeaderQuoteId: {
    borderLeft: `1px solid ${theme.palette.other.border}`,
    margin: '0px 20px 0px 10px',
    height: 42,
    paddingLeft: 20,
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
      marginTop: -10,
      borderLeft: 0,
      paddingLeft: 0,
    },
    '& p': {
      margin: '12px 5px 0px 0px',
    },
  },
  headerRecallButton: {
    marginTop: 10,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  unavailableRecallButton: {
    ...theme.typography.body2Bold,
    width: 'fit-content',
    borderRadius: 25,
    padding: '5px 15px',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  cnqButton: {
    backgroundColor: theme.palette.warning.light,
  },
  dnqButton: {
    backgroundColor: theme.palette.error.light,
  },
  successButton: {
    backgroundColor: theme.palette.success.light,
  },
  expiredButton: {
    backgroundColor: theme.palette.grey[300],
  },
  recallButton: {
    height: 40,
    width: '100%',
  },
  recallPurchasedContainer: {
    textAlign: 'left',
  },
  recallPurchasedPolicy: {
    color: theme.palette.success.main,
  },
}));
