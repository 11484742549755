import type { AllConfigs } from '../../types.js';
import { introPageFlow as introPageFlowBase } from './metadata.js';

export const introPageFlow: AllConfigs = [
  ...introPageFlowBase,
  {
    executionOrder: 50,
    startEffectiveDate: '01/01/2021',
    description: 'Consumer Monoline Renters Intro Flow for Zillow',
    criteria: {
      baseExp: ['Renters'],
      expId: ['2844', '2845', '2874'],
    },
    deltas: [
      {
        operation: 'reset',
        target: 'navigationOrder',
        value: ['landing'],
      },
      {
        operation: 'reset',
        target: 'byPageId',
        value: {
          landing: {
            path: '/quote/landing',
            pageTitle: '',
          },
        },
      },
    ],
  },
];
