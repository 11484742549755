import type { AllConfigs } from '../../types.js';
import { productPageFlow as productPageFlowBase } from './metadata.js';

export const productPageFlow: AllConfigs = [
  ...productPageFlowBase,
  {
    executionOrder: 350,
    startEffectiveDate: '01/01/2021',
    description: 'Renters - Renters: Base menu structure with Property details page added',
    criteria: {
      baseExp: ['Agent'],
      flow: ['', 'FLOW.SHORT', 'FLOW.LONG'],
      product: ['homesite.renters'],
      expId: ['2811', '7192'],
    },
    config: {
      menuItems: [
        {
          text: 'Renters',
          pageId: 'discounts',
          path: '/quote/renters/discounts',
          subMenuItems: [
            {
              text: 'Rental property details',
              pageId: 'propertyDetails',
              path: '/quote/renters/propertyDetails',
            },
            {
              text: 'Discounts',
              pageId: 'discounts',
              path: '/quote/renters/discounts',
            },
          ],
        },
      ],
      navigationOrder: ['discounts'],
      byPageId: {
        propertyDetails: {
          path: '/quote/renters/propertyDetails',
          pageTitle: 'Rental property details',
        },
        discounts: {
          path: '/quote/renters/discounts',
          pageTitle: 'Renters Discounts',
        },
      },
    },
  },
];
