import { useEffect, useState } from 'react';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { AgentRecallSidebar } from '@ecp/features/sales/navigationbar';
import { Page } from '@ecp/features/sales/shared/components';
import {
  getDrivers,
  getOfferSetId,
  getRecalled,
  getUserSelection,
  setRecalled,
  updateOffers,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';

import { AgentDashboardForm as AgentDashboardFormRedesign } from '../AgentRetrieveDashBoard';
import { AgentDashboardForm } from './AgentDashboardForm';

export const AgentDashboardPage: React.FC = () => {
  const drivers = useSelector(getDrivers);
  const dispatch = useDispatch();
  const [isLoadingOffers, setIsLoadingOffers] = useState(false);
  const initialRecall = useSelector(getRecalled);
  const offerSetId = useSelector(getOfferSetId);
  const userSelection = useSelector(getUserSelection);
  const agentDashboardRedesign = flagValues[FeatureFlags.AGENT_DASHBOARD_REDESIGN];

  /*
  Handle back navigation for agent Dashboard
  Use case:
  In the agent flow from the table of offers, select an offer and navigate to coverages
  there is a patch on inquiries with the user selected offer(BUNDLED/UNBUNDLED)
  using the browser back button, navigate back to agent Dashboard
  For all offers to be available on back nav, the user selection needs to be cleared

  if product.userSelection !== null -> clear UserSelection And UpdateOffers
  */
  // TODO - Revisit this useEffect after SAPI resolves confusion regarding product.userSelection in answers
  useEffect(() => {
    const refreshOffers = async (): Promise<void> => {
      // If not initialRecall -> update Offers
      if (!initialRecall) {
        setIsLoadingOffers(true);
        if (offerSetId) {
          await dispatch(updateOffers({ clearUserSelection: !!userSelection }));
        }
        setIsLoadingOffers(false);
      } else {
        // else set Recalled flag to false -> initialRecall -> no update to offers required
        dispatch(setRecalled(false));
      }
    };

    refreshOffers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return agentDashboardRedesign ? (
    <Page
      title='Agent dashboard'
      sidebarProps={{
        drawer: <AgentRecallSidebar />,
      }}
      retrieveSidebar
    >
      <AgentDashboardFormRedesign isLoadingOffers={isLoadingOffers} drivers={drivers} />
    </Page>
  ) : (
    <Page title='Agent dashboard'>
      <AgentDashboardForm isLoadingOffers={isLoadingOffers} drivers={drivers} />
    </Page>
  );
};
