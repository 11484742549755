import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import type { Answers } from '@ecp/features/sales/shared/types';
import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { salesRequest } from '@ecp/features/sales/shared/utils/network';

import type {
  AnswersResponse,
  CheckPolicyTypeResponse,
  InquiryResponse,
  SubmitPolicyTypeResponse,
  SyncPeopleResponse,
} from './types';

// one file for each endpoint.
// in each file, provide methods for each method the endpoint accepts

export function getInquiry({
  dalSessionId,
}: {
  dalSessionId: string;
}): Promise<SalesResponse<InquiryResponse>> {
  return salesRequest({
    endpoint: `session/${dalSessionId}/quote/questions`,
  });
}

export function postInquiry({
  answers = {},
}: {
  answers?: Answers;
}): Promise<SalesResponse<InquiryResponse>> {
  const body = JSON.stringify(answers);
  const shouldUseCustomFetch = flagValues[FeatureFlags.CUSTOM_FETCH_RETRY_TIMEOUT];

  return salesRequest({
    endpoint: 'session',
    options: {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      // Retry configuration
      ...(shouldUseCustomFetch && {
        retry: {
          maxAttempts: 1, // Maximum number of retry attempts (total attempts = initial + 1 retry) for default network errors or timeout errors
        },
      }),
    },
  });
}

export function deleteInquiry({
  dalSessionId,
  refType,
  refId,
}: {
  dalSessionId: string;
  refType: string;
  refId: string;
}): Promise<SalesResponse<AnswersResponse>> {
  return salesRequest({
    endpoint: `session/${dalSessionId}/quote/answers/${refType}/${refId}`,
    options: {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

export function getAnswers({
  dalSessionId,
}: {
  dalSessionId: string;
}): Promise<SalesResponse<AnswersResponse>> {
  return salesRequest({
    endpoint: `session/${dalSessionId}/quote/answers`,
    options: {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

export function patchAnswers({
  dalSessionId,
  answers,
}: {
  dalSessionId: string;
  answers: Answers;
}): Promise<SalesResponse<AnswersResponse>> {
  const body = JSON.stringify(answers);

  return salesRequest({
    endpoint: `session/${dalSessionId}/quote/answers`,
    options: {
      body,
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

export function postPrefill({
  dalSessionId,
}: {
  dalSessionId: string;
}): Promise<SalesResponse<InquiryResponse>> {
  return salesRequest({
    endpoint: `session/${dalSessionId}/quote/prefill`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

export function postPolicyType({
  dalSessionId,
}: {
  dalSessionId: string;
}): Promise<SalesResponse<SubmitPolicyTypeResponse>> {
  return salesRequest({
    endpoint: `session/${dalSessionId}/quote/policy-type`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

export function postCheckPolicyType({
  dalSessionId,
  addressState,
}: {
  dalSessionId: string;
  addressState: string;
}): Promise<SalesResponse<CheckPolicyTypeResponse>> {
  const body = JSON.stringify({ state: addressState });

  return salesRequest({
    endpoint: `session/${dalSessionId}/quote/policy-type/available`,
    options: {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

export const postSessionRenew = ({
  dalSessionId,
}: {
  dalSessionId: string;
}): Promise<SalesResponse<unknown>> => {
  return salesRequest({
    endpoint: `session/${dalSessionId}/renew`,
    options: {
      method: 'POST',
    },
  });
};

export function postProofDraft({
  dalSessionId,
  section,
  policyTypes,
}: {
  dalSessionId: string;
  section: string;
  policyTypes?: string[];
}): Promise<SalesResponse<InquiryResponse>> {
  const body = JSON.stringify({ section, policyTypes });

  return salesRequest({
    endpoint: `session/${dalSessionId}/quote/proof-draft`,
    options: {
      body,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}

/**
 * Posts the sync people api call
 * @param dalSessionId Current dal session id
 * @returns A request to the dal for the sync people call
 */
export function postSyncPeople({
  dalSessionId,
}: {
  dalSessionId: string;
}): Promise<SalesResponse<SyncPeopleResponse>> {
  return salesRequest({
    endpoint: `session/${dalSessionId}/customer/sync-people`,
    options: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    },
  });
}
