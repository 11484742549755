export interface Metadata {
  currentAutoInsuranceText?: string;
  currentPolicyEndText?: string;
  currentPolicyLimitsHelpText: string;
  priorPolicyLimitsHelpText?: string;
  secondaryPolicyHolderHelpText: string;
  comprehensiveKey: string;
  collisionKey: string;
  priorInsuranceHistory: string;
}
/**
 * We need this workaround for now because SAPI doesn't have the capability to fix this on their side.
 * They can't remove the “driver.<id>.insurance.prior.*” questions because they are needed for the PNI.
 */

const metadata: Metadata = {
  currentPolicyLimitsHelpText:
    'Please choose the Bodily Injury Limits that are closest to what you currently have. This can be found on the declaration page of your current policy.',
  secondaryPolicyHolderHelpText:
    'The Secondary Named Insured has the same rights and duties as the Primary Named Insured and must also have an ownership interest in the vehicle being insured.',
  comprehensiveKey: 'COVERAGE.COMPREHENSIVE',
  collisionKey: 'COVERAGE.COLLISION',
  priorInsuranceHistory:
    'Sharing your auto insurance history helps get you the most accurate quote.',
};

export default metadata;
