import { trackErrorBoundary } from '@ecp/utils/analytics/tracking';
import { datadogLog } from '@ecp/utils/logger';
import type { Location } from '@ecp/utils/routing';

import { ErrorReason } from '@ecp/features/sales/shared/constants';
import type { GlobalError } from '@ecp/features/sales/shared/store/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

export const trackError = ({
  error,
  reason,
  text,
  navigationText,
  nextPage,
  customerId,
  dalSessionId,
  inquiryId,
  offerId,
  location,
  previousPage,
}: {
  error: GlobalError;
  text: string;
  reason?: string;
  dalSessionId?: string;
  inquiryId?: string;
  offerId?: string;
  previousPage?: string;
  nextPage?: string;
  location: Location;
  navigationText?: string;
  customerId?: string;
}): void => {
  const analyticsEventDetail = {
    OopsPageCategory: error.errorReason || ErrorReason.GLOBAL,
    OopsPageCode: error.name,
    RequestUrl: error.requestUrl,
    ErrorText: error.text || error.errorReasonMessage || text,
    UserShownText: reason,
    UserNavigationText: navigationText,
    NextPage: nextPage || previousPage || '/quote/landing',
    XTraceID: error.requestId,
    DalSessionId: dalSessionId,
    InquiryId: inquiryId,
    OfferId: offerId,
    OopsPagePath: location.pathname,
    // TODO Replace with CustomerId after checking with John Sarantos
    ChoiceID: customerId,
  };
  trackErrorBoundary({ label: reason });
  trackSapiAnalyticsEvent({
    element: 'choice.oopsPage.page',
    event: 'render',
    eventDetail: JSON.stringify(analyticsEventDetail),
  });
  datadogLog({
    logType: 'error',
    message: `${error.name} - ${
      error.text || text || error.message || error.errorReasonMessage || error.errorReason
    }, ${reason}`,
    context: {
      logOrigin: 'Oops Page',
      functionOrigin: 'SessionErrorStopPage',
      contextType: 'Oops Page',
      oopsPageCategory: error.errorReason || ErrorReason.GLOBAL,
      errorReason: error.errorReason,
      errorMessage: error.errorReasonMessage || error.message,
      errorText: error.text || text,
      errorType: error.name,
      oopsPagePath: location.pathname,
      navigationText,
      requestUrl: error.requestUrl,
      requestId: error.requestId,
      transactionId: error.transactionId,
      nextPage: nextPage || previousPage || '/quote/landing',
      offerId,
    },
  });
};
