import { useCallback, useState } from 'react';

import { CircularProgress, Grid } from '@mui/material';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { Card } from '@ecp/components';
import { env } from '@ecp/env';
import { Button, SaveAndExit } from '@ecp/features/sales/shared/components';
import { IconUIInfoCircle, IconUIRefresh } from '@ecp/themes/base';

import type { CheckoutData } from '../../../types';
import { useStyles } from './CheckoutCard.styles';
import { CheckoutCardBody } from './CheckoutCardBody';

export interface CheckoutCardProps {
  checkoutData: CheckoutData;
  buttonText?: string;
  buttonDisabled?: boolean;
  onCheckout?: () => void;
  shouldRecalc?: (value: boolean) => void;
  isPatchingAnswers?: boolean;
  recalculate?: boolean;
  onRecalc?: () => void;
  recalcButtonTrackingLabel?: string;
  retrieveOffer?: () => Promise<void>;
  isProcessing?: boolean;
  buttonTrackingName?: string;
  buttonTrackingLabel?: string;
  phoneNumberTrackingValue?: string;
  chatTrackingValue?: string;
  showError?: boolean;
  checkoutElement?: string;
  sapiAnalyticsSelectedOfferEventDetail?: string;
  isMVRActive?: boolean;
  showStateFees?: boolean;
  showCTAButton?: boolean;
  isCheckout?: boolean;
}

export const CheckoutCard: React.FC<CheckoutCardProps> = (props) => {
  const {
    checkoutData,
    onCheckout,
    buttonText,
    buttonDisabled,
    shouldRecalc,
    recalculate,
    onRecalc,
    recalcButtonTrackingLabel,
    retrieveOffer,
    isProcessing,
    buttonTrackingName,
    buttonTrackingLabel,
    sapiAnalyticsSelectedOfferEventDetail,
    checkoutElement,
    showError,
    isMVRActive,
  } = props;
  const { classes, cx } = useStyles();
  const [showProcess, setShowProcess] = useState(false);

  const recalculateClick = useCallback(async () => {
    if (shouldRecalc && retrieveOffer) {
      setShowProcess(true);
      await retrieveOffer();
      shouldRecalc(false);
      setShowProcess(false);
      onRecalc?.();
    }
  }, [shouldRecalc, retrieveOffer, onRecalc]);

  const body = (
    <div className={classes.body}>
      <CheckoutCardBody checkoutData={checkoutData} isMVRActive={isMVRActive} />
    </div>
  );

  const recalculateLayoutBody = (
    <Grid container className={classes.recalculateContainer}>
      <IconUIInfoCircle className={classes.infoIcon} />
      <p className={classes.recalculateBody} role='alert'>
        An update to your policy has been made. Please recalculate to get a more accurate quote.
      </p>
      {showProcess ? (
        <CircularProgress className={classes.infoIcon} />
      ) : (
        <Button
          variant='iconText'
          className={classes.recalculateButton}
          icon={<IconUIRefresh />}
          onClick={recalculateClick}
          trackingName='RecalculateButton'
          trackingLabel={recalcButtonTrackingLabel}
          data-testid='RecalculateButton'
        >
          Recalculate your premium
        </Button>
      )}
    </Grid>
  );

  const errorLayoutBody = (
    <Grid container className={classes.recalculateContainer}>
      <p className={classes.recalculateBody} role='alert'>
        Please review coverages and correct any errors
      </p>
    </Grid>
  );

  // Variant B: Remove “Save & Exit” link from the entire Checkout page
  const isVariantB = flagValues[FeatureFlags.SAVE_AND_EXIT] === 'VARIANT_B';

  // Variant C: Add save&exit link and text to “Important Information” block at bottom of checkout page.
  // Important Information will display only if the corresponding state has fraud warning
  const isVariantC = flagValues[FeatureFlags.SAVE_AND_EXIT] === 'VARIANT_C';

  const footer = (
    <Grid container className={classes.footer} justifyContent='center'>
      <Button
        variant='success'
        data-testid='sidePurchase'
        onClick={onCheckout}
        classes={{
          root: cx(classes.button),
        }}
        disabled={buttonDisabled}
        isProcessing={isProcessing}
        trackingName={buttonTrackingName}
        trackingLabel={buttonTrackingLabel}
        analyticsElement={checkoutElement}
        analyticsEventDetail={sapiAnalyticsSelectedOfferEventDetail}
      >
        {buttonText}
      </Button>
      {!env.static.isAgent && !isVariantB && !isVariantC && <SaveAndExit />}
    </Grid>
  );

  return (
    <div className={classes.cardContainer}>
      {showError && (
        <Card
          body={errorLayoutBody}
          divider={false}
          classes={{
            root: classes.recalculateCardContainer,
          }}
        />
      )}
      {!showError && recalculate && (
        <Card
          body={recalculateLayoutBody}
          divider={false}
          classes={{
            root: classes.recalculateCardContainer,
          }}
        />
      )}
      {!showError && !recalculate && (
        <Card classes={{ root: classes.card }} body={body} footer={footer} divider={false} />
      )}
    </div>
  );
};
