import { NavStatus } from '@ecp/features/sales/shared/constants';
import type { NavTracking } from '@ecp/features/sales/shared/store/types';

export const getNavbarStatus = (
  navTracking: NavTracking,
): { active: boolean; completed: boolean } => {
  return {
    active: navTracking.inProgress,
    completed: !navTracking.inProgress && navTracking.status === NavStatus.VALID,
  };
};
