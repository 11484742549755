import type { QuestionsUIMetadata } from '@ecp/features/sales/shared/types';
import {
  GraphicHomeDetailAttachedImageUrl,
  GraphicHomeDetailBasementImageUrl,
  GraphicHomeDetailBuiltinImageUrl,
  GraphicHomeDetailCarportImageUrl,
  GraphicHomeDetailClosedBasementImageUrl,
  GraphicHomeDetailComplexOrCustomImageUrl,
  GraphicHomeDetailCrawlspaceImageUrl,
  GraphicHomeDetailDetachedImageUrl,
  GraphicHomeDetailFlatImageUrl,
  GraphicHomeDetailGableImageUrl,
  GraphicHomeDetailGambrelImageUrl,
  GraphicHomeDetailHipImageUrl,
  GraphicHomeDetailMansardImageUrl,
  GraphicHomeDetailNoneImageUrl,
  GraphicHomeDetailNoSlopeImageUrl,
  GraphicHomeDetailOverHillImageUrl,
  GraphicHomeDetailsFloorTypeBrickOnFrameBareConcreteImageUrl,
  GraphicHomeDetailsFloorTypeBrickPaversImageUrl,
  GraphicHomeDetailsFloorTypeCarpetImageUrl,
  GraphicHomeDetailsFloorTypeCorkImageUrl,
  GraphicHomeDetailsFloorTypeHardwoodImageUrl,
  GraphicHomeDetailsFloorTypeHardwoodParquetImageUrl,
  GraphicHomeDetailsFloorTypeLaminateWoodImageUrl,
  GraphicHomeDetailsFloorTypeSheetVinylImageUrl,
  GraphicHomeDetailsFloorTypeSlateImageUrl,
  GraphicHomeDetailsFloorTypeStoneImageUrl,
  GraphicHomeDetailsFloorTypeTerrazzoImageUrl,
  GraphicHomeDetailsFloorTypeTileCeramicImageUrl,
  GraphicHomeDetailsFloorTypeTileMarbleGraniteImageUrl,
  GraphicHomeDetailShedImageUrl,
  GraphicHomeDetailsInterWallTypeAdobeImageUrl,
  GraphicHomeDetailsInterWallTypeBrickSolidImageUrl,
  GraphicHomeDetailsInterWallTypeConcreteBlockImageUrl,
  GraphicHomeDetailsInterWallTypeDrywallVeneerPlasterImageUrl,
  GraphicHomeDetailsInterWallTypeGlassBlockImageUrl,
  GraphicHomeDetailsInterWallTypeLathAndPlasterImageUrl,
  GraphicHomeDetailsInterWallTypeLogSolidImageUrl,
  GraphicHomeDetailsInterWallTypeStoneSolidImageUrl,
  GraphicHomeDetailsInterWallTypeStudsOnlyImageUrl,
  GraphicHomeDetailsKitchenCountertopConcretePouredImageUrl,
  GraphicHomeDetailsKitchenCountertopConcreteTerrazzoImageUrl,
  GraphicHomeDetailsKitchenCountertopCulturedMarbleImageUrl,
  GraphicHomeDetailsKitchenCountertopGraniteOrMarbleImageUrl,
  GraphicHomeDetailsKitchenCountertopImportedGraniteOrMarbleImageUrl,
  GraphicHomeDetailsKitchenCountertopNonePedestalSinkImageUrl,
  GraphicHomeDetailsKitchenCountertopPlasticLaminateFormicaImageUrl,
  GraphicHomeDetailsKitchenCountertopQuartzSilestoneImageUrl,
  GraphicHomeDetailsKitchenCountertopSolidSurfaceCorianImageUrl,
  GraphicHomeDetailsKitchenCountertopStainlessSteelImageUrl,
  GraphicHomeDetailsKitchenCountertopTileImageUrl,
  GraphicHomeDetailsKitchenCountertopWoodButcherBlockImageUrl,
  GraphicHomeDetailSlabImageUrl,
  GraphicHomeDetailSlopeImageUrl,
  GraphicHomeDetailsRoofMaterialAluminumCorrugatedImageUrl,
  GraphicHomeDetailsRoofMaterialAluminumShinglesImageUrl,
  GraphicHomeDetailsRoofMaterialAluminumStandingSeamImageUrl,
  GraphicHomeDetailsRoofMaterialArchitecturalShinglesImageUrl,
  GraphicHomeDetailsRoofMaterialAsphaltShinglesImageUrl,
  GraphicHomeDetailsRoofMaterialClayTileImageUrl,
  GraphicHomeDetailsRoofMaterialCompositionShinglesImageUrl,
  GraphicHomeDetailsRoofMaterialConcreteTileImageUrl,
  GraphicHomeDetailsRoofMaterialFoamImageUrl,
  GraphicHomeDetailsRoofMaterialRolledRoofImageUrl,
  GraphicHomeDetailsRoofMaterialRubberImageUrl,
  GraphicHomeDetailsRoofMaterialSlateImageUrl,
  GraphicHomeDetailsRoofMaterialSpanishTileImageUrl,
  GraphicHomeDetailsRoofMaterialSteelSidingImageUrl,
  GraphicHomeDetailsRoofMaterialSteelStandingSeamImageUrl,
  GraphicHomeDetailsRoofMaterialTarOrGravelImageUrl,
  GraphicHomeDetailsRoofMaterialTinMembraneImageUrl,
  GraphicHomeDetailsRoofMaterialWoodShinglesShakesImageUrl,
  GraphicHomeDetailStiltsImageUrl,
  GraphicHomeDetailsWallConstructionAdobeImageUrl,
  GraphicHomeDetailsWallConstructionAluminumOnMasonryImageUrl,
  GraphicHomeDetailsWallConstructionAluminumSidingImageUrl,
  GraphicHomeDetailsWallConstructionBarnPlankSidingImageUrl,
  GraphicHomeDetailsWallConstructionBrickOnFrameImageUrl,
  GraphicHomeDetailsWallConstructionBrickOnMasonryImageUrl,
  GraphicHomeDetailsWallConstructionClapboardImageUrl,
  GraphicHomeDetailsWallConstructionConcreteBlockImageUrl,
  GraphicHomeDetailsWallConstructionEIFSSyntheticStuccoImageUrl,
  GraphicHomeDetailsWallConstructionHardboardSidingImageUrl,
  GraphicHomeDetailsWallConstructionHardyPlankCementFiberImageUrl,
  GraphicHomeDetailsWallConstructionLogsImageUrl,
  GraphicHomeDetailsWallConstructionPineShinglesImageUrl,
  GraphicHomeDetailsWallConstructionSteelSidingImageUrl,
  GraphicHomeDetailsWallConstructionStoneOnFrameImageUrl,
  GraphicHomeDetailsWallConstructionStoneOnMasonryImageUrl,
  GraphicHomeDetailsWallConstructionStuccoOnFrameImageUrl,
  GraphicHomeDetailsWallConstructionStuccoOnMasonryImageUrl,
  GraphicHomeDetailsWallConstructionVinylOnMasonryImageUrl,
  GraphicHomeDetailsWallConstructionVinylSidingImageUrl,
  GraphicHomeDetailsWallConstructionWoodShakesImageUrl,
  GraphicHomeDetailsWallConstructionWoodSidingImageUrl,
  GraphicHomeDetailWalkinImageUrl,
} from '@ecp/themes/base';

import { FOUNDATION_TYPE } from '../constants/questions';

const propertyQuestionsUIMetadata: QuestionsUIMetadata = {
  'deck.<id>.type': {
    title: 'Type of deck',
    options: [
      {
        value: 'DECK_TYPE.WOOD',
        label: 'Wood',
      },
      {
        value: 'DECK_TYPE.COMPOSITE',
        label: 'Composite',
      },
    ],
  },
  'discount.property.centralOrDirect.alarm.burglar': {
    title: 'Discount for central / direct burglar alarm',
  },
  'discount.property.centralOrDirect.alarm.fire': {
    title: 'Discount for central / direct fire alarm',
  },
  'discount.property.indoorSprinkler': {
    title: 'Discount for indoor sprinkler',
  },
  'discount.property.newHomePurchase': {
    title: 'Discount for new home',
  },
  // TODO: Remove comment and feature flag this discount tile
  // 'discount.property.wildfireMitigation': {
  //   title: 'Discount for wildfire mitigation',
  // },
  'exteriorWall.<id>.constructionMaterial': {
    title: "What’s the home's primary exterior wall construction?",
    subtitle: 'Please select up to two exterior wall constructions.',
    options: [
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.ADOBE',
        label: 'Adobe',
        image: GraphicHomeDetailsWallConstructionAdobeImageUrl,
        alt: 'Adobe',
        helpText: 'Organic “bricks’ usually made from a combination of clay and straw.',
        sortingKey: 1,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.ALUMINUM_ON_MASONRY',
        label: 'Aluminum on masonry',
        image: GraphicHomeDetailsWallConstructionAluminumOnMasonryImageUrl,
        alt: 'Aluminum on masonry',
        helpText:
          'Lap or bevel aluminum siding laid either horizontally or vertically over the exterior wall. Attached to a masonry exterior wall.',
        sortingKey: 2,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.ALUMINUM_SIDING',
        label: 'Aluminum siding',
        image: GraphicHomeDetailsWallConstructionAluminumSidingImageUrl,
        alt: 'Aluminum siding',
        helpText:
          'Lightweight metal produced in long panels that can be smooth or textured. Installed horizontally and attached directly to the exterior framing.',
        sortingKey: 3,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.BARN_PLANK_SIDING',
        label: 'Barn plank siding',
        image: GraphicHomeDetailsWallConstructionBarnPlankSidingImageUrl,
        alt: 'Barn plank siding',
        helpText: 'Thick, rough cut plank siding installed vertically over the exterior wall.',
        sortingKey: 4,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.BRICK_ON_FRAME',
        label: 'Brick on frame',
        image: GraphicHomeDetailsWallConstructionBrickOnFrameImageUrl,
        alt: 'Brick on frame',
        helpText:
          'A home constructed of wood or metal frame. The exterior brick is for ornamentation purposes only, not structural support for the home.',
        sortingKey: 5,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.BRICK_ON_MASONRY',
        label: 'Brick on masonry',
        image: GraphicHomeDetailsWallConstructionBrickOnMasonryImageUrl,
        alt: 'Brick on masonry',
        helpText:
          'Exterior walls constructed of solid bricks, not a veneer. Bricks provide support for structure.',
        sortingKey: 6,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CEMENT_FIBER_SIDING',
        label: 'Cement fiber siding',
        image: GraphicHomeDetailsWallConstructionHardyPlankCementFiberImageUrl,
        alt: 'Cement fiber siding',
        helpText:
          'Cement-like product installed horizontally and attached directly to the exterior framing.',
        sortingKey: 7,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CLAPBOARD_SIDING',
        label: 'Clapboard siding',
        image: GraphicHomeDetailsWallConstructionClapboardImageUrl,
        alt: 'Clapboard siding',
        helpText:
          'Long thin wood pieces installed horizontally and attached directly to the exterior framing.',
        sortingKey: 8,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.CONCRETE_BLOCK',
        label: 'Concrete block',
        image: GraphicHomeDetailsWallConstructionConcreteBlockImageUrl,
        alt: 'Concrete block',
        helpText:
          'A home constructed of concrete block or masonry. The exterior block is usually painted and does not have any other exterior material covering the block. These types of homes are rarely constructed.',
        sortingKey: 9,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.HARDBOARD_SIDING',
        label: 'Hardboard siding',
        image: GraphicHomeDetailsWallConstructionHardboardSidingImageUrl,
        alt: 'Hardboard siding',
        helpText:
          'Cement-like product installed horizontally and attached directly to the exterior framing.',
        sortingKey: 10,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.LOG_SIDING',
        label: 'Log siding',
        image: GraphicHomeDetailsWallConstructionLogsImageUrl,
        alt: 'Log siding',
        helpText:
          'Rounded wood planks that attach directly to the exterior framing structure, and give the look of a log cabin. Usually made from pine.',
        sortingKey: 11,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.PINE_SHINGLES',
        label: 'Pine shingles',
        image: GraphicHomeDetailsWallConstructionPineShinglesImageUrl,
        alt: 'Pine shingles',
        helpText:
          'Wood shingles installed to create a decorative pattern. Prevalent in Cape Cod and Colonial homes. Typically, shingles are factory made.',
        sortingKey: 12,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STEEL_SIDING',
        label: 'Steel siding',
        image: GraphicHomeDetailsWallConstructionSteelSidingImageUrl,
        alt: 'Steel siding',
        helpText:
          'Steel siding is resistant to rot, insects, and fire damage. It is stronger, heavier, and more resistant to denting than aluminum. Susceptible to rusting.',
        sortingKey: 13,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STONE_ON_FRAME',
        label: 'Stone on frame',
        image: GraphicHomeDetailsWallConstructionStoneOnFrameImageUrl,
        alt: 'Stone on frame',
        helpText:
          'A home constructed of wood or metal frame. The exterior stone, such as granite, fieldstone, limestone or slate is for ornamentation purposes only, not structural support of the home.',
        sortingKey: 14,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STONE_ON_MASONRY',
        label: 'Stone on masonry',
        image: GraphicHomeDetailsWallConstructionStoneOnMasonryImageUrl,
        alt: 'Stone on masonry',
        helpText: 'Layer(s) of stone applied to specifically prepared masonry surfaces.',
        sortingKey: 15,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STUCCO_ON_FRAME',
        label: 'Stucco on frame',
        image: GraphicHomeDetailsWallConstructionStuccoOnFrameImageUrl,
        alt: 'Stucco on frame',
        helpText:
          'Made by mixing sand and lime with water and other ingredients - most often cement.',
        sortingKey: 16,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STUCCO_ON_FRAME_EIFS',
        label: 'Stucco on frame - EIFS',
        image: GraphicHomeDetailsWallConstructionEIFSSyntheticStuccoImageUrl,
        alt: 'Stucco on frame - EIFS',
        helpText:
          'Exterior Insulation and Finish Systems. Lightweight and synthetic. Has inner (foam insulation), middle (base coat) and exterior layers (finish coat).',
        sortingKey: 17,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.STUCCO_ON_MASONRY',
        label: 'Stucco on masonry',
        image: GraphicHomeDetailsWallConstructionStuccoOnMasonryImageUrl,
        alt: 'Stucco on masonry',
        helpText:
          'Made by mixing sand and lime with water and other ingredients - most often cement. Mixture is applied to specially prepared masonry surfaces.',
        sortingKey: 18,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.VINYL_ON_MASONRY',
        label: 'Vinyl on masonry',
        image: GraphicHomeDetailsWallConstructionVinylOnMasonryImageUrl,
        alt: 'Vinyl on masonry',
        helpText:
          'Lap or bevel solid vinyl siding laid either horizontally or vertically over a masonry exterior wall.',
        sortingKey: 19,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.VINYL_SIDING',
        label: 'Vinyl siding',
        image: GraphicHomeDetailsWallConstructionVinylSidingImageUrl,
        alt: 'Vinyl siding',
        helpText:
          'Strong, impact resistant planks. Installed horizontally and attached directly to the exterior framing.',
        sortingKey: 20,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.WOOD_SHAKES',
        label: 'Wood shakes',
        image: GraphicHomeDetailsWallConstructionWoodShakesImageUrl,
        alt: 'Wood shakes',
        helpText:
          'Shingles are sawn or handmade to create a uniform thickness. Shakes are a rough type of wooden shingle. Installed in horizontal, overlapping rows.',
        sortingKey: 21,
      },
      {
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL.WOOD_SIDING',
        label: 'Wood siding',
        image: GraphicHomeDetailsWallConstructionWoodSidingImageUrl,
        alt: 'Wood siding',
        helpText:
          'Types include board and batten, cypress/reclaimed plywood, cedar, pine and redwood.',
        sortingKey: 22,
      },
    ],
  },
  'exteriorWall.<id>.constructionMaterialPercentage': {
    title: 'Exterior Wall Construction Material Percentage',
    options: [
      {
        label: '25%',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL_PERCENTAGE.TWENTY_FIVE_PERCENT',
      },
      {
        label: '50%',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL_PERCENTAGE.FIFTY_PERCENT',
      },
      {
        label: '75%',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL_PERCENTAGE.SEVENTY_FIVE_PERCENT',
      },
      {
        label: '100%',
        value: 'EXTERIOR_WALL.CONSTRUCTION_MATERIAL_PERCENTAGE.ONE_HUNDRED_PERCENT',
      },
    ],
  },
  'exteriorWall.<id>.description': {
    title: 'Exterior Wall Construction Description',
  },
  'floor.<id>.percentage': {
    title: 'floor type percentage',
  },
  'floor.<id>.type': {
    title: `What are the home's floor types?`,
    subtitle: 'Select any that apply',
    options: [
      {
        value: 'FLOOR.TYPE.CARPET',
        label: 'Carpet',
        image: GraphicHomeDetailsFloorTypeCarpetImageUrl,
        alt: 'Carpet',
        helpText:
          'A thick, heavy textile floor-covering, usually made of synthetic fibers, knitted or needle-tufted fabric. It can be installed with carpet strip tacks, adhesive or staples.',
        sortingKey: 1,
      },
      {
        value: 'FLOOR.TYPE.LAMINATE_WOOD',
        label: 'Laminate wood',
        image: GraphicHomeDetailsFloorTypeLaminateWoodImageUrl,
        alt: 'Laminate wood',
        helpText:
          'A tongue and groove type of material placed over a thin foam pad to create a "floating" floor that is not nailed to the substrate. Laminate flooring is comprised of a thin top layer of resin-infused paper on top of wood-chip composite. Laminate thickness ranges from 1/4" to 7/16", depending on the manufacturer and quality of the flooring.',
        sortingKey: 2,
      },
      {
        value: 'FLOOR.TYPE.HARDWOOD',
        label: 'Hardwood',
        image: GraphicHomeDetailsFloorTypeHardwoodImageUrl,
        alt: 'Hardwood',
        helpText:
          'A finished board made from a hardwood material, such as oak or decorative wood, and is installed side by side with all of the wood lengths running the same direction. The boards may have a tongue and groove built in to the edges to provide a tighter fit with no gaps.',
        sortingKey: 3,
      },
      {
        value: 'FLOOR.TYPE.HARDWOOD_PARQUET',
        label: 'Hardwood parquet',
        image: GraphicHomeDetailsFloorTypeHardwoodParquetImageUrl,
        alt: 'Hardwood parquet',
        helpText:
          'Finished hardwood board made of small, inlaid, wood pieces, such as oak or decorative wood, installed in a series of small squares in a decorative pattern. The squares may have a tongue and groove built in to the edges to provide a tighter fit with no gaps.',
        sortingKey: 4,
      },
      {
        value: 'FLOOR.TYPE.TILE_CERAMIC',
        label: 'Tile ceramic',
        image: GraphicHomeDetailsFloorTypeTileCeramicImageUrl,
        alt: 'Tile ceramic',
        helpText:
          'Small squares (1" x 1" to 20" x 20" typically) of hard, brittle, heat-resistant and corrosion-resistant material, usually made of clay that has been baked at a high temperature. The individual squares are attached to the surface with a thin mortar and grout is used to fill the space between the individual pieces.',
        sortingKey: 5,
      },
      {
        value: 'FLOOR.TYPE.TILE_MARBLE_GRANITE',
        label: 'Tile marble granite',
        image: GraphicHomeDetailsFloorTypeTileMarbleGraniteImageUrl,
        alt: 'Tile marble granite',
        helpText:
          'These are marble or granite tiles that are attached to the floor/wall structure. All the edges are sealed with natural or colored grout. Marble tiles are available in a wide variety of colors and textures, and can originate from almost any country.',
        sortingKey: 6,
      },
      {
        value: 'FLOOR.TYPE.BARE_CONCRETE',
        label: 'Bare concrete',
        image: GraphicHomeDetailsFloorTypeBrickOnFrameBareConcreteImageUrl,
        alt: 'Bare concrete',
        helpText: 'An unfinished concrete floor with no additional covering. It may be painted.',
        sortingKey: 7,
      },
      {
        value: 'FLOOR.TYPE.STONE',
        label: 'Stone',
        image: GraphicHomeDetailsFloorTypeStoneImageUrl,
        alt: 'Stone',
        helpText:
          'Stone flooring is made of naturally occurring rock. It may be cut and polished to make tiles, or may be used as irregular shapes. Mortar is used to attach stone to the flooring substrate and grout is used to fill the spaces between individual pieces.',
        sortingKey: 8,
      },
      {
        value: 'FLOOR.TYPE.SLATE',
        label: 'Slate',
        image: GraphicHomeDetailsFloorTypeSlateImageUrl,
        alt: 'Slate',
        helpText:
          'Slate flooring is made of naturally occurring rock. It may be cut and polished to make tiles, or may be used as irregular shapes. Mortar is used to attach slate to the flooring substrate and grout is used to fill the spaces between individual pieces.',
        sortingKey: 9,
      },
      {
        value: 'FLOOR.TYPE.CORK',
        label: 'Cork',
        image: GraphicHomeDetailsFloorTypeCorkImageUrl,
        alt: 'Cork',
        helpText:
          'Cork is durable flooring that is a thermal and acoustical insulator; and is comfortable to walk on due to its shock-absorbing properties. Cork can be fine-grained, marbleized, or have a wood grain appearance; and is available in a variety of colors. Cork flooring also repels bugs, mold, and termites.',
        sortingKey: 10,
      },
      {
        value: 'FLOOR.TYPE.BRICK_PAVERS',
        label: 'Brick pavers',
        image: GraphicHomeDetailsFloorTypeBrickPaversImageUrl,
        alt: 'Brick pavers',
        helpText:
          'Molded rectangular blocks of clay used for floors. Brick floor material is solid with no holes.',
        sortingKey: 11,
      },
      {
        value: 'FLOOR.TYPE.TERRAZZO',
        label: 'Terrazzo',
        image: GraphicHomeDetailsFloorTypeTerrazzoImageUrl,
        alt: 'Terrazzo',
        helpText:
          'Epoxy or portland-cement-based epoxy with marble chips that is ground and polished to give the appearance of stone or marble.',
        sortingKey: 12,
      },
      {
        value: 'FLOOR.TYPE.SHEET_VINYL',
        label: 'Sheet vinyl',
        image: GraphicHomeDetailsFloorTypeSheetVinylImageUrl,
        alt: 'Sheet vinyl',
        helpText:
          'Sheets of inexpensive flooring made from polyvinyl chloride. May be inlaid, non-inlaid, have a hard or soft finish, and vary in the thickness depending on the overall quality selected.',
        sortingKey: 13,
      },
    ],
  },
  [FOUNDATION_TYPE]: {
    title: "What’s the home's primary foundation type?",
    subtitle: 'Please select up to two foundation types.',
    helpText:
      'The foundation is the lowest part of your home which makes contact with the ground. If your home has multiple types of foundations, select the type which is beneath the majority of your home. For example, if 80% of the home is on a Basement and 20% is on a Slab, you would select Basement as your Type of Foundation.',
    options: [
      {
        value: 'FOUNDATION.FOUNDATION_TYPE.BASEMENT_UNDERGROUND_BELOW_GRADE',
        label: 'Basement - underground / below grade',
        helpText:
          'A foundation type where the lowest story or level of a building below the main level, is almost entirely below ground level. The full height of the structural exterior walls are typically concrete, block or stone. Ceiling height is typically 6 to 8 feet. Newly constructed homes may have upgraded basement ceiling heights of 9 to 10 feet.',
        image: GraphicHomeDetailClosedBasementImageUrl,
        alt: 'Basement - underground/below grade',
        sortingKey: 1,
      },
      {
        value: 'FOUNDATION.FOUNDATION_TYPE.SLAB',
        label: 'Slab',
        helpText:
          'A concrete floor slab foundation is formed and poured on the existing ground surface or gravel as a foundation with a stem wall that extends down to the frost line.',
        image: GraphicHomeDetailSlabImageUrl,
        alt: 'Slab',
        sortingKey: 2,
      },
      {
        value: 'FOUNDATION.FOUNDATION_TYPE.ENCLOSED_CRAWL_SPACE_PIER_AND_BEAM',
        label: 'Enclosed crawl space/pier and beam',
        helpText:
          'A foundation that has an unfinished accessible area below the first floor of a structure, enclosed by the foundation walls with a dirt floor. The space is not habitable, and is used for access to pipes, wiring, etc. The height ranges from approximately one to four feet.',
        image: GraphicHomeDetailCrawlspaceImageUrl,
        alt: 'Enclosed crawl space/pier and beam',
        sortingKey: 3,
      },
      {
        value: 'FOUNDATION.FOUNDATION_TYPE.BASEMENT_DAYLIGHT_WALKOUT',
        label: 'Basement - daylight/walkout',
        helpText: `Daylight - this basement has window(s) that look out at or above ground
      level. Walkout - this basement has full size door(s) that allow direct access to a
      patio or lawn.`,
        image: GraphicHomeDetailWalkinImageUrl,
        alt: 'Basement - daylight/walkout',
        sortingKey: 4,
      },
      {
        value: 'FOUNDATION.FOUNDATION_TYPE.OPEN_STILTS_PIER',
        label: 'Open/stilts/pier',
        helpText:
          'Pier foundation is a grid system of girders (beams), piers, and footings used in construction to elevate the superstructure above the ground plane or grade. The piers serve as columns for the superstructure.',
        image: GraphicHomeDetailStiltsImageUrl,
        alt: 'Open/stilts/pier',
        sortingKey: 5,
      },
      {
        value: 'FOUNDATION.FOUNDATION_TYPE.SUSPENDED_OVER_HILLSIDE',
        label: 'Suspended over hillside',
        helpText:
          'A foundation system constructed of various size posts extending out of the ground surface. The posts are connected by level beams that the structure is built on. It is typically used for structures constructed on steep sloping property, in areas at risk for flooding, or in areas with unstable soil conditions.',
        image: GraphicHomeDetailOverHillImageUrl,
        alt: 'Suspended over hillside',
        sortingKey: 6,
      },
    ],
  },
  'foundation.<id>.percentage': {
    title: 'Foundation Percentage',
    options: [
      {
        label: '25%',
        value: 'FOUNDATION.PERCENTAGE.TWENTY_FIVE_PERCENT',
      },
      {
        label: '50%',
        value: 'FOUNDATION.PERCENTAGE.FIFTY_PERCENT',
      },
      {
        label: '75%',
        value: 'FOUNDATION.PERCENTAGE.SEVENTY_FIVE_PERCENT',
      },
      {
        label: '100%',
        value: 'FOUNDATION.PERCENTAGE.ONE_HUNDRED_PERCENT',
      },
    ],
  },
  'property.foundation.percentageFinished': {
    title: 'What percentage of your basement is finished?',
    options: [
      {
        label: '0%',
        value: 'FOUNDATION.PERCENTAGE_FINISHED.ZERO_PERCENT',
      },
      {
        label: '25%',
        value: 'FOUNDATION.PERCENTAGE_FINISHED.TWENTY_FIVE_PERCENT',
      },
      {
        label: '50%',
        value: 'FOUNDATION.PERCENTAGE_FINISHED.FIFTY_PERCENT',
      },
      {
        label: '75%',
        value: 'FOUNDATION.PERCENTAGE_FINISHED.SEVENTY_FIVE_PERCENT',
      },
      {
        label: '100%',
        value: 'FOUNDATION.PERCENTAGE_FINISHED.ONE_HUNDRED_PERCENT',
      },
    ],
  },
  'heatingSource.<id>.fuelType': {
    title: 'Fuel Type',
    options: [
      {
        label: 'Wood',
        value: 'FUEL_TYPE.WOOD',
      },
      {
        label: 'Pellet',
        value: 'FUEL_TYPE.PELLET',
      },
      {
        label: 'Gas',
        value: 'FUEL_TYPE.GAS',
      },
      {
        label: 'Corn',
        value: 'FUEL_TYPE.CORN',
      },
      {
        label: 'Coal',
        value: 'FUEL_TYPE.COAL',
      },
    ],
  },
  'heatingSource.<id>.locatedInDetatchedStructure': {
    title: 'locatedInDetatchedStructure',
  },
  'heatingSource.<id>.material': {
    title: 'Fireplace Type',
    options: [
      {
        label: 'Brick or stone (masonry)',
        value: 'BUILT_IN_FIREPLACE.FIREPLACE_TYPE.BRICK_OR_STONE_MASONRY',
      },
      {
        label: 'Masonry - custom design',
        value: 'BUILT_IN_FIREPLACE.FIREPLACE_TYPE.MASONRY_CUSTOM_DESIGN',
      },
      {
        label: 'Prefabricated/zero clearance',
        value: 'BUILT_IN_FIREPLACE.FIREPLACE_TYPE.PREFABRICATED_ZERO_CLEARANCE',
      },
      {
        label: 'Beehive (Kiva)',
        value: 'BUILT_IN_FIREPLACE.FIREPLACE_TYPE.BEEHIVE_KIVA',
      },
    ],
  },
  'heatingSource.<id>.type': {
    title: 'Type of heating source',
    options: [
      {
        label: 'Built-in fireplace',
        value: 'PROPERTY.HEATING_SOURCE.BUILT_IN_FIREPLACE',
      },
      {
        label: 'Fireplace insert',
        value: 'PROPERTY.HEATING_SOURCE.FIREPLACE_INSERT',
      },
      {
        label: 'Freestanding stove',
        value: 'PROPERTY.HEATING_SOURCE.FREE_STANDING_STOVE',
      },
    ],
  },
  'interiorWall.<id>.material': {
    title: `What are the home's interior wall materials?`,
    subtitle: 'Select any that apply',
    helpText:
      'Homes built prior to 1940 were built using wood lath and gypsum plaster walls. If renovations have taken place and the walls have been replaced, choose Drywall.',
    options: [
      {
        value: 'INTERIOR_WALL.INTERIOR_WALL_MATERIAL.DRYWALL_VENEER_PLASTER',
        label: 'Drywall/veneer plaster',
        image: GraphicHomeDetailsInterWallTypeDrywallVeneerPlasterImageUrl,
        alt: 'Drywall/veneer plaster',
        helpText:
          'An interior wall material with a gypsum core, heavy paper on one side and liner paper on the other. It is applied in large sheets, 48" wide and in lengths measured every 24". Also known as gypsum board, Sheetrock, and wallboard.',
        sortingKey: 1,
      },
      {
        value: 'INTERIOR_WALL.INTERIOR_WALL_MATERIAL.LATH_AND_PLASTER',
        label: 'Lath and plaster',
        image: GraphicHomeDetailsInterWallTypeLathAndPlasterImageUrl,
        alt: 'Lath and plaster',
        helpText:
          'Wood, metal, or gypsum product fastened to structural members to create a support for a layer of plaster. Thin-coat plaster is considered lath and plaster.',
        sortingKey: 2,
      },
      {
        value: 'INTERIOR_WALL.INTERIOR_WALL_MATERIAL.BRICK_SOLID',
        label: 'Brick solid',
        image: GraphicHomeDetailsInterWallTypeBrickSolidImageUrl,
        alt: 'Brick solid',
        helpText:
          'Should be selected when brick is the supporting structure of the wall instead of wood framing or block walls. In other words, the wall gets its strength from the brick. Solid brick may be found in older homes.',
        sortingKey: 3,
      },
      {
        value: 'INTERIOR_WALL.INTERIOR_WALL_MATERIAL.STONE_SOLID',
        label: 'Stone solid',
        image: GraphicHomeDetailsInterWallTypeStoneSolidImageUrl,
        alt: 'Stone solid',
        helpText:
          'Solid stone walls are both the exterior finish and the supporting walls of the structure and do not include any additional supporting elements, such as wood framing or concrete block walls. Solid stone walls are typically constructed with rubble or field stone.',
        sortingKey: 4,
      },
      {
        value: 'INTERIOR_WALL.INTERIOR_WALL_MATERIAL.LOG_SOLID',
        label: 'Log solid',
        image: GraphicHomeDetailsInterWallTypeLogSolidImageUrl,
        alt: 'Log solid',
        helpText: 'Refers to a solid log construction.',
        sortingKey: 5,
      },
      {
        value: 'INTERIOR_WALL.INTERIOR_WALL_MATERIAL.CONCRETE_BLOCK',
        label: 'Concrete block',
        image: GraphicHomeDetailsInterWallTypeConcreteBlockImageUrl,
        alt: 'Concrete block',
        helpText:
          'Pre-cast hollow rectangular concrete masonry units (CMU) of various widths and thickness usually bonded together with mortared joints. May or may not be reinforced with steel bars.',
        sortingKey: 6,
      },
      {
        value: 'INTERIOR_WALL.INTERIOR_WALL_MATERIAL.ADOBE',
        label: 'Adobe',
        image: GraphicHomeDetailsInterWallTypeAdobeImageUrl,
        alt: 'Adobe',
        helpText:
          'Construction found in the southwestern United States. It is a sun-dried clay that comes in various sizes and has a rough texture. It is often covered on the interior and exterior with mud plaster, lime plaster, whitewash, or stucco.',
        sortingKey: 7,
      },
      {
        value: 'INTERIOR_WALL.INTERIOR_WALL_MATERIAL.GLASS_BLOCK',
        label: 'Glass block',
        image: GraphicHomeDetailsInterWallTypeGlassBlockImageUrl,
        alt: 'Glass block',
        helpText:
          'A decorative barrier or wall that is made from textured, rippled, or semi-transparent glass blocks that allows light in without being able to see into the area.',
        sortingKey: 8,
      },
      {
        value: 'INTERIOR_WALL.INTERIOR_WALL_MATERIAL.STUDS_ONLY',
        label: 'Studs only',
        image: GraphicHomeDetailsInterWallTypeStudsOnlyImageUrl,
        alt: 'Studs only',
        helpText:
          'No wall material (drywall, lath and plaster) has been applied to the framed walls, therefore the wood or steel framing studs are exposed.',
        sortingKey: 9,
      },
    ],
  },
  'interiorWall.<id>.percentage': {
    title: 'Interior Wall Material Percentage',
  },
  'porch.<id>.type': {
    title: 'Type of porch',
    options: [
      {
        label: 'Enclosed',
        value: 'PORCH_TYPE.ENCLOSED',
      },
      {
        label: 'Open',
        value: 'PORCH_TYPE.OPEN',
      },
      {
        label: 'Screened',
        value: 'PORCH_TYPE.SCREENED',
      },
    ],
  },
  'property.airConditioning': {
    title: 'Does this home have central air conditioning?',
  },
  'property.businessOnPremises': {
    title:
      'Does anyone conduct business activity from the home, including commercial/retail, farming and child day care?',
  },
  'property.ceiling.cathedralOrVaulted': {
    title: 'How many rooms have cathedral or vaulted ceilings?',
    options: [
      {
        label: '0',
        value: 'CEILING.CATHEDRALORVAULTED.ZERO',
      },
      {
        label: '1',
        value: 'CEILING.CATHEDRALORVAULTED.ONE',
      },
      {
        label: '2',
        value: 'CEILING.CATHEDRALORVAULTED.TWO',
      },
      {
        label: '3',
        value: 'CEILING.CATHEDRALORVAULTED.THREE',
      },
      {
        label: '4',
        value: 'CEILING.CATHEDRALORVAULTED.FOUR',
      },
      {
        label: '5',
        value: 'CEILING.CATHEDRALORVAULTED.FIVE',
      },
      {
        label: '6',
        value: 'CEILING.CATHEDRALORVAULTED.SIX',
      },
      {
        label: '7',
        value: 'CEILING.CATHEDRALORVAULTED.SEVEN',
      },
      {
        label: '8',
        value: 'CEILING.CATHEDRALORVAULTED.EIGHT',
      },
      {
        label: '9+',
        value: 'CEILING.CATHEDRALORVAULTED.NINE_PLUS',
      },
    ],
  },
  'property.ceiling.height': {
    title: `What’s the home's average ceiling height?`,
    options: [
      {
        label: "8' or less",
        value: 'CEILING.HEIGHT.EIGHT_FEET_OR_LESS',
      },
      {
        label: "9'",
        value: 'CEILING.HEIGHT.NINE_FEET',
      },
      {
        label: "10' or more",
        value: 'CEILING.HEIGHT.TEN_FEET_OR_MORE',
      },
    ],
  },
  'property.ceiling.roomsWithCrownMolding': {
    title: 'How many rooms have crown molding?',
    options: [
      {
        label: '0',
        value: 'CEILING.ROOMSWITHCROWNMOLDING.ZERO',
      },
      {
        label: '1',
        value: 'CEILING.ROOMSWITHCROWNMOLDING.ONE',
      },
      {
        label: '2',
        value: 'CEILING.ROOMSWITHCROWNMOLDING.TWO',
      },
      {
        label: '3',
        value: 'CEILING.ROOMSWITHCROWNMOLDING.THREE',
      },
      {
        label: '4',
        value: 'CEILING.ROOMSWITHCROWNMOLDING.FOUR',
      },
      {
        label: '5',
        value: 'CEILING.ROOMSWITHCROWNMOLDING.FIVE',
      },
      {
        label: '6',
        value: 'CEILING.ROOMSWITHCROWNMOLDING.SIX',
      },
      {
        label: '7',
        value: 'CEILING.ROOMSWITHCROWNMOLDING.SEVEN',
      },
      {
        label: '8',
        value: 'CEILING.ROOMSWITHCROWNMOLDING.EIGHT',
      },
      {
        label: '9+',
        value: 'CEILING.ROOMSWITHCROWNMOLDING.NINE_PLUS',
      },
    ],
  },
  'property.fullBaths': {
    title: 'How many full baths are in the home?',
    options: [
      {
        label: '1',
        value: 'PROPERTY.FULL_BATHS.ONE',
      },
      {
        label: '2',
        value: 'PROPERTY.FULL_BATHS.TWO',
      },
      {
        label: '3',
        value: 'PROPERTY.FULL_BATHS.THREE',
      },
      {
        label: '4',
        value: 'PROPERTY.FULL_BATHS.FOUR',
      },
      {
        label: '5',
        value: 'PROPERTY.FULL_BATHS.FIVE',
      },
      {
        label: '6+',
        value: 'PROPERTY.FULL_BATHS.SIX_PLUS',
      },
    ],
  },
  'property.garage.size': {
    title: 'What size is the garage?',
    options: [
      {
        label: '1',
        value: 'GARAGE.SIZE.ONE_CAR',
      },
      {
        label: '1.5',
        value: 'GARAGE.SIZE.ONE_AND_HALF_CAR',
      },
      {
        label: '2',
        value: 'GARAGE.SIZE.TWO_CAR',
      },
      {
        label: '2.5',
        value: 'GARAGE.SIZE.TWO_AND_HALF_CAR',
      },
      {
        label: '3',
        value: 'GARAGE.SIZE.THREE_CAR',
      },
      {
        label: '3.5',
        value: 'GARAGE.SIZE.THREE_AND_HALF_CAR',
      },
      {
        label: '4',
        value: 'GARAGE.SIZE.FOUR_CAR',
      },
      {
        label: '4.5',
        value: 'GARAGE.SIZE.FOUR_AND_HALF_CAR',
      },
      {
        label: '5+',
        value: 'GARAGE.SIZE.FIVE_PLUS_CAR',
      },
    ],
  },
  'property.garage.type': {
    title: `What's the home's garage type?`,
    options: [
      {
        value: 'GARAGE.TYPE.ATTACHED_NO_LIVING_SPACE_ABOVE',
        label: 'Attached - no living space above',
        image: GraphicHomeDetailAttachedImageUrl,
        alt: 'Attached - no living space above',
        helpText: 'Directly attached to the house and no living area above it.',
      },
      {
        value: 'GARAGE.TYPE.BASEMENT',
        label: 'Basement',
        image: GraphicHomeDetailBasementImageUrl,
        alt: 'Basement',
        helpText: 'Built partially below ground with the entrance even with the basement floor.',
      },
      {
        value: 'GARAGE.TYPE.BUILT_IN_LIVING_SPACE_ABOVE',
        label: 'Built in - living space above',
        image: GraphicHomeDetailBuiltinImageUrl,
        alt: 'Built in - living space above',
        helpText: 'Built into the residence at ground level with a living area above it.',
      },
      {
        value: 'GARAGE.TYPE.CARPORT',
        label: 'Carport',
        image: GraphicHomeDetailCarportImageUrl,
        alt: 'Carport',
        helpText:
          'A flat or gable roofed shelter supported by posts, generally attached to the residence on one side.',
      },
      {
        value: 'GARAGE.TYPE.DETACHED',
        label: 'Detached',
        image: GraphicHomeDetailDetachedImageUrl,
        alt: 'Detached',
        helpText: 'Free standing and completely separate from your home.',
      },
      {
        value: 'GARAGE.TYPE.NONE',
        label: 'None',
        image: GraphicHomeDetailNoneImageUrl,
        alt: 'None',
        helpText: 'No garage present at home.',
      },
    ],
  },
  'property.halfBaths': {
    title: 'How many half baths are in the home?',
    options: [
      {
        label: '0',
        value: 'PROPERTY.HALF_BATHS.ZERO',
      },
      {
        label: '1',
        value: 'PROPERTY.HALF_BATHS.ONE',
      },
      {
        label: '2',
        value: 'PROPERTY.HALF_BATHS.TWO',
      },
      {
        label: '3',
        value: 'PROPERTY.HALF_BATHS.THREE',
      },
      {
        label: '4',
        value: 'PROPERTY.HALF_BATHS.FOUR',
      },
      {
        label: '5',
        value: 'PROPERTY.HALF_BATHS.FIVE',
      },
      {
        label: '6+',
        value: 'PROPERTY.HALF_BATHS.SIX_PLUS',
      },
    ],
  },
  'property.hasPool': {
    title: 'Pool on property',
    options: [
      {
        label: 'No pool on property',
        value: 'HAS_POOL.NO_POOL_ON_PROPERTY',
      },
      {
        label: 'Pool with fence or retaining wall less than four feet',
        value: 'HAS_POOL.POOL_WITH_FENCE_OR_WALL_LT_FOUR_FEET',
      },
      {
        label: 'Pool with fence or retaining wall four feet or higher',
        value: 'HAS_POOL.POOL_WITH_FENCE_OR_WALL_GT_FOUR_FEET',
      },
      {
        label: 'Pool in screened enclosure',
        value: 'HAS_POOL.POOL_IN_SCREENED_ENCLOSURE',
      },
      {
        label: 'Pool without fence or enclosure',
        value: 'HAS_POOL.POOL_WITHOUT_FENCE_OR_ENCLOSURE',
      },
      {
        label: 'Indoor pool',
        value: 'HAS_POOL.INDOOR_POOL',
      },
    ],
  },
  'property.heating.primaryType': {
    title: `What’s the home's primary heating type?`,
    options: [
      {
        label: 'Electric',
        value: 'HEATING.PRIMARY_TYPE.ELECTRIC',
      },
      {
        label: 'Gas hot air',
        value: 'HEATING.PRIMARY_TYPE.GAS_HOT_AIR',
      },
      {
        label: 'Central air',
        value: 'HEATING.PRIMARY_TYPE.CENTRAL_AIR',
      },
      {
        label: 'Gas hot water',
        value: 'HEATING.PRIMARY_TYPE.GAS_HOT_WATER',
      },
      {
        label: 'Propane hot air',
        value: 'HEATING.PRIMARY_TYPE.PROPANE_HOT_AIR',
      },
      {
        label: 'Oil hot water',
        value: 'HEATING.PRIMARY_TYPE.OIL_HOT_WATER',
      },
      {
        label: 'Propane hot water',
        value: 'HEATING.PRIMARY_TYPE.PROPANE_HOT_WATER',
      },
      {
        label: 'Wood',
        value: 'HEATING.PRIMARY_TYPE.WOOD',
      },
      {
        label: 'Oil hot air',
        value: 'HEATING.PRIMARY_TYPE.OIL_HOT_AIR',
      },
    ],
  },
  'property.homeBuiltOnSlope': {
    title: 'Is your home built on a slope?',
    helpText: `Select "Yes" if your property is built into a steep slope greater than 30 degrees. If this home is on a lot just steep enough to access the basement from outside, select "No."`,
    hasStaticOptions: true,
    options: [
      {
        value: 'true',
        label: 'Yes',
        image: GraphicHomeDetailSlopeImageUrl,
        alt: 'Yes, built on slope',
      },
      {
        value: 'false',
        label: 'No',
        image: GraphicHomeDetailNoSlopeImageUrl,
        alt: 'No, not built on slope',
      },
    ],
  },
  'property.kitchenCountertopMaterial': {
    title: 'What are the kitchen counters made of?',
    subtitle: 'Select any that apply',
    options: [
      {
        label: 'Plastic laminate (Formica)',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.PLASTIC_LAMINATE_FORMICA',
        image: GraphicHomeDetailsKitchenCountertopPlasticLaminateFormicaImageUrl,
        alt: 'Plastic laminate (Formica™)',
        helpText:
          'Plastic laminate countertops include a laminate (Formica®,Wilsonart®, or equivalent brand) glued to a high-density particle board substrate. The countertop may be "flat laid" with a beveled edge, or "post formed" with a rolled edge and integrated 4" back splash.',
      },
      {
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.SOLID_SURFACE_CORIAN',
        label: 'Solid surface (Corian™)',
        image: GraphicHomeDetailsKitchenCountertopSolidSurfaceCorianImageUrl,
        alt: 'Solid surface (Corian™)',
        helpText:
          'Solid surface countertops are a plastic resin (Corian®, Avonite®,Gibraltar®, Swanstone®, or equivalent brands) and may be solid-colored or variegated to look like granite or stone. They may also include an inlaid metal or wood strip on the edge.',
      },
      {
        label: 'Cultured marble',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.CULTURED_MARBLE',
        image: GraphicHomeDetailsKitchenCountertopCulturedMarbleImageUrl,
        alt: 'Cultured marble',
        helpText:
          'Cultured marble is man-made and contains polyester resins and crushed stone particles. Typically cast in gel-coated molds to create specific pieces.',
      },
      {
        label: 'Tile',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.TILE',
        image: GraphicHomeDetailsKitchenCountertopTileImageUrl,
        alt: 'Tile',
        helpText:
          'Ceramic tile countertops include the tile, mastic, grout, edging, and plywood substrate.',
      },
      {
        label: 'Concrete (Terrazzo)',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.CONCRETE_TERRAZZO',
        image: GraphicHomeDetailsKitchenCountertopConcreteTerrazzoImageUrl,
        alt: 'Concrete terrazzo',
        helpText:
          'Terrazzo is a composite construction material made by combining chips of aggregates such as marble or stone on top of a solid and level concrete foundation.',
      },
      {
        label: 'Concrete (Poured)',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.CONCRETE_POURED',
        image: GraphicHomeDetailsKitchenCountertopConcretePouredImageUrl,
        alt: 'Concrete poured',
        helpText:
          'Concrete countertops are cast, cured, stained and sealed, 1 1/2" thick with eased, square edges and high polished surface. Because of weight and complexity they are often formed and poured in place.',
      },
      {
        label: 'Quartz (Silestone)',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.QUARTZ_SILESTONE',
        image: GraphicHomeDetailsKitchenCountertopQuartzSilestoneImageUrl,
        alt: 'Quartz (Silestone™)',
        helpText:
          'Engineered quartz material with a natural stone appearance consisting of 95% natural quartz and 5% polymer resins.',
      },
      {
        label: 'Domestic granite or marble',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.DOMESTIC_GRANITE_OR_MARBLE',
        image: GraphicHomeDetailsKitchenCountertopGraniteOrMarbleImageUrl,
        alt: 'Domestic granite or marble',
        helpText:
          'Countertops made of solid granite, typically fabricated in ¾" solid sheets, with a rolled or beveled edge. They are available in many colors and grain patterns. Sinks are typically placed with an "under-mount" application.',
      },
      {
        label: 'Imported granite or marble',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.IMPORTED_GRANITE_OR_MARBLE',
        image: GraphicHomeDetailsKitchenCountertopImportedGraniteOrMarbleImageUrl,
        alt: 'Imported granite or marble',
        helpText: 'Imported Granite/Marble includes natural stones and natural materials.',
      },
      {
        label: 'Wood (Butcher block)',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.WOOD_BUTCHER_BLOCK',
        image: GraphicHomeDetailsKitchenCountertopWoodButcherBlockImageUrl,
        alt: 'Wood (butcher block)',
        helpText:
          'Wood countertops use pieces of hard rock maple strips that are laminated (glued) together to form a butcher block pattern. Once finished, the countertop is a solid wood surface, typically having a mineral oil finish.',
      },
      {
        label: 'Stainless steel',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.STAINLESS_STEEL',
        image: GraphicHomeDetailsKitchenCountertopStainlessSteelImageUrl,
        alt: 'Stainless steel',
        helpText:
          'A stainless steel countertop includes solid-sheet stainless steel with integral backsplash, wood backer, one sink cutout, square or rolled edges, and welded seams.',
      },
      {
        label: 'None (Pedestal sink)',
        value: 'PROPERTY.KITCHEN_COUNTERTOP_MATERIAL.NONE_PEDESTAL_SINK',
        image: GraphicHomeDetailsKitchenCountertopNonePedestalSinkImageUrl,
        alt: 'None (pedestal sink)',
        helpText: 'No kitchen counter top.',
      },
    ],
  },
  'property.livingArea': {
    title: 'Excluding the basement and garage, how many square feet is the home?',
  },
  'property.numberOfBalconies': {
    title: 'Number of balconies',
  },
  'property.numberOfStories': {
    title: 'Excluding the basement, how many stories is the home?',
    options: [
      {
        label: '1',
        value: 'NUMBER_OF_STORIES.ONE',
      },
      {
        label: '1.5',
        value: 'NUMBER_OF_STORIES.ONE_AND_HALF',
      },
      {
        label: '2',
        value: 'NUMBER_OF_STORIES.TWO',
      },
      {
        label: '2.5',
        value: 'NUMBER_OF_STORIES.TWO_AND_HALF',
      },
      {
        label: '3',
        value: 'NUMBER_OF_STORIES.THREE',
      },
      {
        label: '3.5',
        value: 'NUMBER_OF_STORIES.THREE_AND_HALF',
      },
      {
        label: '4+',
        value: 'NUMBER_OF_STORIES.FOUR_OR_MORE',
      },
    ],
  },
  'property.occupancyType': {
    title: 'Within 60 days of your policy start date how will you be using this home?',
  },
  'property.otherStructures': {
    title: 'Other Structures',
  },
  'property.roof.installOrReplace': {
    title: 'When was roof installed or replaced?',
  },
  'property.roof.material': {
    title: 'What’s the material on the roof?',
    options: [
      {
        value: 'ROOF.MATERIAL.ALUMINUM_CORRUGATED',
        label: 'Aluminum corrugated',
        image: GraphicHomeDetailsRoofMaterialAluminumCorrugatedImageUrl,
        alt: 'Aluminum corrugated',
        helpText:
          'A type of metal roof, which can be large metal paneled with heavily wavy ridges, smaller individual metal shingles or tiles, or standing seam panels, which have a seam at one end and a lock at the other, and are installed with clips.',
      },
      {
        value: 'ROOF.MATERIAL.ALUMINUM_SHINGLES',
        label: 'Aluminum shingles',
        image: GraphicHomeDetailsRoofMaterialAluminumShinglesImageUrl,
        alt: 'Aluminum shingles',
        helpText: 'Interlocking panels or shingles made from rust-resistant aluminum.',
      },
      {
        value: 'ROOF.MATERIAL.ALUMINUM_STANDING_SEAM',
        label: 'Aluminum, standing seam',
        image: GraphicHomeDetailsRoofMaterialAluminumStandingSeamImageUrl,
        alt: 'Aluminum, standing seam',
        helpText: 'Long, narrow aluminum roofing panels, joined with raised seams.',
      },
      {
        value: 'ROOF.MATERIAL.ARCHITECTURAL_SHINGLES',
        label: 'Architectural shingles',
        image: GraphicHomeDetailsRoofMaterialArchitecturalShinglesImageUrl,
        alt: 'Architectural shingles',
        helpText:
          'Factory installed designer asphalt/fiberglass shingles featuring multilayered construction, where random tabs or pads are applied to a base shingle to achieve added dimension and replicate the look of wood shakes. These shingles are sometimes referred to as laminated or three dimensional shingles.',
      },
      {
        value: 'ROOF.MATERIAL.ASPHALT_FIBERGLASS_SHINGLES',
        label: 'Asphalt/fiberglass shingles',
        image: GraphicHomeDetailsRoofMaterialAsphaltShinglesImageUrl,
        alt: 'Asphalt/fiberglass shingles',
        helpText:
          'Typically one layer of asphalt that is reinforced with fiberglass or organic felt and has 3 distinct breaks for each shingle. It creates a distinct symmetrical pattern resembling stacked bricks.',
      },
      {
        value: 'ROOF.MATERIAL.BUILT_UP_TAR_AND_GRAVEL',
        label: 'Built-up/tar and gravel',
        image: GraphicHomeDetailsRoofMaterialTarOrGravelImageUrl,
        alt: 'Built-Up/tar and gravel',
        helpText:
          'Typically installed on flat roofs. A layer of rocks or marble chips can be placed on top to protect the tar/gravel from the sun and add weight to protect the roof during a windstorm.',
      },
      {
        value: 'ROOF.MATERIAL.CLAY_TILE',
        label: 'Clay tile',
        image: GraphicHomeDetailsRoofMaterialClayTileImageUrl,
        alt: 'Clay tile',
        helpText:
          'A decorative material that is available in a variety of colors, shapes, patterns and textures.',
      },
      {
        value: 'ROOF.MATERIAL.COMPOSITION_SHINGLES',
        label: 'Composition shingles',
        image: GraphicHomeDetailsRoofMaterialCompositionShinglesImageUrl,
        alt: 'Composition shingles',
        helpText:
          'Made from a combination of materials such as asphalt or asbestos, tar paper, slate, shake, laminate and wood.',
      },
      {
        value: 'ROOF.MATERIAL.CONCRETE_TILE',
        label: 'Concrete tile',
        image: GraphicHomeDetailsRoofMaterialConcreteTileImageUrl,
        alt: 'Concrete tile',
        helpText:
          'Flat, barrel (or mission), and ""S"" tile are the common styles. It’s made primarily from sand, cement and water. The three main appearances are flat, low and high profile.',
      },
      {
        label: 'Foam',
        value: 'ROOF.MATERIAL.FOAM',
        image: GraphicHomeDetailsRoofMaterialFoamImageUrl,
        alt: 'Foam',
        helpText:
          'Insulating sprayed-on foam usually coated with an elastomeric membrane can be installed in various thicknesses to provide slope to drain or protect it from specified weather elements.',
      },
      {
        value: 'ROOF.MATERIAL.ROLLED_ROOF_SINGLE_PLY',
        label: 'Rolled roof/single ply',
        image: GraphicHomeDetailsRoofMaterialRolledRoofImageUrl,
        alt: 'Rolled roof/single ply',
        helpText:
          'A flexible pre-manufactured membrane usually made from rubber or plastic materials. Single-ply roofing comes in large rolls and is glued or mechanically fastened to a roof and sealed at all seams.',
      },
      {
        value: 'ROOF.MATERIAL.RUBBER',
        label: 'Rubber',
        image: GraphicHomeDetailsRoofMaterialRubberImageUrl,
        alt: 'Rubber',
        helpText:
          'A rubber roof is generally found on homes with a small pitch and is applied in rolls.',
      },
      {
        label: 'Slate',
        value: 'ROOF.MATERIAL.SLATE',
        image: GraphicHomeDetailsRoofMaterialSlateImageUrl,
        alt: 'Slate',
        helpText:
          'Arranged in overlapping rows. Found in unfading or weathering colors. Unfading colors stay close to their original color and weathering colors change as they age.',
      },
      {
        label: 'Spanish tile',
        value: 'ROOF.MATERIAL.SPANISH_TILE',
        image: GraphicHomeDetailsRoofMaterialSpanishTileImageUrl,
        alt: 'Spanish tile',
        helpText:
          'Type of clay tile. A decorative material that is available in a variety of colors, shapes, patterns and textures.',
      },
      {
        label: 'Steel siding',
        value: 'ROOF.MATERIAL.STEEL_SIDING',
        image: GraphicHomeDetailsRoofMaterialSteelSidingImageUrl,
        alt: 'Steel siding',
        helpText:
          'Steel siding is resistant to rot, insects, and fire damage. It is stronger, heavier, and more resistant to denting than aluminum. Susceptible to rusting.',
      },
      {
        label: 'Steel, standing seam',
        value: 'ROOF.MATERIAL.STEEL_STANDING_SEAM',
        image: GraphicHomeDetailsRoofMaterialSteelStandingSeamImageUrl,
        alt: 'Steel, standing seam',
        helpText: 'Long, narrow steel roofing panels, joined with raised seams.',
      },
      {
        label: 'Tin/membrane',
        value: 'ROOF.MATERIAL.TIN_MEMBRANE',
        image: GraphicHomeDetailsRoofMaterialTinMembraneImageUrl,
        alt: 'Tin/membrane',
        helpText:
          'Found in large sheets, fused at the joints to form a continuous surface. Long-lasting and UV resistant. Membrane may be classified as EPDM (Ethylene Propylene Diene Monomer) or PVC (Polyvinyl Chloride).',
      },
      {
        label: 'Wood shakes',
        value: 'ROOF.MATERIAL.WOOD_SHAKES',
        image: GraphicHomeDetailsWallConstructionWoodShakesImageUrl,
        alt: 'Wood shakes',
        helpText:
          'Cedar, redwood, southern pine and other woods of different shapes are installed to create a decorative pattern. Prevalent in Cape Cod and Colonial homes. Typically, shakes are handmade.',
      },
      {
        label: 'Wood shingles',
        value: 'ROOF.MATERIAL.WOOD_SHINGLES',
        image: GraphicHomeDetailsRoofMaterialWoodShinglesShakesImageUrl,
        alt: 'Wood shingles',
        helpText:
          'Cedar, redwood, southern pine and other woods of different shapes are installed to create a decorative pattern. Prevalent in Cape Cod and Colonial homes. Typically, shingles are factory made.',
      },
    ],
  },
  'property.roof.shape': {
    title: `What’s the home's roof shape?`,
    options: [
      {
        label: 'Gable',
        value: 'ROOF.SHAPE.GABLE',
        image: GraphicHomeDetailGableImageUrl,
        alt: 'Gable',
        helpText:
          'Roof style consisting of two sides that slope in opposite directions down from the peak or ridge. These sides may or may not have the same pitch. The roof ends form an inverted "V" and are filled in with triangular-shaped gable end walls.',
      },
      {
        label: 'Hip',
        value: 'ROOF.SHAPE.HIP',
        image: GraphicHomeDetailHipImageUrl,
        alt: 'Hip',
        helpText:
          'A roof which slopes upward from all four sides of a building, requiring a hip rafter at each corner. It typically resembles a "pyramid" or elongated pyramid.',
      },
      {
        label: 'Gambrel',
        value: 'ROOF.SHAPE.GAMBREL',
        image: GraphicHomeDetailGambrelImageUrl,
        alt: 'Gambrel',
        helpText:
          'Roof style consisting of two sides that meet at the ridge and slope in opposite directions. Each side has two sections, the lower section having a steeper slope than the upper section. The gambrel roof is often used on barns.',
      },
      {
        label: 'Flat',
        value: 'ROOF.SHAPE.FLAT',
        image: GraphicHomeDetailFlatImageUrl,
        alt: 'Flat',
        helpText:
          'Roofs considered to be flat have a minor slope which is not noticeable to most people. Because of its slight slope, conventional shingles cannot be used so they are typically covered with a built-up (hot mopped) rubber or vinyl membrane or with sprayed-on foam material.',
      },
      {
        label: 'Mansard',
        value: 'ROOF.SHAPE.MANSARD',
        image: GraphicHomeDetailMansardImageUrl,
        alt: 'Mansard',
        helpText:
          'A roof with two slopes on all four sides where the lower slope is very steep and the upper slope is almost flat. The second or uppermost story of the structure is typically surrounded by a very steep (almost vertical) wall covered with roof material.',
      },
      {
        label: 'Shed',
        value: 'ROOF.SHAPE.SHED',
        image: GraphicHomeDetailShedImageUrl,
        alt: 'Shed',
        helpText: 'Roof style with a single roof face that slopes in one direction only.',
      },
      {
        label: 'Complex/Custom',
        value: 'ROOF.SHAPE.COMPLEX_CUSTOM',
        image: GraphicHomeDetailComplexOrCustomImageUrl,
        alt: 'Complex/custom',
        helpText: 'A roof with multiple shapes or styles, intersections, slopes and projections.',
      },
    ],
  },
  'property.singleOrMultiFamily': {
    title: 'How many separate living units does your home have?',
    options: [
      {
        label: '1',
        value: 'SINGLE_OR_MULTI_FAMILY.SINGLE_FAMILY',
      },
      {
        label: '2',
        value: 'SINGLE_OR_MULTI_FAMILY.TWO_FAMILY',
      },
      {
        label: '3',
        value: 'SINGLE_OR_MULTI_FAMILY.THREE_FAMILY',
      },
      {
        label: '4',
        value: 'SINGLE_OR_MULTI_FAMILY.FOUR_FAMILY',
      },
      {
        label: '5+',
        value: 'SINGLE_OR_MULTI_FAMILY.FIVE_PLUS_FAMILY',
      },
    ],
  },
  'property.styleOfHome': {
    title: `What’s the home's style of construction?`,
  },
  'property.yearBuilt': {
    title: 'When was this home built?',
  },
  'property.solarPanels': {
    title: 'Does your home have solar panels attached to its roof?',
  },
  'property.numberOfSolarPanels': {
    title: 'How many solar panels are installed?',
    helpText:
      'You can find this number by counting the squares in the diagram on the red sticker the installer placed by your electrical meter.',
  },
  'homesite.home.coverages.coverageAAmount': {
    title: 'How much do you estimate it would cost to rebuild your home?',
  },
};

export default propertyQuestionsUIMetadata;
