import type { ReactElement } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
  Typography,
} from '@mui/material';

import { GridItem } from '@ecp/components';
import { useIsSniValue } from '@ecp/features/sales/quotes/auto';
import { getDrivers, getVehicles } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconCardMultiVehicle, IconUIDriver, IconUIExpandMore } from '@ecp/themes/base';

import { useStyles } from './AutoCardFooter.styles';

export const AutoCardFooter: React.FC = () => {
  const { classes } = useStyles();
  const vehicles = useSelector(getVehicles);
  const drivers = useSelector(getDrivers);

  const VehicleDriverHeader = (): JSX.Element => {
    return (
      <h4>
        Vehicles & drivers
        <span className={classes.vehiclesDriverAmounts}>
          ({vehicles.length} vehicle{vehicles.length > 1 && 's'}, {drivers.length} driver
          {drivers.length > 1 && 's'})
        </span>
      </h4>
    );
  };

  const AutoDetailsHeader = ({
    title,
    icon,
  }: {
    title: string;
    icon: ReactElement;
  }): JSX.Element => {
    return (
      <GridItem className={classes.autoSectionHeader}>
        {icon}
        <Typography variant='body1Bold'>{title}</Typography>
      </GridItem>
    );
  };

  const AutoDetails = ({
    name,
    details,
  }: {
    name: string;
    type: 'driver' | 'vehicle';
    details: string | JSX.Element;
  }): JSX.Element => {
    return (
      <GridItem xs={12} sm={12} md={6} className={classes.footerDetailsCard}>
        <Typography variant='body4'>{name}</Typography>
        <div className={classes.footerDetailsItem}>
          <Typography variant='body1Bold'>Garaged address</Typography>
          {details}
        </div>
      </GridItem>
    );
  };

  const DriverDetails = ({
    name,
    details,
    personRef,
  }: {
    name: string;
    details: string;
    personRef: string;
  }): JSX.Element => {
    const isSni = useIsSniValue(personRef);
    const driverDetails = isSni ? 'Secondary named insured' : details;

    return (
      <GridItem xs={12} sm={12} md={6} className={classes.footerDetailsCard}>
        <Typography variant='body4'>{name}</Typography>
        <div className={classes.footerDetailsItem}>{driverDetails}</div>
      </GridItem>
    );
  };

  return (
    <Accordion defaultExpanded={false} className={classes.footerAccordion}>
      <AccordionSummary expandIcon={<IconUIExpandMore />}>
        <VehicleDriverHeader />
      </AccordionSummary>
      <AccordionDetails className={classes.autoAccordionDetails}>
        <>
          <AutoDetailsHeader title='Vehicles' icon={<IconCardMultiVehicle />} />
          <Grid container className={classes.autoDetailsSection}>
            {vehicles.map((vehicle, i) => {
              const { garage, make, model } = vehicle;
              const { line1, line2, city, state, zipcode } = garage;
              const addressState = state.slice(-2);
              const displayAddressLine = `${line1}${line2 ? `, ${line2}` : ''}`;
              const displayCityStateZip = `${city}, ${addressState} ${zipcode}`;

              const addressinfo = (
                <p>
                  {displayAddressLine}
                  <br />
                  {displayCityStateZip}
                </p>
              );

              return (
                <AutoDetails
                  key={`${make}-${i}`}
                  name={`${make} ${model}`}
                  type='vehicle'
                  details={addressinfo}
                />
              );
            })}
          </Grid>
          <Divider />
          <AutoDetailsHeader title='Drivers' icon={<IconUIDriver />} />
          <Grid container className={classes.autoDetailsSection}>
            {drivers.map((driver, i) => {
              const { firstName, lastName, personRef } = driver;

              return (
                <DriverDetails
                  key={`${lastName}-${i}`}
                  name={`${firstName} ${lastName}`}
                  details={i === 0 ? 'Primary named insured' : 'Additional driver'}
                  personRef={personRef}
                />
              );
            })}
          </Grid>
        </>
      </AccordionDetails>
    </Accordion>
  );
};
