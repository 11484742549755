import { useCallback, useMemo, useState } from 'react';

import { Grid } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { Button, Dialog } from '@ecp/features/sales/shared/components';
import { NavStatus } from '@ecp/features/sales/shared/constants';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getAggregatePathTrackingStatus,
  getAllPagePathsExceptSummaryFlows,
  getBasicVehicles,
  getCurrentPage,
  getDrivers,
  getFlowsTrackingStatusTillQuote,
  getNavTracking,
  getPageFlowPaths,
  getPageFlowReferencePartialPaths,
  getProductFlowForLOB,
  getVehicleRefs,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { useIsMobile } from '@ecp/themes/base';

import { useStyles } from './DiscountsForm.styles';

const ButtonText: Record<LineOfBusiness, string> = {
  [LineOfBusiness.HOME]: 'Continue to Home',
  [LineOfBusiness.AUTO]: 'Continue to Auto',
  [LineOfBusiness.RENTERS]: 'Renters', // TODO specify correct value
  [LineOfBusiness.BUNDLE]: 'Bundle',
  [LineOfBusiness.BUNDLE_AUTO_RENTERS]: 'Bundle Auto + Renters',
};

type Props = React.ComponentProps<typeof Button> & {
  onSecondaryClick: React.ComponentProps<typeof Button>['onClick'];
  isFormValid: () => Promise<boolean>;
  setContinueClicked?: (isContinueClicked: boolean) => void;
};

export const DiscountsFormButton: React.FC<Props> = (props) => {
  const {
    disabled,
    variant = 'primary',
    isFormValid,
    onClick,
    onSecondaryClick,
    children,
    setContinueClicked,
    className,
    isProcessing,
    trackingLabel,
  } = props;

  const { classes } = useStyles();
  const isMobile = useIsMobile();
  const navTracking = useSelector(getNavTracking);
  const currentPath = useSelector(getCurrentPage);
  const productFlowsForLOB = useSelector(getProductFlowForLOB);
  const [productsIncompleteDialog, setProductsIncompleteDialog] = useState(false);
  const [showProductIncompleteSpinner, setShowProductIncompleteSpinner] = useState(false);
  const allProductPaths = useSelector((state: RootStore) =>
    getAllPagePathsExceptSummaryFlows(state, currentPath ? [currentPath] : undefined),
  );

  const drivers = useSelector(getDrivers);
  const vehicles = useSelector((state) => getBasicVehicles(state, getVehicleRefs(state)));

  const navTrackingStatus = useSelector((state: RootStore) => {
    const ignorePaths =
      drivers.length === 1 && vehicles.length === 1 ? [PagePath.DRIVER_ASSIGNMENT] : [];

    return getFlowsTrackingStatusTillQuote(
      state,
      currentPath ? [currentPath, ...ignorePaths] : ignorePaths,
    );
  });
  const nextPage = useMemo(() => {
    // Incase we can't find the correct path, just route back to person page so each page is validated again.
    let nextPage: string = PagePath.PERSON;
    if (navTrackingStatus.status !== NavStatus.VALID) {
      const path = allProductPaths.find(
        (path) => getAggregatePathTrackingStatus(navTracking, [path]).status !== NavStatus.VALID,
      );
      if (path) {
        nextPage = path;
      }
    }

    return nextPage;
  }, [allProductPaths, navTracking, navTrackingStatus.status]);

  const navigateToUnfinishedPage = useNavigateToPage(nextPage);

  const handleProductIncompleteDialogSubmit = useCallback(async () => {
    setShowProductIncompleteSpinner(true);
    await navigateToUnfinishedPage();
    setShowProductIncompleteSpinner(false);
  }, [navigateToUnfinishedPage]);

  const nextLOBProduct = useMemo((): LineOfBusiness | undefined => {
    const currentProductFlowLOB = productFlowsForLOB.find(
      (flow) =>
        getAggregatePathTrackingStatus(
          navTracking,
          getPageFlowPaths(flow.pageFlow),
          getPageFlowReferencePartialPaths(flow.pageFlow),
        ).inProgress,
    );

    const nextProductIndex = currentProductFlowLOB
      ? productFlowsForLOB.indexOf(currentProductFlowLOB) + 1
      : -1;

    return nextProductIndex === -1 || nextProductIndex >= productFlowsForLOB.length
      ? undefined
      : productFlowsForLOB[nextProductIndex].lob;
  }, [navTracking, productFlowsForLOB]);

  const handleSubmit = useCallback(
    async (
      event: React.MouseEvent<HTMLButtonElement> | undefined,
      onButtonClick: typeof onClick,
    ) => {
      setContinueClicked?.(true);
      if (await isFormValid()) {
        const hasIncompleteProducts =
          navTrackingStatus.status !== NavStatus.VALID && !nextLOBProduct;
        const handleIncompleteProducts: () => void | Promise<void> = env.static.isAgent
          ? () => setProductsIncompleteDialog(true)
          : // route consumer back to incomplete product if they manage to get out of
            // order and have incomplete pages (could happen with back nav)
            () => navigateToUnfinishedPage();

        if (hasIncompleteProducts) {
          await handleIncompleteProducts();
        } else if (event) {
          await onButtonClick?.(event);
        }
      }
    },
    [
      isFormValid,
      navTrackingStatus.status,
      navigateToUnfinishedPage,
      nextLOBProduct,
      setContinueClicked,
    ],
  );

  const handlePrimarySubmit = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement> | undefined) => handleSubmit(event, onClick),
    [handleSubmit, onClick],
  );

  const handleSecondarySubmit = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement> | undefined) =>
      handleSubmit(event, onSecondaryClick),
    [handleSubmit, onSecondaryClick],
  );

  const toggleProductsAddedDialog = useCallback(() => {
    setProductsIncompleteDialog((prev) => !prev);
  }, []);

  const isSecondarySubmitAvailable =
    env.static.isAgent && navTrackingStatus.status === NavStatus.VALID && nextLOBProduct;

  return (
    <>
      <Grid container>
        <GridItem xs={isMobile ? 12 : 0}>
          <Button
            disabled={disabled}
            variant={isSecondarySubmitAvailable ? 'outlinePrimary' : variant}
            onClick={handlePrimarySubmit}
            className={className}
            isProcessing={isProcessing}
            trackingLabel={trackingLabel}
            trackingName={GoogleAnalyticsLabels.CONTINUE}
          >
            {isSecondarySubmitAvailable ? ButtonText[nextLOBProduct] : children}
          </Button>
        </GridItem>

        {isSecondarySubmitAvailable && (
          <GridItem xs={isMobile ? 12 : 0} className={classes.secondaryButtonContainer}>
            <Button variant='primary' onClick={handleSecondarySubmit} className={className}>
              View Quote
            </Button>
          </GridItem>
        )}
      </Grid>

      <Dialog
        actionButtonLabel='Yes, continue'
        textButtonLabel='Cancel'
        titleText='Complete the product section'
        buttonPlacement='right'
        actionButtonOnClick={handleProductIncompleteDialogSubmit}
        textButtonOnClick={toggleProductsAddedDialog}
        open={productsIncompleteDialog}
        isProcessing={showProductIncompleteSpinner}
        onClose={toggleProductsAddedDialog}
      >
        <p>Complete the product section before viewing the quote.</p>
      </Dialog>
    </>
  );
};
