import { deepFreeze } from '@ecp/utils/common';

import type { Question } from '@ecp/types';

// policy
export const HOME_POLICY_START_DATE = 'policy.home.effectiveDate';
export const AUTO_POLICY_START_DATE = 'policy.auto.effectiveDate';
export const RENTERS_POLICY_START_DATE = 'policy.renters.effectiveDate';
export const HOME_POLICY_TERM = 'policy.home.policyTerm';
export const AUTO_POLICY_TERM = 'policy.auto.policyTerm';
export const RENTERS_POLICY_TERM = 'policy.renters.policyTerm';
export const AUTO_POLICY_OFFERTYPE = 'policy.auto.offerType';
export const HOME_POLICY_OFFERTYPE = 'policy.home.offerType';
export const RENTERS_POLICY_OFFERTYPE = 'policy.renters.offerType';
export const POLICY_EFFECTIVE_DATE_PARAM = 'static.policyEffectiveDateParam';

// primary
export const PRIMARY_INSURED_PERSON_REF = 'primaryInsured.person.ref';
export const PRIMARY_INSURED_ADDRESS_REF = 'primaryInsured.address.ref';
// https://amfament.atlassian.net/browse/EDSP-11020
// This Static question will store the primary insured risk address reference that UI gets from relate on initial launch of the UI
export const STATIC_INITIAL_PRIMARY_INSURED_ADDRESS_REF_FROM_RELATE =
  'static.initialPrimaryInsuredRiskAddressfromRelate.ref';
// This Static question will store the primary insured mailing address reference that UI gets from relate on initial launch of the UI
export const STATIC_INITIAL_PRIMARY_INSURED_MAILING_ADDRESS_REF_FROM_RELATE =
  'static.initialPrimaryInsuredMailingAddressfromRelate.ref';
// This Static question will store the new risk address reference that will be added as New Risk Address
export const STATIC_PREVIOUS_PRIMARY_INSURED_ADDRESS_REF =
  'static.previous.selected.primaryInsuredAddress.ref';

export const STATIC_PREVIOUS_PRIMARY_MAILING_INSURED_ADDRESS_REF =
  'static.previous.selected.primaryInsuredMailingAddress.ref';
export const PRIMARY_INSURED_MAILING_ADDRESS_REF = 'primaryInsured.mailing.address.ref';
export const PRIMARY_INSURED_PERSON_LOCK = 'primaryInsured.person.lock';
export const PRIMARY_INSURED_ADDRESS_LOCK = 'primaryInsured.address.lock';
export const PRIMARY_INSURED_DRIVER_REF = 'primaryInsured.driver.ref';
export const PRIMARY_INSURED_CREDIT_DISCLOSURE_FLAG = 'primaryInsured.person.creditDisclosureFlag';

// secondary
export const SECONDARY_INSURED_PERSON_REF = 'secondaryInsured.person.ref';
export const HOME_SECONDARY_INSURED_PERSON_REF = 'secondaryInsuredHome.person.ref';
export const RENTERS_SECONDARY_INSURED_PERSON_REF = 'secondaryInsuredRenters.person.ref';

// person
export const PERSON_REF = 'person.ref';
export const PNI_AGE_LONG_FLOW = 'static.pniAgeLongFlow';

// auto
export const DRIVERS_REF = 'driver.ref';
export const POLICY_VEHICLE_REF = 'vehicle.ref';

// Property
export const ROOF_INSTALL_OR_REPLACE = 'property.roof.installOrReplace';

// TPI
export const THIRD_PARTY_INTEREST_REF = 'thirdPartyInterest.ref';

// discount
export const DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_BURGLAR =
  'discount.property.centralOrDirect.alarm.burglar';
export const DISCOUNT_PROPERTY_CENTRAL_OR_DIRECT_ALARM_FIRE =
  'discount.property.centralOrDirect.alarm.fire';
export const DISCOUNT_PROPERTY_INDOOR_SPRINKLER = 'discount.property.indoorSprinkler';
export const DISCOUNT_PROPERTY_NEW_HOME_PURCHASE = 'discount.property.newHomePurchase';
export const DISCOUNT_PROPERTY_WILDFIRE_MITIGATION = 'discount.property.wildfireMitigation';

// Affiliation
export const AFFILIATION_REF_SUFFIX = 'affiliation.ref';

// Partner
export const PARTNER = 'static.partner';
export const PARTNER_SEGMENT = 'static.partner.segment';
export const PARTNER_ACCOUNT = 'static.partner.account';

// Line of business
export const LINE_OF_BUSINESS = 'product.lob';
export const LINE_OF_BUSINESS_AUTO = 'static.product.lob.auto';
export const LINE_OF_BUSINESS_HOME = 'static.product.lob.home';
export const LINE_OF_BUSINESS_RENTERS = 'static.product.lob.renters';
export const LANDING_PAGE_SELECTED_PRODUCT = 'static.selectedProduct';

// discount
export const DISCOUNT_AUTO_4YEAR_DEGREE = 'discount.auto.fourYearDegree';
export const DISCOUNT_AUTO_ANTI_THEFT = 'discount.auto.antiTheft';
export const DISCOUNT_AUTO_AWAY_AT_SCHOOL = 'discount.auto.awayAtSchool';
export const DISCOUNT_AUTO_DEFENSIVE_DRIVER = 'discount.auto.defensiveDriver';
export const DISCOUNT_AUTO_GOOD_STUDENT = 'discount.auto.goodStudent';
export const DISCOUNT_AUTO_KEPT_IN_GARAGE = 'discount.auto.keptInGarage';
export const DISCOUNT_AUTO_PAPERLESS = 'discount.auto.paperless';
export const DISCOUNT_AUTO_PAY = 'discount.auto.autoPay';
export const EXISTING_AUTO_PRODUCT = 'existingAutoProduct';
export const EXISTING_HOME_PRODUCT = 'existingHomeProduct';
export const DISCOUNT_MULTI_PRODUCT = 'discount.multiProduct';
export const DISCOUNT_PARTNER_AUTO_POLICY = 'discount.partnerAutoPolicy';

// offers
export const PREMIUM_TYPE_BUNDLED = 'Bundled';
export const PREMIUM_TYPE_UNBUNDLED = 'Unbundled';
export const DEFAULT_AUTO_POLICY_DURATION = '6';
export const DEFAULT_HOME_POLICY_DURATION = '12';

// home ownership
export const HOME_OWNERSHIP = 'residencyType';

/**
 * SAPI_NAMES_MAP will have the property names which are different in Choice UI vs SAPI.
 * For instance, Choice has dateOfBirth but in SAPI this property name is dob.
 */
export const SAPI_NAMES_MAP = { dateOfBirth: 'dob', maritalStatus: 'married' } as const;

// current page
export const CURRENT_PAGE = 'static.currentPage';
export const CURRENT_PAGE_ANALYTICS = 'analytics.currentPage';

// current page (or most recent page) that exists in the config service
export const CURRENT_CONFIG_PAGE = 'static.currentConfigPage';

// Keeps track of order products were adding to inquiry
export const LOB_ORDER = 'static.lineOfBusinessOrder';

// Product type selected by user
export const USER_SELECTION = 'product.userSelection';

/**
 * This is bottom of the funnel line of business selection narrowing down from Quote Summary page.
 * This is primarily needed to avoid local page state and props drilling, and to persist selection.
 */
export const PRODUCT_LOB_USER_SELECTION = 'static.product.lob.userSelection';

// List of eligible products determined by SAPI
export const SAPI_ELIGIBLE_PRODUCTS = 'sapi.premium.eligibleProducts';

export const INITIAL_OFFERS_REQUESTED = 'static.initialOffersRequested';
export const PARTNER_CHANNEL = 'partner.channel';

export const COSTCO_MEMBERSHIP_TIER = 'membership.costco.tier';

// Marketing
export const HOW_DID_YOU_HEAR_ABOUT_US = 'marketing.howDidYouHearAboutUs';
export const REFERENCE_NUMBER = 'marketing.referenceNumber';

// Embedded module static questions
export const HAS_USER_STARTED_IN_EM = 'static.hasUserStartedInEmbeddedModule';

// Lexis nexis questions
// TODO - Clean this up after these questions are merged on SAPI side
export const LEXIS_NEXIS_TRIGGER = 'analytics.variant.lexisNexisTrigger';
export const LEXIS_NEXIS_CALL = 'analytics.variant.call.lexisNexis';

export const ADDRESS_PREFILL_SUCCESSFUL = 'analytics.addressPrefillSuccessful';

// Used to store offer products selected on quote summary page
export const OFFER_PRODUCTS_SELECTED = 'static.offerProductsSelected';
// user selected payment plan
export const SELECTED_PAYMENT_PLAN = 'static.selectedPaymentPlan';
// ECP-3849
export const DECLINATION_NOTICE_DISPLAYED_PAGE_PATH = 'static.declinationNoticeDisplayedPagePath';

// ECP-12428 10-(business)-day (14 days) locking period for auto product
export const AUTO_LOCKING_PERIOD_DAYS = 'lockingPeriodDays.auto';

export const emptyQuestion = deepFreeze<Question>({
  answerType: 'String',
  questionType: 'provided',
  businessType: ['auto', 'home'],
  required: 'false',
});

// Losses and violations question for each product
export const AUTO_POLICY_HOUSEHOLD_LOSSES = 'policy.auto.lossesInThePastFiveYears';
export const AUTO_POLICY_POST_EFFECTIVE_DATE_LOSSES = 'delta.hasLossesOnOrAfterEffectiveDateInd';
export const HOME_POLICY_HOUSEHOLD_LOSSES = 'policy.home.lossesInThePastFiveYears';
export const RENTERS_POLICY_HOUSEHOLD_LOSSES = 'policy.renters.lossesInThePastFiveYears';
