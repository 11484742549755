import { logPageView } from '@ecp/utils/analytics/tracking';
import { datadogLog, trackDataDogCustomView } from '@ecp/utils/logger';

import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

export const trackPromptRender = (eventDetail: string, isSessionExpired: boolean): void => {
  isSessionExpired
    ? logPageView('session-timeout-expired ', 'Session Timeout Expired')
    : logPageView('session-timeout-warning', 'Session Timeout Warning');
  trackSapiAnalyticsEvent({
    element: isSessionExpired ? 'sessionTimeoutModal.expiration' : 'sessionTimeoutModal.modal',
    event: 'render',
    eventDetail: eventDetail,
  });
  trackDataDogCustomView('SessionExpiryTimerView');
  datadogLog({
    logType: 'info',
    message: 'SessionExpiry Loaded',
    context: {
      logOrigin:
        'libs/features/sales/shared/components/src/SessionExpiryTimer/util/trackPromptRender.ts',
      functionOrigin: 'trackPromptRender',
      view: 'SessionExpiryView',
    },
  });
};
