import { useEffect, useState } from 'react';

import { useIdleTimer } from 'react-idle-timer';

import { useEvent } from '@ecp/utils/react';

const TIMEOUT_MS = 1000 * 60 * 60; // 1 hour
const SHOW_PROMPT_AFTER_INACTIVITY_PERIOD_MS = 1000 * 60 * 58; // 58 minutes

export const useSessionExpiryPromptTimer = ({
  enableTimer = false,
}: {
  enableTimer: boolean;
}): {
  showPrompt: boolean;
  isSessionExpired: boolean;
  remaining: number;
  resetTimer: () => void;
} => {
  const [showPrompt, setShowPrompt] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);

  const onPrompt = (): void => {
    setIsSessionExpired(false);
    setShowPrompt(true);
  };

  const onAction = (): void => {
    setIsSessionExpired(false);
  };

  const onIdle = (): void => {
    setIsSessionExpired(true);
  };

  const [remaining, setRemaining] = useState<number>(TIMEOUT_MS);

  const { getRemainingTime, reset } = useIdleTimer({
    timeout: TIMEOUT_MS,
    promptBeforeIdle: TIMEOUT_MS - SHOW_PROMPT_AFTER_INACTIVITY_PERIOD_MS,
    onPrompt,
    onAction,
    onIdle,
    stopOnIdle: true,
    disabled: !enableTimer,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const resetTimer = useEvent(() => {
    setShowPrompt(false);
    reset();
    setRemaining(0);
  });

  return {
    showPrompt,
    isSessionExpired,
    remaining,
    resetTimer,
  };
};
