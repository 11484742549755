import { lowerFirst, upperFirst } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';

import { env } from '@ecp/env';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

type Event = 'resume' | 'pause';

interface GenesysData {
  operation: Event;
  origin: string;
  email: string;
}

export const callRecording = (event: Event, agentUserName: string, componentName: string): void => {
  const isGenesysCloud = flagValues[FeatureFlags.AGENT_GENESYS_CLOUD_RECORDING];
  const genesysMethod = isGenesysCloud ? 'genesysCloud' : 'genesysWebSocket';

  const data: GenesysData = {
    operation: event,
    origin: env.static.applicationName,
    email: agentUserName,
  };

  datadogLog({
    logType: 'info',
    message: `${agentUserName} agent callRecording using ${genesysMethod} to ${event} recording`,
    context: {
      logOrigin: 'libs/features/sales/shared/components/src/CallRecordingwrapper/utils.ts',
      functionOrigin: 'callRecording',
      agent: agentUserName,
      event: event,
    },
  });

  trackSapiAnalyticsEvent({
    element: `${lowerFirst(componentName)}.genysysCallRecording${upperFirst(event)}`,
    event: 'update',
    eventDetail: JSON.stringify(data),
  });
  isGenesysCloud ? webCloudRecording(data) : webSocketRecording(data);
};

const webSocketRecording = (data: GenesysData): void => {
  const gws = new WebSocket(env.callRecordingServiceUrl);

  gws.onopen = () => {
    gws.send(JSON.stringify(data));
  };
};

const webCloudRecording = async (data: GenesysData): Promise<void> => {
  const { email, operation } = data;
  const endpoint = `${env.genesysCloudApiUrl}/recording/${operation}`;
  const body = {
    emailAddress: email,
  };

  const options: RequestInit = {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      'afi-appname': env.afiAppname,
      'afi-api-key': env.afiApiKey,
      'Content-Type': 'application/json',
      Authorization: '', // TODO
    },
  };
  const response = await fetch(endpoint, options);

  if (response.status !== 200) {
    datadogLog({
      logType: 'error',
      message: `Error in POST against ${endpoint}`,
      context: {
        logOrigin: 'libs/features/sales/shared/components/src/CallRecordingwrapper/utils.ts',
        functionOrigin: 'webCloudRecording',
      },
    });
  }
};
