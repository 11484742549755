import type { AllConfigs } from '../../types.js';
import { thirdPartyInterestPageFlow as thirdPartyInterestPageFlowBase } from './metadata.js';

export const thirdPartyInterestPageFlow: AllConfigs = [
  ...thirdPartyInterestPageFlowBase,
  {
    executionOrder: 20,
    startEffectiveDate: '01/01/2021',
    description: 'Third-Party Interest page flow for amfam advance experiences',
    criteria: {
      baseExp: ['Agent'],
      product: ['amfam-adv.auto', 'amfam-adv.home'],
      expId: ['2860'],
    },
    config: {
      menuItems: [
        {
          text: 'Third Party Interest',
          path: '/app/tpi',
          pageId: 'tpi',
        },
      ],
      navigationOrder: ['tpi'],
      byPageId: {
        tpi: {
          path: '/app/tpi',
          pageTitle: 'Third-Party Interest (TPI)',
        },
      },
    },
  },
  {
    executionOrder: 30,
    startEffectiveDate: '01/01/2021',
    description: 'Third-Party Interest page flow for amfam advance experiences',
    criteria: {
      baseExp: ['Agent'],
      product: ['amfam-adv.renters'],
      expId: ['2860'],
    },
    config: {},
  },
];
