import { useEffect } from 'react';

import { Divider, Grid } from '@mui/material';

import { useEvent } from '@ecp/utils/react';
import { useLocation } from '@ecp/utils/routing';

import { env } from '@ecp/env';
import { Button, DevDiagnostic } from '@ecp/features/sales/shared/components';
import { useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  getCustomerId,
  getDalSessionId,
  getGlobalError,
  getInquiryId,
  getOfferSetId,
  getPreviousPage,
  getPrimaryInsuredStateCode,
  updateGlobalError,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUIArrowLeft } from '@ecp/themes/base';

import { useStyles } from './GlobalError.styles';
import { getErrorPageMetadata } from './metadata';
import { trackError } from './util';

interface GlobalErrorPageProps {
  text: string;
  unsetError(): void;
  clearErrorHash(): void;
}

export const GlobalError: React.FC<GlobalErrorPageProps> = (props) => {
  const { text, unsetError, clearErrorHash } = props;
  const { classes } = useStyles();

  const dispatch = useDispatch();
  const previousPage = useSelector(getPreviousPage);
  const error = useSelector(getGlobalError);
  const dalSessionId = useSelector(getDalSessionId);
  const inquiryId = useSelector(getInquiryId);
  const location = useLocation();
  const offerId = useSelector(getOfferSetId);
  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const customerId = useSelector(getCustomerId);

  const {
    title,
    subtitle,
    reason,
    customReason,
    guidance,
    navigationText,
    nextPage,
    returnButtonTrackingName,
    errorImage,
    errorDetails,
  } = getErrorPageMetadata(error.errorReason, stateCode);

  // replace: true, so that the page which caused the error
  // is not there is history, also don't save previousPage
  const navigateToPreviousPage = useNavigateToPage(nextPage || previousPage || '/quote/landing', {
    replace: true,
    removeQuery: false,
    replaceProfileAdd: false,
  });

  const handleSubmit = useEvent(() => {
    dispatch(updateGlobalError({ hasError: false, requestId: '', transactionId: '' }));
    unsetError();
    clearErrorHash();
    navigateToPreviousPage();
  });

  const shouldShowDiagnosticsDetails =
    (env.static.isAgent || env.static.nodeEnv !== 'production') && reason;
  const pageTitle = `${title} ${subtitle || ''}`;
  const renderReason = customReason || (reason as string);

  useEffect(() => {
    trackError({
      error,
      reason,
      text,
      navigationText,
      nextPage,
      customerId,
      dalSessionId,
      inquiryId,
      offerId,
      location,
      previousPage,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid container item xs={12} md={6}>
          <Grid item xs={11}>
            <h1 className={classes.pageTitle}>{pageTitle}</h1>
            <Divider aria-hidden='true' className={classes.divider} />
            {reason && <p className={classes.reason}>{renderReason}</p>}
            <p className={reason ? classes.guidance : classes.guidanceSubtitle1}>{guidance}</p>
            {navigationText && (
              <Button
                onClick={handleSubmit}
                data-testid='navigationButton'
                trackingName={returnButtonTrackingName}
                trackingLabel={reason}
                variant='iconTextMedium'
                icon={<IconUIArrowLeft />}
              >
                {navigationText}
              </Button>
            )}
          </Grid>
          {shouldShowDiagnosticsDetails && (
            <Grid item xs={12} className={classes.devDiagnostic}>
              <DevDiagnostic errorReason={error.errorReason} details={text} />
            </Grid>
          )}
          {errorDetails && (
            <Grid container className={classes.errorContainer}>
              <Grid item>{errorDetails}</Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={false} md={1} xl={2} />
        <Grid item xs={false} md={5} xl={4} className={classes.imageContainer}>
          <img src={errorImage} alt='Error' />
        </Grid>
      </Grid>
    </div>
  );
};
