import { datadogLog } from '@ecp/utils/logger';

import { MAX_RETRY_ATTEMPTS, MAX_TIMEOUT_VALUE } from './constants';
import type { RetryOptions, TimeoutOptions } from './types';
import { shouldRetryForNetworkErrors, shouldRetryForTimeoutErrors } from './util';

/**
 * Performs an operation and retries it if it fails based on the provided retry options.
 *
 * @template T
 * @param {string} url - The URL associated with the operation.
 * @param {function(retrySettings?: RetryOptions, timeoutSettings?: TimeoutOptions): Promise<T>} operation - The operation to perform,
 * which accepts optional retry and timeout settings.
 * @param {RetryOptions} options - The retry options, including maximum attempts and retry conditions.
 * @param {TimeoutOptions} [timeout] - Optional timeout settings, including the timeout value and factor by which it can be multiplied.
 * @returns {Promise<T>} - The result of the operation if successful.
 * @throws {Error} - Throws the last encountered error if all retry attempts are exhausted.
 *
 * @example
 * const fetchData = async () => {
 *   const response = await retryOperation('https://api.example.com/data', fetchWithTimeout, {
 *     maxAttempts: 3,
 *     retryFunctions: [shouldRetryForNetworkErrors]
 *   }, { value: 5000, factor: 2 });
 *   return response.json();
 * };
 */
export async function retryOperation<T>(
  url: string,
  operation: (retrySettings?: RetryOptions, timeoutSettings?: TimeoutOptions) => Promise<T>,
  options: RetryOptions,
  timeout?: TimeoutOptions,
): Promise<T> {
  const {
    maxAttempts = MAX_RETRY_ATTEMPTS,
    retryFunctions = [shouldRetryForNetworkErrors, shouldRetryForTimeoutErrors],
  } = options;
  let attempt = 0;
  let timeoutUpdatedSettings = timeout;
  let response: T | undefined = undefined;
  let latestError: Error | undefined = undefined;

  while (attempt <= maxAttempts) {
    try {
      response = await operation(options, timeoutUpdatedSettings);
      // Log successful retry
      if (attempt > 0) {
        datadogLog({
          logType: 'info',
          message: `Fetch successful on ${url} ${
            timeout ? `with timeout: ${timeout.value} ms` : ''
          } on retry attempt ${attempt}.`,
          context: {
            logOrigin: 'libs/utils/network/src/retryOperation.ts',
            functionOrigin: 'retryOperation',
          },
        });
      }

      return response;
    } catch (error) {
      latestError = error as Error;

      // If maximum attempts is exhausted, return
      if (attempt > maxAttempts) {
        datadogLog({
          logType: 'info',
          message: `Retries Exhausted : Attempt ${attempt}`,
          context: {
            logOrigin: 'libs/utils/network/src/retryOperation.ts',
            functionOrigin: 'retryOperation',
          },
        });
        break;
      } else {
        const shouldRetry = retryFunctions.some((fn) => fn(error as Error));
        if (!shouldRetry) break;
        if (timeout) {
          const newTimeoutValue = timeout.value * timeout.factor;
          timeoutUpdatedSettings = {
            value: Math.min(newTimeoutValue, MAX_TIMEOUT_VALUE), // Set a maximum cap
            factor: timeout.factor,
          };
        }
        if (attempt > 0) {
          datadogLog({
            logType: 'warn',
            message: `Retry Attempt ${attempt} on ${url} failed with error: ${error}. Is Retriable: ${shouldRetry}`,
            context: {
              logOrigin: 'libs/utils/network/src/retryOperation.ts',
              functionOrigin: 'retryOperation',
            },
            error: error as Error,
          });
        }
      }

      attempt++;
    }
  }

  if (response !== undefined) {
    return response;
  } else {
    throw (
      latestError ?? new Error(`Retry operation failed after ${maxAttempts} attempts for ${url}`)
    );
  }
}
