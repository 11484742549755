import type { AllConfigs } from '../../types';

export const productPageFlow: AllConfigs = [
  {
    executionOrder: 100,
    startEffectiveDate: '01/01/2021',
    description: 'Bundle - Auto - Consumer: Base menu structure',
    criteria: {
      baseExp: ['Bundle'],
      flow: ['', 'FLOW.SHORT', 'FLOW.LONG'],
      product: ['connect.auto', 'amfam.auto'],
    },
    config: {
      menuItems: [
        {
          text: 'Auto',
          pageId: 'profile',
          path: '/quote/auto/profile',
        },
      ],
    },
  },
  {
    executionOrder: 110,
    startEffectiveDate: '01/01/2021',
    description: 'Bundle - Auto - Agent: Base menu structure',
    criteria: {
      baseExp: ['Agent'],
      flow: ['', 'FLOW.SHORT', 'FLOW.LONG'],
      product: ['amfam.auto', 'connect.auto'],
    },
    config: {
      menuItems: [
        {
          text: 'Auto',
          pageId: 'profile',
          path: '/quote/auto/profile',
          subMenuItems: [
            {
              text: 'Vehicles & Drivers',
              pageId: 'profile',
              path: '/quote/auto/profile',
            },
            {
              text: 'Discounts',
              pageId: 'discounts',
              path: '/quote/auto/discounts',
            },
          ],
        },
      ],
    },
  },
  {
    executionOrder: 130,
    startEffectiveDate: '01/01/2021',
    description:
      'Bundle - Auto - Agent & Consumer - Short Form: Add pages with custom short form discounts page',
    criteria: {
      baseExp: ['Bundle', 'Agent'],
      flow: ['FLOW.SHORT'],
      product: ['amfam.auto', 'connect.auto'],
    },
    deltas: [
      {
        operation: 'add',
        target: '',
        value: {
          navigationOrder: ['profile', 'discounts'],
          byPageId: {
            profile: {
              path: '/quote/auto/profile',
              pageTitle: 'Vehicle/Driver Profile',
            },
            discounts: {
              path: '/quote/auto/shortdiscounts',
              pageTitle: 'Vehicle Discounts',
            },
          },
        },
      },
    ],
  },
  {
    executionOrder: 140,
    startEffectiveDate: '01/01/2021',
    description:
      'Bundle - Auto - Agent & Consumer - Long Form: Add pages with long form discounts page',
    criteria: {
      baseExp: ['Bundle', 'Agent'],
      flow: ['FLOW.LONG'],
      product: ['amfam.auto', 'connect.auto'],
    },
    deltas: [
      {
        operation: 'add',
        target: '',
        value: {
          navigationOrder: ['profile', 'discounts'],
          byPageId: {
            profile: {
              path: '/quote/auto/profile',
              pageTitle: 'Vehicle/Driver Profile',
            },
            discounts: {
              path: '/quote/auto/discounts',
              pageTitle: 'Vehicle Discounts',
            },
          },
        },
      },
    ],
  },
  {
    executionOrder: 400,
    startEffectiveDate: '08/15/2024',
    description: 'Auto - Agent: Advanced menu structure',
    criteria: {
      baseExp: ['Agent'],
      flow: ['FLOW.SHORT', 'FLOW.LONG'],
      product: ['amfam-adv.auto'],
    },
    config: {
      menuItems: [
        {
          text: 'Auto',
          pageId: 'addeditdrivers',
          path: '/quote/auto/addeditdrivers',
          subMenuItems: [
            {
              text: 'Drivers',
              pageId: 'addeditdrivers',
              path: '/quote/auto/addeditdrivers',
            },
            {
              text: 'Vehicles',
              pageId: 'addeditvehicles',
              path: '/quote/auto/addeditvehicles',
            },
            {
              text: 'Assign Drivers & Vehicles',
              pageId: 'assignment',
              path: '/quote/auto/assignment',
            },
            {
              text: 'Prior Insurance',
              pageId: 'priorinsurance',
              path: '/quote/auto/priorinsurance',
            },
            {
              text: 'Losses & Violations',
              pageId: 'lossesandviolations',
              path: '/quote/auto/lossesandviolations',
            },
            {
              text: 'Discounts',
              pageId: 'autoDiscounts',
              path: '/quote/auto/discounts',
            },
          ],
        },
      ],
      navigationOrder: [
        'addeditdrivers',
        'addeditvehicles',
        'assignment',
        'priorinsurance',
        'lossesandviolations',
        'autoDiscounts',
      ],
      byPageId: {
        addeditdrivers: {
          path: '/quote/auto/addeditdrivers',
          pageTitle: 'Drivers',
        },
        addeditvehicles: {
          path: '/quote/auto/addeditvehicles',
          pageTitle: 'Vehicles',
        },
        assignment: {
          path: '/quote/auto/assignment',
          pageTitle: 'Driver & Vehicle Assignment',
        },
        priorinsurance: {
          path: '/quote/auto/priorinsurance',
          pageTitle: 'Prior Insurance',
        },
        lossesandviolations: {
          path: '/quote/auto/lossesandviolations',
          pageTitle: 'Review Auto Losses & Violations',
        },
        autoDiscounts: {
          path: '/quote/auto/discounts',
          pageTitle: 'Auto Discounts',
        },
      },
    },
  },
  {
    executionOrder: 200,
    startEffectiveDate: '01/01/2021',
    description: 'Bundle - Home - Consumer: Base menu structure',
    criteria: {
      baseExp: ['Bundle', 'Renters'],
      flow: ['', 'FLOW.SHORT', 'FLOW.LONG'],
      product: ['homesite.home'],
    },
    config: {
      menuItems: [
        {
          text: 'Home',
          pageId: 'basic',
          path: '/quote/home/basic',
        },
      ],
    },
  },
  {
    executionOrder: 210,
    startEffectiveDate: '01/01/2021',
    description: 'Bundle - Home - Agent: Base menu structure',
    criteria: {
      baseExp: ['Agent'],
      flow: ['', 'FLOW.LONG', 'FLOW.SHORT'],
      product: ['homesite.home'],
    },
    config: {
      menuItems: [
        {
          text: 'Home',
          path: '/quote/home/basic',
          pageId: 'basic',
          subMenuItems: [
            {
              text: 'Basic details',
              pageId: 'basic',
              path: '/quote/home/basic',
            },
            {
              text: 'Exterior details',
              pageId: 'exterior',
              path: '/quote/home/exterior',
            },
            {
              text: 'Interior details',
              pageId: 'interior',
              path: '/quote/home/interior',
            },
            {
              text: 'Discounts',
              pageId: 'discounts',
              path: '/quote/home/discounts',
            },
          ],
        },
      ],
    },
  },
  {
    executionOrder: 220,
    startEffectiveDate: '01/01/2021',
    description: 'Bundle - Home - Consumer - Short Form: Add discount page',
    criteria: {
      baseExp: ['Bundle', 'Renters'],
      flow: ['FLOW.SHORT'],
      product: ['homesite.home'],
    },
    deltas: [
      {
        operation: 'add',
        target: '',
        value: {
          navigationOrder: ['discounts'],
          byPageId: {
            discounts: {
              path: '/quote/home/discounts',
              pageTitle: 'Home Discounts',
            },
          },
        },
      },
    ],
  },
  {
    executionOrder: 230,
    startEffectiveDate: '01/01/2021',
    description: 'Bundle - Home - Agent & Consumer - Long Form: Add all home long form pages',
    criteria: {
      baseExp: ['Bundle', 'Agent', 'Renters'],
      flow: ['FLOW.LONG'],
      product: ['homesite.home'],
    },
    deltas: [
      {
        operation: 'add',
        target: '',
        value: {
          navigationOrder: ['basic', 'exterior', 'interior', 'discounts'],
          byPageId: {
            basic: {
              path: '/quote/home/basic',
              pageTitle: 'Home Basic Details',
            },
            exterior: {
              path: '/quote/home/exterior',
              pageTitle: 'Home Exterior Details',
            },
            interior: {
              path: '/quote/home/interior',
              pageTitle: 'Home Interior Details',
            },
            discounts: {
              path: '/quote/home/discounts',
              pageTitle: 'Home Discounts',
            },
          },
        },
      },
    ],
  },
  {
    executionOrder: 240,
    startEffectiveDate: '01/01/2021',
    description: 'Bundle - Home - Consumer - Short Form: Add home short pages for MI short flow',
    criteria: {
      baseExp: ['Bundle', 'Renters'],
      flow: ['FLOW.SHORT'],
      product: ['homesite.home'],
      stateCode: ['MI'],
    },
    deltas: [
      {
        operation: 'reset',
        target: 'navigationOrder',
        value: ['marketvalue', 'discounts'],
      },
      {
        operation: 'reset',
        target: 'byPageId',
        value: {
          marketvalue: {
            path: '/quote/home/marketvalue',
            pageTitle: 'Home Market Value',
          },
          discounts: {
            path: '/quote/home/discounts',
            pageTitle: 'Home Discounts',
          },
        },
      },
    ],
  },
  {
    executionOrder: 250,
    startEffectiveDate: '01/01/2021',
    description: 'Bundle - Home - Agent  - Short Form: Add all pages for short flow',
    criteria: {
      baseExp: ['Agent'],
      flow: ['FLOW.SHORT'],
      product: ['homesite.home'],
    },
    deltas: [
      {
        operation: 'add',
        target: '',
        value: {
          navigationOrder: ['basic', 'exterior', 'interior', 'discounts'],
          byPageId: {
            basic: {
              path: '/quote/home/basic',
              pageTitle: 'Home Basic Details',
            },
            exterior: {
              path: '/quote/home/exterior',
              pageTitle: 'Home Exterior Details',
            },
            interior: {
              path: '/quote/home/interior',
              pageTitle: 'Home Interior Details',
            },
            discounts: {
              path: '/quote/home/discounts',
              pageTitle: 'Home Discounts',
            },
          },
        },
      },
    ],
  },
  {
    executionOrder: 260,
    startEffectiveDate: '01/01/2021',
    description: 'Agent - Home - Short Form - MI: Add market value page for MI short form',
    criteria: {
      baseExp: ['Agent'],
      flow: ['FLOW.SHORT'],
      product: ['homesite.home', 'connect.home'],
      stateCode: ['MI'],
    },
    deltas: [
      {
        operation: 'reset',
        target: 'navigationOrder',
        value: ['marketvalue', 'basic', 'exterior', 'interior', 'discounts'],
      },
      {
        operation: 'reset',
        target: 'byPageId',
        value: {
          basic: {
            path: '/quote/home/basic',
            pageTitle: 'Home Basic Details',
          },
          exterior: {
            path: '/quote/home/exterior',
            pageTitle: 'Home Exterior Details',
          },
          interior: {
            path: '/quote/home/interior',
            pageTitle: 'Home Interior Details',
          },
          discounts: {
            path: '/quote/home/discounts',
            pageTitle: 'Home Discounts',
          },
          marketvalue: {
            path: '/quote/home/marketvalue',
            pageTitle: 'Home Market Value',
          },
        },
      },
      {
        operation: 'reset',
        target: 'menuItems',
        value: [
          {
            text: 'Home',
            path: '/quote/home/marketvalue',
            pageId: 'marketvalue',
            subMenuItems: [
              { pageId: 'marketvalue', text: 'Home Market Value', path: '/quote/home/marketvalue' },
              {
                text: 'Basic details',
                pageId: 'basic',
                path: '/quote/home/basic',
              },
              {
                text: 'Exterior details',
                pageId: 'exterior',
                path: '/quote/home/exterior',
              },
              {
                text: 'Interior details',
                pageId: 'interior',
                path: '/quote/home/interior',
              },
              {
                text: 'Discounts',
                pageId: 'discounts',
                path: '/quote/home/discounts',
              },
            ],
          },
        ],
      },
    ],
  },
  {
    executionOrder: 380,
    startEffectiveDate: '08/15/2024',
    description: 'Home - Agent: Advanced menu structure',
    criteria: {
      baseExp: ['Agent'],
      flow: ['FLOW.SHORT', 'FLOW.LONG'],
      product: ['amfam-adv.home'],
    },
    config: {
      menuItems: [
        {
          text: 'Home',
          path: '/quote/home/eligibility',
          pageId: 'eligibility',
          subMenuItems: [
            {
              text: 'Eligibility',
              pageId: 'eligibility',
              path: '/quote/home/eligibility',
            },
            {
              text: 'Basic Details',
              pageId: 'basic',
              path: '/quote/home/basic',
            },
            {
              text: 'Exterior Details',
              pageId: 'exterior',
              path: '/quote/home/exterior',
            },
            {
              text: 'Interior Details',
              pageId: 'interior',
              path: '/quote/home/interior',
            },
            {
              text: 'Other Structures',
              pageId: 'otherStructures',
              path: '/quote/home/otherStructures',
            },
            {
              text: 'Losses',
              pageId: 'losses',
              path: '/quote/home/losses',
            },
            {
              text: 'Discounts',
              pageId: 'homeDiscounts',
              path: '/quote/home/discounts',
            },
          ],
        },
      ],
      navigationOrder: [
        'eligibility',
        'basic',
        'exterior',
        'interior',
        'otherStructures',
        'losses',
        'homeDiscounts',
      ],
      byPageId: {
        eligibility: {
          path: '/quote/home/eligibility',
          pageTitle: 'Eligibility',
        },
        basic: {
          path: '/quote/home/basic',
          pageTitle: 'Basic Details',
        },
        exterior: {
          path: '/quote/home/exterior',
          pageTitle: 'Exterior Details',
        },
        interior: {
          path: '/quote/home/interior',
          pageTitle: 'Interior Details',
        },
        otherStructures: {
          path: '/quote/home/otherStructures',
          pageTitle: 'Other Structures',
        },
        losses: {
          path: '/quote/home/losses',
          pageTitle: 'Review Property Losses',
        },
        homeDiscounts: {
          path: '/quote/home/discounts',
          pageTitle: 'Home Discounts',
        },
      },
    },
  },
  {
    executionOrder: 310,
    startEffectiveDate: '01/01/2021',
    description: 'Bundle - Renters: Base menu structure',
    criteria: {
      baseExp: ['Bundle', 'Agent'],
      flow: ['', 'FLOW.SHORT', 'FLOW.LONG'],
      product: ['homesite.renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Renters',
          pageId: 'discounts',
          path: '/quote/renters/discounts',
        },
      ],
      navigationOrder: ['discounts'],
      byPageId: {
        discounts: {
          path: '/quote/renters/discounts',
          pageTitle: 'Renters Discounts',
        },
      },
    },
  },
  {
    executionOrder: 330,
    startEffectiveDate: '01/01/2021',
    description: 'Renters - Renters: Base menu structure',
    criteria: {
      baseExp: ['Renters'],
      flow: ['', 'FLOW.SHORT', 'FLOW.LONG'],
      product: ['homesite.renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Renters',
          pageId: 'discounts',
          path: '/quote/renters/discounts',
        },
      ],
      navigationOrder: ['discounts'],
      byPageId: {
        discounts: {
          path: '/quote/renters/discounts',
          pageTitle: 'Renters Discounts',
        },
      },
    },
  },
  {
    executionOrder: 390,
    startEffectiveDate: '08/15/2024',
    description: 'Renters - Agent: Advanced menu structure',
    criteria: {
      baseExp: ['Agent'],
      flow: ['FLOW.SHORT', 'FLOW.LONG'],
      product: ['amfam-adv.renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Renters',
          pageId: 'propertydetails',
          path: '/quote/renters/propertydetails',
          subMenuItems: [
            {
              text: 'Eligibility',
              pageId: 'propertydetails',
              path: '/quote/renters/propertydetails',
            },
            {
              text: 'Losses',
              pageId: 'losses',
              path: '/quote/renters/losses',
            },
            {
              text: 'Discounts',
              pageId: 'rentersDiscounts',
              path: '/quote/renters/discounts',
            },
          ],
        },
      ],
      navigationOrder: ['propertydetails', 'losses', 'rentersDiscounts'],
      byPageId: {
        propertydetails: {
          path: '/quote/renters/propertydetails',
          pageTitle: 'Eligibility',
        },
        losses: {
          path: '/quote/renters/losses',
          pageTitle: 'Review Property Losses',
        },
        rentersDiscounts: {
          path: '/quote/renters/discounts',
          pageTitle: 'Renters Discounts',
        },
      },
    },
  },
];
