import { Stack } from '@mui/material';

import { IconUITimeout } from '@ecp/themes/base';

import { convertSecondsToMinAndSecDisplay } from './util';

export const SessionExpiryPromptTimeRemaining: React.FC<{ remaining: number; spacing: string }> = ({
  remaining,
  spacing,
}) => {
  return (
    <Stack direction='row' alignItems='center' spacing={spacing} justifyContent='center'>
      <IconUITimeout width={82} height={82} />
      <h1>{convertSecondsToMinAndSecDisplay(remaining)}</h1>
    </Stack>
  );
};
