import type { AllConfigs } from '../types';

export const thirdPartyReportsPageFlow: AllConfigs = [
  {
    executionOrder: 10,
    startEffectiveDate: '01/01/2021',
    description: 'Third party reports for all products',
    criteria: {
      baseExp: ['Agent'],
      product: ['amfam-adv.auto', 'amfam-adv.home', 'amfam-adv.renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Third Party Reports',
          path: '/app/thirdpartyreports',
          pageId: 'thirdPartyReports',
        },
      ],
      navigationOrder: ['thirdPartyReports'],
      byPageId: {
        thirdPartyReports: {
          path: '/app/thirdpartyreports',
          pageTitle: 'Third-Party Reports',
        },
      },
    },
  },
];
