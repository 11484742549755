import { datadogLog, trackDataDogUserAction } from '@ecp/utils/logger';

import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

export const trackContinueQuoteButtonClick = (eventDetail: string, closeButton?: boolean): void => {
  trackSapiAnalyticsEvent({
    element: closeButton
      ? 'sessionTimeoutModal.xToContinueQuoteButton'
      : 'sessionTimeoutModal.continueQuoteButton',
    event: 'click',
    eventDetail,
  });
  datadogLog({
    logType: 'info',
    message: 'Continue Quote Clicked',
    context: {
      logOrigin:
        'libs/features/sales/shared/components/src/SessionExpiryTimer/util/trackContinueQuoteButtonClick.ts',
      functionOrigin: 'trackContinueQuoteButtonClick',
    },
  });
  trackDataDogUserAction({
    name: 'Continue Quote Clicked',
    context: { action: 'handleContinueQuote' },
  });
};
