import { memo, useCallback, useState } from 'react';

import { Card, CardHeader, Grid } from '@mui/material';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { upperFirst } from '@ecp/utils/common';

import { callToActions } from '@ecp/features/sales/shared/components';
import type { OfferStatusCode } from '@ecp/features/sales/shared/constants';
import { PrefillFlow } from '@ecp/features/sales/shared/constants';
import { nextPage, PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import {
  describeOfferSummary,
  getAutoDeltaQuestionExists,
  getCurrentFlows,
  getOffersForSelectedLob,
  getPolicyStartDates,
  isConfirmationRequired,
  isOfferEstimated,
  isOfferQuoteNoBind,
  patchUserSelectionAndUpdateOffer,
  updateOfferProductChanged,
  updateProductLobUserSelection,
} from '@ecp/features/sales/shared/store';
import type { OfferInfo } from '@ecp/features/sales/shared/store/types';
import type { OfferDetails, QuoteSummaryOffers } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  CarrierDisplayName,
  Product,
  ProductDisplayName,
  ProductName,
} from '@ecp/features/shared/product';
import {
  getCarrierDisplayName,
  getCarrierNameFromProduct,
  getProductDisplayNameFromProduct,
  getProductNameFromProduct,
  getReducedProductNameFromProduct,
  isProductRenters,
  LineOfBusiness,
} from '@ecp/features/shared/product';

import { useStyles } from './QuoteRetrieveCardContainer.styles';
import { getContainterProductName, HeaderIcon, QuoteRetrieveButton } from './util';

export interface QuoteRetrieveCardContainerProps {
  cardProduct: Product | ProductName | 'bundle';
  children: React.ReactNode;
  isChildContainer?: boolean;
  cardQuoteDetails?: OfferInfo;
  cnqDnqQuoteDetails?: {
    description: string;
    value: string;
  }[];
  offerStatusCode?: OfferStatusCode;
  key: string;
  isParentContainer?: boolean;
}

interface OfferData {
  carrierDisplayName: CarrierDisplayName;
  products: (ProductData | BundleData)[];
}

interface BundleData {
  isBundle: boolean;
  isPurchased: boolean;
  isLocked: boolean;
  auto?: ProductData;
  home?: ProductData;
  renters?: ProductData;
  button?: React.ReactElement;
  displayNotAvailableAndHideRecall: boolean;
}

interface ProductData {
  isBundle: boolean;
  policyStartDate?: string;
  name: ProductName;
  isPurchased: boolean;
  isLocked: boolean;
  displayName: ProductDisplayName;
  monthlyPremium?: number;
  yearlyPremium: number;
  quoteDetails: {
    description: string;
    value: string;
  }[];
  button?: React.ReactElement;
}

interface Props {
  offers: QuoteSummaryOffers;
  hasExpiredQuotes?: boolean;
}

const buttonVariant = 'iconText';
const buttonText = 'Recall quote';

const gaTrackClick = (trackingLabel: string): void => {
  trackClick({ action: 'RecallQuoteLink', label: trackingLabel });
};

export const QuoteRetrieveCardContainer: React.FC<QuoteRetrieveCardContainerProps> = memo(
  (props) => {
    const { cx, classes } = useStyles();

    const {
      cardProduct,
      children,
      isChildContainer = false,
      cardQuoteDetails,
      offerStatusCode,
      isParentContainer = false,
    } = props;

    const offers = useSelector(getOffersForSelectedLob);
    const dispatch = useDispatch();
    const productName = cardProduct === 'bundle' ? 'bundle' : getContainterProductName(cardProduct);
    const flow = useSelector(getCurrentFlows);
    const isFlowShortAuto = flow.auto === PrefillFlow.SHORT;
    const navigateToCoverages = useNavigateToPage(PagePath.COVERAGES);
    const navigateToAutoDelta = useNavigateToPage(PagePath.AUTO_DELTA);
    const policyStartDates = useSelector(getPolicyStartDates);
    const indicativeMidvaleAuto =
      cardQuoteDetails?.isEstimated && cardQuoteDetails?.product === 'amfam.auto';
    const quoteId =
      cardProduct !== 'bundle' && !indicativeMidvaleAuto && cardQuoteDetails?.details.quoteNumber;
    const [isRecallInProgress, setIsRecallInProgress] = useState(false);

    const hasAutoDeltaQuestions = useSelector(getAutoDeltaQuestionExists);
    const processOfferDetail = (
      offerDetails: OfferDetails,
    ): { carrierDisplayName: CarrierDisplayName; products: ProductData[] } =>
      Object.keys(offerDetails).reduce(
        (acc, product) => {
          const carrierName = getCarrierNameFromProduct(product);
          const carrierDisplayName = getCarrierDisplayName(carrierName);
          const productName = getProductNameFromProduct(product);
          const productReducedName = getReducedProductNameFromProduct(product);
          const productDisplayName = getProductDisplayNameFromProduct(product);
          const offerInfo = offerDetails[product];
          if (!offerInfo) return acc;
          const productData: ProductData = {
            isBundle: false,
            name: productName,
            isPurchased: offerInfo.isPurchased,
            isLocked: offerInfo.isLocked,
            displayName: productDisplayName,
            policyStartDate: policyStartDates[productReducedName],
            yearlyPremium: offerInfo.fullPremium?.totalPremium,
            monthlyPremium: offerInfo.monthlyPremium?.installmentPayment,
            quoteDetails: Object.keys(offerInfo.coverages).map((coverageKey) => {
              return {
                description: coverageKey,
                value: offerInfo.coverages[coverageKey],
              };
            }),
          };
          acc.carrierDisplayName = carrierDisplayName;
          acc.products.push(productData);

          return acc;
        },
        { carrierDisplayName: undefined, products: [] } as unknown as ReturnType<
          typeof processOfferDetail
        >,
      );

    const processBundle = (
      offerDetails: OfferDetails,
    ): { carrierDisplayName: CarrierDisplayName; bundleData: BundleData } => {
      const { carrierDisplayName, products } = processOfferDetail(offerDetails);
      const isPurchased = products.every((product) => product.isPurchased === true);
      const isLocked = products.filter((product) => product.isLocked).length > 0;
      const displayNotAvailableAndHideRecall = !products.every(
        (product) => product.isPurchased === false,
      );
      const bundleData: BundleData = {
        isBundle: true,
        isPurchased: isPurchased,
        isLocked: isLocked,
        displayNotAvailableAndHideRecall: displayNotAvailableAndHideRecall,
      };
      products.forEach((product: ProductData) => {
        bundleData[product.name] = product;
      });

      return { carrierDisplayName, bundleData };
    };

    const navigateToNext = useCallback(
      async (indicative: boolean) => {
        const path = nextPage(hasAutoDeltaQuestions, isFlowShortAuto, indicative);

        if (path === PagePath.AUTO_DELTA) {
          await navigateToAutoDelta();
        } else {
          await navigateToCoverages();
        }
      },
      [isFlowShortAuto, hasAutoDeltaQuestions, navigateToAutoDelta, navigateToCoverages],
    );

    const handleBuy = useCallback(
      async (trackingLabel: string, offerProductsSelected: Product[]) => {
        setIsRecallInProgress(true);
        if (offerProductsSelected.length > 0) {
          await dispatch(updateOfferProductChanged(offerProductsSelected));
          await dispatch(updateProductLobUserSelection(offerProductsSelected));
          await dispatch(patchUserSelectionAndUpdateOffer());
          gaTrackClick(trackingLabel);
          await navigateToNext(false);
        }
        setIsRecallInProgress(false);
      },
      [dispatch, navigateToNext, setIsRecallInProgress],
    );

    const handleFinishAndBuy = useCallback(
      async (trackingLabel: string, offerProductsSelected: Product[]) => {
        if (offerProductsSelected.length > 0) {
          await dispatch(updateProductLobUserSelection(offerProductsSelected));
          await dispatch(updateOfferProductChanged(offerProductsSelected));
          gaTrackClick(trackingLabel);
          await navigateToNext(true);
        }
      },
      [dispatch, navigateToNext],
    );

    const handleRetrieveLinkClick = useCallback(
      async (trackingLabel: string, offerProductsSelected: Product[], retrieveLink?: string) => {
        if (offerProductsSelected.length > 0) {
          gaTrackClick(trackingLabel);
          await dispatch(updateProductLobUserSelection(offerProductsSelected));
          await dispatch(updateOfferProductChanged(offerProductsSelected));
        }
        if (retrieveLink) {
          window.location.href = retrieveLink;
        }
      },
      [dispatch],
    );

    const createRecallButton = (
      quoteSummary: Props['offers'],
      selectedLob: LineOfBusiness,
      isPurchased: boolean,
      isLocked: boolean,
      displayNotAvailableAndHideRecall: boolean,
    ): React.ReactElement => {
      const {
        autoBundledOffer,
        autoOnlyOffer,
        homeBundledOffer,
        homeOnlyOffer,
        rentersBundledOffer,
        rentersOnlyOffer,
        carrierName,
        isBundleSelected,
        offerProductsSelected,
        trackingLabel,
      } = describeOfferSummary(quoteSummary, selectedLob);

      const { isEstimate, estimateTrackingName } = isOfferEstimated({
        autoOnlyOffer,
        autoBundledOffer,
        selectedLob,
      });

      if (displayNotAvailableAndHideRecall) {
        return (
          <div className={classes.recallPurchasedContainer}>
            <p>Not available</p>
            {isPurchased && <p className={classes.recallPurchasedPolicy}>Purchased policy</p>}
          </div>
        );
      }

      const retrieveLink =
        selectedLob === LineOfBusiness.AUTO && autoOnlyOffer && autoOnlyOffer.retrieveLink
          ? autoOnlyOffer.retrieveLink
          : undefined;
      const disabled = isLocked;
      const { actions: button } = callToActions({
        carrierName,
        estimateTrackingName,
        isBundleSelected,
        isEstimate,
        isQuoteNoBind: isOfferQuoteNoBind({
          autoOnlyOffer,
          homeOnlyOffer,
          rentersOnlyOffer,
          autoBundledOffer,
          homeBundledOffer,
          rentersBundledOffer,
          selectedLob,
        }),
        isConfirmationRequired: isConfirmationRequired({
          autoOnlyOffer,
          autoBundledOffer,
          selectedLob,
        }),
        retrieveLink,
        selectedLob,
        trackingLabel,
        handleBuy: () => {
          handleBuy(trackingLabel, offerProductsSelected);
        },
        handleFinishAndBuy: () => {
          handleFinishAndBuy(trackingLabel, offerProductsSelected);
        },
        handleRetrieveLinkClick: () => {
          handleRetrieveLinkClick(trackingLabel, offerProductsSelected, retrieveLink);
        },
        inProgress: isRecallInProgress && !disabled,
        buttonVariant,
        buttonText,
        className: classes.recallButton,
        disabled,
      });

      return button;
    };

    const displayData = (): OfferData => {
      const result: OfferData = {
        carrierDisplayName: undefined as unknown as CarrierDisplayName,
        products: [],
      };

      if (offers?.bundle) {
        const { carrierDisplayName, bundleData } = processBundle(offers.bundle);
        result.carrierDisplayName = carrierDisplayName;
        const selectedLob = Object.keys(offers.bundle).reduce(
          (acc, product) => (isProductRenters(product) ? LineOfBusiness.BUNDLE_AUTO_RENTERS : acc),
          LineOfBusiness.BUNDLE,
        );
        bundleData.button = createRecallButton(
          offers,
          selectedLob,
          bundleData.isPurchased,
          bundleData.isLocked,
          bundleData.displayNotAvailableAndHideRecall,
        );
        result.products.push(bundleData);
      }

      const productNameToLob: Record<ProductName, LineOfBusiness> = {
        auto: LineOfBusiness.AUTO,
        home: LineOfBusiness.HOME,
        renters: LineOfBusiness.RENTERS,
      };
      const productKeys = offers && Object.keys(offers);
      productKeys &&
        productKeys.forEach((productKey) => {
          const offerDetails = offers[productKey];
          if (offerDetails) {
            const { carrierDisplayName, products } = processOfferDetail(offerDetails);
            result.carrierDisplayName ??= carrierDisplayName;
            if (products && products.length === 1) {
              const selectedLob = productNameToLob[products[0].name];
              const displayNotAvailableAndHideRecall = offers.bundle
                ? processBundle(offers.bundle).bundleData.displayNotAvailableAndHideRecall
                : products[0].isPurchased;
              products[0].button = createRecallButton(
                offers,
                selectedLob,
                products[0].isPurchased,
                products[0].isLocked,
                displayNotAvailableAndHideRecall,
              );
              result.products.push(products[0]);
            }
          }
        });

      return result;
    };

    displayData();

    return (
      <Card
        data-testid='productSummaryCard'
        className={cx(
          classes.root,
          !offerStatusCode && classes.defaultBorderColor,
          offerStatusCode === 'CNQ' && classes.warningBorderColor,
          offerStatusCode === 'DNQ' && classes.errorBorderColor,
          offerStatusCode === 'PURCHASED' && classes.successBorderColor,
          offerStatusCode === 'EXPIRED' && classes.defaultBorderColor,
          isChildContainer && classes.subCard,
          isParentContainer && classes.parentCard,
        )}
      >
        <CardHeader
          title={
            <Grid
              container
              className={cx(classes.cardHeader, isParentContainer && classes.parentCardHeader)}
            >
              <Grid
                item
                className={cx(
                  classes.monolineCardTitleContainer,
                  isChildContainer && classes.subCardTitle,
                  isParentContainer && classes.parentCardTitle,
                )}
              >
                <div className={classes.cardTitle}>
                  <HeaderIcon productName={productName} />
                  <h3>{upperFirst(productName)} </h3>
                </div>
                {quoteId && (
                  <div className={classes.cardHeaderQuoteId}>
                    <p>
                      <strong>Quote number</strong>
                    </p>
                    <p>{quoteId}</p>
                  </div>
                )}
              </Grid>
              {!isChildContainer && (
                <Grid item className={classes.headerRecallButton}>
                  <QuoteRetrieveButton
                    offerStatusCode={offerStatusCode}
                    handleBuy={handleBuy}
                    isRecallInProgress={isRecallInProgress}
                  />
                </Grid>
              )}
            </Grid>
          }
        />
        {children}
      </Card>
    );
  },
);
