import type { AllConfigs } from '../types';

export const homePrefillSummaryPageFlow: AllConfigs = [
  {
    executionOrder: 10,
    startEffectiveDate: '01/01/2024',
    description: 'Consumer - Home - Short Form - Prefill Home Summary Page',
    criteria: {
      baseExp: ['Bundle'],
      product: ['homesite.home'],
      flow: ['FLOW.SHORT'],
      // Need to verify the flag, Can the EXP check be removed
      expId: ['2812', '2813'],
    },
    config: {
      menuItems: [
        {
          text: 'Review home details',
          pageId: 'prefillHomeSummary',
          path: '/review-home-details',
        },
      ],
      navigationOrder: ['prefillHomeSummary'],
      byPageId: {
        prefillHomeSummary: {
          path: '/review-home-details',
          pageTitle: 'Review home details',
        },
      },
    },
  },
];
