import { Grid } from '@mui/material';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import { Button, SaveAndExit } from '@ecp/features/sales/shared/components';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { getCurrentPage } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { useIsMobile } from '@ecp/themes/base';

import type { CheckoutCardProps } from '../CheckoutCard';
import { CheckoutCardBody } from '../CheckoutCard';
import { useStyles } from './MobilePurchaseCard.styles';

export const MobilePurchaseCard: React.FC<CheckoutCardProps> = (props) => {
  const {
    checkoutData,
    onCheckout,
    buttonText,
    buttonDisabled,
    isProcessing,
    buttonTrackingName,
    buttonTrackingLabel,
    checkoutElement,
    isMVRActive,
  } = props;
  const { classes } = useStyles();
  const isMobile = useIsMobile();

  const currentPage = useSelector(getCurrentPage);
  // Variant B: Remove “Save & Exit” link from the entire Checkout page
  const isVariantB = flagValues[FeatureFlags.SAVE_AND_EXIT] === 'VARIANT_B';

  // Variant C: Add save&exit link and text to “Important Information” block at bottom of checkout page.
  // Important Information will display only if the corresponding state has fraud warning
  const isVariantC = flagValues[FeatureFlags.SAVE_AND_EXIT] === 'VARIANT_C';
  const removeSaveExit = currentPage === PagePath.CHECKOUT && (isVariantB || isVariantC);

  return (
    <Grid container className={classes.bottomCheckoutBarContainer}>
      <Grid item xs={isMobile ? 6 : 4}>
        <CheckoutCardBody checkoutData={checkoutData} isMVRActive={isMVRActive} compactDivider />
      </Grid>
      <Grid item xs={6} className={classes.bottomCheckoutBarButton}>
        <div className={classes.carrierButtons}>
          <Button
            variant='success'
            data-testid='mobilePurchase'
            onClick={onCheckout}
            isProcessing={isProcessing}
            disabled={buttonDisabled}
            trackingName={buttonTrackingName}
            trackingLabel={buttonTrackingLabel}
            analyticsElement={checkoutElement}
          >
            {buttonText}
          </Button>
          {!env.static.isAgent && !removeSaveExit && <SaveAndExit />}
        </div>
      </Grid>
    </Grid>
  );
};
