export const convertSecondsToMinAndSecDisplay = (remainingSeconds: number): string => {
  remainingSeconds = Number(remainingSeconds);
  const minutes = Math.floor((remainingSeconds % 3600) / 60);
  const seconds = Math.floor((remainingSeconds % 3600) % 60);

  const minutesDisplay = String(minutes).padStart(2, '0');
  const secondsDisplay = String(seconds).padStart(2, '0');

  return minutesDisplay + ':' + secondsDisplay;
};
