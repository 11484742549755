import type { SetStateAction } from 'react';
import { useCallback, useState } from 'react';

import { Grid } from '@mui/material';

import { Alert, GridItem, LogoSpinner } from '@ecp/components';
import { Form, QuotesPolicyStartDateDialog } from '@ecp/features/sales/shared/components';
import {
  getCnqDndProducts,
  getHasAnyQuoteExpired,
  getOffersForSelectedLob,
} from '@ecp/features/sales/shared/store';
import type { CnqDnqProductData } from '@ecp/features/sales/shared/store';
import type { OfferInfo } from '@ecp/features/sales/shared/store/types';
import type { QuoteSummaryOffers } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Driver } from '@ecp/features/sales/shared/types';
import {
  type AutoProduct,
  LineOfBusiness,
  type Product,
  type ProductName,
} from '@ecp/features/shared/product';
import { IconUIExclaimTriangle } from '@ecp/themes/base';

import { useStyles } from './AgentDashboardForm.styles';
import {
  AvailableQuoteRetrieveCardSection,
  UnavailableQuoteRetrieveCardSection,
} from './QuoteRetrieveCards';

type Props = {
  drivers: Driver[];
  isLoadingOffers: boolean;
};

interface DashBoardTableProps {
  offersForSelectedLob: QuoteSummaryOffers | null;
  handleChangeStartDateToggle: () => void;
  setDialogProduct: React.Dispatch<SetStateAction<LineOfBusiness>>;
  cnqDndProducts: CnqDnqProductData[];
}

const determineOfferLocked = (
  offersForSelectedLob: Partial<Record<Product, OfferInfo>>,
  key: Product,
): boolean | undefined => {
  if (offersForSelectedLob) {
    if (offersForSelectedLob[key]) return offersForSelectedLob[key]?.isLocked;
  }

  return false;
};
const AgentDashboardTable = (props: DashBoardTableProps): JSX.Element => {
  const { offersForSelectedLob, handleChangeStartDateToggle, setDialogProduct, cnqDndProducts } =
    props;
  const { classes } = useStyles();

  const offers = offersForSelectedLob ? Object.entries(offersForSelectedLob) : [];
  const sortedOffers = [
    ...offers.filter((offerInfo) => offerInfo[0] === 'bundle'),
    ...offers.filter((offerInfo) => offerInfo[0] !== 'bundle'),
  ];

  const quoteRetrieveCards = sortedOffers.map(([key, data]) => {
    return (
      <AvailableQuoteRetrieveCardSection
        product={key}
        data={data}
        handleChangeStartDateToggle={handleChangeStartDateToggle}
        setDialogProduct={setDialogProduct}
        key={key}
      />
    );
  });

  const unavailableQuoteCards =
    cnqDndProducts && cnqDndProducts.length !== 0 ? (
      <>
        <h2 className={classes.agentUnavailableHeaders}>Unavailable quotes</h2>
        <UnavailableQuoteRetrieveCardSection data={cnqDndProducts} />
      </>
    ) : null;

  const availableQuoteRetrieveCards =
    quoteRetrieveCards && quoteRetrieveCards.length !== 0 ? (
      <>
        <h2>Available quotes</h2>
        {quoteRetrieveCards}
      </>
    ) : null;

  return (
    <GridItem topSpacing='lg' xs={12}>
      {availableQuoteRetrieveCards}
      {unavailableQuoteCards}
    </GridItem>
  );
};
export const AgentDashboardForm: React.FC<Props> = (props) => {
  const { isLoadingOffers } = props;
  const { classes } = useStyles();

  const offersForSelectedLob = useSelector(getOffersForSelectedLob);
  const cnqDndProducts = useSelector(getCnqDndProducts);
  const recalledOfferProducts: ProductName[] = [];
  offersForSelectedLob?.auto && recalledOfferProducts.push('auto');
  offersForSelectedLob?.home && recalledOfferProducts.push('home');
  offersForSelectedLob?.renters && recalledOfferProducts.push('renters');
  const expiredQuotes = useSelector(getHasAnyQuoteExpired(recalledOfferProducts));
  const autoProductKey: Product = offersForSelectedLob?.auto
    ? Object.keys(offersForSelectedLob?.auto)[0]
    : ('' as AutoProduct);
  const isOfferLocked =
    offersForSelectedLob?.auto && determineOfferLocked(offersForSelectedLob?.auto, autoProductKey);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogProduct, setDialogProduct] = useState(LineOfBusiness.AUTO);

  const handleChangeStartDateToggle = useCallback(() => {
    setDialogOpen(!dialogOpen);
  }, [setDialogOpen, dialogOpen]);

  if (isLoadingOffers) return <LogoSpinner />;

  return (
    <div className={classes.root}>
      <Form>
        <Grid container>
          {(offersForSelectedLob || cnqDndProducts) && (
            <>
              {isOfferLocked && (
                <Alert
                  withIcon
                  icon={<IconUIExclaimTriangle className={classes.alertIcon} />}
                  type='error'
                  className={classes.errorAlert}
                >
                  The customer's quote is being reviewed. The auto quote can not be retrieved for 14
                  days from the original quote date.
                </Alert>
              )}

              {expiredQuotes && (
                <GridItem topSpacing='sm' xs={12}>
                  <Alert withIcon type='warning'>
                    <p className={classes.expiredQuotesWarning}>
                      New policy start date. Premium may have changed.
                    </p>
                  </Alert>
                </GridItem>
              )}
              <AgentDashboardTable
                offersForSelectedLob={offersForSelectedLob!}
                handleChangeStartDateToggle={handleChangeStartDateToggle}
                setDialogProduct={setDialogProduct}
                cnqDndProducts={cnqDndProducts}
              />
              <QuotesPolicyStartDateDialog
                open={dialogOpen}
                selectedLob={dialogProduct}
                toggleDialog={handleChangeStartDateToggle}
              />
            </>
          )}
        </Grid>
      </Form>
    </div>
  );
};
