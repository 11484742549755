import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { datadogLog } from '@ecp/utils/logger';

import { SalesRequestError } from '@ecp/features/sales/shared/utils/network';

import { getSapiTarget } from '../../api';
import { updateGlobalCarrierErrors } from '../../global';
import { wrapThunkActionWithErrHandler } from '../../util/wrapThunkActionWithErrHandler';
import { setInquiryApiGetSuccess } from '../actions';
import { postProofDraft } from '../api';
import { getDalSessionId } from '../selectors';
import { ProofDraftSection } from '../types';

export const submitProofDraft = wrapThunkActionWithErrHandler<{
  policyTypes?: string[];
  section?: string;
}>(
  ({ policyTypes, section = ProofDraftSection.PRE_QUOTE }) =>
    async (dispatch, getState) => {
      const state = getState();
      const dalSessionId = getDalSessionId(state);
      const sapiTarget = getSapiTarget(state);
      const shouldSubmitProofDraft = flagValues[FeatureFlags.PROOF_DRAFT_ENABLED];
      const redesignedErrorHandling = flagValues[FeatureFlags.ERROR_HANDLING_REDESIGN];

      if (!dalSessionId || sapiTarget !== 'v4' || !shouldSubmitProofDraft) {
        return;
      }

      try {
        const response = await postProofDraft({
          dalSessionId,
          section,
          policyTypes,
        });
        dispatch(setInquiryApiGetSuccess(response.payload));
      } catch (e) {
        const error = e as Error | SalesRequestError;

        // EDSP-13850 - Update errors to global state to display on the oops page
        if (redesignedErrorHandling) {
          const errors = error instanceof SalesRequestError ? error.errors : [];
          if (errors && errors.length > 0) {
            dispatch(updateGlobalCarrierErrors(errors));
          }
        }

        datadogLog({
          logType: 'error',
          message: `Error posting proofDraft - ${error?.message}`,
          context: {
            logOrigin:
              'libs/features/sales/shared/store/lib/src/inquiry/thunks/submitProofDraft.ts',
            functionOrigin: 'submitProofDraft',
            ...(error instanceof SalesRequestError && { errorData: error.errorData }),
          },
          error,
        });

        throw e;
      }
    },
  'submitProofDraft',
);
