import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AutoCardFooter' })((theme) => ({
  root: {},
  footerAccordion: {
    boxShadow: 'none',
    border: `1px solid ${theme.palette.other.border}`,
    '&:last-of-type': {
      borderRadius: 10,
    },
    '&::first-of-type': {
      borderRadius: 10,
    },
    '& .MuiAccordionSummary-root > .MuiAccordionSummary-expandIconWrapper > svg > path': {
      fill: theme.palette.primary.main,
    },
    '& > .MuiAccordionSummary-root': {
      padding: '10px 20px',
      '&.Mui-expanded': {
        borderBottom: `1px solid ${theme.palette.other.border}`,
      },
    },
  },
  autoAccordionDetails: {
    marginBottom: -20,
  },
  vehiclesDriverAmounts: {
    ...theme.typography.body1,
    color: theme.palette.text.tertiary,
    marginLeft: 10,
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginLeft: 0,
    },
  },
  autoSectionHeader: {
    display: 'flex',
    '& svg': {
      margin: '5px 10px 5px 0px',
      width: 20,
      ...theme.mixins.setColorSvg(theme.palette.grey[900]),
    },
    '& span': {
      marginTop: 18,
    },
  },
  footerDetailsCard: {
    marginBottom: 20,
  },
  footerDetailsItem: {
    display: 'flex',
    justifyContent: 'left',
    flexDirection: 'row',
    padding: '10px 15px',
    borderRadius: 10,
    backgroundColor: theme.palette.grey[200],
    width: '94%',
    marginTop: 5,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    '& p': {
      marginLeft: 15,
      [theme.breakpoints.down('sm')]: {
        marginLeft: 0,
      },
    },
  },
  autoDetailsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '103%',
  },
}));
