import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { trackRender } from '@ecp/utils/analytics/tracking';
import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { FeatureFlags, flagValues, flagVariables } from '@ecp/utils/flags';

import { Card } from '@ecp/components';
import { env } from '@ecp/env';
import {
  Button,
  QuotesPolicyStartDateDialog,
  SaveAndExit,
} from '@ecp/features/sales/shared/components';
import { PaymentPlan } from '@ecp/features/sales/shared/constants';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { getCurrentPage } from '@ecp/features/sales/shared/store';
import {
  getAreSomeSelectedProductsIndicative,
  getHidePremiumToggleForOffers,
  getOfferProductsSelectedByType,
  getPolicyStartDates,
  getSapiAnalyticsSelectedOfferEventDetail,
  getSelectedPaymentPlan,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { useIsTablet } from '@ecp/themes/base';

import { getSelectedPaymentOptionForProduct } from '../../state/paymentoptions';
import type { CheckoutCardProps } from '../CheckoutCard';
import { IndicativeQuoteWarning } from '../IndicativeQuoteWarning';
import { MonthlyPaymentsBundleCheckoutView } from './MonthlyPaymentsBundleCheckoutView';
import { MonthlyPaymentsBundleCoverageView } from './MonthlyPaymentsBundleCoverageView';
import { useStyles } from './MonthlyPaymentsCheckoutCard.styles';
import { MonthlyPaymentsMonolineCheckoutView } from './MonthlyPaymentsMonolineCheckoutView';
import { MonthlyPaymentsMonolineCoverageView } from './MonthlyPaymentsMonolineCoverageView';

export const MonthlyPaymentsCheckoutCard: React.FC<CheckoutCardProps> = (props) => {
  const {
    checkoutData: { auto: autoPremium, property: propertyPremium },
    onCheckout,
    buttonText,
    buttonDisabled,
    isProcessing,
    buttonTrackingLabel,
    buttonTrackingName = GoogleAnalyticsLabels.CONTINUE,
    checkoutElement = 'choice.policyCoveragePage.checkoutButton',
    isMVRActive,
    shouldRecalc,
    isPatchingAnswers,
    recalculate,
    retrieveOffer,
    sapiAnalyticsSelectedOfferEventDetail,
    showError,
    showStateFees,
    showCTAButton,
    isCheckout,
  } = props;
  const { classes, cx } = useStyles();
  const isTablet = useIsTablet();
  const dispatch = useDispatch();
  const [showProcess, setShowProcess] = useState(false);
  const [recalculated, setRecalculated] = useState(false);
  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );
  const autoPaymentOption = useSelector((state) =>
    getSelectedPaymentOptionForProduct(state, autoOfferProduct),
  );
  const propertyPaymentOption = useSelector((state) =>
    getSelectedPaymentOptionForProduct(state, propertyOfferProduct),
  );

  const sapiAnalyticsEventDetails = useSelector(getSapiAnalyticsSelectedOfferEventDetail);
  const policyStartDates = useSelector(getPolicyStartDates);

  const [dialogOpenAuto, setDialogOpenAuto] = useState(false);
  const [dialogOpenHome, setDialogOpenHome] = useState(false);
  const hidePremiumToggle = useSelector(getHidePremiumToggleForOffers);
  const areSomeSelectedProductsIndicative = useSelector(getAreSomeSelectedProductsIndicative);
  const premiumPlan = useSelector(getSelectedPaymentPlan);
  const ctaButtonVariant = areSomeSelectedProductsIndicative ? 'primary' : 'success';
  const isCOWildfireLegislationFlagOn = flagValues[FeatureFlags.CO_WILDFIRE_LEGISLATION];
  const cOWildfireLegislationAdditionalMessage =
    flagVariables[FeatureFlags.CO_WILDFIRE_LEGISLATION].PAYMENT_ADDITIONAL_MESSAGE;

  // This makes sure the updated values are sent correctly
  // TODO - These state changes in useEffects are troublesome & needs to be revisited
  useEffect(() => {
    if (recalculated) {
      setRecalculated(false);
      trackRender({ action: 'RecalculatedCoverages', label: sapiAnalyticsEventDetails });

      trackSapiAnalyticsEvent({
        element: 'choice.policyCoveragePage.recalcButton',
        event: 'click',
        eventDetail: sapiAnalyticsEventDetails,
      });
    }
  }, [dispatch, recalculated, sapiAnalyticsEventDetails]);

  const recalculateClick = useCallback(async () => {
    if (shouldRecalc && retrieveOffer) {
      setShowProcess(true);
      retrieveOffer().then(() => {
        shouldRecalc(false);
        setShowProcess(false);
        setRecalculated(true);
      });
    }
  }, [shouldRecalc, retrieveOffer]);

  // Auto policy start date dialog toggle
  const toggleDialogOpenAuto = useCallback(() => {
    setDialogOpenAuto(!dialogOpenAuto);
  }, [dialogOpenAuto, setDialogOpenAuto]);
  // Home policy start date dialog toggle
  const toggleDialogOpenHome = useCallback(() => {
    setDialogOpenHome(!dialogOpenHome);
  }, [dialogOpenHome, setDialogOpenHome]);

  const getBody = (): React.ReactElement => {
    const isBundle = !!(autoOfferProduct && autoPremium && propertyOfferProduct && propertyPremium);
    const isAutoOnly = !!(!isBundle && autoOfferProduct && autoPremium);
    const isPropertyOnly = !!(!isBundle && propertyOfferProduct && propertyPremium);
    const checkoutViewCommonProps = {
      premiumPlan,
      recalculate,
      showProcess,
      showStateFees,
    };

    return (
      <Grid container className={classes.items} justifyContent='flex-start'>
        <QuotesPolicyStartDateDialog
          open={dialogOpenAuto && !recalculate}
          selectedLob={LineOfBusiness.AUTO}
          toggleDialog={toggleDialogOpenAuto}
        />
        <QuotesPolicyStartDateDialog
          open={dialogOpenHome && !recalculate}
          selectedLob={LineOfBusiness.HOME}
          toggleDialog={toggleDialogOpenHome}
        />
        {isAutoOnly &&
          (isCheckout && autoPaymentOption ? (
            <MonthlyPaymentsMonolineCheckoutView
              {...checkoutViewCommonProps}
              offerProduct={autoOfferProduct}
              showEstimated={!isMVRActive}
              psd={policyStartDates.auto}
              paymentOption={autoPaymentOption}
            />
          ) : (
            <MonthlyPaymentsMonolineCoverageView
              {...checkoutViewCommonProps}
              offerProduct={autoOfferProduct}
              showEstimated={!isMVRActive}
              psd={policyStartDates.auto}
              showToggle={!hidePremiumToggle}
            />
          ))}
        {isPropertyOnly &&
          (isCheckout && propertyPaymentOption ? (
            <MonthlyPaymentsMonolineCheckoutView
              {...checkoutViewCommonProps}
              offerProduct={propertyOfferProduct}
              psd={policyStartDates.property}
              paymentOption={propertyPaymentOption}
            />
          ) : (
            <MonthlyPaymentsMonolineCoverageView
              {...checkoutViewCommonProps}
              offerProduct={propertyOfferProduct}
              psd={policyStartDates.property}
              showToggle={!hidePremiumToggle}
            />
          ))}
        {isBundle &&
          (isCheckout && propertyPaymentOption && autoPaymentOption ? (
            <MonthlyPaymentsBundleCheckoutView
              {...checkoutViewCommonProps}
              autoOfferProduct={autoOfferProduct}
              propertyOfferProduct={propertyOfferProduct}
              autoPaymentOption={autoPaymentOption}
              propertyPaymentOption={propertyPaymentOption}
              isMVRActive={isMVRActive}
              autoPSD={policyStartDates.auto}
              homePSD={policyStartDates.property}
            />
          ) : (
            <MonthlyPaymentsBundleCoverageView
              {...checkoutViewCommonProps}
              autoOfferProduct={autoOfferProduct}
              propertyOfferProduct={propertyOfferProduct}
              isMVRActive={isMVRActive}
              autoPSD={policyStartDates.auto}
              homePSD={policyStartDates.property}
              showToggle={!hidePremiumToggle}
            />
          ))}
      </Grid>
    );
  };

  const currentPage = useSelector(getCurrentPage);
  // Variant B: Remove “Save & Exit” link from the entire Checkout page
  const isVariantB = flagValues[FeatureFlags.SAVE_AND_EXIT] === 'VARIANT_B';

  // Variant C: Add save&exit link and text to “Important Information” block at bottom of checkout page.
  // Important Information will display only if the corresponding state has fraud warning
  const isVariantC = flagValues[FeatureFlags.SAVE_AND_EXIT] === 'VARIANT_C';
  const removeSaveExit = currentPage === PagePath.CHECKOUT && (isVariantB || isVariantC);

  const getFooter = (): React.ReactElement => (
    <Grid container className={classes.footer} justifyContent='center'>
      <>
        {isTablet
          ? null
          : showCTAButton && (
              <div className={classes.carrierButtons}>
                <Button
                  variant={ctaButtonVariant}
                  data-testid='sidePurchase'
                  onClick={onCheckout}
                  classes={{
                    root: cx(classes.button),
                  }}
                  disabled={buttonDisabled}
                  isProcessing={isProcessing}
                  trackingName={buttonTrackingName}
                  trackingLabel={buttonTrackingLabel ?? sapiAnalyticsSelectedOfferEventDetail}
                  analyticsElement={checkoutElement}
                  analyticsEventDetail={sapiAnalyticsSelectedOfferEventDetail}
                >
                  {buttonText}
                </Button>
                {!env.static.isAgent && !removeSaveExit && <SaveAndExit />}
              </div>
            )}
        {isTablet
          ? null
          : premiumPlan === PaymentPlan.MONTHLY_PREMIUM &&
            !isCheckout && (
              <Grid item xs={12} className={classes.installmentStatement}>
                <p>Premium does not include installment fees</p>
              </Grid>
            )}
        {isCOWildfireLegislationFlagOn && cOWildfireLegislationAdditionalMessage && !isCheckout && (
          <Grid item xs={12} className={classes.installmentStatement}>
            <p>{cOWildfireLegislationAdditionalMessage}</p>
          </Grid>
        )}
      </>
    </Grid>
  );

  const getRecalculateFooter = (): React.ReactElement => (
    <Grid container className={classes.footer} justifyContent='center'>
      {isTablet ? null : (
        <Button
          variant={ctaButtonVariant}
          disabled={showProcess}
          isProcessing={showProcess}
          className={cx(classes.button, classes.recalculateButton, {
            [classes.recalcButtonBg]: !showProcess,
          })}
          onClick={recalculateClick}
          trackingName='recalculate_button'
          trackingLabel={sapiAnalyticsEventDetails}
          data-testid='RecalculateButton'
        >
          Calculate new rate
        </Button>
      )}
    </Grid>
  );

  const errorLayoutBody = (
    <Grid container className={classes.recalculateContainer}>
      <p className={classes.recalculateBody} role='alert'>
        Please review coverages and correct any errors
      </p>
    </Grid>
  );

  return (
    <>
      {env.static.isAgent && <IndicativeQuoteWarning />}
      <div className={classes.cardContainer}>
        {showError ? (
          <Card
            body={errorLayoutBody}
            divider={false}
            classes={{
              root: classes.recalculateCardContainer,
            }}
          />
        ) : recalculate && !isPatchingAnswers ? (
          <Card
            body={getBody()}
            divider={false}
            footer={getRecalculateFooter()}
            classes={{
              root: classes.monthlyPaymentsCardContainer,
            }}
          />
        ) : (
          <Card
            body={getBody()}
            footer={getFooter()}
            divider={false}
            classes={{
              root: classes.monthlyPaymentsCardContainer,
            }}
          />
        )}
      </div>
    </>
  );
};
