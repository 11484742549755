import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SessionExpiryPromptBodyText' })((theme) => ({
  root: {
    ...theme.typography.body1,
    [theme.breakpoints.up('md')]: {
      ...theme.typography.body3,
    },
  },
}));
