import { useEffect } from 'react';

import { createSelector } from '@reduxjs/toolkit';

import { useInteractionId } from '@ecp/utils/analytics/interaction-id';
import * as logger from '@ecp/utils/logger';

import {
  getCustomerId,
  getDalSessionId,
  getInquiryId,
  getLineOfBusiness,
  getOfferProductsSelected,
  getOffers,
  getPartnerExperienceId,
  getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

type Offers = ReturnType<typeof getOffers>;
type QuotesStatus = Record<
  keyof Offers,
  NonNullable<Offers[keyof Offers]>['eligibility']['statusCode']
>;
const getQuotesStatus = createSelector(getOffers, (offers) => {
  const quoteStatus = Object.keys(offers).reduce((acc, product) => {
    acc[product] = offers[product]?.eligibility?.statusCode;

    return acc;
  }, {} as QuotesStatus);

  return quoteStatus;
});

/**
 * Keeps track of various variables in the app which it associates with the current logging/monitoring session.
 */
export const useLogger = (): void => {
  const interactionId = useInteractionId().get();
  useEffect(() => {
    if (interactionId) logger.setGlobalContext('interactionId', interactionId);
  }, [interactionId]);

  const partnerExpId = useSelector(getPartnerExperienceId);
  useEffect(() => {
    if (partnerExpId) logger.setGlobalContext('partnerExpId', partnerExpId);
  }, [partnerExpId]);

  const dalSessionId = useSelector(getDalSessionId);
  useEffect(() => {
    if (dalSessionId) logger.setGlobalContext('salesWebSessionId', dalSessionId);
  }, [dalSessionId]);

  const inquiryId = useSelector(getInquiryId);
  useEffect(() => {
    if (inquiryId) logger.setGlobalContext('inquiryId', inquiryId);
  }, [inquiryId]);

  const lineOfBusiness = useSelector(getLineOfBusiness);
  useEffect(() => {
    if (lineOfBusiness) logger.setGlobalContext('lineOfBusiness', lineOfBusiness);
  }, [lineOfBusiness]);

  const stateCode = useSelector(getPrimaryInsuredStateCode);
  useEffect(() => {
    if (stateCode) logger.setGlobalContext('stateCode', stateCode);
  }, [stateCode]);

  const customerId = useSelector(getCustomerId);
  useEffect(() => {
    if (customerId) logger.setGlobalContext('customerId', customerId);
  }, [customerId]);

  const offerProductsSelected = useSelector(getOfferProductsSelected);
  useEffect(() => {
    logger.setGlobalContext('product', offerProductsSelected.join(','));
  }, [offerProductsSelected]);

  // TODO We need to figure out why we created it in the first place (see the first implementation)
  // and adjust the quotesStatus (vs ex-quoteStatus) shape accounting for multiple products
  const quotesStatus = useSelector(getQuotesStatus);
  useEffect(() => {
    logger.setGlobalContext('quoteStatus', quotesStatus);
  }, [quotesStatus]);
};
