import { useCallback, useEffect } from 'react';

import dayjs from 'dayjs';

import { GoogleAnalyticsLabels, trackClick } from '@ecp/utils/analytics/tracking';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { useEvent } from '@ecp/utils/react';

import type { SelectProps } from '@ecp/components';
import { GridItem, TextField, TooltipWithIcon } from '@ecp/components';
import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import {
  CheckboxGroup,
  DatePicker,
  RadioGroupWithOptions,
  Select,
} from '@ecp/features/sales/shared/components';
import {
  calculateMaxPolicyStartDaysLimit,
  createRef,
  deleteInquiryRef,
  getAnswer,
  getOffersExist,
  updateAddedRef,
  updateAnswers,
  updateSecondaryPolicyHolderByDriver,
  useField,
  useFieldWithPrefix,
  useMultiFieldsForCheckBoxGroup,
  usePolicyStartDateFields,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { AnswerValue, OptionProps } from '@ecp/features/sales/shared/types';
import type { Field } from '@ecp/types';

import { PriorPolicyCoverageOptions } from '../../../../metadata';
import { useIsSniValue } from '../../../../state';
import { useStyles } from './InsuranceQuestions.styles';
import metadata from './metadata';

export interface InsuranceQuestionsProps {
  driverRef: string;
  personRef: string;
  isPni: boolean;
  allowAddSNI: boolean;
}

export interface CurrentInsuranceFields {
  hasPriorInsurance: Field;
  state: Field;
  carrier: Field;
  carrierNameText: Field;
  years: Field;
  policyInceptionDate: Field;
  endDate: Field;
  lapse: Field;
  limit: Field;
  vehicleComprehensive: Field;
  vehicleCollision: Field;
}

const useGetFields = (driverRef: string, priorInsuranceRef: string): CurrentInsuranceFields => {
  const useDriverField = useFieldWithPrefix(driverRef, 'driver.<id>');
  const userPriorInsuranceField = useFieldWithPrefix(priorInsuranceRef, 'priorInsurance.<id>');

  return {
    hasPriorInsurance: useDriverField('hasPriorInsurance'),
    state: userPriorInsuranceField('state'),
    carrier: userPriorInsuranceField('carrierName'),
    carrierNameText: userPriorInsuranceField('carrierNameText'),
    years: userPriorInsuranceField('years'),
    policyInceptionDate: userPriorInsuranceField('policyInceptionDate'),
    endDate: userPriorInsuranceField('policyEndDate'),
    lapse: userPriorInsuranceField('lapse'),
    limit: userPriorInsuranceField('coverages.policy.bodilyInjury'),
    vehicleComprehensive: userPriorInsuranceField('coverages.vehicle.comprehensive'),
    vehicleCollision: userPriorInsuranceField('coverages.vehicle.collision'),
  };
};

// TODO: move to it's own file
export const DriverSni: React.FC<{ driverRef: string; personRef: string; allowAddSNI: boolean }> = (
  props,
) => {
  const { allowAddSNI, driverRef, personRef } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();

  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');

  const {
    props: { value: name = '' },
  } = usePersonField('firstName');
  const nameOrYou = name || 'you';

  const isSni = useField('static.isSni');
  const isSniValue = useIsSniValue(personRef);

  const handleUpdateSecondaryPolicyHolder = useEvent(async (value: AnswerValue) => {
    await isSni.props.actionOnChange(value);
    const remove = !value;
    await dispatch(updateSecondaryPolicyHolderByDriver(driverRef, remove));
  });

  return (
    <GridItem topSpacing='lg' xs={12} show={allowAddSNI}>
      <RadioGroupWithOptions
        id='secondaryDriver'
        {...isSni.props}
        value={isSniValue}
        label={
          <>
            {`Will ${nameOrYou} be a secondary policy holder? `}
            <span className={classes.textTertiary}>(optional)</span>
            <TooltipWithIcon title={metadata.secondaryPolicyHolderHelpText} />
          </>
        }
        variant='yesNoButton'
        data-testid='isSni'
        actionOnComplete={handleUpdateSecondaryPolicyHolder}
        trackingName='SecondaryPolicyHolderButton'
      />
    </GridItem>
  );
};

export const InsuranceQuestions: React.FC<InsuranceQuestionsProps> = (props) => {
  const { driverRef, personRef, isPni, allowAddSNI } = props;
  const { classes } = useStyles();
  const dispatch = useDispatch();
  const isAutoProductSwapStateRollout = flagValues[FeatureFlags.AUTO_PRODUCT_SWAP_STATE_ROLLOUT];
  const policyStartDateFields = usePolicyStartDateFields();

  const priorRefsKey = `${driverRef}.priorInsurance.ref`;
  const priorRefsValue = useSelector((state: RootStore) =>
    getAnswer(state, priorRefsKey),
  ) as Array<string>;
  const priorRef = priorRefsValue?.[0] || '';

  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const insuranceFields = useGetFields(driverRef, priorRef);

  const {
    hasPriorInsurance,
    state,
    carrier,
    carrierNameText,
    years,
    policyInceptionDate,
    endDate,
    lapse,
    limit,
    vehicleCollision,
    vehicleComprehensive,
  } = insuranceFields;

  const baseInsuranceQuestions = {
    hasPriorInsurance,
    state,
    carrier,
    carrierNameText,
    years,
    policyInceptionDate,
    endDate,
    lapse,
    limit,
    vehicleCollision,
    vehicleComprehensive,
  };

  // PNI and the midvale v4 flow will have effective date displayed in these set of insurance questions
  useAddFields(
    isPni && isAutoProductSwapStateRollout
      ? { ...baseInsuranceQuestions, policyStartDateFields }
      : baseInsuranceQuestions,
  );

  useInitValues({
    [hasPriorInsurance.key]: false,
  });

  const minEffectiveDate = dayjs().startOf('d').add(1, 'd');

  const maxDays = calculateMaxPolicyStartDaysLimit({
    isAutoProduct: true,
  });

  const maxEffectiveDate = dayjs().startOf('d').add(maxDays, 'd');

  const offersExist = useSelector(getOffersExist);
  const isSniValue = useIsSniValue(personRef);
  const requestCoveragesBI = useField('offer.requestedCoverages.policy.bodilyInjury');

  const getRequestedOfferBI = useCallback(
    (priorBIValue: string): string => {
      const { options } = requestCoveragesBI.props;
      if (!priorBIValue || !options) return '';
      const last = priorBIValue.substring(priorBIValue.lastIndexOf('.') + 1);
      const match = options.find(
        (option) => option.value.substring(option.value.lastIndexOf('.') + 1) === last,
      );
      if (!match) {
        // only one that doesn't match
        return 'REQUESTED_COVERAGES.POLICY.BODILY_INJURY.TWOHUNDREDFIFTY_FIVEHUNDRED';
      }

      return match.value;
    },
    [requestCoveragesBI.props],
  );

  const {
    props: { value: name = '' },
  } = usePersonField('firstName');
  const nameOrYou = name || 'you';
  const possessiveName = name ? `${name}'s` : 'your';

  const currentCarrier = carrier.props.options?.find((option) => option.value === carrier.value);
  let currentCarrierName = currentCarrier ? currentCarrier.label : 'current insurance company';
  const filteredCarrierProps = {
    ...carrier.props,
    options: carrier.props?.options?.filter((option) => option.value !== 'CARRIER.PREFILL'),
  };
  if (currentCarrier?.value === 'CARRIER.PREFILL') {
    filteredCarrierProps.options?.push({
      ...currentCarrier,
      label: carrierNameText.value as string,
    });
    currentCarrierName = carrierNameText.value as string;
  }

  const priorPolicyProps = useMultiFieldsForCheckBoxGroup(
    [vehicleCollision, vehicleComprehensive],
    PriorPolicyCoverageOptions,
  );

  // FIXME: useEffect that send data are dangerous
  // we could instead have:
  // const [priorRef, setPriorRef] = useState(dispatch(createRef('priorInsurance')));
  // and then only when we save the page do we attach the prior ref to the driver
  // Another alternative is that the useEffect is dispatching a specific action
  // that could be mocked:
  // useEffect(() => {
  //   // warning async code
  //   dispatch(ensurePriorInsuranceRef(driverRef));
  // }, [driverRef]);
  useEffect(() => {
    if (!priorRefsValue) {
      const priorRef = dispatch(createRef('priorInsurance'));
      // warning async code
      dispatch(
        updateAddedRef({
          type: `${priorRefsKey}`,
          newRef: priorRef,
        }),
      );
    }
  }, [priorRefsValue, priorRefsKey, dispatch, driverRef]);

  useEffect(() => {
    // WARNING async call
    if (vehicleCollision.props.value == null) vehicleCollision.updateAndPatch(false);
  }, [vehicleCollision]);

  useEffect(() => {
    // WARNING async call
    if (vehicleComprehensive.props.value == null) vehicleComprehensive.updateAndPatch(false);
  }, [vehicleComprehensive]);

  useEffect(() => {
    trackClick({ action: 'current_bi_limit_selection', label: limit.props.value });
  }, [limit.props.value]);

  useEffect(() => {
    if (endDate.props.value !== 'Invalid date')
      trackClick({ action: 'current_policy_end_date', label: endDate.props.value });
  }, [endDate.props.value]);

  const handlePolicyCoveragesComplete = useEvent(
    (value: AnswerValue, valueClicked?: string, newChecked?: boolean): void => {
      if (valueClicked === metadata.comprehensiveKey) {
        vehicleComprehensive.props.actionOnComplete(newChecked);
      } else if (valueClicked === metadata.collisionKey) {
        vehicleCollision.props.actionOnComplete(newChecked);
      }
    },
  );

  const handleCarrierChange = useEvent((value: AnswerValue) => {
    carrier.props.actionOnComplete(value);
    // Remove all prior insurance information as carrier 'None' has been selected
    if (value === 'CARRIER.NONE') {
      years.validateUpdateAndPatch('');
      policyInceptionDate.validateAndPatch('');
      endDate.validateUpdateAndPatch('');
      limit.validateUpdateAndPatch('');
      if (vehicleCollision.props.value || vehicleComprehensive.props.value) {
        vehicleCollision.validateUpdateAndPatch('false');
        vehicleComprehensive.validateUpdateAndPatch('false');
      }
    }
    // Reset lapse fields
    carrierNameText.validateUpdateAndPatch('');
    lapse.validateUpdateAndPatch('');
  });

  const handleBIComplete: NonNullable<SelectProps['actionOnComplete']> = useEvent(async (value) => {
    limit.props.actionOnComplete(value);
    if (value && isPni && !offersExist) {
      // not really a great way to default since request coverages BI already exists by the time PNI selects this
      // so it becomes a conditional "overwrite". We will overwrite it until we get offers. After that, we would run the risk
      // of overwriting a user-customized value on customize coverage feature
      await dispatch(
        updateAnswers({
          answers: { [requestCoveragesBI.key]: getRequestedOfferBI(value as string) },
        }),
      );
    }
  });

  const handleHasPriorInsuranceChange = useEvent(async (value: AnswerValue) => {
    hasPriorInsurance.validateUpdateAndPatch(value);
    // Reset the prior carrier fields when No is selected
    if (!value) {
      // clear any priorInsurance that may have been set
      if (priorRefsValue[0]) {
        await dispatch(deleteInquiryRef(priorRefsValue[0]));
      }
    }
  });

  // The "real" requirement is that CONNECT does not require SNI to answer
  // prior insurance. But, to make things simpler, we are going to treat
  // both CONNECT and MIDVALE as prior insurance is required for SNI
  // (it is always required for PNI).
  // (For Amfam-Advanced -- not asked via this component, both as well).
  const askPriorInsuranceSection = isPni || isSniValue;

  // For V3 only there is a hasPriorInsurance question
  // This question's answer determines if the details are asked.
  // For V4 there is no hasPriorInsurance question
  // So, the details are always asked
  const askPriorInsuranceQuestion = hasPriorInsurance.exists;
  const askPriorInsuranceName = hasPriorInsurance.exists ? hasPriorInsurance.value : true;

  const carrierOtherSelected = carrier.props.value === 'CARRIER.OTHER';
  const carrierNoneSelected = carrier.props.value === 'CARRIER.NONE';
  const carrierSelected = carrier.props.value && !carrierNoneSelected;

  // only shown once the user has picked a carrier
  const priorInsuranceDetails = (
    <>
      <GridItem topSpacing='lg' xs={12} show={years.exists}>
        <Select
          {...years.props}
          id='years'
          fullWidth={false}
          placeholder='Select one'
          data-testid='years'
          inputButtonAriaLabel='Years'
          groupLabel={`How many years has ${nameOrYou} been insured with ${currentCarrierName}?`}
          trackingName='years_with_current_company_select'
          className={classes.sliderOverrideClass}
        />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} show={policyInceptionDate.exists}>
        <DatePicker
          {...policyInceptionDate.props}
          fullWidth={false}
          id='CurrentAutoPolicyInceptionDate'
          groupLabel={`What was the inception date of ${possessiveName} policy?`}
          trackingName='current_policy_inception_date'
          trackingLabel={policyInceptionDate.props.value}
        />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} show={endDate.exists}>
        <DatePicker
          {...endDate.props}
          fullWidth={false}
          id='CurrentAutoPolicyEndDate'
          groupLabel={`When does ${possessiveName} current policy end?`}
          trackingName='current_policy_end_date'
          trackingLabel={endDate.props.value}
        />
      </GridItem>

      <GridItem topSpacing='lg' xs={12} show={isPni && isAutoProductSwapStateRollout}>
        <DatePicker
          {...policyStartDateFields.auto.props}
          fullWidth={false}
          data-testid='autoPSD'
          id='AutoPolicyStart'
          groupLabel={`When does ${nameOrYou} want their new coverage to start?`}
          minDate={minEffectiveDate}
          maxDate={maxEffectiveDate}
          trackingName='auto_policy_start_date'
          trackingLabel={policyStartDateFields.auto.props.value}
          analyticsElement='choice.policyStartDateModal.autoPolicyDatePicker'
          helperText='You can get a better rate by selecting the same date your current policy ends.'
        />
      </GridItem>

      <GridItem topSpacing='lg' xs={12} show={limit.exists}>
        <Select
          {...(limit.props as OptionProps)}
          actionOnComplete={handleBIComplete}
          fullWidth={false}
          groupLabel={
            <>
              What are your current Bodily Injury (liability) Limits?
              <TooltipWithIcon title={metadata.currentPolicyLimitsHelpText} />
            </>
          }
          id='CurrentAutoPolicyBILimits'
          inputButtonAriaLabel='Current Auto Policy BI Limits'
          data-testid='bodilyInjury'
          trackingName='current_bi_limit_selection'
        />
      </GridItem>
      <GridItem topSpacing='lg' xs={12} show={!!priorPolicyProps.name}>
        <CheckboxGroup
          cardSize='medium'
          options={PriorPolicyCoverageOptions}
          label={`Which of the following coverages apply to ${possessiveName} current policy?`}
          helperText='Select any that apply.'
          data-testid='coverage'
          {...priorPolicyProps}
          actionOnComplete={handlePolicyCoveragesComplete}
          trackingName='current_auto_coverages_multiselect'
        />
      </GridItem>
    </>
  );

  return (
    <>
      {/* is the user an SNI? NOTE: allowAddSNI is driven by the feature flag SNI_DRIVER_QUESTIONS */}
      <DriverSni allowAddSNI={allowAddSNI} driverRef={driverRef} personRef={personRef} />

      {askPriorInsuranceSection && (
        <>
          {/* this question is only asked on V3 */}
          <GridItem topSpacing='lg' xs={12} show={askPriorInsuranceQuestion}>
            <RadioGroupWithOptions
              {...hasPriorInsurance.props}
              actionOnComplete={handleHasPriorInsuranceChange}
              label={`Does ${nameOrYou} have an active insurance policy?`}
              id='HasPriorInsurance'
              variant='yesNoButton'
              data-testid='hasPriorInsurance'
              trackingName='PriorInsurance'
              trackingLabel={GoogleAnalyticsLabels.REDACTED}
            />
          </GridItem>
          {/* if the above question is answered YES, or this is V4 */}
          {askPriorInsuranceName && (
            <>
              <GridItem topSpacing='lg' xs={12} show={carrier.exists}>
                <Select
                  {...(filteredCarrierProps as OptionProps)}
                  fullWidth={false}
                  placeholder='Select one'
                  groupLabel={`Who is ${possessiveName} current auto insurance company?`}
                  id='CurrentAutoCompany'
                  inputButtonAriaLabel='Insurance Carrier Name'
                  data-testid='carrier'
                  actionOnChange={handleCarrierChange}
                  trackingName='current_insurance_company_selection'
                />
              </GridItem>

              {carrierOtherSelected && (
                <GridItem topSpacing='lg' xs={12} show={carrierNameText.exists}>
                  <TextField
                    {...carrierNameText.props}
                    fullWidth={false}
                    id='CarrierName'
                    ariaLabel='carrier name'
                    maxLength={30}
                    groupLabel='Please enter current carrier name'
                    trackingName='other_carrier_name'
                  />
                </GridItem>
              )}
              {carrierNoneSelected && (
                /* this is priorInsurance.<id>.lapse it appears when the client has specified NONE */
                <GridItem topSpacing='lg' xs={12} show={lapse.exists}>
                  <Select
                    {...(lapse.props as OptionProps)}
                    fullWidth={false}
                    id='lapse'
                    inputButtonAriaLabel='Lapse Reason'
                    placeholder='Select one'
                    groupLabel={`Why doesn't ${nameOrYou} currently have insurance?`}
                    label='Reason'
                    data-testid='lapse'
                    trackingName='no_insurance_reason_selection'
                  />
                </GridItem>
              )}

              {/* as long as the user has picked something other than NONE, show the details */}
              {carrierSelected && priorInsuranceDetails}
            </>
          )}
        </>
      )}
    </>
  );
};
