import { memo, useEffect } from 'react';

import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { Page } from '@ecp/features/sales/shared/components';
import { ErrorReason } from '@ecp/features/sales/shared/constants';
import { getGlobalCarrierErrors, getGlobalError } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

import { QuotesError } from '../../components';
import { useStyles } from './SessionErrorStopPage.styles';
import { GlobalError } from './sub-pages/GlobalError/GlobalError';
import { SessionExpiredError } from './sub-pages/SessionExpiredError/SessionExpiredError';
import { useNavigateToErrorUrl } from './util';

interface Props {
  text: string;
  unsetError(): void;
  clearErrorHash(): void;
}

const QUOTE_ERROR_REASONS = new Set([ErrorReason.DNQ, ErrorReason.QNB, ErrorReason.CNQ]);

export const SessionErrorStopPage: React.FC<Props> = memo((props) => {
  const { text, unsetError, clearErrorHash } = props;
  const { classes } = useStyles();
  const { errorReason, requestId, transactionId } = useSelector(getGlobalError);
  const redesignedErrorHandling = flagValues[FeatureFlags.ERROR_HANDLING_REDESIGN];
  const carrierErrors = useSelector(getGlobalCarrierErrors);

  const hasQuotesError = QUOTE_ERROR_REASONS.has(errorReason);
  const { navigateToErrorUrl } = useNavigateToErrorUrl({ errorReason });

  useEffect(() => {
    navigateToErrorUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (redesignedErrorHandling && carrierErrors) {
    return (
      <Page>
        <div className={classes.quotesErrorContainer}>
          {/* TODO: Add new error page once created (Story reference - EDSP-11150) */}
          <QuotesError xRequestId={requestId} transactionId={transactionId} />
        </div>
      </Page>
    );
  }

  if (hasQuotesError) {
    return (
      <Page>
        <div className={classes.quotesErrorContainer}>
          <QuotesError xRequestId={requestId} transactionId={transactionId} />
        </div>
      </Page>
    );
  }

  if (errorReason === ErrorReason.SESSION_EXPIRED) {
    return (
      <Page>
        <SessionExpiredError />
      </Page>
    );
  }

  return (
    <Page>
      <GlobalError unsetError={unsetError} clearErrorHash={clearErrorHash} text={text} />
    </Page>
  );
});
