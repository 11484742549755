import { useEffect } from 'react';

import { Container, Grid } from '@mui/material';

import { env } from '@ecp/env';
import type { DialogProps } from '@ecp/features/sales/shared/components';
import { Button, Dialog, ErrorDetails } from '@ecp/features/sales/shared/components';
import {
  getDalSessionId,
  getErrorDetailsForOffers,
  getInquiryId,
  getLineOfBusiness,
  getOfferSetId,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { Product } from '@ecp/features/shared/product';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { GraphicErrorCallUsImageUrl } from '@ecp/themes/base';

import { DNQ } from '../DNQ';
import { getDeclinationData } from '../util';
import { useStyles } from './DeclinationDialog.styles';
import { getDialogCloseBtnText } from './util';

export interface Props extends Pick<DialogProps, 'open' | 'onClose'> {
  product: Product;
  xRequestId?: string;
  transactionId?: string;
}

export const DeclinationDialog: React.FC<Props> = (props) => {
  const { open, onClose, product, xRequestId, transactionId } = props;
  const declinationData = useSelector(getDeclinationData(product));
  const { extendedMessage = '', quoteNumber } = declinationData;
  const { classes } = useStyles();

  useEffect(() => {
    trackSapiAnalyticsEvent({
      element: 'choice.declinationDialog.dialog',
      event: 'render',
      eventDetail: quoteNumber,
    });
  }, [quoteNumber]);
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const erroredOfferDetails = useSelector(getErrorDetailsForOffers);
  const offerSetId = useSelector(getOfferSetId);
  const dalSessionId = useSelector(getDalSessionId);
  const inquiryId = useSelector(getInquiryId);

  return (
    <Dialog
      fullScreen
      open={open}
      titleText='Declination'
      onClose={onClose}
      className={classes.root}
      trackingNameButton='DeclintionDialogCloseButton'
      trackingLabelButton={quoteNumber}
      trackingNameCloseIcon='DeclinationDialog_X'
      trackingLabelCloseIcon={quoteNumber}
      hideTitleCloseButton
    >
      <Container className={classes.container}>
        <Grid container spacing={0}>
          <Grid
            item
            md={7}
            xs={12}
            container
            direction='row'
            justifyContent='left'
            alignItems='flex-start'
          >
            <DNQ extendedMessage={extendedMessage} />
            {/* This is required only for bundle,
            For monoline quotes its already in place. */}
            {lineOfBusiness === LineOfBusiness.BUNDLE && env.static.isAgent && (
              <ErrorDetails
                dalSessionId={dalSessionId}
                inquiryId={inquiryId}
                offerSetId={offerSetId}
                xRequestId={xRequestId}
                transactionId={transactionId}
                errorDetails={erroredOfferDetails}
              />
            )}
            <Button variant='primary' onClick={onClose} className={classes.nextButton}>
              {getDialogCloseBtnText(lineOfBusiness, product)}
            </Button>
          </Grid>
          <Grid item xs={false} md={false} lg={1} />
          <Grid
            className={classes.imageGrid}
            item
            lg={4}
            md={5}
            xs={false}
            container
            direction='row'
            justifyContent='center'
            alignItems='center'
          >
            <div className={classes.imageContainer}>
              <img src={GraphicErrorCallUsImageUrl} alt='Declination notice' />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};
