import {
  LINE_OF_BUSINESS,
  PARTNER,
  PARTNER_ACCOUNT,
  PARTNER_SEGMENT,
} from '@ecp/features/sales/shared/constants';
import type { FieldsDef, Person } from '@ecp/features/sales/shared/types';
import type { LineOfBusiness } from '@ecp/features/shared/product';
import type { Field, Fields } from '@ecp/types';

import { useField, useFieldWithPrefix } from '../form/formUtil';
import { usePrimaryAddressRef } from './addressModelUtil';
import { usePniRef } from './personModelUtil';

interface PartnerFields extends Fields {
  partner: Field;
  segment: Field;
  account: Field;
}

interface ProductFields {
  product: Field<LineOfBusiness | null>;
  zipCode: Field<string | null>;
}

interface PersonFields extends Fields {
  person: FieldsDef<
    Pick<
      Person,
      'firstName' | 'lastName' | 'middleName' | 'suffix' | 'dateOfBirth' | 'email' | 'phone'
    >
  >;
}

interface RetrieveFields extends Fields {
  zipCode: Field;
  person: FieldsDef<Pick<Person, 'firstName' | 'lastName' | 'dateOfBirth' | 'email'>>;
  product: Field;
}

export interface AddressFields extends Fields {
  address: {
    line1: Field;
    line2: Field;
    city: Field;
    state: Field;
    zipcode: Field;
    isLocked?: Field;
  };
}
export const useGetProductFields = (): ProductFields => {
  const useProductField = useFieldWithPrefix('', '');
  const primaryAddressRef = usePrimaryAddressRef();
  const useAddressField = useFieldWithPrefix(primaryAddressRef, 'address.<id>');

  return {
    product: useProductField(LINE_OF_BUSINESS),
    zipCode: useAddressField('zipcode'),
  };
};

export const useGetPersonFields = (): PersonFields => {
  const pniRef = usePniRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');

  return {
    person: {
      firstName: usePersonField('firstName'),
      lastName: usePersonField('lastName'),
      middleName: usePersonField('middleName'),
      suffix: usePersonField('suffix'),
      // Use static field as SAPI person.<id>.dob field gets masked and on page refresh or backnav-forwardnav it sends masked value, which breaks
      dateOfBirth: usePersonField('dob'),
      email: usePersonField('email'),
      phone: usePersonField('phone'),
    },
  };
};

export const useGetPartnerFields = (): PartnerFields => {
  return {
    partner: useField(PARTNER),
    segment: useField(PARTNER_SEGMENT),
    account: useField(PARTNER_ACCOUNT),
  };
};

export const useGetRetrieveFields = (): RetrieveFields => {
  const pniRef = usePniRef();
  const primaryAddressRef = usePrimaryAddressRef();
  const usePersonField = useFieldWithPrefix(pniRef, 'person.<id>');
  const useAddressField = useFieldWithPrefix(primaryAddressRef, 'address.<id>');

  return {
    zipCode: useAddressField('zipcode'),
    person: {
      firstName: usePersonField('firstName'),
      lastName: usePersonField('lastName'),
      // Use static field as SAPI person.<id>.dob field gets masked and on page refresh or backnav-forwardnav it sends masked value, which breaks
      dateOfBirth: useField('static.person.dob'),
      email: usePersonField('email'),
    },
    product: useField(LINE_OF_BUSINESS),
  };
};

export const useGetAddressFields = (): AddressFields => {
  const primaryAddressRef = usePrimaryAddressRef();
  const useAddressField = useFieldWithPrefix(primaryAddressRef, 'address.<id>');

  return {
    address: {
      line1: useAddressField('line1'),
      line2: useAddressField('line2'),
      city: useAddressField('city'),
      state: useAddressField('state'),
      zipcode: useAddressField('zipcode'),
      isLocked: useAddressField('isLocked'),
    },
  };
};

export const useGetAddressFieldsFromRef = (addressRef: string): AddressFields => {
  const useAddressField = useFieldWithPrefix(addressRef, 'address.<id>');

  return {
    address: {
      line1: useAddressField('line1'),
      line2: useAddressField('line2'),
      city: useAddressField('city'),
      state: useAddressField('state'),
      zipcode: useAddressField('zipcode'),
      isLocked: useAddressField('isLocked'),
    },
  };
};
