import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SessionExpiryPromptContactInfo' })((theme) => ({
  root: {
    '& p': {
      ...theme.typography.body1,
      [theme.breakpoints.up('md')]: {
        ...theme.typography.body3,
      },
    },
  },
}));
