import dayjs from 'dayjs';

import { setDimension, TrackingDimensions } from '@ecp/utils/analytics/tracking';
import { agentAuth } from '@ecp/utils/auth';
import { uppercase, uuid } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { Queue } from '@ecp/utils/queue';
import { sessionStorage } from '@ecp/utils/storage';

import { PurchaseErrorReason, PurchaseStatusCode } from '@ecp/features/sales/shared/constants';
import {
  getAnswer,
  getIsProductOfferBindable,
  getIsProductOfferPurchased,
  getQuoteNumber,
  updatePurchaseError,
  wrapThunkActionWithErrHandler,
} from '@ecp/features/sales/shared/store';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import { SalesRequestError } from '@ecp/features/sales/shared/utils/network';
import type { Product } from '@ecp/features/shared/product';
import { getReducedProductNameFromProduct } from '@ecp/features/shared/product';

import type { ProductPurchaseStatus } from '../../types';
import { fetchAcknowledgementResponse } from '../acknowledgements';
import { fetchPaymentOptions, getCreditCardUrl, getPaymentPlanForProduct } from '../paymentoptions';
import type { PaymentOptionsRequest } from '../paymentoptions/types';
import { fetchPostBindQuestions } from '../postbind';
import {
  setCreditCardFinancialAccountToken,
  setCreditCardToken,
  setEFTPayToken,
  setEFTPayTokenForReccuringPayment,
  setGettingTokenFor,
  setHomeInspectionStatus,
  setPaymentOptionsAndAcksRefetchRequired,
  setPciToken,
  setPurchaseApiPostSuccess,
  setPurchaseRequestProduct,
  setTokenForEFTPay,
  setTokenForReccuringPayment,
} from './actions';
import {
  createPciToken,
  financialAccountsToken,
  payeezyTokenizeCreditCard,
  postOnPurchase,
} from './api';
import {
  getCreditCardType,
  getGettingTokenFor,
  getPciToken,
  getPurchaseCardToken,
  getPurchaseCardTokenFetching,
  getTokenForEFTPay,
  getTokenForReccuringPayment,
} from './selectors';
import type {
  AmFamAdvTokenParams,
  CreditCardType,
  PurchaseRequestOnSubmit,
  PurchaseResponse,
} from './types';

interface PostPurchase {
  dalSessionId: string | undefined;
  offerSetId: string | undefined;
  purchaseRequest: PurchaseRequestOnSubmit;
  products: Product[];
  timeout?: number;
  skipFetchPostBindQuestions?: boolean;
}

interface CreditCardTokenParams {
  product: Product;
  cardNumber: string;
  fullName: string;
  expirationDate: string;
  cardType: CreditCardType;
}

export interface EFTPayTokenParams {
  product: Product;
  accountType: string;
  accountUse: string;
  routingNumber: string;
  accountNumber: string;
  isReccuringPayment: boolean;
}

export const postPurchase = wrapThunkActionWithErrHandler<
  PostPurchase,
  PurchaseResponse | undefined
>(
  ({ dalSessionId, offerSetId, purchaseRequest, products, timeout, skipFetchPostBindQuestions }) =>
    async (dispatch, getState) => {
      if (!dalSessionId || !offerSetId) {
        return Promise.resolve(undefined);
      }

      // Reset missingCreditCardTokenError info
      dispatch(updatePurchaseError(null));

      const membershipCardNumber = getAnswer(getState(), 'membership.costco.number');
      let requestId = '';
      let transactionId = '';
      const controller = new AbortController();
      const { signal } = controller;
      const timeoutResult = setTimeout(() => {
        controller.abort();
      }, timeout);

      const response = await postOnPurchase({
        dalSessionId,
        purchaseRequest,
        membershipCardNumber,
        signal,
      })
        .then((result) => {
          requestId = result.requestId || '';
          transactionId = result.transactionId || '';

          return result;
        })
        .catch((error) => {
          let errorReason = null;
          if (error instanceof SalesRequestError) {
            const errorBody = error.errorBody?.toLowerCase();
            // TODO - Revisit this logic after SAPI adds proper error code instead of these random error text
            if (errorBody) {
              if (
                errorBody.includes('missingcreditcardtoken') ||
                errorBody.includes('invalid paymentoptioncreditcard') ||
                errorBody.includes('missing_cc_data') ||
                errorBody.includes('invalid_financial_token')
              ) {
                errorReason = PurchaseErrorReason.MISSING_CREDITCARD_TOKEN_ERROR;
              } else if (
                errorBody.includes('invalid membership number') ||
                errorBody.includes('member is not eligible')
              ) {
                errorReason = PurchaseErrorReason.INVALID_MEMBERSHIP_FAILURE;
              }
            }
          }

          datadogLog({
            logType: errorReason ? 'warn' : 'error',
            message: `Error posting purchase - ${error.message}`,
            context: {
              logOrigin: 'libs/features/sales/checkout/src/state/purchase/thunks.ts',
              functionOrigin: 'postPurchase',
              message: error.message,
              reason: errorReason,
              ...(error instanceof SalesRequestError && { errorData: error.errorData }),
            },
            error,
          });

          if (errorReason) {
            dispatch(updatePurchaseError(errorReason));
          }
        })
        .finally(() => {
          clearTimeout(timeoutResult);
        });

      let purchaseResponse: PurchaseResponse | undefined;
      if (response && response.payload) {
        purchaseResponse = response.payload.data;
        dispatch(setPurchaseApiPostSuccess(response.payload));
      }

      // If policies don't exist within payload, there is an internal error and user
      // is redirected to technical failure page
      if (!purchaseResponse?.policies) {
        sessionStorage.setItem('displayTechnicalFailure', true);
        datadogLog({
          logType: 'error',
          message: `Technical failure in purchase of dalSessionId ${dalSessionId}, offerSetId ${offerSetId}`,
          context: {
            logOrigin: 'libs/features/sales/checkout/src/state/purchase/thunks.ts',
            contextType: 'Purchase Error',
            functionOrigin: 'postPurchase',
            severity: 'high',
            dalSessionId,
            endpoint: `session/${dalSessionId}/offers/purchase`,
            requestId,
            transactionId,
            errorReason: PurchaseErrorReason.UNKNOWN_PURCHASE_FAILURE,
          },
        });
      } else {
        let isCreditCardDeclined = false;
        sessionStorage.setItem('displayTechnicalFailure', false);
        products.forEach((product) => {
          // Log if policy number exists
          if (purchaseResponse?.policies?.[product]?.policyNumber) {
            const reducedProductName = uppercase(getReducedProductNameFromProduct(product));
            setDimension(
              TrackingDimensions.POLICY_NUMBER[reducedProductName],
              purchaseResponse.policies[product]?.policyNumber,
            );
          }

          const policyPurchaseStatus = purchaseResponse?.policies?.[product]?.purchaseStatus;
          const policyErrorReason = purchaseResponse?.policies?.[product]?.errorReason;
          const policyErrorReasonText = policyErrorReason?.toLowerCase();
          // TODO - Revisit this logic after SAPI adds proper error code instead of these random error text
          if (
            policyErrorReasonText &&
            (policyErrorReasonText.includes('duplicate cc payment') ||
              policyErrorReasonText.includes('error processing payment') ||
              policyErrorReasonText.includes('credit card authorization failed'))
          ) {
            isCreditCardDeclined = true;
            dispatch(updatePurchaseError(PurchaseErrorReason.CREDIT_CARD_DECLINED_ERROR));
          }
          /* To make this logic backward compatible with previous version of SAPI,
          verify if the purchaseStatus is returned in response.
          For previous logic, this if condition would be skipped */
          if (policyPurchaseStatus && policyPurchaseStatus !== PurchaseStatusCode.COMPLETE) {
            // Data Dog logging
            datadogLog({
              logType: isCreditCardDeclined ? 'warn' : 'error',
              message: `dalSessionId ${dalSessionId}, offerSetId ${offerSetId}: ${product} returned purchase status ${policyPurchaseStatus}. ${
                policyErrorReason || ''
              }`,
              context: {
                logOrigin: 'libs/features/sales/checkout/src/state/purchase/thunks.ts',
                contextType: isCreditCardDeclined
                  ? 'Invalid Payment Method Warning'
                  : 'Purchase Error',
                functionOrigin: 'postPurchase',
                severity: isCreditCardDeclined ? 'medium' : 'high',
                dalSessionId,
                endpoint: `session/${dalSessionId}/offers/purchase`,
                product,
                policyPurchaseStatus,
                policyErrorReason,
                requestId,
                transactionId,
                errorReason: isCreditCardDeclined
                  ? PurchaseErrorReason.CREDIT_CARD_DECLINED_ERROR
                  : PurchaseErrorReason.UNKNOWN_PURCHASE_FAILURE,
              },
            });
          }
        });
        const homePolicy = Object.keys(purchaseResponse.policies).find((k) => k.includes('home'));
        dispatch(
          setHomeInspectionStatus(
            (homePolicy && purchaseResponse.policies[homePolicy].inspectionStatus) || '',
          ),
        );

        if (!skipFetchPostBindQuestions) dispatch(fetchPostBindQuestions({ dalSessionId }));

        const currentPurchasePolicyStatus = sessionStorage.getItem(
          'purchasePolicyStatus',
        ) as Record<string, ProductPurchaseStatus>;

        const updatedPurchasePolicyStatus = currentPurchasePolicyStatus
          ? { ...currentPurchasePolicyStatus, ...purchaseResponse.policies }
          : purchaseResponse.policies;

        sessionStorage.setItem('purchasePolicyStatus', updatedPurchasePolicyStatus);
      }

      return purchaseResponse;
    },
  'postPurchase',
);

export const tokenQueue = new Queue();

const makeHomesiteHomeToken =
  ({
    product,
    cardNumber,
    fullName,
    expirationDate,
    cardType,
  }: CreditCardTokenParams): ThunkAction<Promise<string | null>> =>
  async (dispatch, getState) => {
    let token = null;
    const creditCardType = getCreditCardType(getState(), product, cardType);
    try {
      token = await tokenQueue.add({
        key: 'token',
        work: () =>
          payeezyTokenizeCreditCard({
            cardNumber,
            fullName,
            expirationDate,
            cardType: creditCardType !== 'AmericanExpress' ? creditCardType : 'American Express',
          }),
      });
      if (token) {
        dispatch(setCreditCardToken({ product, type: cardType, token }));
      } else {
        datadogLog({
          logType: 'error',
          message: `Payeezy API Error - homesite home token was null`,
          context: {
            product,
            logOrigin: 'libs/features/sales/checkout/src/state/purchase/thunks.ts',
            contextType: 'Purchase Error',
            functionOrigin: 'makeHomesiteHomeToken',
          },
        });
      }
    } catch (error) {
      const e = error as Error;
      const isDupeError = e.message === 'no problem, rejected by duplicate';

      datadogLog({
        logType: isDupeError ? 'warn' : 'error',
        message: isDupeError
          ? `Payeezy API Error - ${e.message}`
          : `Payeezy API Error - could not make homesite home token - ${e.message}`,
        context: {
          // TODO: we cannot spread anything into DD logs
          // TODO: We need to revisit this because it is logging PII/PCI Data
          // ...e,
          product,
          logOrigin: 'libs/features/sales/checkout/src/state/purchase/thunks.ts',
          contextType: 'Purchase Error',
          functionOrigin: 'makeHomesiteHomeToken',
          message: e.message,
        },
        error: e,
      });

      return null;
    }

    return token;
  };

const makeAmfamAutoToken =
  ({
    product,
    cardNumber,
    expirationDate,
    cardType,
  }: CreditCardTokenParams): ThunkAction<Promise<string | undefined | null>> =>
  async (dispatch, getState) => {
    let token: string | null = null;
    try {
      const creditCardUrl = getCreditCardUrl(getState(), product);
      const quoteNumber = getQuoteNumber(getState(), product);
      if (!creditCardUrl) return null;

      token = (await tokenQueue.add({
        key: 'token',
        work: async () => {
          return createPciToken({
            consumerKey: quoteNumber,
            destinationLists: ['FinancialAccountService'],
            authorizationKey: creditCardUrl,
          }).then((result) => {
            return financialAccountsToken({
              consumerKey: quoteNumber,
              financialType: 'CARD',
              cardNumber,
              expirationDate: dayjs(expirationDate).format('MM/YY'),
              authorizationKey: result,
            });
          });
        },
      })) as string;
      if (token) {
        dispatch(setCreditCardToken({ token, product, type: cardType }));
      } else {
        datadogLog({
          logType: 'error',
          message: `amfam api error - amfam auto token was null`,
          context: {
            logOrigin: 'libs/features/sales/checkout/src/state/purchase/thunks.ts',
            contextType: 'Purchase Error',
            functionOrigin: 'makeAmfamAutoToken',
            product,
          },
        });
      }
    } catch (error) {
      const e = error as Error;

      datadogLog({
        logType: 'error',
        message: `amfam api error - There is an error on amfam token generation - ${e.message}`,
        context: {
          logOrigin: 'libs/features/sales/checkout/src/state/purchase/thunks.ts',
          contextType: 'Purchase Error',
          functionOrigin: 'makeAmfamAutoToken',
          product,
          message: e.message,
          // TODO: we cannot spread anything into DD logs
          // TODO: We need to revisit this because it is logging PII/PCI Data
          // ...e,
        },
        error: e,
      });
    }

    return token;
  };

const makeAmfamAdvFinancialAccountToken =
  ({
    product,
    financialType,
    cardNumber,
    expirationDate,
    accountType,
    accountUse,
    routingNumber,
    accountNumber,
    isReccuringPayment,
  }: AmFamAdvTokenParams): ThunkAction<Promise<string | undefined | null>> =>
  async (dispatch, getState) => {
    let token: string | null = null;
    try {
      const agentToken = await agentAuth.token;
      const quoteNumber = getQuoteNumber(getState(), product);
      const paymentPlan = getPaymentPlanForProduct(getState(), product);
      const transactionId = uuid();
      const paymentPurpose = paymentPlan === 'Full' ? 'FULL_PAYMENT' : 'DOWN_PAYMENT';
      const paymentId = uuid();

      if (!agentToken) return null;

      // Create PCI token only once and reuse for all payments
      const existingPciToken = getPciToken(getState());
      const currentPciToken = existingPciToken
        ? existingPciToken
        : await createPciToken({
            consumerKey: quoteNumber,
            destinationLists: ['PaymentGateWayService', 'FinancialAccountService'],
            authorizationKey: `Bearer ${agentToken}`,
            transactionId: transactionId,
          });

      if (!existingPciToken) {
        dispatch(setPciToken(currentPciToken));
      }
      token = (await financialAccountsToken({
        consumerKey: quoteNumber,
        financialType,
        authorizationKey: currentPciToken,
        cardNumber,
        expirationDate: dayjs(expirationDate).format('MM/YY'),
        accountType,
        accountUse,
        routingNumber,
        accountNumber,
      })) as string;

      if (token) {
        if (financialType === 'BANK' && !isReccuringPayment) {
          dispatch(setEFTPayToken({ token, product, paymentPurpose, paymentId }));
        } else if (financialType === 'BANK' && isReccuringPayment) {
          dispatch(
            setEFTPayTokenForReccuringPayment({
              token,
              product,
              paymentPurpose: 'RECURRING_PAYMENT',
              paymentId,
            }),
          );
        } else if (financialType === 'CARD') {
          dispatch(
            setCreditCardFinancialAccountToken({
              token,
              product,
              type: 'creditCard',
              paymentPurpose,
              paymentId,
            }),
          );
        }
      } else {
        datadogLog({
          logType: 'error',
          message: `amfam api error - amfam auto token was null`,
          context: {
            logOrigin: 'libs/features/sales/checkout/src/state/purchase/thunks.ts',
            contextType: 'Purchase Error',
            functionOrigin: 'makeAmfamAutoToken',
            product,
          },
        });
      }
    } catch (error) {
      datadogLog({
        logType: 'error',
        message: 'amfam api error - There is an error on amfam token generation',
        context: {
          logOrigin: 'libs/features/sales/checkout/src/state/purchase/thunks.ts',
          contextType: 'Purchase Error',
          functionOrigin: 'makeAmfamAutoToken',
          product,
          message: (error as Error).message,
        },
        error: error as Error,
      });
    }

    return token;
  };

export const ensureCreditCardToken = wrapThunkActionWithErrHandler<CreditCardTokenParams, void>(
  ({ product, cardNumber, fullName, expirationDate, cardType }) =>
    async (dispatch, getState) => {
      const {
        cardNumber: prevCardNumber = '',
        fullName: prevFullName = '',
        expirationDate: prevExpirationDate = '',
        type: prevCardType = '',
      } = getGettingTokenFor(getState(), product, cardType) || {};
      const token = getPurchaseCardToken(getState(), product, cardType);

      if (
        cardNumber !== prevCardNumber ||
        fullName !== prevFullName ||
        expirationDate !== prevExpirationDate ||
        cardType !== prevCardType ||
        !token
      ) {
        const tokenFetchFor = { product, cardNumber, fullName, expirationDate, type: cardType };
        const tokenFetchKey = JSON.stringify(tokenFetchFor);
        const tokenFetching = getPurchaseCardTokenFetching(getState(), product, cardType);

        // Already fetching the token for the same card type and details
        if (tokenFetching && tokenFetching === tokenFetchKey) return;

        dispatch(setGettingTokenFor(tokenFetchFor));
        switch (product) {
          case 'homesite.home':
          case 'homesite.renters': {
            if (!fullName || !cardNumber || !expirationDate || !cardType) return;
            await dispatch(
              makeHomesiteHomeToken({ product, fullName, cardNumber, expirationDate, cardType }),
            );
            break;
          }
          case 'amfam.auto': {
            if (!fullName || !cardNumber || !expirationDate) return;
            await dispatch(
              makeAmfamAutoToken({ product, fullName, cardNumber, expirationDate, cardType }),
            );
            break;
          }
          case 'amfam-adv.auto':
          case 'amfam-adv.home':
          case 'amfam-adv.renters': {
            if (!fullName || !cardNumber || !expirationDate || !cardType) return;
            await dispatch(
              makeAmfamAdvFinancialAccountToken({
                product,
                financialType: 'CARD',
                fullName,
                cardNumber,
                expirationDate,
                cardType,
              }),
            );
            break;
          }
          default:
        }
      }
    },
  'ensureCreditCardToken',
);

// This is only used for amfam adavnce and can be extended to other partners
export const ensureEFTPayToken = wrapThunkActionWithErrHandler<EFTPayTokenParams, void>(
  ({ product, accountType, accountUse, accountNumber, routingNumber, isReccuringPayment }) =>
    async (dispatch, getState) => {
      if (isReccuringPayment) {
        const {
          accountType: prevAccountType = '',
          accountUse: prevAccountUse = '',
          accountNumber: prevAccountNumber = '',
          routingNumber: prevRoutingNumber = '',
        } = getTokenForReccuringPayment(getState(), product) || {};
        if (
          accountType !== prevAccountType ||
          accountUse !== prevAccountUse ||
          accountNumber !== prevAccountNumber ||
          routingNumber !== prevRoutingNumber
        ) {
          dispatch(
            setTokenForReccuringPayment({
              product,
              accountType,
              accountUse,
              accountNumber,
              routingNumber,
            }),
          );

          switch (product) {
            case 'amfam-adv.auto':
            case 'amfam-adv.home':
            case 'amfam-adv.renters': {
              if (!accountType || !accountUse || !accountNumber || !routingNumber) return;
              await dispatch(
                makeAmfamAdvFinancialAccountToken({
                  product,
                  financialType: 'BANK',
                  accountType,
                  accountUse,
                  accountNumber,
                  routingNumber,
                  isReccuringPayment,
                }),
              );
              break;
            }
            default:
          }
        }
      } else {
        const {
          accountType: prevAccountType = '',
          accountUse: prevAccountUse = '',
          accountNumber: prevAccountNumber = '',
          routingNumber: prevRoutingNumber = '',
        } = getTokenForEFTPay(getState(), product) || {};
        if (
          accountType !== prevAccountType ||
          accountUse !== prevAccountUse ||
          accountNumber !== prevAccountNumber ||
          routingNumber !== prevRoutingNumber
        ) {
          dispatch(
            setTokenForEFTPay({
              product,
              accountType,
              accountUse,
              accountNumber,
              routingNumber,
            }),
          );
          switch (product) {
            case 'amfam-adv.auto':
            case 'amfam-adv.home':
            case 'amfam-adv.renters': {
              if (!accountType || !accountUse || !accountNumber || !routingNumber) return;
              await dispatch(
                makeAmfamAdvFinancialAccountToken({
                  product,
                  financialType: 'BANK',
                  accountType,
                  accountUse,
                  accountNumber,
                  routingNumber,
                }),
              );
              break;
            }
            default:
          }
        }
      }
    },
  'ensureEFTPayToken',
);

export const getPaymentOptionsAndAcksForCheckout =
  ({ dalSessionId, products }: PaymentOptionsRequest): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    // Dont allow getting payment options and acknowledgements for purchased and non-bindable products
    const productsForCheckout = products
      .filter((product) => !getIsProductOfferPurchased(getState(), product))
      .filter((product) => getIsProductOfferBindable(getState(), product));
    if (!productsForCheckout.length) {
      return;
    }

    await Promise.all([
      dispatch(fetchPaymentOptions({ dalSessionId, products: productsForCheckout })),
      dispatch(
        fetchAcknowledgementResponse({
          dalSessionId,
          products: productsForCheckout,
        }),
      ),
    ]);
    dispatch(setPaymentOptionsAndAcksRefetchRequired(false));
    dispatch(setPurchaseRequestProduct(productsForCheckout));
  };

export const getPaymentOptionsForRetry =
  ({ dalSessionId, products }: PaymentOptionsRequest): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    // Dont allow getting payment options for purchased products
    const productsForCheckout = products.filter(
      (product) => !getIsProductOfferPurchased(getState(), product),
    );
    if (!productsForCheckout.length) {
      return;
    }
    await dispatch(fetchPaymentOptions({ dalSessionId, products: productsForCheckout }));
    dispatch(setPaymentOptionsAndAcksRefetchRequired(false));
    dispatch(setPurchaseRequestProduct(productsForCheckout));
  };
