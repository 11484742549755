import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useMemo, useState } from 'react';

import { trackClick } from '@ecp/utils/analytics/tracking';
import { ensureStringArray, uniqueBy } from '@ecp/utils/common';
import type { GeoAddress } from '@ecp/utils/geo';
import { fetchSuggestions, useGeoAddressOptions, validateAndCombineAddress } from '@ecp/utils/geo';
import { datadogLog } from '@ecp/utils/logger';

import {
  PRIMARY_INSURED_ADDRESS_REF,
  PRIMARY_INSURED_MAILING_ADDRESS_REF,
  STATE_CODE_PREFIX,
  STATIC_INITIAL_PRIMARY_INSURED_ADDRESS_REF_FROM_RELATE,
  STATIC_INITIAL_PRIMARY_INSURED_MAILING_ADDRESS_REF_FROM_RELATE,
  STATIC_PREVIOUS_PRIMARY_INSURED_ADDRESS_REF,
  STATIC_PREVIOUS_PRIMARY_MAILING_INSURED_ADDRESS_REF,
} from '@ecp/features/sales/shared/constants';
import type { AddressFields } from '@ecp/features/sales/shared/store';
import {
  createRef,
  deleteInquiryRef,
  getAddressesValues,
  getAddressInfo,
  getAllValues,
  getAnswer,
  getFieldValue,
  getInquiryLoaded,
  getPrimaryInsuredAddressLock,
  getPrimaryInsuredAddressRef,
  getPrimaryInsuredPersonLock,
  updateAddedRef,
  updateAnswers,
  useFieldWithPrefix,
  usePniRef,
  wrapThunkActionWithErrHandler,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Address, Answers } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { Field } from '@ecp/types';

export const usePrimaryAddressRef = (): string =>
  useSelector(getAllValues)[PRIMARY_INSURED_ADDRESS_REF] as string;

export const useIsPrimaryInsuredPersonLock = (): boolean =>
  useSelector(getPrimaryInsuredPersonLock);

export const usePrimaryInsuredAddressLock = (): boolean =>
  useSelector(getPrimaryInsuredAddressLock);

export const useInitialPrimaryAddressRefFromRelate = (): string =>
  useSelector(getAllValues)[STATIC_INITIAL_PRIMARY_INSURED_ADDRESS_REF_FROM_RELATE] as string;

export const useInitialPrimaryMailingAddressRefFromRelate = (): string =>
  useSelector(getAllValues)[
    STATIC_INITIAL_PRIMARY_INSURED_MAILING_ADDRESS_REF_FROM_RELATE
  ] as string;

export const usePreviousPrimaryAddressRef = (): string =>
  useSelector(getAllValues)[STATIC_PREVIOUS_PRIMARY_INSURED_ADDRESS_REF] as string;

export const usePreviousPrimaryMailingAddressRef = (): string =>
  useSelector(getAllValues)[STATIC_PREVIOUS_PRIMARY_MAILING_INSURED_ADDRESS_REF] as string;

export const useMailingAddressRef = (): string =>
  useSelector(getAllValues)[PRIMARY_INSURED_MAILING_ADDRESS_REF] as string;

export const usePriorAddressRef = (): string => {
  const pniRef = usePniRef();

  return useSelector(getAllValues)[`${pniRef}.additionalInformation.priorAddressRefIds`] as string;
};

// FIXME: functions like this can leverage data in noun-selectors to build these objects automatically?
// i.e. export a utility function like this one, but that uses the mapping and template information
// that way the get selectors in noun-selectors will match these create functions (they would always
// be in sync/reverseable).
export const createAddressForUpdate = (address: Address): Answers => {
  const addressRef = address.ref;
  const addressToBeUpdated = {
    [`${addressRef}.city`]: address.city,
    [`${addressRef}.latitude`]: address.latitude,
    [`${addressRef}.line1`]: address.line1,
    [`${addressRef}.line2`]: address.line2,
    [`${addressRef}.longitude`]: address.longitude,
    [`${addressRef}.state`]: `${STATE_CODE_PREFIX}${address.state}`, // add prefix to match SAPI state code
    [`${addressRef}.zipcode`]: address.zipcode,
  };

  return addressToBeUpdated;
};

export const useAddressFieldValue = (addressRef: string, fieldName: string): string =>
  useSelector(getAllValues)[`${addressRef}.${fieldName}`] as string;

export const usePrimaryAddress = (): string => {
  const primaryAddressRef = useSelector(getPrimaryInsuredAddressRef);
  const allValues = useSelector(getAllValues);

  const city = allValues[`${primaryAddressRef}.city`];
  const line1 = allValues[`${primaryAddressRef}.line1`];
  const line2 = allValues[`${primaryAddressRef}.line2`];
  const stateCode = allValues[`${primaryAddressRef}.state`] as string;

  if (line1 && city && stateCode) {
    const state = stateCode ? stateCode.substring(STATE_CODE_PREFIX.length, stateCode.length) : '';

    return `${line1}${line2 && line2 !== '' ? ` ${line2}` : ''}, ${city} ${state}`;
  }

  return '';
};

export const useAddressesValues = (refs: string[]): Record<string, Address> => {
  return useSelector((state: RootStore) => getAddressesValues(state, refs));
};

export interface AddressSuggestions {
  parsedAddress: Address | null;
  isValidAddress: boolean;
  geoAddressSuggestions: GeoAddress[];
}

export interface AddressOptions {
  label: string;
  value: string;
}
export const useAddressFields = (addressRef: string): AddressFields => {
  const useAddressField = useFieldWithPrefix(addressRef, 'address.<id>');

  return {
    address: {
      line1: useAddressField('line1'),
      line2: useAddressField('line2'),
      city: useAddressField(`city`),
      state: useAddressField(`state`),
      zipcode: useAddressField('zipcode'),
      isLocked: useAddressField('isLocked'),
    },
  };
};

export const useRemoveAllAddressRef = (ref: string): (() => void) => {
  const dispatch = useDispatch();
  const addressRefs = useAddressRef(ref);

  return useCallback(async () => {
    addressRefs.forEach(async (addressRef) => {
      await dispatch(deleteInquiryRef(addressRef));
    });
  }, [dispatch, addressRefs]);
};

export const useAddressRef = (ref: string): string[] =>
  (useSelector((state: RootStore) => getAnswer(state, ref)) as Array<string>) || [];

export const useAddAddress = (): ((ref: string) => string) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  return useCallback(
    (ref: string) => {
      if (!inquiryLoaded) {
        datadogLog({
          logType: 'error',
          message: 'inquiry not loaded',
          context: {
            logOrigin: 'apps/sales/edsp-asp/src/common/utils/AddressUtil.ts',
            functionOrigin: 'useAddAddress/useCallback',
          },
        });
        throw new Error('inquiry not loaded');
      }

      const addressRef = dispatch(createRef('address'));

      dispatch(
        updateAddedRef({
          type: ref,
          newRef: addressRef,
        }),
      );

      return addressRef;
    },
    [dispatch, inquiryLoaded],
  );
};
interface AddressAutoComplete {
  autoCompleteAddressSuggestions: string[];
  addressSuggestions: GeoAddress[];
  handleSuggestionsClearRequested: () => void;
  handleAddressSuggestionsFetch: (value: string, zip: Field, state: Field) => Promise<void>;
  handleAddressSelection: (value: string) => GeoAddress | undefined;
  gaTrackSuggestionClick: () => void;
  handleAptSuggestionFetchRequested(
    searchValue: string,
    selectedValue: string,
  ): Promise<GeoAddress[]>;
  setAddressSuggestions: Dispatch<SetStateAction<GeoAddress[]>>;
}

export const useAddressSearch = (): AddressAutoComplete => {
  const {
    handleSuggestionsFetchRequested,
    handleSuggestionsClearRequested,
    handleAptSuggestionFetchRequested,
  } = useGeoAddressOptions();
  const [addressSuggestions, setAddressSuggestions] = useState<GeoAddress[]>([]);

  const autoCompleteAddressSuggestions = useMemo(
    () =>
      addressSuggestions.map((s: GeoAddress, index, arr) => {
        if (arr.length - 1 === index) {
          return s.street_line;
        }

        return (
          s.street_line +
          (s.secondary !== ''
            ? ` ${s.secondary} (${s.entries} more entries) ${s.city}, ${s.state} ${s.zipcode}`
            : ` ${s.city}, ${s.state} ${s.zipcode}`)
        );
      }),
    [addressSuggestions],
  );

  const handleAddressSuggestionsFetch = useCallback(
    async (value: string, zip: Field, state: Field): Promise<void> => {
      if (!value.includes('Apt') || !value.includes('Ste')) {
        // Need to pass state value as well to grab suggestions instead of just filtering on zipcode because garage address
        // may be different from primary address filled in.
        const output = await handleSuggestionsFetchRequested(
          value,
          zip.value as string,
          (state.value as string) || '',
        );
        setAddressSuggestions(output);
      }
    },
    [handleSuggestionsFetchRequested],
  );

  const handleAddressSelection = useCallback(
    (value: string) => {
      const addressToBeValidated = addressSuggestions.find((address: GeoAddress) => {
        const fullAddress =
          address.street_line +
          (address.secondary !== ''
            ? ` ${address.secondary} (${address.entries} more entries) ${address.city}, ${address.state} ${address.zipcode}`
            : ` ${address.city}, ${address.state} ${address.zipcode}`);

        return fullAddress === value;
      });

      return addressToBeValidated;
    },
    [addressSuggestions],
  );
  const gaTrackSuggestionClick = (): void => {
    // TODO: Update tracking events as per address usage
    trackClick({
      action: 'SmartyStreetSuggestedAddress',
      label: 'ClickedSuggestion',
    });
    trackSapiAnalyticsEvent({
      element: 'choice.personForm.smartyStreetSuggestion',
      event: 'click',
      eventDetail: 'true',
    });
  };

  return {
    autoCompleteAddressSuggestions,
    addressSuggestions,
    handleSuggestionsClearRequested,
    handleAddressSuggestionsFetch,
    handleAddressSelection,
    gaTrackSuggestionClick,
    handleAptSuggestionFetchRequested,
    setAddressSuggestions,
  };
};

export const useUpdatePriorAddressType = (): (() => Promise<void>) => {
  const dispatch = useDispatch();

  const addressRef = usePriorAddressRef();

  const useAddressField = useFieldWithPrefix(addressRef, 'address.<id>');
  const addressType = useAddressField('addressTypes');

  return useCallback(async () => {
    if (addressRef) {
      await dispatch(
        updateAnswers({
          answers: { [addressType.key]: 'PRIOR_PRIMARY' },
        }),
      );
    }
  }, [addressRef, addressType.key, dispatch]);
};

export const buildAddressLabel = (
  addressInfo: Pick<Address, 'city' | 'line1' | 'line2' | 'state' | 'zipcode'>,
): string => {
  const addressLabel = `${addressInfo.line1} ${
    addressInfo.line2 ? ` ${addressInfo.line2},` : ','
  }${' '}${addressInfo.city} ${addressInfo.state} ${addressInfo.zipcode}`;

  return addressLabel;
};

export const isAddressComplete = (
  ref: Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'isLocked'>,
): boolean => {
  return !!(ref.line1 && ref.city && ref.state && ref.zipcode);
};

export const isAddressUnique = (
  Address1: Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'isLocked'>,
  Address2: Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'zipcode' | 'isLocked'>,
): boolean => {
  return JSON.stringify(Address1) !== JSON.stringify(Address2);
};

export type AddressUpdateParams = {
  existingAddressRef: string;
  selectedAddressRef: string;
  addressType: string;
};

export const updateAddress = wrapThunkActionWithErrHandler<AddressUpdateParams, void>(
  (params) => async (dispatch, getState) => {
    const { existingAddressRef, selectedAddressRef, addressType } = params;

    if (selectedAddressRef === existingAddressRef) return;

    const existingAddressTypesKey = `${existingAddressRef}.addressTypes`;
    const selectedAddressTypesKey = `${selectedAddressRef}.addressTypes`;

    // in addition to assigning the new address to selectedRef
    // we need to take the existing selected Address and adjust it's addressTypes
    const existingAddressTypes = ensureStringArray(
      getFieldValue(getState(), existingAddressTypesKey),
    );
    const selectedAddressTypes = ensureStringArray(
      getFieldValue(getState(), selectedAddressTypesKey),
    );
    let newExistingAddressTypes: string[] = [];
    let newSelectedAddressTypes: string[] = [];

    let answers: Answers = {};
    switch (addressType) {
      case 'MAILING':
        newExistingAddressTypes = existingAddressTypes.filter((type) => type !== 'MAILING');
        if (newExistingAddressTypes.length === 0) newExistingAddressTypes.push('UNASSIGNED');

        newSelectedAddressTypes = selectedAddressTypes;
        if (!newSelectedAddressTypes.includes('MAILING')) newSelectedAddressTypes.push('MAILING');
        newSelectedAddressTypes = newSelectedAddressTypes.filter((type) => type !== 'UNASSIGNED');

        answers = {
          [existingAddressTypesKey]: newExistingAddressTypes.join(','),
          [selectedAddressTypesKey]: newSelectedAddressTypes,
          [PRIMARY_INSURED_MAILING_ADDRESS_REF]: [selectedAddressRef],
        };
        break;
      case 'PRIMARY':
        newExistingAddressTypes = existingAddressTypes.filter(
          (type) => type !== 'PRIMARY' && type !== 'INSURED',
        );
        if (newExistingAddressTypes.length === 0) newExistingAddressTypes.push('UNASSIGNED');

        newSelectedAddressTypes = selectedAddressTypes;
        if (!newSelectedAddressTypes.includes('PRIMARY')) newSelectedAddressTypes.push('PRIMARY');
        newSelectedAddressTypes = newSelectedAddressTypes.filter((type) => type !== 'UNASSIGNED');

        answers = {
          [existingAddressTypesKey]: newExistingAddressTypes.join(','),
          [selectedAddressTypesKey]: newSelectedAddressTypes,
          [PRIMARY_INSURED_ADDRESS_REF]: [selectedAddressRef],
        };
        break;
      default:
        break;
    }

    await dispatch(
      updateAnswers({
        answers,
      }),
    );
  },
  'updateAddress',
);

export const useValidateAddress = (
  addressRef: string,
): ((addressRef: string) => Promise<AddressSuggestions>) => {
  const {
    address: { line1, line2, city, state, zipcode },
  } = useAddressFields(addressRef);

  return useCallback(
    async (addressRef: string) => {
      const stateWithoutPrefix = state.value as string;
      const inputAddress = {
        street: `${line1.value} ${line2.value ? (line2.value as string) : ''}`,
        state: stateWithoutPrefix ? stateWithoutPrefix.replace(STATE_CODE_PREFIX, '') : '',
        city: city.value as string,
      };
      const parsedAddress = await validateAndCombineAddress(inputAddress, addressRef);

      const streetAddress = `${line1.value ?? ''}${
        line2.value && line2.value ? ` ${line2.value}` : ''
      }`;
      let addressSuggestions: AddressSuggestions = {
        parsedAddress,
        isValidAddress: false,
        geoAddressSuggestions: [],
      };

      const enteredZipcode = zipcode ? (zipcode?.value?.toString() as string) : '';

      const lookupQuery = {
        value: streetAddress,
        zipcode: enteredZipcode,
        state: '',
      };
      if (
        parsedAddress &&
        parsedAddress.line1.toLowerCase() === line1?.value?.toString().toLowerCase()
      ) {
        addressSuggestions = {
          parsedAddress,
          isValidAddress: true,
          geoAddressSuggestions: [],
        };
      } else {
        await fetchSuggestions(lookupQuery).then(async (result) => {
          if (result && Array.isArray(result) && result.length > 0) {
            addressSuggestions = {
              parsedAddress,
              isValidAddress: false,
              geoAddressSuggestions: result,
            };
          } else {
            addressSuggestions = {
              parsedAddress,
              isValidAddress: false,
              geoAddressSuggestions: [],
            };
          }
        });
      }

      return addressSuggestions;
    },
    [city.value, line1.value, line2.value, state.value, zipcode],
  );
};

type AddressInfo = ReturnType<typeof getAddressInfo>;
// returns Address objects for each reference
export const getAddressInfosForRefs =
  (refs: string[]) =>
  (store: RootStore): Record<string, AddressInfo> =>
    Object.fromEntries(refs.map((ref) => [ref, getAddressInfo(store, ref)]));
// returns Address objects for individual reference
export const getAddressInfosForRef =
  (ref: string) =>
  (store: RootStore): AddressInfo =>
    getAddressInfo(store, ref);

export const useGetAllAddressRefs = (): string[] => {
  const addressesRefs = [];
  const allValues = useSelector(getAllValues);

  for (const key in allValues) {
    if (key.startsWith('address.') && key.endsWith('.line1')) {
      const addressRef = key.split('.')[1];
      addressesRefs.push('address.' + addressRef);
    }
  }

  return [...new Set(addressesRefs)];
};

export const useGetAllAddressOptions = (
  draftRef: string,
  selectedRef: string,
): AddressOptions[] => {
  // grab the Address objects for all interesting references
  const allAddressRefs = useGetAllAddressRefs();

  // Fetch address information for all references
  const allAddressInfos = useSelector(getAddressInfosForRefs(allAddressRefs));

  // Check if the selected reference is a new address
  const isSelectedRefNewAddress = !allAddressRefs.includes(selectedRef);
  const selectedAddressInfo = useSelector(getAddressInfosForRef(selectedRef));

  // Build the selected address option
  const selectedAddressOption: AddressOptions = {
    label: buildAddressLabel(selectedAddressInfo),
    value: selectedRef,
  };

  // Build options for all existing addresses
  const allExistingAddressOptions: AddressOptions[] = Object.entries(allAddressInfos).map(
    ([ref, addressInfo]) => {
      return {
        label: buildAddressLabel(addressInfo),
        value: ref,
      };
    },
  );

  // Filter out the selected address as there can be scenario
  // Primary Address is same with different ref
  // Mailing Address is same with Different ref
  // so in this case uniqby can filter out the selected ref which ends up in empty dropdown
  let uniqueAddressOptions: AddressOptions[] = isSelectedRefNewAddress
    ? allExistingAddressOptions
    : allExistingAddressOptions.filter(
        (allExistingAddressOption) =>
          allExistingAddressOption.label !== selectedAddressOption.label,
      );

  // format these addresses by their display name, and only keep the unique ones
  // NOTE: if two address are the "same" but are different refs, we will only show
  // one of them.
  uniqueAddressOptions = uniqueBy(uniqueAddressOptions, (addressOption) => addressOption.label);
  if (!isSelectedRefNewAddress) {
    uniqueAddressOptions.push(selectedAddressOption);
  }

  // Finally, add the Add New Address option, which uses the draftRef
  uniqueAddressOptions.push({
    label: 'Add New Address',
    value: isSelectedRefNewAddress && selectedRef !== draftRef ? selectedRef : draftRef,
  });

  return uniqueAddressOptions;
};
