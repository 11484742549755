import type { AllConfigs } from '../types';

export const secondaryNamedInsuredPageFlow: AllConfigs = [
  {
    executionOrder: 10,
    startEffectiveDate: '01/01/2021',
    description: 'Secondary named insured for all products',
    criteria: {
      baseExp: ['Bundle', 'Agent', 'Renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Secondary named insured',
          path: '/app/sni',
          pageId: 'sni',
        },
      ],
      navigationOrder: ['sni'],
      byPageId: {
        sni: {
          path: '/app/sni',
          pageTitle: 'Secondary named insured',
        },
      },
    },
  },
];
