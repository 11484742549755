import { emptyArray } from '@ecp/utils/common';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { ConfigType, PrefillFlow } from '@ecp/features/sales/shared/constants';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import type { LineOfBusiness, Product } from '@ecp/features/shared/product';
import { hasHomeProduct } from '@ecp/features/shared/product';
import { hasPropertyProduct, productMatchesLOB } from '@ecp/features/shared/product';

import {
  getHomePrefillFlow,
  getLastConfigVisitedPage,
  getLineOfBusiness,
  getLineOfBusinessOrder,
  getPartnerExperienceId,
} from '../inquiry/selectors';
import { getAggregatePathTrackingStatus, getNavTracking } from '../nav';
import type { NavTracking } from '../nav/types';
import { getProductsForLOB } from '../offers';
import { getPageFlowPaths, getPageFlowReferencePartialPaths } from './configUtil';
import type { PageFlow } from './types';

// Sorts page flow based on product order, since product order is based on user selection
const sortByProductOrder = (state: RootStore, flows: PageFlow[]): PageFlow[] => {
  const productOrder: string[] = getLineOfBusinessOrder(state).reduce((result, lob) => {
    const id = flows.find((flow) => productMatchesLOB(lob, flow.id as Product))?.id;
    if (id) result.push(id);

    return result;
  }, [] as string[]);

  const sortedPageFlows = flows
    .filter((p) => p.value?.pageFlows?.length)
    .sort((a, b) => productOrder.indexOf(a.id) - productOrder.indexOf(b.id));

  return sortedPageFlows;
};
export const getConfig = (state: RootStore): RootStore['config'] => state.config;

export const getPageFlowIntro = (state: RootStore): PageFlow => state.config.intro;

export const getPageFlowProducts = (state: RootStore): PageFlow[] => {
  return sortByProductOrder(state, state.config.products);
};

export const getPageFlowDelta = (state: RootStore): PageFlow[] => {
  return sortByProductOrder(state, state.config.deltas);
};

export const getProductFlowForLOB = (
  state: RootStore,
): { lob: LineOfBusiness; pageFlow: PageFlow }[] => {
  const lobs = getLineOfBusinessOrder(state);
  const productFlow = getPageFlowProducts(state);

  return lobs.length === productFlow.length
    ? lobs.map((lob, index) => {
        return {
          lob: lob,
          pageFlow: productFlow[index],
        };
      })
    : (emptyArray as unknown as { lob: LineOfBusiness; pageFlow: PageFlow }[]);
};

export const getAllConfigPagePaths = (state: RootStore): string[] => {
  const allPageFlows = getAllPageFlows(state);
  const paths: string[] = [];
  allPageFlows.forEach((pageFlow) => {
    Object.keys(pageFlow.value.byPageId).forEach((key) => {
      paths.push(pageFlow.value.byPageId[key].path);
    });
  });

  return paths;
};

const getValidPageFlows = (pageFlows: PageFlow[]): PageFlow[] => {
  return pageFlows.filter((pageFlow) => Object.keys(pageFlow.value).length > 0);
};

export const getAllPageFlows = (state: RootStore): PageFlow[] => {
  const introProductPageFlows = [state.config.intro, ...getPageFlowProducts(state)];
  const summaryDeltaAndPurchaseFlows = [
    state.config.initialSummary,
    ...getPageFlowDelta(state),
    state.config.finalSummary,
    state.config.purchase,
  ];

  const expId = getPartnerExperienceId(state);
  const isASP = expId === '2860';

  if (isSniPageEnabled(state) && state.config.secondaryNamedInsured) {
    introProductPageFlows.push(state.config.secondaryNamedInsured);
  }

  if (isTpiPageEnabled(state) && state.config.thirdPartyInterest && !isASP) {
    introProductPageFlows.push(state.config.thirdPartyInterest);
  }

  if (isHomePrefillPageEnabled(state) && state.config.homePrefillSummary) {
    introProductPageFlows.push(state.config.homePrefillSummary);
  }

  if (isTpiPageEnabled(state) && state.config.thirdPartyInterest && isASP) {
    summaryDeltaAndPurchaseFlows.splice(
      summaryDeltaAndPurchaseFlows.findIndex(
        (ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW,
      ),
      0,
      state.config.thirdPartyInterest,
    );
  }

  if (flagValues[FeatureFlags.THIRD_PARTY_REPORTS_FLOW] && state.config.thirdPartyReports) {
    summaryDeltaAndPurchaseFlows.splice(
      summaryDeltaAndPurchaseFlows.findIndex(
        (ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW,
      ),
      0,
      state.config.thirdPartyReports,
    );
  }

  return getValidPageFlows([...introProductPageFlows, ...summaryDeltaAndPurchaseFlows]);
};

export const getQuoteDeltaPurchaseFlows = (state: RootStore): PageFlow[] => {
  const summaryDeltaAndPurchaseFlows = [
    state.config.initialSummary,
    ...getPageFlowDelta(state),
    state.config.finalSummary,
    state.config.purchase,
  ];

  return summaryDeltaAndPurchaseFlows;
};

export const getSummaryDeltaAndPurchaseFlows = (state: RootStore): PageFlow[] => {
  let summaryDeltaAndPurchaseFlows = [
    state.config.initialSummary,
    ...getPageFlowDelta(state),
    state.config.finalSummary,
    state.config.purchase,
  ];

  const expId = getPartnerExperienceId(state);
  const isASP = expId === '2860';

  if (isTpiPageEnabled(state) && state.config.thirdPartyInterest && !isASP) {
    summaryDeltaAndPurchaseFlows = [state.config.thirdPartyInterest].concat(
      summaryDeltaAndPurchaseFlows,
    );
  }

  if (isSniPageEnabled(state) && state.config.secondaryNamedInsured) {
    summaryDeltaAndPurchaseFlows = [state.config.secondaryNamedInsured].concat(
      summaryDeltaAndPurchaseFlows,
    );
  }

  if (isHomePrefillPageEnabled(state) && state.config.homePrefillSummary) {
    summaryDeltaAndPurchaseFlows.splice(
      summaryDeltaAndPurchaseFlows.findIndex(
        (ele) => ele.id === ConfigType.INITIAL_SUMMARY_PAGE_FLOW,
      ),
      0,
      state.config.homePrefillSummary,
    );
  }

  if (isTpiPageEnabled(state) && state.config.thirdPartyInterest && isASP) {
    summaryDeltaAndPurchaseFlows.splice(
      summaryDeltaAndPurchaseFlows.findIndex(
        (ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW,
      ),
      0,
      state.config.thirdPartyInterest,
    );
  }

  if (flagValues[FeatureFlags.THIRD_PARTY_REPORTS_FLOW] && state.config.thirdPartyReports) {
    summaryDeltaAndPurchaseFlows.splice(
      summaryDeltaAndPurchaseFlows.findIndex(
        (ele) => ele.id === ConfigType.FINAL_SUMMARY_PAGE_FLOW,
      ),
      0,
      state.config.thirdPartyReports,
    );
  }

  return summaryDeltaAndPurchaseFlows.filter((pageFlow) => Object.keys(pageFlow.value).length > 0);
};

export const getDeltaAndPurchaseFlows = (state: RootStore): PageFlow[] => {
  let deltaAndPurchaseFlows = [
    ...getPageFlowDelta(state),
    state.config.finalSummary,
    state.config.purchase,
  ];

  if (isSniPageEnabled(state) && state.config.secondaryNamedInsured) {
    deltaAndPurchaseFlows = [state.config.secondaryNamedInsured].concat(deltaAndPurchaseFlows);
  }

  return deltaAndPurchaseFlows.filter((pageFlow) => Object.keys(pageFlow.value).length > 0);
};

export const getActiveRootStepNumber = (state: RootStore): number => {
  const lastConfigVisitedPage = getLastConfigVisitedPage(state);
  const allPageFlows = getAllPageFlows(state);

  const currentPageFlow = allPageFlows.find((pageFlow) =>
    Object.keys(pageFlow.value.byPageId)
      .map((key) => pageFlow.value.byPageId[key].path)
      .includes(lastConfigVisitedPage),
  );

  if (currentPageFlow) return allPageFlows.indexOf(currentPageFlow);

  return -1;
};
export const getAllPageFlowsExceptSummaryFlows = (state: RootStore): PageFlow[] => {
  const introProductPageFlows = [state.config.intro, ...getPageFlowProducts(state)];
  const remainingFlowsTillQuote = [];

  const expId = getPartnerExperienceId(state);
  const isASP = expId === '2860';

  if (isSniPageEnabled(state) && state.config.secondaryNamedInsured) {
    introProductPageFlows.push(state.config.secondaryNamedInsured);
  }

  if (isTpiPageEnabled(state) && state.config.thirdPartyInterest && !isASP) {
    introProductPageFlows.push(state.config.thirdPartyInterest);
  }

  if (
    flagValues[FeatureFlags.HOME_PREFILL_SUMMARY_PAGE_ABC_TEST] === 'VARIANT_A' &&
    state.config.homePrefillSummary
  ) {
    introProductPageFlows.push(state.config.homePrefillSummary);
  }
  if (isTpiPageEnabled(state) && state.config.thirdPartyInterest && isASP) {
    remainingFlowsTillQuote.push(state.config.thirdPartyInterest);
  }

  if (flagValues[FeatureFlags.THIRD_PARTY_REPORTS_FLOW] && state.config.thirdPartyReports) {
    remainingFlowsTillQuote.push(state.config.thirdPartyReports);
  }

  return getValidPageFlows([...introProductPageFlows, ...remainingFlowsTillQuote]);
};
export const getAllPagePathsExceptSummaryFlows = (
  state: RootStore,
  excludePaths?: string[],
): Array<string> => {
  const allFlowPaths = getAllPageFlowsExceptSummaryFlows(state).flatMap((flow) =>
    getPageFlowPaths(flow),
  );

  return excludePaths
    ? [...allFlowPaths].filter((p) => !excludePaths.includes(p))
    : [...allFlowPaths];
};
export const getAllProductPaths = (state: RootStore, excludePaths?: string[]): Array<string> => {
  const introPageFlow = getPageFlowIntro(state);
  const productPageFlows = getPageFlowProducts(state);
  const introPageFlowPaths = getPageFlowPaths(introPageFlow);
  const productPageFlowPaths = productPageFlows.flatMap((productPageFlow) =>
    getPageFlowPaths(productPageFlow),
  );

  return [...introPageFlowPaths, ...productPageFlowPaths];
};

export const getProductTrackingStatus = (
  state: RootStore,
  excludePaths?: string[],
): NavTracking => {
  const navTracking = getNavTracking(state);
  const allProductPaths = getAllProductPaths(state, excludePaths);

  const productPageFlowReferencePaths = getPageFlowProducts(state).flatMap((productPageFlow) =>
    getPageFlowReferencePartialPaths(productPageFlow),
  );

  return getAggregatePathTrackingStatus(
    navTracking,
    allProductPaths,
    productPageFlowReferencePaths,
  );
};

export const getFlowsTrackingStatusTillQuote = (
  state: RootStore,
  excludePaths?: string[],
): NavTracking => {
  const navTracking = getNavTracking(state);
  const allPathsExceptSummary = getAllPagePathsExceptSummaryFlows(state, excludePaths);
  const productPageFlowReferencePaths = getPageFlowProducts(state).flatMap((productPageFlow) =>
    getPageFlowReferencePartialPaths(productPageFlow),
  );

  return getAggregatePathTrackingStatus(
    navTracking,
    allPathsExceptSummary,
    productPageFlowReferencePaths,
  );
};

export const isSniPageEnabled = (state: RootStore): boolean => {
  const lineOfBusiness = getLineOfBusiness(state);
  const selectedProducts = lineOfBusiness ? getProductsForLOB(state) : [];

  const sniQuestionsEnabled = flagValues[FeatureFlags.SNI_DRIVER_QUESTIONS];
  const sniPageFeatureEnabled = flagValues[FeatureFlags.SECONDARY_NAMED_INSURED_PAGE];
  const sniPageEnabled =
    // SNIPage enabled, Auto SNI is on Additional Driver page, but there is a property product
    (sniPageFeatureEnabled && sniQuestionsEnabled && hasPropertyProduct(selectedProducts)) ||
    // SNIPage enabled, Auto SNI isn't rendered on Additional Driver page
    (sniPageFeatureEnabled && !sniQuestionsEnabled);

  return sniPageEnabled;
};

export const isTpiPageEnabled = (state: RootStore): boolean => {
  const lineOfBusiness = getLineOfBusiness(state);
  const selectedProducts = lineOfBusiness ? getProductsForLOB(state) : [];

  const tpiPageFeatureEnabled = flagValues[FeatureFlags.THIRD_PARTY_INTEREST_FLOW];
  const isAutoProductSwapStateRollout = flagValues[FeatureFlags.AUTO_PRODUCT_SWAP_STATE_ROLLOUT];

  const tpiPageEnabled =
    // if there is a property product, show Tpi page
    // (note: flag is excluding renters, this code would allow it)
    (tpiPageFeatureEnabled && hasPropertyProduct(selectedProducts)) ||
    // do not show Tpi page if only auto and it's Midvale
    (tpiPageFeatureEnabled && !isAutoProductSwapStateRollout);

  return tpiPageEnabled;
};

export const isHomePrefillPageEnabled = (state: RootStore): boolean => {
  const lineOfBusiness = getLineOfBusiness(state);
  const selectedProducts = lineOfBusiness ? getProductsForLOB(state) : [];

  const homePrefillPageFeatureEnabled =
    flagValues[FeatureFlags.HOME_PREFILL_SUMMARY_PAGE_ABC_TEST] === 'VARIANT_A';
  const isShortHomeFlow = getHomePrefillFlow(state) === PrefillFlow.SHORT;
  const homePrefillPageEnabled =
    homePrefillPageFeatureEnabled && hasHomeProduct(selectedProducts) && isShortHomeFlow;

  return homePrefillPageEnabled;
};
