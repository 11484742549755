import { useCallback, useEffect, useState } from 'react';

import { FormLabel, Grid } from '@mui/material';
import Iframe from 'react-iframe';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import {
  isMasked,
  maskPaymentAccountNumber,
  resetMaskedPaymentAccountNumber,
} from '@ecp/utils/common';

import { Alert, GridItem } from '@ecp/components';
import { useAddFields } from '@ecp/features/sales/form';
import { DatePicker, TextField } from '@ecp/features/sales/shared/components';
import { PurchaseErrorReason } from '@ecp/features/sales/shared/constants';
import { getPurchaseError, useField } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type { Product, ProductName } from '@ecp/features/shared/product';
import { useIsMobile, IconUIExclaimTriangle as WarningIcon } from '@ecp/themes/base';

import type { CreditCardType } from '../../../state/purchase';
import {
  ensureCreditCardToken,
  getCreditCardType,
  setCardNumber,
  setCreditCardType,
  setExpirationDate,
  setFilled,
  setName,
} from '../../../state/purchase';
import { useStyles } from './CreditCardPayment.styles';
import type { PaymentFieldOption } from './PaymentTerm';
import { PaymentTerm } from './PaymentTerm';

interface Props {
  reuseAutoPayment: boolean;
  paymentOptions: PaymentFieldOption[];
  creditCardUrl: string;
  type: CreditCardType;
  product: Product;
  coverageType: ProductName;
  shouldSyncPropertyToAuto: boolean;
  syncPropertyToAuto?: (newType: string) => void;
  hasTokens: boolean;
  displayPaymentImage?: boolean;
}

export const CreditCardPayment: React.FC<Props> = (props) => {
  const { classes, cx } = useStyles();
  const isMobile = useIsMobile();
  const {
    reuseAutoPayment,
    paymentOptions,
    type,
    creditCardUrl,
    product,
    coverageType,
    shouldSyncPropertyToAuto,
    syncPropertyToAuto,
    hasTokens,
  } = props;
  const creditCardType = useSelector((state: RootStore) => getCreditCardType(state, product, type));
  const dispatch = useDispatch();
  // TODO Extract iframe CC payment into connect metadata file
  const useIFrame = product?.includes('connect');
  const purchaseError = useSelector(getPurchaseError);

  const missingCreditCardToken =
    purchaseError === PurchaseErrorReason.MISSING_CREDITCARD_TOKEN_ERROR;
  const iFrameWrapperId = 'IFrameWrapperId';
  const fullNameKey =
    type === 'creditCard'
      ? `static.checkout.${coverageType}.creditCardFullName`
      : `static.checkout.${coverageType}.costcoCardFullName`;

  const cardNumberKey =
    type === 'creditCard'
      ? `static.checkout.${coverageType}.creditCardCardNumber`
      : `static.checkout.${coverageType}.costcoCardNumber`;

  const expirationDateKey =
    type === 'creditCard'
      ? `static.checkout.${coverageType}.creditCardExpirationDate`
      : `static.checkout.${coverageType}.costcoCardExpirationDate`;

  const fullName = useField(fullNameKey);
  const cardNumber = useField(cardNumberKey);
  const expirationDate = useField(expirationDateKey);

  useAddFields({
    [fullNameKey]: fullName,
    [cardNumberKey]: cardNumber,
    [expirationDateKey]: expirationDate,
  });

  useEffect(() => {
    const run = async (): Promise<void> => {
      // TODO this should be redacted when we add validation to the checkout page
      if (cardNumber.value && fullName.value && expirationDate.value && creditCardType) {
        dispatch(setFilled({ filled: true, product, type }));
      }
    };
    run();
  }, [
    dispatch,
    cardNumber.props.value,
    fullName.props.value,
    expirationDate.props.value,
    product,
    type,
    creditCardType,
    cardNumber.value,
    fullName.value,
    expirationDate.value,
  ]);

  const cardNumberValue = cardNumber.props.value;
  const [ccDisplayValue, setCcDisplayValue] = useState<string | undefined>(
    cardNumberValue ? maskPaymentAccountNumber(cardNumberValue) : undefined,
  );

  const setCardTypeByFirstDigit = useCallback(
    (value: string): void => {
      const valueTrimmed = value.trim();
      const cardNumberFirstDigit = valueTrimmed.charAt(0);

      switch (cardNumberFirstDigit) {
        case '3':
          dispatch(setCreditCardType({ creditCardType: 'AmericanExpress', product, type }));
          break;
        case '4':
          dispatch(setCreditCardType({ creditCardType: 'VISA', product, type }));
          break;
        case '5':
          dispatch(setCreditCardType({ creditCardType: 'MasterCard', product, type }));
          break;
        case '6':
          dispatch(setCreditCardType({ creditCardType: 'Discover', product, type }));
          break;
        default:
          break;
      }
    },
    [dispatch, product, type],
  );

  useEffect(() => {
    if (
      fullName.props.error ||
      cardNumber.props.error ||
      expirationDate.props.error ||
      expirationDate.props.value === 'Invalid Date'
    )
      return;
    if (!(fullName.props.value && cardNumber.props.value && expirationDate.props.value)) return;
    if (shouldSyncPropertyToAuto && syncPropertyToAuto) {
      syncPropertyToAuto(type);
    }
    dispatch(
      ensureCreditCardToken({
        product,
        cardNumber: cardNumber.props.value,
        fullName: fullName.props.value,
        expirationDate: expirationDate.props.value,
        cardType: type,
      }),
    );
  }, [
    fullName.props.error,
    cardNumber.props.error,
    expirationDate.props.error,
    fullName.props.value,
    cardNumber.props.value,
    expirationDate.props.value,
    dispatch,
    product,
    shouldSyncPropertyToAuto,
    syncPropertyToAuto,
    type,
  ]);

  useEffect(() => {
    if (cardNumber.props.value) {
      setCardTypeByFirstDigit(cardNumber.props.value);
    }
    if (missingCreditCardToken) {
      const el = document.getElementById(iFrameWrapperId);
      el?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [cardNumber.props.value, missingCreditCardToken, setCardTypeByFirstDigit]);

  const onCcNameChange = useCallback(
    (value: string) => {
      dispatch(setName({ fullName: value, type, product }));
      fullName.validateUpdateAndPatch(value);
    },
    [dispatch, fullName, product, type],
  );

  const onCcNumberChange = useCallback(
    (value: string) => {
      let valueTrimmed = value.trim();

      if (isMasked(valueTrimmed)) {
        if (ccDisplayValue && valueTrimmed.length === ccDisplayValue?.length) return; // user clicks into field and leaves
        valueTrimmed = resetMaskedPaymentAccountNumber(valueTrimmed, ccDisplayValue);
      }
      setCcDisplayValue(undefined);
      dispatch(setCardNumber({ cardNumber: valueTrimmed, product, type }));
      cardNumber.updateAndPatch(valueTrimmed);
    },
    [dispatch, product, type, cardNumber, ccDisplayValue],
  );

  const onCcNumberBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const valueTrimmed = value.trim();
      onCcNumberChange(value);
      if (cardNumber.errors?.length) return;

      const maskedValue = maskPaymentAccountNumber(valueTrimmed, 15);
      setCcDisplayValue(maskedValue);
      cardNumber.validateUpdateAndPatch(valueTrimmed);
    },

    [onCcNumberChange, cardNumber],
  );

  const onCcExpirationDateChange = useCallback(
    (value: string) => {
      dispatch(setExpirationDate({ expirationDate: value, type, product }));
      expirationDate.validateUpdateAndPatch(value);
    },
    [dispatch, expirationDate, product, type],
  );

  return (
    <Grid
      container
      direction='column'
      alignItems='flex-start'
      data-testid={`cardPayment-container-${product}`}
    >
      {!reuseAutoPayment && (
        <>
          {(purchaseError === PurchaseErrorReason.MISSING_CREDITCARD_TOKEN_ERROR || !hasTokens) &&
            !useIFrame && (
              <FormLabel component='legend' focused={false} error>
                Please re-enter credit card information
              </FormLabel>
            )}
          {purchaseError === PurchaseErrorReason.CREDIT_CARD_DECLINED_ERROR && (
            <FormLabel component='legend' focused={false} error>
              We were unable to authorize payment using this card.
              <br />
              Please check the card details or use a different card.
            </FormLabel>
          )}
          {useIFrame && (
            <div id={iFrameWrapperId} className={classes.iFrameWrapper}>
              <Alert
                type='warning'
                withIcon
                icon={
                  <WarningIcon
                    className={missingCreditCardToken ? classes.errorIcon : classes.warningIcon}
                  />
                }
                className={
                  missingCreditCardToken ? classes.alertContainerError : classes.alertContainer
                }
              >
                <span className={classes.alertText}>
                  Click &quot;Use This Card&quot; after entering your credit card information.
                </span>
              </Alert>
              <div
                ignore='true'
                className={
                  missingCreditCardToken
                    ? cx(classes.iFrameWrapper, classes.iFrameWrapperError)
                    : cx(classes.iFrameWrapper)
                }
              >
                <Iframe
                  title='Credit Card Frame for CONNECT'
                  url={creditCardUrl}
                  width='98%'
                  height={isMobile ? '270' : '338'}
                  className={isMobile ? classes.iFrameMobile : classes.iFrame}
                  loading='lazy'
                />
                <p className={isMobile ? classes.helperTextMobile : classes.helperText}>
                  Click &quot;Use This Card&quot; before choosing a payment term.
                </p>
              </div>
            </div>
          )}
          {!useIFrame && (
            <div ignore='true' className={classes.creditCardWrapper}>
              <Grid container justifyContent='space-between' columnSpacing={3.5}>
                <Grid item xs={12} className={classes.creditCardField}>
                  <TextField
                    label='Card number'
                    data-testid='ccNumber'
                    id='CcNumber'
                    inputMode='numeric'
                    pattern='[0-9]*'
                    trackingName={type === 'creditCard' ? 'CCNumber' : 'CostcoCCNumber'}
                    trackingLabel={GoogleAnalyticsLabels.REDACTED}
                    {...cardNumber.props}
                    actionOnChange={onCcNumberChange}
                    maxLength={creditCardType === 'AmericanExpress' ? 16 : 17}
                    onBlur={onCcNumberBlur}
                    value={ccDisplayValue ?? cardNumber.value}
                  />
                </Grid>
                <GridItem topSpacing='sm' xs={8} className={classes.creditCardField}>
                  <TextField
                    label='Cardholder name'
                    data-testid='cardholderName'
                    ariaLabel='Cardholder Name'
                    id={`CcName${type}`}
                    trackingName={type === 'creditCard' ? 'CCCardholder' : 'CostcoCCCardholder'}
                    trackingLabel={GoogleAnalyticsLabels.REDACTED}
                    {...fullName.props}
                    actionOnComplete={onCcNameChange}
                  />
                </GridItem>
                <GridItem topSpacing='sm' xs={4} className={classes.creditCardField}>
                  <DatePicker
                    className={classes.datePicker}
                    data-testid='expDate'
                    format='MM/YYYY'
                    placeholder='MM/YYYY'
                    hidePicker
                    id={`CcExpirationDate${type}`}
                    label='Exp. date'
                    trackingName={type === 'creditCard' ? 'CCExp' : 'CostcoCCExp'}
                    trackingLabel={GoogleAnalyticsLabels.REDACTED}
                    {...expirationDate.props}
                    // We should be only resetting field errors on change
                    actionOnChange={onCcExpirationDateChange}
                    actionOnComplete={onCcExpirationDateChange}
                  />
                </GridItem>
              </Grid>
            </div>
          )}
        </>
      )}
      <Grid container direction='column' alignItems='stretch'>
        <GridItem topSpacing='sm'>
          <PaymentTerm
            product={product}
            paymentOptions={paymentOptions}
            coverageType={coverageType}
          />
        </GridItem>
      </Grid>
    </Grid>
  );
};
