import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'InsuranceQuestions' })((theme) => ({
  ...theme.mixins.formAll,
  sliderOverrideClass: {
    '& .MuiSlider-root': {
      [theme.breakpoints.down('md')]: {
        marginBottom: 50,
      },
    },
    '& .MuiFormHelperText-root.Mui-error': {
      marginTop: 10,
    },
  },
  textTertiary: {
    color: theme.palette.text.tertiary,
  },
  hideLabel: {
    '& label': { padding: 0 },
  },
}));
