import { useCallback, useEffect, useMemo } from 'react';

import dayjs from 'dayjs';

import { ensureStringArray } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';

import {
  createRef,
  deleteAnswers,
  deleteInquiryRef,
  getAllValues,
  getIncidentRef,
  getIncidentRefsForDriver,
  getInquiryLoaded,
  getQuestion,
  getValueForPrefix,
  setNavIncidentRefChanged,
  updateAddedRef,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  Answers,
  DriverBasicInfo,
  Incident,
  QuestionMetadata,
} from '@ecp/features/sales/shared/types';
import type { Option } from '@ecp/types';

import { INCIDENT_REF_SUFFIX } from '../../constants';
import { IncidentTypeQuestionMetadata } from '../../metadata';

interface StringMap {
  [key: string]: string;
}

interface IncidentOptions {
  typeMap: StringMap;
  violationDescriptionMap: StringMap;
  claimDescriptionMap: StringMap;
  lossAmountMap: StringMap;
}

const getOptions = <T extends QuestionMetadata, U extends NonNullable<T['options']>>({
  options,
}: T): Array<Pick<U[keyof U], 'label' | 'value'>> => {
  if (options) {
    return Object.keys(options).map((key) => {
      const { value, label } = options[key];

      return { value, label };
    });
  }

  return [] as unknown as ReturnType<typeof getOptions>;
};

const getIncident = (
  ref: string,
  allValues: Answers,
  typeMap: StringMap,
  violationDescriptionMap: StringMap,
  claimDescriptionMap: StringMap,
  lossAmountMap: StringMap,
): Incident => {
  const getString = getValueForPrefix<string>(ref, allValues);
  const getNumber = getValueForPrefix<number>(ref, allValues);

  return {
    ref,
    type: typeMap[getString('type')],
    date: getString('date'),
    year: getString('year'),
    month: getString('month'),
    combinedDate: getString('incidentDate'), // This is combined date with year, month and date
    violationDescription: violationDescriptionMap[getString('violation.description')],
    claimDescription: claimDescriptionMap[getString('claim.description')],
    lossAmount: lossAmountMap[getString('lossAmount')],
    claimAmount: getString('claimAmount'),
    day: getString('day'),
    lossAmountUserEntered: getNumber('lossAmountUserEntered'),
    incidentSource: getString('incidentSource'),
    incidentStatus: getString('incidentStatus'),
  };
};

const arrayToObject = (array: Option[]): StringMap =>
  array.reduce((obj, item) => {
    // TODO StringMap must really be { [key: string]: React.ReactElement | string }
    // Change type and make sure all usage doesn't have unexpected behavior
    // @ts-ignore FIXME ASAP
    obj[item.value] = item.label;

    return obj;
  }, {} as StringMap);

const useIncidentOptions = (): IncidentOptions => {
  const typeMap = useMemo(() => {
    const incidentTypeOptions = getOptions(IncidentTypeQuestionMetadata);

    return arrayToObject(incidentTypeOptions);
  }, []);

  const violationDescriptionQuestion = useSelector(
    getQuestion('incident.<id>.violation.description'),
  );
  const violationDescriptionMap = useMemo(() => {
    return arrayToObject(violationDescriptionQuestion.options || []);
  }, [violationDescriptionQuestion?.options]);

  const claimDescriptionQuestion = useSelector(getQuestion('incident.<id>.claim.description'));
  const claimDescriptionMap = useMemo(() => {
    return arrayToObject(claimDescriptionQuestion.options || []);
  }, [claimDescriptionQuestion?.options]);

  const lossAmountQuestion = useSelector(getQuestion('incident.<id>.lossAmount'));
  const lossAmountMap = useMemo(() => {
    return arrayToObject(lossAmountQuestion.options || []);
  }, [lossAmountQuestion?.options]);

  return { typeMap, violationDescriptionMap, claimDescriptionMap, lossAmountMap };
};

export const useIncident = (incidentRef: string): Incident => {
  const allValues = useSelector(getAllValues);
  const { typeMap, violationDescriptionMap, claimDescriptionMap, lossAmountMap } =
    useIncidentOptions();

  return getIncident(
    incidentRef,
    allValues,
    typeMap,
    violationDescriptionMap,
    claimDescriptionMap,
    lossAmountMap,
  );
};

export const useIncidents = (driverRef: string): Incident[] => {
  const allValues = useSelector(getAllValues);
  const { typeMap, violationDescriptionMap, claimDescriptionMap, lossAmountMap } =
    useIncidentOptions();

  const refs: string[] = ensureStringArray(allValues[`${driverRef}.${INCIDENT_REF_SUFFIX}`]);

  return refs.map((ref) =>
    getIncident(
      ref,
      allValues,
      typeMap,
      violationDescriptionMap,
      claimDescriptionMap,
      lossAmountMap,
    ),
  );
};

export const useAllIncidents = (drivers: DriverBasicInfo[]): Incident[] => {
  const allValues = useSelector(getAllValues);
  const { typeMap, violationDescriptionMap, claimDescriptionMap, lossAmountMap } =
    useIncidentOptions();
  const allDriverIncidents = drivers.flatMap((driver) => {
    const refs: string[] = ensureStringArray(allValues[`${driver.ref}.${INCIDENT_REF_SUFFIX}`]);

    return refs.map((ref) =>
      getIncident(
        ref,
        allValues,
        typeMap,
        violationDescriptionMap,
        claimDescriptionMap,
        lossAmountMap,
      ),
    );
  });

  return allDriverIncidents;
};

export interface AddIncidentResult {
  // the api has received the update
  done: Promise<string>;
  // the ref used
  incidentRef: string;
}

export const useAddIncident = (driverRef?: string): (() => AddIncidentResult) => {
  const dispatch = useDispatch();
  const inquiryLoaded = useSelector(getInquiryLoaded);

  const driverIncidentRefs = useSelector((state: RootStore) =>
    getIncidentRefsForDriver(state, { driverRef }),
  );

  return useCallback(() => {
    // FIXME: assume inquiry is loaded?
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/incidentModelUtil.ts',
          functionOrigin: 'useAddIncident/useCallback',
        },
      });
      throw new Error('inquiry not loaded');
    }

    let incidentRef = driverRef ? dispatch(createRef('incident')) : '';
    // FIXME: createRef would start to use the same seed when init the page once there are 3 or more incidents exist
    // This is a strange behavior that may also occur on other pages that need to use createRef in the same way
    while (driverIncidentRefs.includes(incidentRef)) {
      // eslint-disable-next-line no-console
      console.log(`Duplicate incidentRef ${incidentRef} for ${driverRef}`);
      incidentRef = dispatch(createRef('incident'));
    }
    const updateIncidentNav = dispatch(setNavIncidentRefChanged(incidentRef || ''));
    const done = Promise.all([updateIncidentNav]).then((): string => incidentRef);

    return { done, incidentRef };
  }, [dispatch, inquiryLoaded, driverIncidentRefs, driverRef]);
};

export const useAttachIncidentToDriver = (
  driverRef: string,
): ((incidentRef: string) => AddIncidentResult) => {
  const dispatch = useDispatch();
  const allValues = useSelector(getAllValues);
  const inquiryLoaded = useSelector(getInquiryLoaded);

  return useCallback(
    (incidentRef: string): AddIncidentResult => {
      // FIXME: assume inquiry is loaded?
      if (!inquiryLoaded) {
        datadogLog({
          logType: 'error',
          message: 'inquiry not loaded',
          context: {
            logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/incidentModelUtil.ts',
            functionOrigin: 'useAttachIncidentToDriver',
          },
        });
        throw new Error('inquiry not loaded');
      }

      const updateIncidentNav = dispatch(setNavIncidentRefChanged(incidentRef || ''));
      const updateIncidents = dispatch(
        updateAddedRef({
          allValues,
          type: `${driverRef}.${INCIDENT_REF_SUFFIX}`,
          newRef: incidentRef,
        }),
      );
      const done = Promise.all([updateIncidents, updateIncidentNav]).then(
        (): string => incidentRef,
      );

      return { done, incidentRef };
    },
    [allValues, dispatch, driverRef, inquiryLoaded],
  );
};

// Don't use Promise.all to combine deleteInquiryRef and deleteAnswers
// because deleteAnswers create a set of keys to be deleted based on answers
// state and if answers state is not latest, it could cause problems.
// E.g. user entered incident type and description. Then just entered date in
// the text box and clidked on NO option for hasIncidents. So, onBlur event of
// incident date field gets triggered alongside hasIncidents false which could
// cause potential race condition and static.incidentDate not getting deleted successfully.
export const useRemoveIncident = (
  driverRef: string,
): ((incident: Incident | undefined) => Promise<void>) => {
  const dispatch = useDispatch();

  return useCallback(
    async (incident: Incident | undefined) => {
      if (incident) {
        // This block executes when an incident attached to a driver is removed
        if (driverRef) {
          await dispatch(deleteInquiryRef(incident.ref));
        } else {
          // This block executes when we have an incident ref created but not attached to any driver
          // if conditions are IMP, NEVER remove them. This is to capture scenarios
          // where onBLUR event is triggered on the incident fields after user
          // selected No for hasIncidents questions. Also, deleteInquiryRef should be called before deleteAnswers
          // TODO Revisit the logic of finding the delta in updateAnswers thunk. (answers preprocessor)
          if (incident.type || incident.month || incident.year || incident.claimDescription) {
            await dispatch(deleteInquiryRef(incident.ref));
            if (incident.month || incident.year) {
              await dispatch(deleteAnswers({ keys: ['static.incidentDate'] }));
            }
          }
        }
      }
    },
    [dispatch, driverRef],
  );
};

export const useAndEnsureCurrentIncidentRef = (driverRef?: string): string | undefined => {
  const inquiryLoaded = useSelector(getInquiryLoaded);
  const currentIncidentRef = useSelector(getIncidentRef);
  const addIncident = useAddIncident(driverRef);

  useEffect(() => {
    // FIXME: assume inquiry is loaded?
    if (!inquiryLoaded) {
      datadogLog({
        logType: 'error',
        message: 'inquiry not loaded',
        context: {
          logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/incidentModelUtil.ts',
          functionOrigin: 'useAndEnsureCurrentIncidentRef/useEffect',
        },
      });
      throw new Error('inquiry not loaded');
    }

    const ref = currentIncidentRef;
    if (!ref && driverRef) {
      addIncident();
    }
  }, [inquiryLoaded, currentIncidentRef, addIncident, driverRef]);

  return currentIncidentRef;
};

export const isFutureIncidentDate = ({
  year,
  month = 0,
}: {
  year: number;
  month?: number;
}): boolean => {
  if (!year) {
    datadogLog({
      logType: 'warn',
      message: 'year required',
      context: {
        logOrigin: 'libs/features/sales/quotes/auto/src/state/modelUtil/incidentModelUtil.ts',
        functionOrigin: 'isFutureIncidentDate',
      },
    });
    throw new Error('year required');
  }
  // eslint-disable-next-line no-nested-ternary
  const resolution = month ? 'M' : 'y';
  // months are zero indexed, years and days of the month are 1 indexed
  const monthIndex = month ? month - 1 : month;

  return dayjs(new Date(year, monthIndex)).isAfter(dayjs().startOf('d'), resolution);
};
