import type { AllConfigs } from '../../types.js';
import { productPageFlow as productPageFlowBase } from './metadata.js';

export const productPageFlow: AllConfigs = [
  ...productPageFlowBase,
  {
    executionOrder: 370,
    startEffectiveDate: '01/01/2021',
    description: 'Homesite Renters Product Flows',
    criteria: {
      baseExp: ['Bundle', 'Agent'],
      flow: ['', 'FLOW.SHORT', 'FLOW.LONG'],
      product: ['homesite.renters'],
      expId: ['2870', '2871'],
    },
    deltas: [
      {
        operation: 'remove',
        target: 'navigationOrder',
        value: [''],
      },
      {
        operation: 'remove',
        target: 'byPageId',
        value: 'discounts',
      },
      {
        operation: 'remove',
        target: 'menuItems',
        value: [''],
      },
    ],
  },
];
