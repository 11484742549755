import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'UnavailableQuoteRetrieveCardContent' })((theme) => ({
  root: {
    border: `1px solid ${theme.palette.other.border}`,
    height: '100%',
    backgroundColor: theme.palette.grey[50],
    boxShadow: `0px 3px 5px ${theme.palette.shadow.primary}`,
  },
  cardHeader: {
    borderBottom: `1px solid ${theme.palette.other.divider}`,
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      paddingBottom: 15,
    },
  },
  monolineCardTitleContainer: {
    ...theme.typography.h4,
    backgroundColor: theme.palette.grey[200],
    borderRadius: 5,
    width: '100%',
    maxWidth: 'fit-content',
    padding: '10px 10px 0px 0px',
    marginBottom: 16,
    textTransform: 'none',
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
      display: 'block',
      padding: '10px 10px 0px',
      marginBottom: 10,
    },
  },
  cardTitle: {
    ...theme.typography.h4,
    marginBottom: 10,
    textTransform: 'none',
    display: 'flex',
    '& h3': {
      width: 80,
      height: 42,
      borderRight: `1px solid ${theme.palette.other.border}`,
      margin: '0px 20px 0px 0px',
      paddingTop: 10,
      [theme.breakpoints.down('md')]: {
        borderRight: 0,
      },
    },
  },
  cardHeaderTitle: {
    display: 'flex',
  },
  cardHeaderQuoteId: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginTop: -10,
    },
    '& p': {
      margin: '12px 5px 0px 0px',
    },
  },
  headerRecallButton: {
    marginTop: 5,
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
    },
  },
  cardContent: {
    display: 'flex',
    padding: 0,
    margin: '0px 20px 16px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardColumn: {
    marginRight: 20,
    borderRight: `1px solid ${theme.palette.other.divider}`,
    [theme.breakpoints.down('md')]: {
      borderRight: 0,
      marginRight: 0,
      marginBottom: 10,
      paddingBottom: 10,
    },
  },
  cardList: {
    listStyleType: 'none',
    marginBlockStart: '0.5em',
    marginBlockEnd: '0em',
    '& li': {
      marginLeft: -40,
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  // TODO: Fix, this isn't working
  cardListValue: {
    textAlign: 'right',
  },
  quoteDetailList: {
    margin: '0px 0px 5px',
    listStyle: 'none',
    '& li': {
      ...theme.typography.body4,
      margin: '0px 0px 5px -40px',
    },
  },
  quoteIdDetails: {
    marginBottom: 10,
  },
  quoteId: theme.typography.body4,
}));
