import { Typography } from '@mui/material';

import { NumberFormat, TooltipWithIcon } from '@ecp/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export const NumberOfSolarPanelsQuestion: React.FC<QuestionProps> = (props) => {
  const numberOfSolarPanelsField = usePropertyField('numberOfSolarPanels', true);

  const {
    trackingName = 'no_of_solar_panels',
    label = numberOfSolarPanelsField?.question?.title,
    helperText = numberOfSolarPanelsField?.question?.helpText,
  } = props;

  if (!numberOfSolarPanelsField) return null;

  return (
    <NumberFormat
      {...numberOfSolarPanelsField?.props}
      groupLabel={
        <>
          <Typography variant='body4'>{label}</Typography>
          <TooltipWithIcon title={helperText} />
        </>
      }
      format='###'
      id='numberOfSolarPanels'
      name='numberOfSolarPanels'
      trackingName={trackingName}
      fullWidth={false}
    />
  );
};
