import type { ProductName } from '@ecp/features/shared/product';
import { LineOfBusiness } from '@ecp/features/shared/product';

export const getLineOfBusinessFromProductName = (product: ProductName): LineOfBusiness => {
  switch (product) {
    case 'auto':
      return LineOfBusiness.AUTO;
    case 'home':
      return LineOfBusiness.HOME;
    case 'renters':
      return LineOfBusiness.RENTERS;
  }
};
