import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'PropertyCardFooter' })((theme) => ({
  root: {},
  propertyFooterLabel: {
    ...theme.typography.body1Bold,
    marginBottom: 5,
  },
  mapLink: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: 30,
    color: theme.palette.text.link,
  },
  mapLinks: {
    marginTop: 15,
    '& > *:first-child': {
      marginRight: 20,
    },
  },
  mapIcon: {
    marginRight: 5,
    textAlign: 'left',
    ...theme.mixins.setColorSvg(theme.palette.text.link),
  },
  zillowIcon: {
    marginRight: 5,
    textAlign: 'left',
    fontSize: 14,
  },
}));
