import type { PurchaseErrorReason } from '@ecp/features/sales/shared/constants';
import type { RootStore } from '@ecp/features/sales/shared/store/types';

import { isAnyApiInProgress } from '../api/selectors';
import type { GlobalCarrierErrors, GlobalError, GlobalState } from './types';

export const getGlobal = (state: RootStore): GlobalState => state.global;
export const getGlobalError = (state: RootStore): GlobalError => state.global.error;
export const getGlobalCarrierErrors = (state: RootStore): GlobalCarrierErrors =>
  state.global.carrierErrors;
export const getPurchaseError = (state: RootStore): PurchaseErrorReason | null =>
  state.global.purchaseError;
export const getGlobalSeed = (state: RootStore): number => state.global.seed;
export const getInitializing = (state: RootStore): boolean => state.global.initializing;
export const getStartedInitializing = (state: RootStore): boolean =>
  state.global.startedInitializing;
export const getShowThirdPartyBanner = (state: RootStore): boolean =>
  state.global.showThirdPartyBanner;
export const getBootstrapSessionInProgress = (state: RootStore): boolean =>
  isAnyApiInProgress(state, 'bootstrap-session');
export const getShouldTeardownApp = (state: RootStore): boolean => state.global.shouldTeardownApp;
export const getBridgeOver = (state: RootStore): boolean => state.global.bridgeOver;
