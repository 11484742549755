import type { AllConfigs } from '../../types';

export const thirdPartyInterestPageFlow: AllConfigs = [
  {
    executionOrder: 10,
    startEffectiveDate: '01/01/2021',
    description: 'Interested Parties Page Flow all products',
    criteria: {
      baseExp: ['Bundle', 'Agent', 'Renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Interested parties',
          path: '/app/tpi',
          pageId: 'tpi',
        },
      ],
      navigationOrder: ['tpi'],
      byPageId: {
        tpi: {
          path: '/app/tpi',
          pageTitle: 'Interested parties',
        },
      },
    },
  },
];
