import { useCallback, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';
import { isMasked } from '@ecp/utils/common';
import { useEffectOnce, useEvent } from '@ecp/utils/react';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { Checkbox, DatePicker, TextField } from '@ecp/features/sales/shared/components';
import {
  HOME_SECONDARY_INSURED_PERSON_REF,
  RENTERS_SECONDARY_INSURED_PERSON_REF,
  SECONDARY_INSURED_PERSON_REF,
} from '@ecp/features/sales/shared/constants';
import {
  createRef,
  deleteAnswers,
  getSapiTarget,
  updateAnswers,
  useField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { LineOfBusiness } from '@ecp/features/shared/product';
import { IconCardHome, IconCardRent } from '@ecp/themes/base';
import type { AnswerValue, Field } from '@ecp/types';

import { useStyles } from './SecondaryNamedInsuredForm.styles';

interface HomeSniSectionProps {
  canCopyFromAuto?: boolean;
  selectedProduct: string;
}

export const SniQuestionsProperty: React.FC<HomeSniSectionProps> = ({
  canCopyFromAuto,
  selectedProduct,
}) => {
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const [newPersonRef, setNewPersonRef] = useState<string>();
  const label = selectedProduct === LineOfBusiness.HOME ? 'home' : 'renter';

  const propertyFieldRef =
    selectedProduct === LineOfBusiness.HOME
      ? HOME_SECONDARY_INSURED_PERSON_REF
      : RENTERS_SECONDARY_INSURED_PERSON_REF;

  const secondaryInsuredPersonRef = useField(propertyFieldRef);
  const autoSniFieldValue = useField(SECONDARY_INSURED_PERSON_REF).props.value?.trim();
  const propertyPersonRef = secondaryInsuredPersonRef.props.value
    ? secondaryInsuredPersonRef.props.value
    : newPersonRef;

  const useCopyFromAuto = useField('static.homeSni.useAutoSni');
  const isSAPIV4 = useSelector(getSapiTarget) === 'v4';
  const personQuestionPrefix = isSAPIV4 ? 'person.<id>' : 'personPropertySNI.<id>';
  const usePersonField = useFieldWithPrefix(propertyPersonRef, personQuestionPrefix);
  const personPrefix = isSAPIV4 ? 'person' : 'personPropertySNI';

  const firstName = usePersonField('firstName');
  const lastName = usePersonField('lastName');
  const dateOfBirth = usePersonField('dob');
  const staticDateOfBirth = useField(`static.personPropertySNI.dob`);
  const propertySNI = useField('person.addSecondaryInsured');

  // Auto fields, used for copying from auto SNI
  const useAutoField = useFieldWithPrefix(autoSniFieldValue, 'person.<id>');
  const driverFirstName = useAutoField('firstName');
  const driverLastName = useAutoField('lastName');
  const driverDOB = useField(`${autoSniFieldValue}.dob`);
  const staticDriverDateOfBirth = useField(`static.${autoSniFieldValue}.dob`);
  const sniDriverDateOfBirth =
    staticDriverDateOfBirth && staticDriverDateOfBirth.props.value !== undefined
      ? staticDriverDateOfBirth
      : driverDOB;

  useEffectOnce(() => {
    if (!newPersonRef) setNewPersonRef(dispatch(createRef(personPrefix)));
  });

  const handleActionOnChange = useCallback(
    (
        func: (value: AnswerValue) => void,
        secondaryInsuredPersonRef: Field<AnswerValue>,
        newPersonRef: string | undefined,
      ) =>
      (value: AnswerValue) => {
        func(value);
        if (!secondaryInsuredPersonRef.props.value)
          secondaryInsuredPersonRef.props.actionOnComplete(newPersonRef);
      },
    [],
  );

  const handleCheckbox = useEvent(async (value) => {
    if (!useCopyFromAuto.value) {
      // going to be checked
      const keysToUpdate = isSAPIV4
        ? {
            // For v4, this will point to the same person, so just use autoSni reference
            [secondaryInsuredPersonRef.key]: autoSniFieldValue,
            [staticDateOfBirth.key]: sniDriverDateOfBirth.props.value,
          }
        : {
            [firstName.key]: driverFirstName.props.value,
            [lastName.key]: driverLastName.props.value,
            [dateOfBirth.key]: sniDriverDateOfBirth.props.value,
            [secondaryInsuredPersonRef.key]: propertyPersonRef,
            [staticDateOfBirth.key]: sniDriverDateOfBirth.props.value,
          };

      await dispatch(updateAnswers({ answers: keysToUpdate }));
      firstName.update(driverFirstName.props.value);
      lastName.update(driverLastName.props.value);
      dateOfBirth.update(sniDriverDateOfBirth.props.value);
      secondaryInsuredPersonRef.update(propertyPersonRef);
      staticDateOfBirth.update(sniDriverDateOfBirth.props.value);
      useCopyFromAuto.update(true);
      propertySNI.update(true);
    } else {
      const keysToDelete = [
        firstName.key,
        lastName.key,
        dateOfBirth.key,
        secondaryInsuredPersonRef.key,
        staticDateOfBirth.key,
      ];
      await dispatch(deleteAnswers({ keys: keysToDelete }));
      firstName.update('');
      lastName.update('');
      dateOfBirth.update('');
      staticDateOfBirth.update('');
      secondaryInsuredPersonRef.update('');
      useCopyFromAuto.update(false);
      propertySNI.update(false);
    }
  });

  const handleActionOnComplete = useCallback(
    (
        field: Field<AnswerValue>,
        secondaryInsuredPersonRef: Field<AnswerValue>,
        firstName: Field<AnswerValue>,
        lastName: Field<AnswerValue>,
        dateOfBirth: Field<AnswerValue>,
        staticDob?: boolean,
      ) =>
      (value: AnswerValue) => {
        if (value) {
          field.props.actionOnComplete(value);
        } else {
          field.props.actionOnComplete('');
          field.update('');
        }
        propertySNI.update(true);
        if (staticDob) {
          staticDateOfBirth.props.actionOnComplete(value);
        }
        if (!firstName.props.value && !lastName.props.value && !dateOfBirth.props.value) {
          secondaryInsuredPersonRef.props.actionOnComplete('');
          propertySNI.update(false);
        }
      },
    [staticDateOfBirth.props, propertySNI],
  );

  const disableFields =
    canCopyFromAuto && !!staticDriverDateOfBirth.props.value && !!useCopyFromAuto.value;

  return (
    <Grid container>
      <GridItem className={classes.questionLabel} topSpacing='lg' xs={12}>
        <Box>
          <div className={classes.labelIcon}>
            {selectedProduct === LineOfBusiness.HOME ? <IconCardHome /> : <IconCardRent />}
          </div>
        </Box>
        <Typography variant='body4'>
          Who would you like to add to your{' '}
          {selectedProduct === LineOfBusiness.HOME ? 'home' : 'renter'} policy?
          <span className={classes.optional}>(optional)</span>
        </Typography>
      </GridItem>

      {canCopyFromAuto &&
        (env.static.isAgent || (staticDriverDateOfBirth.props.value && !env.static.isAgent)) && (
          <GridItem topSpacing={10} xs={12}>
            <Checkbox
              className={classes.checkBox}
              onChange={handleCheckbox}
              checked={!!useCopyFromAuto.value}
              disabled={!autoSniFieldValue}
              trackingName={`${label}_add_sni_checkbox`}
              trackingLabel={useCopyFromAuto.value ? '0' : '1'}
            />
            <span className={cx(classes.questionSubLabel, !autoSniFieldValue && classes.disabled)}>
              Add the SNI from my auto policy
            </span>
          </GridItem>
        )}
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <TextField
          {...firstName.props}
          id='SecondaryInsuredFirstName'
          label='First name'
          ariaLabel='Secondary insured first name'
          trackingName={`${label}_sni_first_name`}
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          actionOnChange={handleActionOnChange(
            firstName.props.actionOnChange,
            secondaryInsuredPersonRef,
            newPersonRef,
          )}
          disabled={disableFields}
          actionOnComplete={handleActionOnComplete(
            firstName,
            secondaryInsuredPersonRef,
            firstName,
            lastName,
            dateOfBirth,
          )}
        />
      </GridItem>
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnRight}>
        <TextField
          {...lastName.props}
          id='SecondaryInsuredLastName'
          label='Last name'
          ariaLabel='Secondary insured last name'
          trackingName={`${label}_sni_last_name`}
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          actionOnChange={handleActionOnChange(
            lastName.props.actionOnChange,
            secondaryInsuredPersonRef,
            newPersonRef,
          )}
          disabled={disableFields}
          actionOnComplete={handleActionOnComplete(
            lastName,
            secondaryInsuredPersonRef,
            firstName,
            lastName,
            dateOfBirth,
          )}
        />
      </GridItem>
      <GridItem topSpacing='sm' xs={12} md={6} className={classes.columnLeft}>
        <DatePicker
          {...dateOfBirth.props}
          value={
            isMasked(dateOfBirth.props.value)
              ? staticDateOfBirth.props.value
              : dateOfBirth.props.value
          }
          hidePicker
          id='SecondaryInsuredDob'
          label='Date of birth'
          trackingName={`${label}_sni_dob`}
          trackingLabel={GoogleAnalyticsLabels.REDACTED}
          disabled={disableFields}
          actionOnChange={handleActionOnChange(
            dateOfBirth.props.actionOnChange,
            secondaryInsuredPersonRef,
            newPersonRef,
          )}
          actionOnComplete={handleActionOnComplete(
            dateOfBirth,
            secondaryInsuredPersonRef,
            firstName,
            lastName,
            dateOfBirth,
            true,
          )}
        />
      </GridItem>
    </Grid>
  );
};
