import type { OfferInfo } from '@ecp/features/sales/shared/store/types';
import type { LineOfBusiness, Product, ProductName } from '@ecp/features/shared/product';

import { QuoteRetrieveCardContent } from '../../CardContent';
import { QuoteRetrieveCardContainer } from '../../QuoteRetrieveCardContainer';

interface AvailableQuoteCardSectionProps {
  product: ProductName | 'bundle';
  data: Partial<Record<Product, OfferInfo>> | null;
  handleChangeStartDateToggle: () => void;
  setDialogProduct: React.Dispatch<React.SetStateAction<LineOfBusiness>>;
}
export const AvailableQuoteRetrieveCardSection: React.FC<AvailableQuoteCardSectionProps> = ({
  product,
  data,
  handleChangeStartDateToggle,
  setDialogProduct,
}): JSX.Element | null => {
  if (data === null) return null;

  const cards = (
    <>
      {Object.entries(data).map(([cardProduct, cardQuoteDetails], index) => {
        return (
          <QuoteRetrieveCardContainer
            cardProduct={cardProduct}
            cardQuoteDetails={cardQuoteDetails}
            isChildContainer={product === 'bundle'}
            isParentContainer={false}
            key={`${index}-${cardProduct}`}
          >
            <QuoteRetrieveCardContent
              cardProduct={cardProduct}
              cardQuoteDetails={cardQuoteDetails}
              handleChangeStartDateToggle={handleChangeStartDateToggle}
              setDialogProduct={setDialogProduct}
            />
          </QuoteRetrieveCardContainer>
        );
      })}
    </>
  );

  return (
    <>
      {product === 'bundle' ? (
        <QuoteRetrieveCardContainer
          key='bundle'
          isParentContainer
          cardProduct='bundle'
          isChildContainer={false}
        >
          {cards}
        </QuoteRetrieveCardContainer>
      ) : (
        cards
      )}
    </>
  );
};
