import type { AllConfigs } from '../../types.js';
import { finalSummaryPageFlow as finalSummaryPageFlowsBase } from './metadata.js';

export const finalSummaryPageFlow: AllConfigs = [
  ...finalSummaryPageFlowsBase,
  {
    executionOrder: 30,
    startEffectiveDate: '01/01/2021',
    description: 'Policy Summary page for amfam advanced experiences',
    criteria: {
      baseExp: ['Agent'],
      product: ['amfam-adv.auto', 'amfam-adv.renters', 'amfam-adv.home'],
      expId: ['2860'],
    },
    config: {
      menuItems: [
        {
          text: 'Policy Summary',
          pageId: 'coverages',
          path: '/app/coverages',
        },
      ],
      navigationOrder: ['coverages'],
      byPageId: {
        coverages: {
          path: '/app/coverages',
          pageTitle: 'Coverages',
        },
      },
    },
  },
];
