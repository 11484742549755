import { useCallback } from 'react';

import { GoogleAnalyticsLabels } from '@ecp/utils/analytics/tracking';

import { useAddFields } from '@ecp/features/sales/form';
import { TextField } from '@ecp/features/sales/shared/components';
import {
  getLineOfBusiness,
  isDuplicatePerson,
  setFormErrorsResetByField,
  useFieldWithPrefix,
} from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { isLineOfBusinessAuto } from '@ecp/features/shared/product';
import type { AnswerValue } from '@ecp/types';

import type { QuestionProps } from '../../questionProps.types';

interface Props extends QuestionProps {
  personRef: string;
  disabled: boolean;
  actionOnComplete?: (value: AnswerValue) => void | null;
  addHelperText?: boolean;
}

export const FirstNameQuestion: React.FC<Props> = (props) => {
  const {
    personRef,
    id = 'FirstName',
    label = 'First name',
    trackingName = 'first_name',
    trackingLabel = GoogleAnalyticsLabels.REDACTED,
    disabled,
    addHelperText = true,
    actionOnComplete = null,
  } = props;
  const dispatch = useDispatch();
  const usePersonField = useFieldWithPrefix(personRef, 'person.<id>');
  const firstName = usePersonField('firstName');
  const lastName = usePersonField('lastName');
  const dateOfBirth = usePersonField('dob');
  const lineOfBusiness = useSelector(getLineOfBusiness);
  const isAutoQuote = isLineOfBusinessAuto(lineOfBusiness);
  const helperText =
    !addHelperText || !isAutoQuote ? '' : 'Enter as displayed on your driver’s license.';

  useAddFields({ firstName });

  const handleFirstNameComplete = useCallback(
    (value: AnswerValue): void => {
      firstName.props.actionOnComplete(value);
      if (isDuplicatePerson(lastName.props.error))
        dispatch(setFormErrorsResetByField({ key: `${personRef}.lastName` }));
      if (isDuplicatePerson(dateOfBirth.props.error)) {
        dispatch(setFormErrorsResetByField({ key: `${personRef}.dob` }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastName.props.error, dateOfBirth.props.error, dispatch, personRef],
  );

  if (!firstName.exists && !disabled) return null;

  return (
    <TextField
      {...firstName.props}
      actionOnComplete={actionOnComplete ? actionOnComplete : handleFirstNameComplete}
      ariaLabel='First Name'
      autoComplete='given-name'
      data-testid='firstName'
      disabled={disabled}
      helperText={helperText}
      id={id}
      label={label}
      trackingLabel={trackingLabel}
      trackingName={trackingName}
    />
  );
};
