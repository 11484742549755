export const WARNING_BODY_TEXT = 'For your security, this session will end soon.';
export const EXPIRED_TITLE_TEXT = 'Session expired';

export const getSessionPromptWarningLabels = (): {
  buttonText: string;
  titleText: string;
  trackingLabel: string;
  trackingName: string;
  trackingNameCloseIcon: string;
} => {
  const buttonText = 'Continue session';
  const titleText = 'Session time out in';
  const trackingLabel = 'time_out_modal_version: Warning';
  const trackingName = 'continue_session_button';
  const trackingNameCloseIcon = 'continue_session_x';

  return {
    buttonText,
    titleText,
    trackingLabel,
    trackingName,
    trackingNameCloseIcon,
  };
};

export const getSessionPromptExpiredBodyText = (offerExist: boolean): string => {
  return !offerExist
    ? 'To safeguard your data, your session has closed. You may start over to continue getting a quote.'
    : 'To safeguard your data, your session has closed. You may continue your saved quote to pick up where you left off.';
};

export const getSessionPromptExpiredLabels = (
  offerExist: boolean,
): {
  buttonText: string;
  titleText: string;
  trackingLabel: string;
  trackingName: string;
  trackingNameCloseIcon: string;
} => {
  const titleText = EXPIRED_TITLE_TEXT;
  const buttonText = !offerExist ? 'Get quote' : 'Continue quote';
  const trackingLabel = !offerExist
    ? 'time_out_modal_version:expired_no_offer'
    : 'time_out_modal_version:expired_with_offer';

  const trackingName = !offerExist ? 'get_quote_button' : 'continue_quote_button';
  const trackingNameCloseIcon = !offerExist ? 'get_quote_x' : 'continue_quote_x';

  return {
    buttonText,
    titleText,
    trackingLabel,
    trackingName,
    trackingNameCloseIcon,
  };
};

export const getSessionPromptLabels = (
  isSessionIdle: boolean,
  offerExist: boolean,
): {
  buttonText: string;
  titleText: string;
  trackingLabel: string;
  trackingName: string;
  trackingNameCloseIcon: string;
} => {
  if (!isSessionIdle) {
    return getSessionPromptWarningLabels();
  }

  return getSessionPromptExpiredLabels(offerExist);
};
