import type { AllConfigs } from '../../types';

export const finalSummaryPageFlow: AllConfigs = [
  {
    executionOrder: 10,
    startEffectiveDate: '01/01/2021',
    description: 'Coverages page for all experiences',
    criteria: {
      baseExp: ['Bundle', 'Renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Policy coverages',
          pageId: 'coverages',
          path: '/app/coverages',
        },
      ],
      navigationOrder: ['coverages'],
      byPageId: {
        coverages: {
          path: '/app/coverages',
          pageTitle: 'Coverages',
        },
      },
    },
  },
  {
    executionOrder: 20,
    startEffectiveDate: '01/01/2021',
    description: 'Verified summary for all Moonshot',
    criteria: {
      baseExp: ['Agent'],
    },
    config: {
      menuItems: [
        {
          text: 'Verified summary',
          pageId: 'coverages',
          path: '/app/coverages',
        },
      ],
      navigationOrder: ['coverages'],
      byPageId: {
        coverages: {
          path: '/app/coverages',
          pageTitle: 'Verified summary',
        },
      },
    },
  },
];
