import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import type { QuestionProps } from '@ecp/features/sales/shared/questions';

import { usePropertyField } from '../../utils';

export default {};
export const SolarPanelsQuestion: React.FC<QuestionProps> = (props) => {
  const solarPanelsField = usePropertyField('solarPanels');

  const { label = solarPanelsField?.question?.title, trackingName = 'solar_panels' } = props;

  if (!solarPanelsField) return null;

  return (
    <RadioGroupWithOptions
      {...solarPanelsField.props}
      variant='yesNoButton'
      id='SolarPanels'
      trackingName={trackingName}
      trackingLabel={solarPanelsField.props.value}
      label={label}
    />
  );
};
