import { Fragment } from 'react';

import {
  getCheckoutDataFromOffers,
  getOfferProductsSelected,
  getPrimaryInsuredStateCode,
} from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { CarrierMessagingType } from '@ecp/features/sales/shared/types';
import type { Product, ProductName } from '@ecp/features/shared/product';
import {
  getProductDisplayNameFromProduct,
  getProductNameFromProduct,
  getReducedProductNameFromProduct,
} from '@ecp/features/shared/product';

import { Dialog } from '../Dialog';
import { useStyles } from './CarrierMessagingDialog.styles';

export interface Props {
  carrierMessages: Record<ProductName, CarrierMessagingType[]>;
  open: boolean;
  onClose(): void;
}

export const CarrierMessagingDialog: React.FC<Props> = ({ open, onClose, carrierMessages }) => {
  const { classes } = useStyles();

  const stateCode = useSelector(getPrimaryInsuredStateCode);
  const checkoutData = useSelector(getCheckoutDataFromOffers);
  const productsSelected = useSelector(getOfferProductsSelected);

  const getMessages = (product?: Product): React.ReactElement | null => {
    if (!product) return null;

    const productName = getProductNameFromProduct(product);
    const messages = carrierMessages[productName];
    if (!messages?.length) return null;

    const productDisplayName = getProductDisplayNameFromProduct(product);
    const premiumKey = getReducedProductNameFromProduct(product);
    const statePremium = checkoutData[premiumKey]?.statePremium;
    const ourPremium = checkoutData[premiumKey]?.premiumAmount;
    const messageNames = messages
      .filter((message) => !message.messageDescription)
      .map((value) => value.name || '');

    return (
      <Fragment key={productName}>
        {messageNames.length !== 0 && (
          <h3 className={classes.subHeader}>{`${productDisplayName} messages`}</h3>
        )}
        <div className={classes.body}>
          {messages.length === 1 ? (
            <p>{messages[0].message(stateCode, statePremium, ourPremium)}</p>
          ) : (
            <ul>
              {messages.map((carrierMessage, index) => (
                <li key={index}>{carrierMessage.message(stateCode, statePremium, ourPremium)}</li>
              ))}
            </ul>
          )}
        </div>
      </Fragment>
    );
  };

  // we want to add the state code. As per Bob and Tara the state code i.e. AL, IL, WI, etc.
  // will work in place of the full state name.
  const titleText = `Important information for ${stateCode}`;

  return (
    <Dialog
      actionButtonLabel='Close'
      titleText={titleText}
      actionButtonOnClick={onClose}
      open={open}
      buttonPlacement='right'
      onClose={onClose}
      trackingDimension='CarrierMessagingDialog'
      trackingNameButton='CarrierMessagingCloseButton'
      trackingLabelButton='CarrierMessagingClose'
      analyticsElementRender='choice.CarrierMessagingModal.modal'
      analyticsElementButton='choice.CarrierMessagingModal.CloseButton'
      enableBackdropClick
    >
      {productsSelected.map(getMessages)}
    </Dialog>
  );
};
