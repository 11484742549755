import { stringifySearchParams, useLocation } from '@ecp/utils/routing';

import { ErrorReason } from '@ecp/features/sales/shared/constants';
import { useNavigateToPage } from '@ecp/features/sales/shared/routing';

export const useNavigateToErrorUrl = ({
  errorReason,
}: {
  errorReason: string | undefined;
}): {
  navigateToErrorUrl: () => void;
} => {
  const location = useLocation();
  const errorCodeForUrl = `#error-${errorReason || ErrorReason.GLOBAL}`;
  // Get correct path for route
  const routePath = `${location.pathname}${stringifySearchParams(location.search)}`;
  const navigateToErrorUrl = useNavigateToPage(`${routePath}${errorCodeForUrl}`, {
    replace: true,
    removeQuery: true,
    replaceProfileAdd: false,
    addErrorHash: true,
  });

  return { navigateToErrorUrl };
};
