import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { logPageView } from '@ecp/utils/analytics/tracking';
import { agentAuth } from '@ecp/utils/auth';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import { clearDatadogSession } from '@ecp/utils/logger';
import { location, navigate, stringifySearchParams } from '@ecp/utils/routing';
import { scrollToTop } from '@ecp/utils/web';

import { env } from '@ecp/env';
import {
  PARTNER,
  PARTNER_ACCOUNT,
  PARTNER_EXPERIENCE_ID,
  PARTNER_SEGMENT,
} from '@ecp/features/sales/shared/constants';
import type { Session } from '@ecp/features/sales/shared/misc';
import { setSession } from '@ecp/features/sales/shared/misc';
import { PagePath } from '@ecp/features/sales/shared/routing';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';

import { setDalSessionId, setInquiryId } from '../inquiry';
import { setNavPreviousPageChanged } from '../nav/actions';
import { updateNavCurrentPage } from '../nav/thunks';
import { updateShouldTeardownApp } from './actions';
import { getGlobalError } from './selectors';

interface NavParam {
  ref: string;
  replace?: boolean;
}

interface NavPathParam extends NavParam {
  path: string;
  referencePage?: string;
}

interface NavRefPageParam extends NavParam {
  referencePage?: string;
}

interface PageParam {
  page: string;
  replace?: boolean;
  title?: string;
  removeQuery?: boolean;
  replaceProfileAdd?: boolean;
  pathWithoutRef?: string;
  skipLogPageView?: boolean;
  addErrorHash?: boolean;
  skipError?: boolean;
}

export const jumpToPage =
  ({
    page,
    replace = false,
    title,
    removeQuery = false,
    replaceProfileAdd = false,
    pathWithoutRef,
    skipLogPageView = false,
    addErrorHash = false,
    skipError = false,
  }: PageParam): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const { hasError } = getGlobalError(getState());
    if (hasError && !addErrorHash && !skipError) return;

    const shouldRemoveQuery = removeQuery && Object.keys(location.search).length > 0;

    // TODO Replace with something more meaningful as navigate function already
    // skips navigation if the new URL is identical to the existing URL
    // and also accounts for searh params and hash
    const match =
      !shouldRemoveQuery &&
      location.pathname.replace(/^\/|\/$/g, '') === page.replace(/^\/|\/$/g, '');
    if (match) return;

    // track page only if match is false to prevent multiple pageview events
    // updateNavCurrentPage only if the page has changed
    if (!skipLogPageView) {
      // tracks each page transition for google analytics
      // page: This field should contain only pathnames without any params or refs
      logPageView(pathWithoutRef || page, title);
      await dispatch(updateNavCurrentPage({ page }));
    }
    if (replace) {
      navigate(page, { replace: true });
    } else if (replaceProfileAdd) {
      // This logic is needed to avoid removing vehicles/drivers on back nav
      const newPage = `${location.pathname}${stringifySearchParams(location.search).replace(
        'Add',
        'Edit',
      )}`;
      navigate(newPage, { replace: true });
      dispatch(setNavPreviousPageChanged(newPage));
      navigate(page);
    } else {
      // Save previous page only if replace is set to false
      // DO NOT SAVE previous page especially when navigating
      // from agent login or session error page where replace = true.
      dispatch(
        setNavPreviousPageChanged(`${location.pathname}${stringifySearchParams(location.search)}`),
      );
      navigate(page);
    }
    scrollToTop();
  };

export const navigateToPageForRef =
  ({ path, ref, replace = false, referencePage = '' }: NavPathParam): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    if (!ref) return;
    let page = `${path}/${ref.split('.')[1]}`;
    if (referencePage) page += `?referencePage=${referencePage}`;
    await dispatch(
      jumpToPage({
        page,
        replace,
        title: document.title,
        pathWithoutRef: path,
      }),
    );
  };

export const navigateToDriver =
  ({ ref, replace = false, referencePage = '' }: NavRefPageParam): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    const path = PagePath.ADD_DRIVER_PROFILE;
    await dispatch(navigateToPageForRef({ path, ref, replace, referencePage }));
  };

export const navigateToVehicle =
  ({ ref, replace = false, referencePage = '' }: NavRefPageParam): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    const path = PagePath.ADD_VEHICLE_PROFILE;
    await dispatch(navigateToPageForRef({ path, ref, replace, referencePage }));
  };

export const navigateToAddTPI =
  ({ ref, replace = false, referencePage = '' }: NavRefPageParam): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    const path = PagePath.THIRD_PARTY_INTEREST;
    await dispatch(navigateToPageForRef({ path, ref, replace, referencePage }));
  };

/**
 * This thunk is reponsible for starting a new session with a new inquiry id, interaction id
 */
export const clearSession: ThunkAction = (dispatch) => {
  dispatch(setInquiryId(''));
  dispatch(setDalSessionId(''));
  interactionId.reset();

  // Reset all partner fields during logout action and reset only account, experience id during start over action
  const partnerFields =
    env.static.isAgent && agentAuth.isAuth
      ? {
          [PARTNER_ACCOUNT]: null,
          [PARTNER_EXPERIENCE_ID]: null,
        }
      : {
          [PARTNER]: null,
          [PARTNER_ACCOUNT]: null,
          [PARTNER_EXPERIENCE_ID]: null,
          [PARTNER_SEGMENT]: null,
        };

  const newSession: Session = {
    dalSessionId: '',
    inquiryId: '',
    offerSetId: '',
    expId: env.static.expId,
    homeInspectionStatus: '',
    ...(env.static.isAgent && partnerFields),
  };

  setSession(newSession);
};

const determineStartingPage = (): PagePath => {
  // `!agentAuth.isAuth` check is needed specifically to start over from static pages
  // which don't need session e.g. privacy policy
  if (env.static.isAgent && !agentAuth.isAuth) return PagePath.AGENT_LOGIN;

  if (
    flagValues[FeatureFlags.SELECT_PRODUCT_PAGE] &&
    !location.search.showRetrieve &&
    !location.search.retrieve &&
    !location.search.product
  ) {
    return PagePath.SELECT_PRODUCT;
  }

  return PagePath.LANDING;
};

/**
 * This thunk is reponsible for starting a new session with a new inquiry id, interaction id and also
 * navigating to either landing page or agent login page.
 * It can be used for both agent flow or consumer flow.
 */
export const startOverFlow =
  (clearQuery = false): ThunkAction<Promise<void>> =>
  async (dispatch) => {
    const page = determineStartingPage();

    dispatch(clearSession);

    await dispatch(
      jumpToPage({
        page,
        skipError: true,
        removeQuery: !!clearQuery,
      }),
    );

    // Reset session_id when agent clicks `start new quote` and `log out`
    if (env.static.isAgent) clearDatadogSession();

    // Teardown Redux store and most of React rendering tree
    dispatch(updateShouldTeardownApp(true));
  };
