import { Stack } from '@mui/material';

import { SessionExpiryPromptBodyText } from '../../sub-components/SessionExpiryPromptBodyText/SessionExpiryPromptBodyText';
import { SessionExpiryPromptContactInfo } from '../../sub-components/SessionExpiryPromptContactInfo/SessionExpiryPromptContactInfo';
import { getSessionPromptExpiredBodyText } from '../../util/labelUtil';

export const ExpiredPrompt: React.FC<{
  offerExist: boolean;
}> = ({ offerExist }) => {
  return (
    <Stack spacing='20px'>
      <SessionExpiryPromptBodyText text={getSessionPromptExpiredBodyText(offerExist)} />
      <SessionExpiryPromptContactInfo alignment='left' />
    </Stack>
  );
};
