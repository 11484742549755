import { datadogLog } from '@ecp/utils/logger';

import { fetchWithTimeout } from '../fetchWithTimeout';
import { retryOperation } from './retryOperation';
import type { RequestInitExtendedWithRetryAndTimeout, RetryOptions, TimeoutOptions } from './types';

interface FetchWithRetryAndTimeoutParams {
  url: string;
  init: RequestInitExtendedWithRetryAndTimeout;
}

/**
 * Performs a custom fetch operation with optional retry and timeout logic.
 *
 * @param {FetchWithRetryAndTimeoutParams} params - The parameters for the fetch operation, including URL and request options.
 * @param {string} params.url - The URL to fetch.
 * @param {RequestInitExtendedWithRetryAndTimeout} params.init - The fetch options which may include retry and timeout settings.
 * @returns {Promise<Response>} - The fetch response if the operation succeeds.
 * @throws {Error} - Throws the error if the fetch operation fails and all retry attempts are exhausted.
 *
 * @example
 * const fetchData = async () => {
 *   try {
 *     const response = await fetchWithRetryAndTimeout({
 *       url: 'https://api.example.com/data',
 *       init: {
 *         method: 'GET',
 *         headers: {
 *           'Content-Type': 'application/json'
 *         },
 *         retry: {
 *           maxAttempts: 3,
 *           retryFunctions: [shouldRetryForNetworkErrors]
 *         },
 *         timeout: {
 *           value: 5000,
 *           factor: 2
 *         }
 *       }
 *     });
 *     const data = await response.json();
 *     console.log(data);
 *   } catch (error) {
 *     console.error('Fetch failed after retries', error);
 *   }
 * };
 */
export async function fetchWithRetryAndTimeout({
  url,
  init,
}: FetchWithRetryAndTimeoutParams): Promise<Response> {
  const { retry, timeout, ...initProps } = init;
  const fetchImplementation: (timeoutSettings?: TimeoutOptions) => Promise<Response> = (
    timeoutSettings,
  ) =>
    timeoutSettings
      ? fetchWithTimeout({ url, init: initProps, timeout: timeoutSettings.value })
      : fetch(url, initProps);

  const operation = async (
    retrySettings?: RetryOptions,
    timeoutSettings?: TimeoutOptions,
  ): Promise<Response> => {
    const response = await fetchImplementation(timeoutSettings);

    if (response.ok) {
      return response;
    } else if (retrySettings) {
      throw response;
    }

    return response;
  };

  if (retry) {
    return retryOperation<Response>(
      url,
      operation,
      {
        maxAttempts: retry.maxAttempts,
        retryFunctions: retry.retryFunctions,
      },
      timeout,
    );
  } else {
    try {
      return await operation(retry, timeout);
    } catch (error) {
      datadogLog({
        logType: 'warn',
        message: `Fetch with timeout failed on ${url} with error`,
        context: {
          logOrigin: 'libs/utils/network/src/fetchWithRetryAndTimeout.ts',
          functionOrigin: 'fetchWithRetryAndTimeout',
        },
        error: error as Error,
      });
      // Throw the error if no more retries are allowed or if the retry condition is not met.
      throw error;
    }
  }
}
