import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'SessionExpiredError' })((theme) => ({
  mobileDivider: {
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderWidth: '1px',
    width: '100%',
    marginTop: 10,
    marginBottom: 10,
  },
  mobileRoot: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    paddingBottom: 100,
  },
  mobileButton: {
    borderRadius: 25,
  },
  desktopContainer: {
    width: 975,
    margin: '150px auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    gap: 150,
  },
  desktopDivider: {
    borderColor: theme.palette.divider,
    borderStyle: 'solid',
    borderWidth: '1px',
    width: '100%',
    marginTop: 20,
    marginBottom: 20,
  },
  desktopButton: {
    borderRadius: 25,
    maxWidth: 225,
  },
  desktopTimeoutIcon: {
    width: 194,
    height: 194,
  },
}));
