import { useCallback, useRef, useState } from 'react';

import { Grid, Link } from '@mui/material';

import { agentAuth, AuthError } from '@ecp/utils/auth';
import { datadogLog } from '@ecp/utils/logger';
import { statusBadRequest } from '@ecp/utils/network';

import { GridItem } from '@ecp/components';
import { env } from '@ecp/env';
import { Button, Form, TextField } from '@ecp/features/sales/shared/components';
import { useForm } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/features/sales/shared/types';
import type { Field, Fields } from '@ecp/types';

import { useStyles } from './AgentLoginForm.styles';

export interface AgentLoginFields extends Fields {
  username: Field;
  password: Field;
}

interface Props {
  fields: AgentLoginFields;
  onNext: () => Promise<void>;
}

export const AgentLoginForm: React.FC<Props> = (props) => {
  const {
    fields,
    fields: { username, password },
    onNext,
  } = props;

  const { classes } = useStyles();
  const [showLoginError, setShowLoginError] = useState(false);
  const [showInvalidLoginError, setShowInvalidLoginError] = useState(false);
  const [loginProcessing, setLoginProcessing] = useState(false);

  const initValues = useRef({});
  const { validateForm } = useForm({ initValues, fields });

  const handleSubmit = useCallback(async () => {
    if (validateForm().isValid) {
      setLoginProcessing(true);
      await agentAuth
        .login(String(username.value), String(password.value))
        .then(onNext)
        .catch((error) => {
          if (error instanceof AuthError) {
            datadogLog({
              logType: statusBadRequest(error.status) ? 'warn' : 'error',
              message: statusBadRequest(error.status)
                ? `Invalid Login Attempt - ${error?.message}`
                : `Authenticate Error - ${error?.message}`,
              context: {
                logOrigin: 'apps/sales/agent/src/views/AgentLogin/AgentLoginForm.tsx',
                functionOrigin: 'handleSubmit',
                responseStatus: error.status,
                errorMessage: error.message,
                agent: username.value,
              },
              error,
            });
            if (statusBadRequest(error.status)) {
              setShowInvalidLoginError(true);
              setShowLoginError(false);

              return;
            }
          }

          datadogLog({
            logType: 'error',
            message: `Login error - ${error?.message}`,
            context: {
              logOrigin: 'apps/sales/agent/src/views/AgentLogin/AgentLoginForm.tsx',
              functionOrigin: 'handleSubmit',
            },
            error,
          });

          setShowInvalidLoginError(false);
          setShowLoginError(true);
        })
        .finally(() => {
          setLoginProcessing(false);
        });
    }
  }, [
    validateForm,
    username.value,
    password.value,
    onNext,
    setShowInvalidLoginError,
    setShowLoginError,
  ]);

  const actionOnPasswordChange = useCallback(
    (val: AnswerValue): void => {
      setShowInvalidLoginError(false);
      setShowLoginError(false);
      password.props.actionOnChange(val);
    },
    [password.props, setShowLoginError],
  );

  const actionOnUsernameChange = useCallback(
    (val: AnswerValue): void => {
      setShowInvalidLoginError(false);
      setShowLoginError(false);
      username.props.actionOnChange(val);
    },
    [setShowLoginError, username.props],
  );

  return (
    <div className={classes.root}>
      <Form showBackdrop={loginProcessing}>
        <Grid container justifyContent='center'>
          <GridItem topSpacing='sm' xs={12} lg={12} className={classes.formGrid}>
            {showInvalidLoginError && (
              <Grid>
                <div className={classes.errorMessage}>Your username or password is incorrect.</div>
              </Grid>
            )}
            {showLoginError && (
              <Grid>
                <div className={classes.errorMessage}>
                  Please re-enter your information and try again.
                </div>
              </Grid>
            )}
            <GridItem>
              <TextField
                {...username.props}
                id='Username'
                label='Username'
                ariaLabel='Username'
                autoComplete='off'
                actionOnChange={actionOnUsernameChange}
              />
            </GridItem>
            <GridItem topSpacing='sm'>
              <TextField
                {...password.props}
                id='Password'
                type='password'
                label='Password'
                ariaLabel='Password'
                autoComplete='off'
                actionOnChange={actionOnPasswordChange}
              />
            </GridItem>
            <GridItem topSpacing='sm'>
              <Link className={classes.forgotPasswordLink} href={env.resetAgentPasswordLink}>
                Forgot password?
              </Link>
            </GridItem>
            <GridItem topSpacing='sm'>
              <Button
                className={classes.loginButton}
                variant='primary'
                onClick={handleSubmit}
                isProcessing={loginProcessing}
                disabled={loginProcessing}
                type='submit'
              >
                Login
              </Button>
            </GridItem>
          </GridItem>
        </Grid>
      </Form>
    </div>
  );
};
