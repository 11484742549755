import { Stack } from '@mui/material';

import {
  Button,
  getSessionPromptExpiredBodyText,
  getSessionPromptExpiredLabels,
  SessionExpiryPromptBodyText,
  SessionExpiryPromptContactInfo,
  useOfferExists,
} from '@ecp/features/sales/shared/components';
import { IconUITimeout, useIsTablet } from '@ecp/themes/base';

import { useStyles } from './SessionExpiredError.styles';
import { useHandleButtonClick } from './useHandleButtonClick';

const MobileVersion: React.FC = () => {
  const { classes } = useStyles();
  const offerExists = useOfferExists();
  const text = getSessionPromptExpiredBodyText(offerExists);
  const { buttonText, trackingName, trackingLabel } = getSessionPromptExpiredLabels(offerExists);
  const { handleButtonClick } = useHandleButtonClick({ offerExists });

  return (
    <Stack className={classes.mobileRoot}>
      <h1>Session Expired</h1>
      <div className={classes.mobileDivider} />
      <Stack spacing={2}>
        <SessionExpiryPromptBodyText text={text} />
        <SessionExpiryPromptContactInfo alignment='left' />
        <Button
          className={classes.mobileButton}
          variant='primary'
          trackingLabel={trackingLabel}
          trackingName={trackingName}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};

const DesktopVersion: React.FC = () => {
  const { classes } = useStyles();
  const offerExists = useOfferExists();
  const text = getSessionPromptExpiredBodyText(offerExists);
  const { buttonText, trackingName, trackingLabel } = getSessionPromptExpiredLabels(offerExists);
  const { handleButtonClick } = useHandleButtonClick({ offerExists });

  return (
    <Stack className={classes.desktopContainer}>
      <Stack>
        <h1>Session Expired</h1>
        <div className={classes.desktopDivider} />
        <Stack spacing={2}>
          <SessionExpiryPromptBodyText text={text} />
          <SessionExpiryPromptContactInfo alignment='left' />
          <Button
            className={classes.desktopButton}
            variant='primary'
            trackingLabel={trackingLabel}
            trackingName={trackingName}
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </Stack>
      </Stack>
      <IconUITimeout className={classes.desktopTimeoutIcon} />
    </Stack>
  );
};

export const SessionExpiredError: React.FC = () => {
  const showMobileVersion = useIsTablet();

  return showMobileVersion ? <MobileVersion /> : <DesktopVersion />;
};
