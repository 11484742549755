import { Grid, Link, Typography } from '@mui/material';

import { getFullPrimaryInsuredAddressInfo } from '@ecp/features/sales/shared/store';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUIMap, IconUIZ } from '@ecp/themes/base';

import { useStyles } from './PropertyCardFooter.styles';

export const PropertyCardFooter: React.FC = () => {
  const { classes } = useStyles();

  const { line1, line2, city, state, zipcode, latitude, longitude } = useSelector(
    getFullPrimaryInsuredAddressInfo,
  );

  const addressState = state.slice(-2);
  const displayAddressLine = `${line1}${line2 ? `, ${line2}` : ''}`;
  const displayCityStateZip = `${city}, ${addressState} ${zipcode}`;

  const googleStreetViewUrl = `https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=${latitude},${longitude}`;
  const zillowUrl = `http://www.zillow.com/homes/${line1},-${city},-${addressState},-${zipcode}_rb/`;

  return (
    <Grid item xs={12}>
      <p className={classes.propertyFooterLabel}>Property address</p>
      <Typography variant='body4'>
        {displayAddressLine}
        <br />
        {displayCityStateZip}
      </Typography>
      <Grid container className={classes.mapLinks}>
        <Grid item>
          <Link href={googleStreetViewUrl} target='_blank' className={classes.mapLink}>
            <IconUIMap className={classes.mapIcon} />
            Google street view
          </Link>
          <Link href={zillowUrl} target='_blank' className={classes.mapLink}>
            <IconUIZ className={classes.zillowIcon} />
            Find property on Zillow
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};
