import { forwardRef, useCallback, useEffect, useMemo } from 'react';

import type { DialogProps as MuiDialogProps, SlideProps } from '@mui/material';
import {
  AppBar,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Dialog as MuiDialog,
  Slide,
  Toolbar,
} from '@mui/material';

import { TrackingDimensions } from '@ecp/utils/analytics/tracking';

import type { ButtonVariant } from '@ecp/components';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { IconUIClose } from '@ecp/themes/base';

import { Button } from '../Button';
import { useStyles } from './Dialog.styles';

export interface DialogProps extends Omit<MuiDialogProps, 'open'> {
  actionButtonLabel?: string;
  actionButtonVariant?: ButtonVariant;
  textButtonLabel?: string;
  textButtonVariant?: ButtonVariant;
  additionalTextButtonLabel?: string;
  additionalTextButtonVariant?: ButtonVariant;
  titleText?: React.ReactElement | string;
  open: boolean;
  isProcessing?: boolean;
  additionalButtonOnClick?(): void;
  actionButtonOnClick?(): void;
  textButtonOnClick?(): void;
  actionHref?: string;
  textHref?: string;
  onClose?(): void;
  hideTitleCloseButton?: boolean;
  disableActionButton?: boolean;
  buttonPlacement?: 'left' | 'right' | 'center';
  trackingDimension?: string;
  trackingNameButton?: string;
  trackingLabelButton?: string;
  trackingNameText?: string;
  trackingLabelText?: string;
  trackingNameCloseIcon?: string;
  trackingLabelCloseIcon?: string;
  analyticsElementRender?: string;
  analyticsEventDetailRender?: string;
  analyticsElementButton?: string;
  analyticsEventDetailButton?: string;
  analyticsElementText?: string;
  analyticsEventDetailText?: string;
  disableClose?: boolean;
  enableBackdropClick?: boolean;
  disclosureText?: string;
  dialogActions?: React.ReactElement;
  showAppBar?: boolean;
  showFullScreenActionButtons?: boolean;
  hideDivider?: boolean;
}

const Transition = forwardRef<HTMLDivElement, Omit<SlideProps, 'direction'>>((props, ref) => (
  <Slide direction='up' ref={ref} {...props} />
));
Transition.displayName = 'DialogTransition';

export const Dialog: React.FC<DialogProps> = (props) => {
  const {
    children,
    actionButtonLabel,
    actionButtonVariant,
    textButtonLabel,
    textButtonVariant,
    additionalTextButtonLabel,
    additionalTextButtonVariant,
    isProcessing,
    additionalButtonOnClick,
    actionButtonOnClick,
    textButtonOnClick,
    actionHref,
    textHref,
    titleText,
    disableActionButton,
    fullScreen,
    open,
    onClose,
    hideTitleCloseButton,
    buttonPlacement = 'left',
    trackingDimension,
    trackingNameButton,
    trackingLabelButton,
    trackingNameText,
    trackingLabelText,
    trackingNameCloseIcon,
    trackingLabelCloseIcon,
    analyticsElementRender,
    analyticsEventDetailRender,
    analyticsElementButton,
    analyticsEventDetailButton,
    analyticsElementText,
    analyticsEventDetailText,
    disableClose,
    enableBackdropClick,
    disclosureText,
    dialogActions,
    showAppBar = true,
    showFullScreenActionButtons,
    hideDivider,
    ...rest
  } = props;
  const { classes, cx } = useStyles();

  const trackingDimensions = useMemo(() => {
    if (!trackingDimension) return undefined;

    return { [TrackingDimensions.MODAL_NAME]: trackingDimension };
  }, [trackingDimension]);

  useEffect(() => {
    if (analyticsElementRender && open) {
      trackSapiAnalyticsEvent({
        element: analyticsElementRender,
        event: 'render',
        eventDetail: analyticsEventDetailRender || 'true',
      });
    }
  }, [analyticsElementRender, analyticsEventDetailRender, open]);

  // If a modal is informative and doesn't need any processing, the action would be `Close`
  const handleActionButtonClick = actionButtonOnClick || onClose;
  const handleTextButtonClick = textButtonOnClick || onClose;
  const handleAdditionalButtonOnClick = additionalButtonOnClick || onClose;

  const handleClose = useCallback<NonNullable<MuiDialogProps['onClose']>>(
    (_, reason) => {
      if (!enableBackdropClick && reason === 'backdropClick') return;
      onClose?.();
    },
    [enableBackdropClick, onClose],
  );

  const actions = (
    <>
      <Grid
        className={cx(classes.buttonsPanel, !showFullScreenActionButtons && classes.buttonWidth)}
        container
        item
        xs={12}
        direction={buttonPlacement === 'left' ? 'row' : 'row-reverse'}
        justifyContent={buttonPlacement === 'center' ? 'space-around' : 'right'}
      >
        {actionButtonLabel && (
          <Grid className={classes.button}>
            <Button
              variant={actionButtonVariant || 'primary'}
              onClick={handleActionButtonClick}
              color='primary'
              className={classes.button}
              isProcessing={isProcessing}
              disabled={disableActionButton || isProcessing}
              trackingDimensions={trackingDimensions}
              trackingName={trackingNameButton}
              trackingLabel={trackingLabelButton}
              href={actionHref}
              analyticsElement={analyticsElementButton}
              analyticsEventDetail={analyticsEventDetailButton}
              type='submit'
            >
              {actionButtonLabel}
            </Button>
          </Grid>
        )}
        {textButtonLabel && (
          <Grid className={classes.textButton}>
            <Button
              variant={textButtonVariant || 'iconTextMedium'}
              onClick={handleTextButtonClick}
              color='primary'
              className={classes.button}
              trackingDimensions={trackingDimensions}
              trackingName={trackingNameText}
              trackingLabel={trackingLabelText}
              href={textHref}
              analyticsElement={analyticsElementText}
              analyticsEventDetail={analyticsEventDetailText}
              disabled={disableClose}
            >
              {textButtonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid
        className={cx(
          classes.additionalButtonPanel,
          buttonPlacement === 'left' ? classes.alignButtonRight : classes.alignButtonLeft,
        )}
      >
        {additionalTextButtonLabel && (
          <Grid className={classes.additionalTextButton}>
            <Button
              variant={actionButtonVariant || 'iconTextMedium'}
              onClick={handleAdditionalButtonOnClick}
              color='primary'
              className={classes.button}
              isProcessing={isProcessing}
              disabled={disableActionButton || isProcessing}
              trackingDimensions={trackingDimensions}
              trackingName={trackingNameButton}
              trackingLabel={trackingLabelButton}
              href={actionHref}
              analyticsElement={analyticsElementButton}
              analyticsEventDetail={analyticsEventDetailButton}
              type='submit'
            >
              {additionalTextButtonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );

  const fullScreenDialogBody = (
    <>
      {showAppBar && (
        <AppBar className={classes.fullScreenAppBar} position='sticky'>
          <Toolbar>
            <Grid
              container
              className={cx(
                classes.fullScreenActionBar,
                actionButtonLabel ? classes.spaceBetween : classes.flexEnd,
              )}
            >
              {!hideTitleCloseButton && (
                <Button
                  variant='iconText'
                  disabled={disableClose}
                  onClick={onClose}
                  color='primary'
                  className={classes.closeButton}
                  trackingName={trackingNameCloseIcon}
                  trackingLabel={trackingLabelCloseIcon}
                  aria-label='Close dialog'
                >
                  <IconUIClose className={classes.appBarCloseIcon} />
                </Button>
              )}

              {actionButtonLabel && (
                <Button
                  variant='outlinePrimary'
                  onClick={handleActionButtonClick}
                  className={classes.fullScreenButton}
                  trackingName={trackingNameButton}
                  trackingLabel={trackingLabelButton}
                  isProcessing={isProcessing}
                  disabled={disableActionButton || isProcessing}
                >
                  {actionButtonLabel}
                </Button>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      )}
      <DialogContent className={cx(classes.fullScreenRoot, classes.contentRoot)}>
        {!showAppBar && <p className={classes.dialogTitle}>{titleText}</p>}
        <form className={classes.fullScreenForm}>
          {children}
          {showFullScreenActionButtons && (
            <DialogActions className={classes.dialogActions}>
              {dialogActions ? dialogActions : actions}
            </DialogActions>
          )}
        </form>
      </DialogContent>
    </>
  );

  const dialogBody = (
    <>
      {titleText && (
        <div className={classes.dialogTitleContainer}>
          <p className={classes.dialogTitle}>{titleText}</p>
          {!hideTitleCloseButton && (
            <Button
              variant='iconText'
              disabled={disableClose}
              onClick={onClose}
              color='primary'
              className={classes.closeButton}
              trackingName={trackingNameCloseIcon}
              trackingLabel={trackingLabelCloseIcon}
              aria-label='Close dialog'
            >
              <IconUIClose className={classes.closeIcon} />
            </Button>
          )}
        </div>
      )}
      {titleText && !hideDivider && (
        <Divider aria-hidden='true' className={classes.dialogDivider} />
      )}
      <DialogContent className={classes.contentRoot}>
        <form>{children}</form>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        {dialogActions ? dialogActions : actions}
      </DialogActions>
      {disclosureText && (
        <Grid item>
          <p className={classes.disclosureText}>{disclosureText}</p>
        </Grid>
      )}
    </>
  );

  return (
    <MuiDialog
      disableEscapeKeyDown
      aria-labelledby='dialog-title'
      fullScreen={fullScreen}
      onClose={handleClose}
      open={open}
      maxWidth={false}
      TransitionComponent={fullScreen ? Transition : undefined}
      classes={{
        paper: cx(classes.dialog, fullScreen ? classes.fullScreen : classes.regularScreen),
      }}
      {...rest}
    >
      {fullScreen ? fullScreenDialogBody : dialogBody}
    </MuiDialog>
  );
};
