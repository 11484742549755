import type { AllConfigs } from '../../types';

export const initialSummaryPageFlow: AllConfigs = [
  {
    executionOrder: 10,
    startEffectiveDate: '01/01/2021',
    description: 'Quote Summary page for all experiences',
    criteria: {
      baseExp: ['Bundle', 'Renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Quote',
          pageId: 'quoteSummary',
          path: '/quote/quotes',
        },
      ],
      navigationOrder: ['quoteSummary'],
      byPageId: {
        quoteSummary: {
          path: '/quote/quotes',
          pageTitle: 'Quote Summary',
        },
      },
    },
  },
  {
    executionOrder: 20,
    startEffectiveDate: '01/01/2021',
    description: 'Customized coverages page for all Moonshot',
    criteria: {
      baseExp: ['Agent'],
    },
    config: {
      menuItems: [
        {
          text: 'Customized coverages',
          pageId: 'quoteSummary',
          path: '/quote/quotes',
        },
      ],
      navigationOrder: ['quoteSummary'],
      byPageId: {
        quoteSummary: {
          path: '/quote/quotes',
          pageTitle: 'Customized coverages',
        },
      },
    },
  },
];
