import type { AllConfigs } from '../../types';

export const introPageFlow: AllConfigs = [
  {
    executionOrder: 10,
    startEffectiveDate: '01/01/2021',
    description: 'Consumer Intro Flow',
    criteria: {
      baseExp: ['Bundle', 'Renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Your information',
          pageId: 'person',
          path: '/quote/person',
        },
      ],
      navigationOrder: ['landing', 'person'],
      byPageId: {
        landing: {
          path: '/quote/landing',
          pageTitle: '',
        },
        person: {
          path: '/quote/person',
          pageTitle: 'Personal Information',
        },
      },
    },
  },
  {
    executionOrder: 20,
    startEffectiveDate: '01/01/2021',
    description: 'Agent Intro Flow that overrides landing page',
    criteria: {
      baseExp: ['Agent'],
    },
    config: {
      menuItems: [
        {
          text: 'Insured information',
          pageId: 'person',
          path: '/quote/person',
        },
      ],
      navigationOrder: ['landing', 'person'],
      byPageId: {
        landing: {
          path: '/quote/landing',
          pageTitle: 'Home',
        },
        person: {
          path: '/quote/person',
          pageTitle: 'Insured Info',
        },
      },
    },
  },
];
