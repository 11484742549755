import { datadogLog, trackDataDogUserAction } from '@ecp/utils/logger';

import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

export const trackGetQuoteButtonClick = (eventDetail: string, closeButton?: boolean): void => {
  trackSapiAnalyticsEvent({
    element: closeButton
      ? 'sessionTimeoutModal.xToGetQuoteButton'
      : 'sessionTimeoutModal.getQuoteButton',
    event: 'click',
    eventDetail,
  });
  datadogLog({
    logType: 'info',
    message: 'Get Quote Clicked',
    context: {
      logOrigin:
        'libs/features/sales/shared/components/src/SessionExpiryTimer/util/trackGetQuoteButtonClick.ts',
      functionOrigin: 'trackGetQuoteButtonClick',
    },
  });
  trackDataDogUserAction({
    name: 'Get Quote Clicked',
    context: { action: 'handleGetQuote' },
  });
};
