import type { AllConfigs } from '../../types.js';
import { introPageFlow as introPageFlowBase } from './metadata.js';

export const introPageFlow: AllConfigs = [
  ...introPageFlowBase,
  {
    executionOrder: 30,
    startEffectiveDate: '01/01/2021',
    description: 'Costco membership page',
    criteria: {
      baseExp: ['Agent', 'Bundle'],
      expId: ['2811', '2812', '2813', '7192'],
    },
    deltas: [
      {
        operation: 'reset',
        target: 'navigationOrder',
        value: ['landing', 'membership', 'person'],
      },
      {
        operation: 'reset',
        target: 'menuItems',
        value: [
          {
            text: 'Insured information',
            pageId: 'membership',
            path: '/quote/membership',
          },
        ],
      },
      {
        operation: 'reset',
        target: 'byPageId',
        value: {
          landing: {
            path: '/quote/landing',
            pageTitle: '',
          },
          person: {
            path: '/quote/person',
            pageTitle: 'Personal Information',
          },
          membership: {
            path: '/quote/membership',
            pageTitle: 'Membership Level',
          },
        },
      },
    ],
  },
];
