import { useCallback } from 'react';

import { CardContent, Grid, Typography } from '@mui/material';

import { parseDollar } from '@ecp/utils/common';
import { formatDate } from '@ecp/utils/date';

import { Button } from '@ecp/features/sales/shared/components';
import { getPolicyStartDates } from '@ecp/features/sales/shared/store';
import type { OfferInfo } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import type { LineOfBusiness } from '@ecp/features/shared/product';
import {
  getProductNameFromProduct,
  getReducedProductNameFromProduct,
  type Product,
} from '@ecp/features/shared/product';
import { IconUIEdit } from '@ecp/themes/base';

import { AutoCardFooter, PropertyCardFooter } from '../../CardFooter';
import { getLineOfBusinessFromProductName } from '../util';
import { useStyles } from './QuoteRetrieveCardContent.styles';

interface QuoteRetrieveCardContentProps {
  cardProduct: Product;
  cardQuoteDetails?: OfferInfo;
  handleChangeStartDateToggle: () => void;
  setDialogProduct: React.Dispatch<React.SetStateAction<LineOfBusiness>>;
}

export const QuoteRetrieveCardContent: React.FC<QuoteRetrieveCardContentProps> = ({
  cardProduct,
  cardQuoteDetails,
  handleChangeStartDateToggle,
  setDialogProduct,
}) => {
  const { classes } = useStyles();
  const reducedProductName = getReducedProductNameFromProduct(cardProduct);
  const productName = getProductNameFromProduct(cardProduct);
  const policyStartDates = useSelector(getPolicyStartDates);

  const handleDisabledFlags = cardQuoteDetails?.isPurchased ? true : false;

  const handleShowProductStartDate = useCallback(() => {
    setDialogProduct(getLineOfBusinessFromProductName(productName));
    handleChangeStartDateToggle();
  }, [setDialogProduct, productName, handleChangeStartDateToggle]);

  // TODO Confirm the amounts for full and monthly for all products
  const monthlyPaymentAmount = cardQuoteDetails?.monthlyPremium?.installmentPayment;
  const payInFullPaymentAmount = cardQuoteDetails?.fullPremium?.downPayment;

  const quoteDetails = cardQuoteDetails?.topCoverages;

  return (
    <>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item sm={12} md={3} className={classes.cardColumn}>
            <p>
              <strong>Policy start date </strong>
            </p>
            <Button
              onClick={handleShowProductStartDate}
              data-testid='changeAutoStartDateLink'
              disabled={handleDisabledFlags}
              icon={<IconUIEdit />}
              variant='iconTextMedium'
              iconLast
            >
              {formatDate(policyStartDates[reducedProductName])}
            </Button>
          </Grid>
          <Grid item sm={12} md={3} className={classes.cardColumn}>
            <p>
              <strong>Premium </strong>
            </p>
            <div className={classes.cardList}>
              <Typography variant='body4'>{parseDollar(monthlyPaymentAmount)}</Typography>
              /monthly
              <br />
              <Typography variant='body4'>{parseDollar(payInFullPaymentAmount)}</Typography>
              /Pay in full
            </div>
          </Grid>
          <Grid item sm={12} md={5}>
            <p>
              <strong>Quote details </strong>
            </p>
            <ul className={classes.cardList}>
              {quoteDetails &&
                Object.entries(quoteDetails).map(([key, value]) => (
                  <li className={classes.quoteDetailList} key={key}>
                    {key} <span>{value}</span>
                  </li>
                ))}
            </ul>
          </Grid>
        </Grid>
      </CardContent>
      <CardContent>
        {reducedProductName === 'property' ? <PropertyCardFooter /> : <AutoCardFooter />}
      </CardContent>
    </>
  );
};
