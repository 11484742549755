import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'ProductQuoteNumber' })((theme) => ({
  root: {},
  productQuoteNumberContainer: {
    display: 'flex',
    paddingTop: 10,
  },
  quoteNumberInformation: {
    color: theme.palette.text.secondary,
    lineHeight: '24px',
  },
  quoteNumberDivider: {
    borderLeft: `1px solid ${theme.palette.other.divider}`,
    paddingLeft: 10,
    marginLeft: 10,
  },
}));
