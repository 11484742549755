import { Card, CardContent, Grid, Typography } from '@mui/material';

import { formatDate } from '@ecp/utils/date';

import { getFullPersonInfo, usePniRef } from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';
import { IconUIPerson } from '@ecp/themes/base';

import { useStyles } from './AgentRecallPersonalInfo.styles';

export const AgentRecallPersonalInfo: React.FC = () => {
  const { classes } = useStyles();
  const pniRef = usePniRef();
  const pni = useSelector((state: RootStore) => getFullPersonInfo(state, pniRef));
  const { firstName, lastName, dateOfBirth, email, phone } = pni;

  return (
    <Card className={classes.cardMargin}>
      <CardContent className={classes.cardPadding}>
        <Grid container justifyContent='flex-start'>
          <Grid item className={classes.iconTitle}>
            <Typography variant='body1Bold'>
              <IconUIPerson className={classes.navBarIcon} />
              <span className={classes.navBarIconTitle}>Customer information</span>
            </Typography>
          </Grid>
        </Grid>
        {pni.firstName && (
          <Grid container>
            <Grid item className={classes.content}>
              <ul>
                <li>
                  {firstName} {lastName}
                </li>
                <li>DOB {formatDate(dateOfBirth)}</li>
                <li>{email}</li>
                <li>{phone}</li>
              </ul>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
