import { useEvent } from '@ecp/utils/react';

import {
  getSessionPromptEventDetail,
  trackContinueQuoteButtonClick,
  trackGetQuoteButtonClick,
} from '@ecp/features/sales/shared/components';
import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { startOverFlow } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

export const useHandleButtonClick = ({
  offerExists,
}: {
  offerExists: boolean;
}): {
  handleButtonClick: () => Promise<void>;
} => {
  const eventDetail = getSessionPromptEventDetail(true, offerExists);
  const dispatch = useDispatch();
  const navigateToRetrievePage = useNavigateToPage(PagePath.LANDING_RETRIEVE);

  const handleGetQuote = useEvent(async () => {
    await dispatch(startOverFlow());
    trackGetQuoteButtonClick(eventDetail);
  });

  const handleContinueQuote = useEvent(async () => {
    await dispatch(startOverFlow());
    await navigateToRetrievePage();
    trackContinueQuoteButtonClick(eventDetail);
  });

  const handleButtonClick = useEvent(async () => {
    if (!offerExists) {
      await handleGetQuote();

      return;
    }

    await handleContinueQuote();
  });

  return { handleButtonClick };
};
