import type { AllConfigs } from '../types';

export const purchasePageFlow: AllConfigs = [
  {
    executionOrder: 10,
    startEffectiveDate: '01/01/2021',
    description: 'Checkout and post bind pages for all experiences',
    criteria: {
      baseExp: ['Bundle', 'Agent', 'Renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Checkout',
          pageId: 'checkout',
          path: '/app/checkout',
        },
      ],
      navigationOrder: ['checkout', 'postbind'],
      byPageId: {
        checkout: {
          path: '/app/checkout',
          pageTitle: 'Checkout',
        },
        postbind: {
          path: '/app/postbind',
          pageTitle: 'Congratulations!',
        },
      },
    },
  },
];
