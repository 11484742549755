import { CardContent, Grid } from '@mui/material';

import { upperFirst } from '@ecp/utils/common';

import type { OfferStatusCode } from '@ecp/features/sales/shared/constants';
import type { Product } from '@ecp/features/shared/product';
import { getReducedProductNameFromProduct } from '@ecp/features/shared/product';

import { AutoCardFooter, PropertyCardFooter } from '../../CardFooter';
import { useStyles } from './UnavailableQuoteRetrieveCardContent.styles';

interface UnavailableQuoteRetrieveCardContentProps {
  offerStatusCode: OfferStatusCode;
  cardProduct: Product;
  cardQuoteDetails?:
    | {
        description: string;
        value: string;
      }[]
    | undefined;
}

export const UnavailableQuoteRetrieveCardContent: React.FC<
  UnavailableQuoteRetrieveCardContentProps
> = ({ offerStatusCode, cardProduct, cardQuoteDetails }) => {
  const { classes } = useStyles();

  const showFooter = !['CNQ', 'DNQ'].includes(offerStatusCode);
  const reducedProductName = getReducedProductNameFromProduct(cardProduct);

  // reasons are currently hardcoded into the 3rd index in getCnqDndProducts
  // per QA only one reason is ever returned for these errors
  const reasons = cardQuoteDetails && cardQuoteDetails[2];
  const inquiryId = cardQuoteDetails?.filter((detail) =>
    detail.description.includes('Inquiry-id'),
  )[0]?.value;
  const offerSetId = cardQuoteDetails?.filter((detail) =>
    detail.description.includes('Offer-Set-id'),
  )[0]?.value;

  return (
    <>
      <CardContent className={classes.cardContent}>
        <Grid container>
          <Grid item sm={12} md={6} className={classes.cardColumn}>
            <p>
              <strong>Reason for {upperFirst(offerStatusCode)}</strong>
            </p>
            <ul className={classes.quoteDetailList}>
              <li>{reasons && reasons.description}</li>
              <li>{reasons && reasons.value}</li>
            </ul>
          </Grid>
          <Grid item sm={12} md={5}>
            {inquiryId && (
              <p>
                <strong>Inquiry-id</strong>
                <br />
                <span className={classes.quoteId}>{inquiryId}</span>
              </p>
            )}
            {offerSetId && (
              <p>
                <strong>Offer-Set-id</strong>
                <br />
                <span className={classes.quoteId}>{offerSetId}</span>
              </p>
            )}
          </Grid>
        </Grid>
      </CardContent>
      {showFooter && (
        <CardContent>
          {reducedProductName === 'property' ? <PropertyCardFooter /> : <AutoCardFooter />}
        </CardContent>
      )}
    </>
  );
};
