import type { OfferStatusCode } from '@ecp/features/sales/shared/constants';
import type { Product, ProductDisplayName, ProductName } from '@ecp/features/shared/product';

import { UnavailableQuoteRetrieveCardContent } from '../../CardContent';
import { QuoteRetrieveCardContainer } from '../../QuoteRetrieveCardContainer';

export interface CnqDnqProductData {
  isBundle: boolean;
  name: ProductName;
  offerStatusCode: OfferStatusCode;
  displayName: ProductDisplayName;
  quoteDetails: {
    description: string;
    value: string;
  }[];
  button?: React.ReactElement;
}

interface QuoteUnavailableRetrieveCard {
  data: CnqDnqProductData[] | null;
}
export const UnavailableQuoteRetrieveCardSection: React.FC<QuoteUnavailableRetrieveCard> = ({
  data,
}): JSX.Element | null => {
  if (data?.length === 0) return null;

  return (
    <>
      {data?.map((products, index) => {
        const { name, quoteDetails, isBundle, offerStatusCode } = products;

        return (
          <QuoteRetrieveCardContainer
            cardProduct={name as Product}
            isChildContainer={isBundle}
            offerStatusCode={offerStatusCode}
            cnqDnqQuoteDetails={quoteDetails}
            key={`${index}-${name}`}
          >
            <UnavailableQuoteRetrieveCardContent
              offerStatusCode={offerStatusCode}
              cardProduct={name as Product}
              cardQuoteDetails={quoteDetails}
            />
          </QuoteRetrieveCardContainer>
        );
      })}
    </>
  );
};
