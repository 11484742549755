import { useCallback } from 'react';

import { useAddFields, useInitValues } from '@ecp/features/sales/form';
import { RadioGroupWithOptions } from '@ecp/features/sales/shared/components';
import { DERIVED_DISCOUNT_HOME, EXISTING_HOME_PRODUCT } from '@ecp/features/sales/shared/constants';
import { useField } from '@ecp/features/sales/shared/store';
import type { AnswerValue } from '@ecp/features/sales/shared/types';

export interface ResidencyTypeQuestionProps {
  variant?: 'button' | 'radioCard';
  label?: string;
  row?: boolean;
}
const defaultLabel = 'Do you own or rent your current residence?';
export const ResidencyTypeQuestion: React.FC<ResidencyTypeQuestionProps> = ({
  variant = 'button',
  label = defaultLabel,
  row = false,
}) => {
  const residencyType = useField('residencyType');
  const derivedDiscountHome = useField(DERIVED_DISCOUNT_HOME);
  const existingHomeProduct = useField(EXISTING_HOME_PRODUCT);
  useInitValues({ [residencyType.key]: 'RESIDENCY_TYPE.OWN' });
  useAddFields({ residencyType });

  const handleResidenceTypeChange = useCallback(
    (value: AnswerValue): void => {
      residencyType.props.actionOnComplete(value);
      if (value === 'RESIDENCY_TYPE.RENT') {
        existingHomeProduct.validateUpdateAndPatch(null);
        derivedDiscountHome.validateUpdateAndPatch(null);
      }
    },
    [derivedDiscountHome, existingHomeProduct, residencyType.props],
  );

  if (!residencyType.exists) return null;

  return (
    <RadioGroupWithOptions
      {...residencyType.props}
      label={label}
      row={row}
      trackingName='own_or_rent_selection'
      adjustableGrid={row}
      adjustableGridProps={
        row ? { forceNumItemsPerRow: residencyType.props.options!.length } : undefined
      }
      variant={variant}
      actionOnComplete={handleResidenceTypeChange}
    />
  );
};
