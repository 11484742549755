import { datadogLog, trackDataDogUserAction } from '@ecp/utils/logger';

import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';

export const trackContinueSessionButtonClick = (closeButton?: boolean): void => {
  trackSapiAnalyticsEvent({
    element: closeButton
      ? 'sessionTimeoutModal.xToContinueSessionButton'
      : 'sessionTimeoutModal.continueSessionButton',
    event: 'click',
    eventDetail: 'version: Warning',
  });
  datadogLog({
    logType: 'info',
    message: 'Continue Session Clicked',
    context: {
      logOrigin:
        'libs/features/sales/shared/components/src/SessionExpiryTimer/util/trackContinueSessionButtonClick.ts',
      functionOrigin: 'trackContinueSessionButtonClick',
    },
  });
  trackDataDogUserAction({
    name: 'Continue Session Clicked',
    context: { action: 'handleContinueSession' },
  });
};
