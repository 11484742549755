import { Box, Stack } from '@mui/material';

import { SessionExpiryPromptBodyText } from '../../sub-components/SessionExpiryPromptBodyText/SessionExpiryPromptBodyText';
import { SessionExpiryPromptTimeRemaining } from '../../sub-components/SessionExpiryPromptTimeRemaining/SessionExpiryPromptTimeRemaining';
import { WARNING_BODY_TEXT } from '../../util/labelUtil';

export const WarningPrompt: React.FC<{
  remaining: number;
}> = ({ remaining }) => {
  return (
    <Stack>
      <Box mt='25px' mb='25px'>
        <SessionExpiryPromptTimeRemaining remaining={remaining} spacing='10px' />
      </Box>
      <SessionExpiryPromptBodyText text={WARNING_BODY_TEXT} />
    </Stack>
  );
};
