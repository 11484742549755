import { Stepper } from '@mui/material';

import { AgentRecallPersonalInfo } from '../AgentRecallPersonalInfo';

export const AgentRecallSidebar: React.FC = () => {
  return (
    <Stepper orientation='vertical'>
      <AgentRecallPersonalInfo />
    </Stepper>
  );
};
