import { agentAuth, AuthError } from '@ecp/utils/auth';
import { datadogLog } from '@ecp/utils/logger';
import { statusBadRequest } from '@ecp/utils/network';

import { env } from '@ecp/env';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';
import { SalesRequestError } from '@ecp/features/sales/shared/utils/network';

import { updateGlobalError } from '../global/actions';
import type { ThunkErrorHandler } from '../offers/types';

// This errhandler deal with session timeout error for now, but we could add logic to handle other error type as well.
// Any sapi calls should have session timeout logic / error handling in them.

/**
 * @param {function} action - the thunk action
 * @param {string} actionName - Provide a name for the action (used for logging)
 */
export function wrapThunkActionWithErrHandler<T = void, R = void>(
  action: (params: T) => ThunkAction<Promise<R>>,
  actionName: string,
): (params: T) => ThunkAction<Promise<ThunkErrorHandler<R> | null>> {
  const logOrigin = `libs/features/sales/shared/store/lib/src/util/wrapThunkActionWithErrHandler.ts - ${actionName}`;
  const shouldShowDiagnosticsDetails = env.static.isAgent || env.static.nodeEnv !== 'production';

  return (params) => async (dispatch) => {
    try {
      // Don't remove the await, we need to wait and see if
      // this action fails
      const response = await dispatch(action(params));

      return { response };
    } catch (error) {
      // eslint-disable-next-line no-console
      if (shouldShowDiagnosticsDetails) console.error(error);
      // we will check the type of the e and do datadog log and update global error accordingly:

      // If the error is from agent auth util (either login or refresh-token call fails),
      // trigger agent logout so that user is redirected to login page immediately
      if (error instanceof AuthError) {
        datadogLog({
          logType: statusBadRequest(error.status) ? 'warn' : 'error',
          message: statusBadRequest(error.status)
            ? `Invalid Login Attempt - ${error?.message}`
            : `Authenticate Error - ${error?.message}`,
          context: {
            logOrigin,
            functionOrigin: 'wrapThunkActionWithErrorHandler',
            responseStatus: error.status,
            errorMessage: error.message,
          },
          error,
        });
        agentAuth.logout();

        return null;
      }

      if (error instanceof SalesRequestError) {
        const {
          requestId,
          transactionId,
          errorReason,
          errorStack,
          name,
          requestUrl,
          message,
          errorData,
        } = error;

        datadogLog({
          logType: 'error',
          message: `${errorReason} - ${error.message}`,
          context: {
            logOrigin,
            functionOrigin: 'wrapThunkActionWithErrHandler',
            requestId,
            transactionId,
            requestUrl,
            errorStack,
            errorReason,
            message,
            errorData,
          },
          error,
        });

        dispatch(
          updateGlobalError({
            hasError: true,
            requestId: requestId || '',
            transactionId: transactionId || '',
            errorReason,
            name,
            text: errorStack,
            requestUrl,
            message,
          }),
        );

        return { error };
      }

      const otherErrors = error as Error;

      datadogLog({
        logType: 'error',
        message: `Encountered unknown error - ${otherErrors.message}`,
        context: {
          logOrigin,
          functionOrigin: 'wrapThunkActionWithErrHandler',
          errorStack: otherErrors.stack,
        },
        error: otherErrors,
      });

      dispatch(
        updateGlobalError({
          hasError: true,
          text: otherErrors.stack,
          name: otherErrors.message,
          requestId: '',
          transactionId: '',
        }),
      );

      return { error: otherErrors };
    }
  };
}
