import {
  getOfferDetailsForProduct,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import { useSelector } from '@ecp/features/sales/shared/store/utils';

/**
 * Check if any offer, be it from auto or property, exists
 */
export const useOfferExists = (): boolean => {
  const { property: propertyOfferProduct, auto: autoOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );

  const propertyOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, propertyOfferProduct),
  );
  const autoOfferDetails = useSelector((state: RootStore) =>
    getOfferDetailsForProduct(state, autoOfferProduct),
  );

  return !!propertyOfferDetails || !!autoOfferDetails;
};
