import { createSelector } from '@reduxjs/toolkit';
import createCachedSelector from 're-reselect';

import { getAge } from '@ecp/utils/date';
import { datadogLog } from '@ecp/utils/logger';

import type { DriverDiscountCardOption } from '@ecp/features/sales/quotes/auto';
import {
  AutoDeltaDriverQuestionMetadata,
  AutoDeltaVehicleQuestionMetadata,
  DRIVER_INTL,
  driverDiscountsOptions,
  getVehicleDescription,
} from '@ecp/features/sales/quotes/auto';
import { PrefillFlow } from '@ecp/features/sales/shared/constants';
import { PagePath } from '@ecp/features/sales/shared/routing';
import { questionExists } from '@ecp/features/sales/shared/store';
import type { AppDispatch, RootStore } from '@ecp/features/sales/shared/store/types';
import { createDeepSelector } from '@ecp/features/sales/shared/store/utils';
import type {
  Answers,
  AnswerValue,
  Driver,
  DriverDiscountMetadata,
  DriverOnly,
  FieldCardOption,
  FieldsDef,
  Insurance,
  Nested,
  PropertyList,
  QuestionsMetadata,
  Template,
  Vehicle,
  VehicleBasic,
  VehicleOnly,
} from '@ecp/features/sales/shared/types';
import type { Product } from '@ecp/features/shared/product';
import type { BaseStateMetadataCollection, CardOption, Field, Fields, Question } from '@ecp/types';

import {
  getAllDriverPersonPriorInsuranceRefs,
  getAllValues,
  getField,
  getPrimaryInsuredPersonRef,
} from '../form/selectors';
import { getCurrentPage } from '../nav/selectors';
import {
  addressMapping,
  addressTemplate,
  getAddressProperties,
  getMappedKey,
  getMappedKeyDynamic,
  getPerson,
  getPropertiesOfTemplateWithRef,
  isTemplate,
  personMapping,
  personTemplate,
  setProperty,
} from './noun-selectors';
import {
  getAnswer,
  getAnswers,
  getDeltaField,
  getPriorInsuranceRef,
  getQuestion,
  getVehicleRefs,
} from './selectors';
import { getValueForPrefix } from './util/getValueForPrefix';

const driverTemplate: Template<DriverOnly> = {
  driversLicense: {
    ageFirstLicensed: '',
    status: '',
    number: '',
    state: '',
    country: '',
    licensedEighteenMonths: false,
    relationshipToInsured: '',
  },
  priorInsuranceRefs: '',
  needFinancialForm: false,
  hasIncidents: false,
  hasPriorInsurance: false,
  isSni: false,
  incidents: '',
  incidentRefs: '',
  personRef: '',
  militaryDeploymentDate: '',
  driverStatus: '',
  ref: '',
};
const insuranceTemplate: Template<Insurance> = {
  currentInsurance: {
    state: '',
    carrier: '',
    carrierNameText: '',
    years: '',
    policyInceptionDate: '',
    endDate: '',
    lapse: '',
    limit: '',
    coverage: '',
    vehicleComprehensive: '',
    vehicleCollision: '',
  },
};
export const getDriverOnlyProperties = (ref: string): PropertyList<DriverOnly> =>
  getPropertiesOfTemplateWithRef(driverTemplate, [ref]);

export const getDriverProperties = (
  personRef: string,
  driverRef: string,
  insuranceRef: string,
): PropertyList<Driver> => [
  ...getPropertiesOfTemplateWithRef(driverTemplate, [driverRef]),
  ...getPropertiesOfTemplateWithRef(personTemplate, [personRef]),
  ...getPropertiesOfTemplateWithRef(insuranceTemplate, [insuranceRef]),
];

const driverMapping: Record<string, string> = {
  // include person mapping
  ...personMapping,
  personRef: 'person.ref',
  'currentInsurance.carrier': 'carrierName',
  'currentInsurance.carrierNameText': 'carrierNameText',
  'currentInsurance.endDate': 'policyEndDate',
  'currentInsurance.vehicleCollision': 'coverages.vehicle.collision',
  'currentInsurance.vehicleComprehensive': 'coverages.vehicle.comprehensive',
  'currentInsurance.years': 'years',
  'currentInsurance.lapse': 'lapse',
  'driversLicense.ageFirstLicensed': 'license.ageFirstLicensed',
  'driversLicense.status': 'license.status',
  'driversLicense.number': 'license.number',
  'driversLicense.state': 'license.state',
  'driversLicense.licensedEighteenMonths': 'license.licensedEighteenMonths',
  'driversLicense.relationshipToInsured': 'license.relationshipToInsured',
  priorInsuranceRefs: 'priorInsurance.ref',
  needFinancialForm: 'sr22FinancialRespFiling',

  // FIXME: incidents isn't mapped, it's as ONE-TO-MANY object

  // FIXME: these don't seem to be returned
  'currentInsurance.coverage': 'coverage',
  'currentInsurance.limit': 'limit',
  // hasFelony
  // hasIncidents
  // hasPriorInsurance
  // isSni
};

export const getDriver = (
  ref: string,
  personRef: string,
  currentInsuranceRef: string,
  allValues: Answers,
  getQuestion: (key: string, questionKey: string) => Question,
): Driver => {
  const getString = getValueForPrefix<string>(ref, allValues);
  const getStringForCurrentInsurance = getValueForPrefix<string>(currentInsuranceRef, allValues);
  const getBoolean = getValueForPrefix<boolean>(ref, allValues);
  const getArray = getValueForPrefix<string[]>(ref, allValues);

  return {
    ...getPerson(personRef, allValues, getQuestion),
    driversLicense: {
      ageFirstLicensed: getString('license.ageFirstLicensed'),
      status: getString('license.status'),
      number: getString('license.number'),
      state: getString('license.state'),
      licensedEighteenMonths: getBoolean('license.usCanadaDriverLast18Months'),
      relationshipToInsured: getString('license.relationshipToInsured'),
    },
    currentInsurance: {
      state: getStringForCurrentInsurance('state'),
      carrier: getStringForCurrentInsurance('carrier'),
      carrierNameText: getStringForCurrentInsurance('carrierNameText'),
      years: getStringForCurrentInsurance('years'),
      policyInceptionDate: getStringForCurrentInsurance('policyInceptionDate'),
      endDate: getStringForCurrentInsurance('policyEndDate'),
      lapse: getStringForCurrentInsurance('lapse'),
      limit: getStringForCurrentInsurance('limit'),
      coverage: getStringForCurrentInsurance('coverage'),
      vehicleComprehensive: getStringForCurrentInsurance('coverages.vehicle.comprehensive'),
      vehicleCollision: getStringForCurrentInsurance('coverages.vehicle.collision'),
    },
    priorInsuranceRefs: getArray('priorInsurance.ref'),
    needFinancialForm: getBoolean('needFinancialForm'),
    fromPrefill: getBoolean('fromPrefill'),
    hasIncidents: getBoolean('hasIncidents'),
    hasPriorInsurance: getBoolean('hasPriorInsurance'),
    isSni: getBoolean('isSni'),
    incidents: getString('incident'),
    incidentRefs: getArray('incident.ref'),
    personRef,
    ref,
    militaryDeploymentDate: getString('militaryDeploymentDate'),
    driverStatus: getString('driverStatus'),
  };
};

export const getDrivers = createSelector(
  (state: RootStore) => getAllValues(state),
  (state: RootStore) => getAllDriverPersonPriorInsuranceRefs(state),
  (state: RootStore) => getPrimaryInsuredPersonRef(state),
  (state: RootStore) => (key: string, questionKey: string) => getQuestion(key, questionKey)(state),
  (allValues, driverPersonPriorInsuranceRefs, primaryInsured, getQuestion): Driver[] => {
    const drivers = driverPersonPriorInsuranceRefs
      .map(([driverRef, personRef, priorRefs]) => {
        const currentInsuranceRef = (priorRefs as string[] | undefined)?.[0] || '';

        return getDriver(
          driverRef as string,
          personRef as string,
          currentInsuranceRef,
          allValues,
          getQuestion,
        );
      })
      .filter((d) => !!d.personRef); // remove zombie included drivers

    // Ensure primary is first in array
    drivers.forEach((driver, i) => {
      if (driver.personRef === primaryInsured) {
        drivers.splice(i, 1);
        drivers.unshift(driver);
      }
    });

    return drivers;
  },
);

export const getNonPniDrivers = createSelector(
  (state: RootStore) => getAllValues(state),
  (state: RootStore) => getDrivers(state),
  (state: RootStore) => getPrimaryInsuredPersonRef(state),
  (allValues, drivers, primaryInsured): Driver[] => {
    const nonPniDrivers = drivers.reduce<Driver[]>((acc, driver) => {
      driver.dateOfBirth =
        allValues[`static.${driver.personRef}.dob`]?.toString() || driver.dateOfBirth;
      if (driver.personRef !== primaryInsured) {
        return [...acc, driver];
      }

      return acc;
    }, []);

    return nonPniDrivers;
  },
);

export const getOperatorsInDrivers = createSelector(
  (state: RootStore) => getDrivers(state),
  (drivers): Driver[] => {
    const operators = drivers.filter(
      (driver) => !['NON_OPERATOR', 'EXCLUDED_OPERATOR'].includes(driver.driverStatus),
    );

    return operators;
  },
);

// FIXME: These selectors are not yet optimized to be reference stable in any way.
export const getDriverValues = (state: RootStore, ref: string): Driver => {
  const answers = getAnswers(state);
  const personRef = answers[`${ref}.person.ref`];
  const currentInsuranceRef = getPriorInsuranceRef(state, ref)[0];
  if (typeof personRef !== 'string') {
    datadogLog({
      logType: 'error',
      message: `${ref} did not have a person.ref`,
      context: {
        logOrigin: 'libs/features/sales/shared/store/lib/src/inquiry/noun-selectors-auto.ts',
        functionOrigin: 'getDriverValues',
      },
    });
    throw new Error(`${ref} did not have a person.ref`);
  }
  const properties = getDriverProperties(personRef, ref, currentInsuranceRef);

  return properties.reduce(
    (result, property) => {
      // the properties given here can be trusted to be part of driver,
      // since they were generated with getDriverProperties

      const key = getMappedKey(property, driverMapping);
      const value = answers[key];
      setProperty(result as unknown as Nested<AnswerValue>, property, value);

      return result;
    },
    { ref } as Driver,
  );
};

export const getDriversValues = (state: RootStore, refs: string[]): Record<string, Driver> => {
  return refs.reduce((result, ref) => {
    result[ref] = getDriverValues(state, ref);

    return result;
  }, {} as Record<string, Driver>);
};

export const getDriverFields = (
  state: RootStore,
  ref: string,
  dispatch: AppDispatch,
  autoOfferProduct: Product,
): FieldsDef<Driver> => {
  const answers = getAnswers(state);
  const personRef = answers[`${ref}.person.ref`];
  const currentInsuranceRef = getPriorInsuranceRef(state, ref)[0];

  if (typeof personRef !== 'string') {
    datadogLog({
      logType: 'error',
      message: `${ref} did not have a person.ref`,
      context: {
        logOrigin: 'libs/features/sales/shared/store/lib/src/inquiry/noun-selectors-auto.ts',
        functionOrigin: 'getDriverFields',
      },
    });
    throw new Error(`${ref} did not have a person.ref`);
  }
  const properties = getDriverProperties(personRef, ref, currentInsuranceRef);

  // the parameters passed to this go into the values that are displayed to the user, like help text
  // since we only care about the keys we don't need to put in the values
  const metadata = AutoDeltaDriverQuestionMetadata()[autoOfferProduct];

  // deltaKeys will look like:
  // ['driver.123', 'license.state'] or ['person.123', 'relationshipToApplicant'], etc.
  const deltaRefsAndFieldnames = metadata
    ? Object.keys(metadata).map((k) => {
        if (metadata[k].key === 'relationshipToApplicant') {
          return [personRef, metadata[k].key];
        }

        return [ref, metadata[k].key];
      })
    : [];

  return properties.reduce(
    (result, property) => {
      // the properties given here can be trusted to be part of driver,
      // since they were generated with getDriverProperties

      let key = getMappedKey(property, driverMapping);
      // key now looks like something that can go into answers,
      // e.g. driver.123.licenes.state
      // if this matches something that we have delta metadata for,
      // then we need to instead use the delta key name
      const matchedDeltaTuple = deltaRefsAndFieldnames.find(
        ([ref, fieldName]) => key === `${ref}.${fieldName}`,
      );
      if (matchedDeltaTuple) {
        // this selector determines the way this field should be named
        // in v3 it would be connect.auto.delta.driver.123.license.state
        // in v4 it would be delta.driver.123.license.state
        key = getDeltaField(state, matchedDeltaTuple[0], matchedDeltaTuple[1]);
      }

      const value = getField(state, { key, dispatch });

      setProperty(result, property, value);

      return result;
    },
    // This object will become a Driver
    {} as FieldsDef<Driver>,
  );
};

export const getDriversFields = (
  state: RootStore,
  refs: string[],
  dispatch: AppDispatch,
  autoOfferProduct: Product,
): Record<string, FieldsDef<Driver>> => {
  return refs.reduce((result, ref) => {
    result[ref] = getDriverFields(state, ref, dispatch, autoOfferProduct);

    return result;
  }, {} as Record<string, FieldsDef<Driver>>);
};

export const getDriverQuestions = (
  state: RootStore,
  drivers: Driver[],
  dispatch: AppDispatch,
  driverProfileKeys: Array<{ type: 'person' | 'driver' | 'priorInsurance'; key: string }>,
  flow: PrefillFlow,
): Record<string, Fields> => {
  return drivers.reduce((driverFields, driver) => {
    const fields: Fields = {};
    for (let i = 0; i < driverProfileKeys.length; i += 1) {
      const curKey = driverProfileKeys[i];

      if (curKey.type === 'driver' && questionExists(`${driver.ref}.${curKey.key}`)) {
        fields[curKey.key] = getField(state, { key: `${driver.ref}.${curKey.key}`, dispatch });

        if (flow === PrefillFlow.SHORT && curKey.key.includes('license')) {
          const question: Question = {
            ...(fields[curKey.key]?.question as Question),
            required: 'true',
          };
          fields[curKey.key] = {
            ...(fields[curKey.key] as Field),
            question,
          };
        }
      }

      if (curKey.type === 'person' && questionExists(`${driver.personRef}.${curKey.key}`)) {
        fields[curKey.key] = getField(state, {
          key: `${driver.personRef}.${curKey.key}`,
          dispatch,
        });
      }

      if (
        curKey.type === 'priorInsurance' &&
        questionExists(`${driver.priorInsuranceRefs?.[0]}.${curKey.key}`)
      ) {
        fields[curKey.key] = getField(state, {
          key: `${driver.priorInsuranceRefs?.[0]}.${curKey.key}`,
          dispatch,
        });
      }
    }

    driverFields[driver.ref] = fields;

    return driverFields;
  }, {} as Record<string, Fields>);
};

export const getDriverAutoDeltaQuestions = (
  state: RootStore,
  drivers: Driver[],
  dispatch: AppDispatch,
  autoOfferProduct: Product,
  pniRef: string,
): { metadata: QuestionsMetadata; fields: Fields; driver: Driver }[] => {
  return (
    (drivers ?? [])
      // if a driver has no questions then we don't want to return them
      .filter((driver) => {
        const metadata = AutoDeltaDriverQuestionMetadata(
          driver.firstName,
          '',
          driver.driversLicense.status === DRIVER_INTL,
        )[autoOfferProduct];
        const field = metadata
          ? Object.keys(metadata).filter(
              // TODO (CPCR-1628): This hack exists because SAPI sends relationshipToApplicant as person.<id> instead of driver.<id>
              // ideally SAPI would send all the auto delta driver questions with the driver id instead of the person id
              (key) =>
                questionExists(getDeltaField(state, driver.ref, key))(state) ||
                questionExists(getDeltaField(state, driver.personRef, key))(state),
            )
          : [];

        return field.length > 0;
      })
      .map((driver) => {
        let pni: Driver | undefined;

        // If we get a secondary driver we want to set up the fields
        // so that they have the correct display text in the metadata
        if (driver.personRef !== pniRef) {
          pni = drivers.find((d) => d.personRef === pniRef);
        }

        const driverMetadata = AutoDeltaDriverQuestionMetadata(
          driver.firstName,
          pni ? pni.firstName : '',
          driver.driversLicense.status === DRIVER_INTL,
        )[autoOfferProduct];
        const driverMetadataKeys = Object.keys(driverMetadata);

        // We need to filter down the keys to the ones that are applicable
        const metadata: QuestionsMetadata = driverMetadataKeys
          // TODO (CPCR-1628): This hack exists because SAPI sends relationshipToApplicant as person.<id> instead of driver.<id>
          // ideally SAPI would send all the auto delta driver questions with the driver id instead of the person id
          .filter(
            (key) =>
              questionExists(getDeltaField(state, driver.ref, key))(state) ||
              questionExists(getDeltaField(state, driver.personRef, key))(state),
          )
          .map((key) => driverMetadata[key])
          .reduce((acc: QuestionsMetadata, curr) => {
            // Since curr.key acts as a global identifier we need the actual key of the field
            const key = driverMetadataKeys.find(
              (x) => driverMetadata[x].key === curr.key,
            ) as string;
            acc[key] = curr;

            return acc;
          }, {});

        const fields = getAutoDeltaFields(state, driver.ref, metadata, dispatch);

        // TODO (CPCR-1628): This hack exists because SAPI sends relationshipToApplicant as person.<id> instead of driver.<id>
        const personFields = getAutoDeltaFields(state, driver.personRef, metadata, dispatch);
        const personFieldKeys = Object.keys(personFields);

        for (let i = 0; i < personFieldKeys.length; i += 1) {
          const key = personFieldKeys[i];
          fields[key] = personFields[key];
        }

        const metadataKeys = Object.keys(metadata);

        /**
         * In the case where we get the license number but not the license state
         * we will want to make the license number take up the full width of the
         * viewport.
         *
         * This would happen in the case of an international license. This is also
         * a good point to keep the key field on the metadata. This way I can keep the
         * integrity of the SAPI keys and have a key that I can expect. For example, if
         * we have a partner that has 'driverLicense' as the key instead of 'license.number'
         * we can do the following:
         *
         * 'driverLicense': {
         *   key: 'license.number'
         *   ...
         * }
         *
         * When we do that it will all work as expected.
         */
        if (
          metadataKeys.find((x) => metadata[x].key === 'license.number') &&
          !metadataKeys.find((x) => metadata[x].key === 'license.state')
        ) {
          const key = metadataKeys.find((x) => metadata[x].key === 'license.number');
          metadata[`${key}`].displayColumns = 12;
        }

        return { metadata, fields, driver };
      })
  );
};

const vehicleTemplate: Template<VehicleOnly> = {
  antiTheft: false,
  businessUse: '',
  odometerReading: '',
  fromPrefill: false,
  keptAtRiskAddress: false,
  vehicleInfoOrVin: false,
  make: '',
  model: '',
  ownershipDate: '',
  primaryUse: '',
  ref: '',
  safetyAirbag: '',
  safetyAntiLockBrakes: '',
  safetyDayTimeRunningLights: '',
  safetyElectronicStabilityControl: '',
  series: '',
  titleholder: '',
  vin: '',
  year: '',
  annualMiles: '',
  garageRef: '',
  description: '',
  plateNumber: '',
  plateType: '',
  tpiRef: '',
  vehicleDetailId: '',
  stubVin: '',
  msrpPrice: '',
  safety: '',
  driverSafetyAirbag: '',
  passengerSafetyAirbag: '',
  sideSafetyAirbag: '',
};

export const getVehicleOnlyProperties = (ref: string): PropertyList<VehicleOnly> =>
  getPropertiesOfTemplateWithRef(vehicleTemplate, [ref]);

export const getVehicleProperties = (
  vehicleRef: string,
  garageRef?: string,
): PropertyList<Vehicle> => [
  ...getVehicleOnlyProperties(vehicleRef),
  ...(garageRef ? getAddressProperties(garageRef, 'garage') : []),
];

const vehicleMapping: Record<string, string> = {
  antiTheft: 'features.antitheft',
  garageRef: 'garage.address.ref',
  safetyAirbag: 'features.safety.airbag',
  safetyAntiLockBrakes: 'features.safety.antiLockBrakes',
  safetyDayTimeRunningLights: 'features.safety.dayTimeRunningLights',
  safetyElectronicStabilityControl: 'features.safety.electronicStabilityControl',
  tpiRef: 'thirdPartyInterest.ref',
  driverSafetyAirbag: 'features.safety.driverSafetyAirbag',
  passengerSafetyAirbag: 'features.safety.passengerSafetyAirbag',
  sideSafetyAirbag: 'features.safety.sideSafetyAirbag',

  // FIXME: unknown mapping
  // keptAtRiskAddress ... keptInGarage?
};

const prefixMapping = (prefix: string, mapping: Record<string, string>): Record<string, string> =>
  Object.keys(mapping).reduce((result, key) => {
    const value = mapping[key];
    result[`${prefix}.${key}`] = value;

    return result;
  }, {} as Record<string, string>);

// generates an empty mapping using a template
const identityMapping = <T extends Record<string, unknown>>(
  template: Template<T>,
  prefix = '', // internal to function, do not assign
): Record<string, string> =>
  Object.keys(template).reduce((result, key) => {
    const prefixedKey = prefix ? `${prefix}.${key}` : key;
    const value = template[key];
    if (isTemplate<T[string]>(value)) {
      return {
        ...result,
        ...identityMapping(value, prefixedKey),
      };
    }
    result[prefixedKey] = key;

    return result;
  }, {} as Record<string, string>);

const garageMapping: Record<string, string> = prefixMapping('garage', {
  ...identityMapping(addressTemplate),
  ...addressMapping,
});

// FIXME: These selectors are not yet optimized to be reference stable in any way.
export const getVehicleValues = (state: RootStore, ref: string): Vehicle => {
  const answers = getAnswers(state);
  const garageRef = String(answers[`${ref}.garage.address.ref`]);
  // garageRef is optional?
  // if (typeof garageRef !== 'string') throw new Error(`${ref} did not have a garage.ref`);
  const properties = getVehicleProperties(ref, garageRef);

  return properties.reduce(
    (result, property) => {
      // the properties given here can be trusted to be part of vehicle,
      // since they were generated with getVehicleProperties

      const key = getMappedKeyDynamic(property, {
        [ref]: vehicleMapping,
        [garageRef]: garageMapping,
      });

      const value = answers[key];
      setProperty(result as unknown as Nested<AnswerValue>, property, value);

      return result;
    },
    { ref } as Vehicle,
  );
};

export const getVehiclesValues = (state: RootStore, refs: string[]): Record<string, Vehicle> => {
  return refs.reduce((result, ref) => {
    result[ref] = getVehicleValues(state, ref);

    return result;
  }, {} as Record<string, Vehicle>);
};

// FIXME: return Record<string, Vehicle> instead?
// FIXME: just require caller to lookup ref list?
// (i.e. just remove this function and call useVehiclesValues directly)
// FIXME: callers probably want useVehiclesFields anyway most of the time.
export const getVehicles = createSelector(
  (state: RootStore) => state,
  getVehicleRefs,
  (state, refs) => Object.values(getVehiclesValues(state, refs)),
);

export const getVehicleFields = (
  state: RootStore,
  ref: string,
  dispatch: AppDispatch,
  autoOfferProduct: Product,
): FieldsDef<Vehicle> => {
  const answers = getAnswers(state);
  const currentPage = getCurrentPage(state);
  const garageRef = String(answers[`${ref}.garage.address.ref`]);
  // garageRef is optional?
  // if (typeof garageRef !== 'string') throw new Error(`${ref} did not have a garage.ref`);
  const properties = getVehicleProperties(ref, garageRef);

  // the parameters passed to this go into the values that are displayed to the user, like help text
  // since we only care about the keys we don't need to put in the values
  const metadata = AutoDeltaVehicleQuestionMetadata('')[autoOfferProduct];

  const deltaRefsAndFieldnames = metadata
    ? Object.keys(metadata).map((k) => [ref, metadata[k].key])
    : [];

  return properties.reduce(
    (result, property) => {
      // the properties given here can be trusted to be part of vehicle,
      // since they were generated with getVehicleProperties

      let key = getMappedKeyDynamic(property, {
        [ref]: vehicleMapping,
        [garageRef]: garageMapping,
      });

      // We only want to track the autoOfferProduct namespaced key-values when we are on the delta page.
      // From the delta page, a user can still back-navigate to the auto profile form and add vehicles.
      // In this scenario, we want to check incoming VINs against the plain 'vehicle.<ref>.vin' key-values.
      // See https://theexperimentationlab.atlassian.net/browse/CPUI-1892 for more details.
      if (currentPage?.includes(PagePath.AUTO_DELTA)) {
        // key now looks like something that can go into answers,
        // e.g. vehicle.123.vin
        // if this matches something that we have delta metadata for,
        // then we need to instead use the delta key name
        const matchedDeltaTuple = deltaRefsAndFieldnames.find(
          ([ref, fieldName]) => key === `${ref}.${fieldName}`,
        );
        if (matchedDeltaTuple) {
          // this selector determines the way this field should be named
          // in v3 it would be connect.auto.delta.driver.123.license.state
          // in v4 it would be delta.driver.123.license.state
          key = getDeltaField(state, matchedDeltaTuple[0], matchedDeltaTuple[1]);
        }
      }

      const value = getField(state, { key, dispatch });

      setProperty(result, property, value);

      return result;
    },
    // This object will become a Vehicle
    {} as FieldsDef<Vehicle>,
  );
};

export const getVehiclesFields = (
  state: RootStore,
  refs: string[],
  dispatch: AppDispatch,
  autoOfferProduct: Product,
): Record<string, FieldsDef<Vehicle>> => {
  return refs.reduce((result, ref) => {
    result[ref] = getVehicleFields(state, ref, dispatch, autoOfferProduct);

    return result;
  }, {} as Record<string, FieldsDef<Vehicle>>);
};

export const getVehicleQuestions = (
  state: RootStore,
  vehicles: VehicleBasic[],
  dispatch: AppDispatch,
  vehicleKeys: { key: string }[],
): Record<string, Fields> => {
  return vehicles.reduce((vehicleFields, vehicle) => {
    if (vehicle.ref) {
      const fields: Fields = {};
      for (let i = 0; i < vehicleKeys.length; i += 1) {
        const curKey = vehicleKeys[i];

        if (questionExists(`${vehicle.ref}.${curKey.key}`)) {
          fields[curKey.key] = getField(state, { key: `${vehicle.ref}.${curKey.key}`, dispatch });
        }
      }

      vehicleFields[vehicle.ref] = fields;
    }

    return vehicleFields;
  }, {} as Record<string, Fields>);
};

/**
 * This will map the metadata to the vehicle fields
 * and return the fields as an object with the key
 */
const getAutoDeltaFields = (
  state: RootStore,
  ref: string, // driver.123
  metadata: QuestionsMetadata,
  dispatch: AppDispatch,
): Fields => {
  const keys = Object.keys(metadata);

  const fields: Fields = {};

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];

    const fieldName = getDeltaField(state, ref, key);

    if (questionExists(fieldName)(state)) {
      fields[key] = getField(state, { key: fieldName, dispatch });
    }
  }

  return fields;
};

export const getVehicleAutoDeltaQuestions = (
  state: RootStore,
  vehicles: Vehicle[],
  dispatch: AppDispatch,
  autoOfferProduct: Product,
): { metadata: QuestionsMetadata; fields: Fields; vehicle: Vehicle }[] => {
  return (
    (vehicles ?? [])
      // If we cannot find any fields for this key then we don't want to return it
      .filter((vehicle) => {
        const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;
        const metadata = AutoDeltaVehicleQuestionMetadata(vehicleName)[autoOfferProduct];
        // We only want to return an item in the array if the question actually exists
        const fields = metadata
          ? Object.keys(metadata).filter((key) =>
              questionExists(getDeltaField(state, vehicle.ref, key))(state),
            )
          : [];

        return fields.length > 0;
      })
      .map((vehicle) => {
        const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`;

        const vehicleMetadata = AutoDeltaVehicleQuestionMetadata(vehicleName)[autoOfferProduct];
        const vehicleMetadataKeys = Object.keys(vehicleMetadata);

        // We need to filter down the keys to the ones that are applicable
        const metadata = vehicleMetadataKeys
          .filter((key) => questionExists(getDeltaField(state, vehicle.ref, key))(state))
          .map((key) => vehicleMetadata[key])
          .reduce((acc, curr) => {
            // Since curr.key acts as a global identifier we need the actual key of the field
            const key = vehicleMetadataKeys.find(
              (x) => vehicleMetadata[x].key === curr.key,
            ) as string;
            acc[key] = curr;

            return acc;
          }, {} as QuestionsMetadata);

        const fields = getAutoDeltaFields(state, vehicle.ref, metadata, dispatch);

        return { metadata, fields, vehicle };
      })
  );
};

// This vehicle reference stable selector is added only for vehicle basic info for now.
// FIXME: Need to make the whole vehicle object reference stable.
export const getBasicVehicle = createCachedSelector(
  (state: RootStore, ref: string) => getAnswer(state, `${ref}.year`) as string,
  (state, ref) => getAnswer(state, `${ref}.make`) as string,
  (state, ref) => getAnswer(state, `${ref}.model`) as string,
  (state, ref) => getAnswer(state, `${ref}.vin`) as string,
  (...[, ref]) => ref,
  (year, make, model, vin, ref): VehicleBasic => {
    const vehicle = {
      year,
      make,
      model,
      vin,
      ref,
    };
    const description = getVehicleDescription(vehicle);

    return {
      ...vehicle,
      description,
    };
  },
)((...[, key]) => key);

export const getBasicVehicles = createDeepSelector(
  (state: RootStore, refs: string[]) => refs.map((ref) => getBasicVehicle(state, ref)),
  (vehicles) => vehicles,
);

export const getStateSpecificVehicleDiscountMetadata = <T extends FieldCardOption | CardOption>(
  cardOptions: T[],
  stateCode: string,
): T[] => {
  return cardOptions.map((option) => {
    let opt = option;
    if (option.stateOptions) {
      const { stateOptions } = option;
      opt = { ...option, ...stateOptions[stateCode] };
    }
    delete opt.stateOptions;

    return opt;
  });
};

export const getStateSpecificAntiTheftCategoryMetadata = (
  metadata: BaseStateMetadataCollection,
  stateCode: string,
): BaseStateMetadataCollection => {
  const data = metadata;
  Object.keys(metadata).forEach((key) => {
    const { stateOptions } = metadata[key];
    if (stateOptions) {
      data[key] = { ...metadata[key], ...stateOptions[stateCode] };
      delete data[key].stateOptions;
    }
  });

  return data;
};

const getStateSpecificDriverDiscountMetadata = (
  cardOptions: DriverDiscountCardOption[],
  stateCode: string,
): DriverDiscountCardOption[] => {
  return cardOptions.map((option) => {
    let opt = option;
    if (option.stateOptions) {
      const { stateOptions } = option;
      opt = { ...option, ...stateOptions[stateCode] };
    }
    // We don't need state options at this point so we can remove it from the card option
    delete opt.stateOptions;

    return opt;
  });
};

// TODO: Remove this once SAPI is able to send the discounts questions based on display rules
// Filter discount when atleast one driver on the quote is older than specified age limit (or)
// When more than one driver is younger than the specified age limit
const checkDriversWithAgeLimit = (drivers: Driver[], option: DriverDiscountCardOption): boolean => {
  // check if any of the drivers on the quote are older than additionalAgeLimit
  const hasDriverOlderThanAdditionalAgeLimit = drivers.some((driver) => {
    const age = getAge(driver.dateOfBirth);

    return age && option.additionalAgeLimit && age > option.additionalAgeLimit;
  });
  // Filter drivers who are younger than ageLimit
  const hasMoreThanOneDriverYoungerThanAgeLimit =
    drivers.filter((dr) => {
      const drAge = getAge(dr.dateOfBirth);

      return drAge && option.ageLimit && drAge < option.ageLimit;
    }).length > 1;

  return hasDriverOlderThanAdditionalAgeLimit || hasMoreThanOneDriverYoungerThanAgeLimit;
};

export const getDriverDiscountMetadata = (
  state: RootStore,
  drivers: Driver[],
  stateCode: string,
): DriverDiscountMetadata[] => {
  return drivers.reduce((acc, curr) => {
    const driverAge = getAge(curr.dateOfBirth);
    const isOperator = curr.driverStatus === 'OPERATOR';
    const filteredMetadata = driverDiscountsOptions().filter((x) => {
      if (x.stateOptions && x.stateOptions[stateCode]?.value)
        return questionExists(`${curr.ref}.discount.${x.stateOptions[stateCode]?.value}`)(state);

      return questionExists(`${curr.ref}.discount.${x.value}`)(state);
    });

    const metadata = getStateSpecificDriverDiscountMetadata(filteredMetadata, stateCode);

    // TODO: Remove this once SAPI is able to send the discounts questions based on display rules
    // Filter options based on the driverStatus and age limts.
    const finalDiscountsMeta =
      metadata.filter((opt) => opt.ageLimit || opt.additionalAgeLimit || opt.ageGreater).length > 0
        ? metadata.filter(
            (option) =>
              isOperator &&
              driverAge &&
              (option.ageLimit ? driverAge < option.ageLimit : true) &&
              (option.ageGreater ? driverAge > option.ageGreater : true) &&
              (option.additionalAgeLimit ? checkDriversWithAgeLimit(drivers, option) : true),
          )
        : metadata;

    acc.push({ ...curr, cardOptions: finalDiscountsMeta });

    return acc;
  }, [] as DriverDiscountMetadata[]);
};

const isDlValid = (
  driver: ReturnType<typeof getDrivers>[number],
  driverLicenseStatusQuestionExists: boolean,
): boolean =>
  !driverLicenseStatusQuestionExists ||
  driver.driversLicense.status === 'LICENSE.STATUS.VALID_US' ||
  driver.driversLicense.status === 'LICENSE.STATUS.INTERNATIONAL';

export const getAssignmentDrivers = createSelector(
  (state: RootStore) => getOperatorsInDrivers(state),
  (state: RootStore) => questionExists('driver.<id>.license.status')(state),
  (operatorsInDrivers, driverLicenseStatusQuestionExists): Driver[] => {
    const operators = operatorsInDrivers.filter((driver) =>
      isDlValid(driver, driverLicenseStatusQuestionExists),
    );

    return operators;
  },
);
