import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'AgentRecallPersonalInfo' })((theme) => ({
  ...theme.mixins.formAll,
  root: {},
  iconTitle: {
    display: 'flex',
    justifyContent: 'center',
    '& svg': {
      background: theme.palette.primary.main,
      borderRadius: 15,
      '& path': {
        fill: theme.palette.background.default,
      },
    },
  },
  navBarIcon: {
    fill: 'black',
  },
  navBarIconTitle: {
    marginLeft: 10,
    verticalAlign: 'super',
  },
  cardPadding: {
    padding: '15px 13px 15px 20px',
  },
  cardMargin: {
    '& .MuiCardContent-root:last-child': {
      padding: '15px 20px',
    },
    marginBottom: 15,
    boxShadow: 'none',
    borderRadius: 4,
  },
  content: {
    marginLeft: 35,
    '& ul': {
      listStyleType: 'none',
      margin: '5px 0px 0px',
      '& li': {
        margin: '0px 0px 5px -40px',
        color: theme.palette.text.secondary,
      },
    },
  },
}));
