import { useEffect } from 'react';

import { useEvent } from '@ecp/utils/react';

import { PagePath, useNavigateToPage } from '@ecp/features/sales/shared/routing';
import { renewSession, startOverFlow } from '@ecp/features/sales/shared/store';
import { useDispatch } from '@ecp/features/sales/shared/store/utils';

import { Dialog } from '../../Dialog';
import { getSessionPromptEventDetail } from '../util/getSessionPromptEventDetail';
import { getSessionPromptLabels } from '../util/labelUtil';
import { trackContinueQuoteButtonClick } from '../util/trackContinueQuoteButtonClick';
import { trackContinueSessionButtonClick } from '../util/trackContinueSessionButtonClick';
import { trackGetQuoteButtonClick } from '../util/trackGetQuoteButtonClick';
import { trackPromptRender } from '../util/trackPromptRender';
import { useOfferExists } from '../util/useOfferExists';
import { useSessionExpiryPromptTimer } from '../util/useSessionExpiryPromptTimer';
import { ExpiredPrompt } from './sub-prompts/ExpiredPrompt';
import { WarningPrompt } from './sub-prompts/WarningPrompt';

export const SessionExpiryPromptDialog: React.FC = () => {
  const dispatch = useDispatch();

  const { showPrompt, isSessionExpired, remaining, resetTimer } = useSessionExpiryPromptTimer({
    enableTimer: true,
  });

  const offerExist = useOfferExists();
  const { buttonText, titleText, trackingLabel, trackingName, trackingNameCloseIcon } =
    getSessionPromptLabels(isSessionExpired, offerExist);
  const navigateToRetrievePage = useNavigateToPage(PagePath.LANDING_RETRIEVE);
  const eventDetail = getSessionPromptEventDetail(isSessionExpired, offerExist);

  useEffect(() => {
    if (showPrompt) trackPromptRender(eventDetail, isSessionExpired);
  }, [eventDetail, isSessionExpired, showPrompt]);

  const handleWarningActionClick = useEvent(async (closeButton?: boolean) => {
    await dispatch(renewSession());
    resetTimer();
    trackContinueSessionButtonClick(closeButton);
  });

  const handleGetQuote = useEvent(async (closeButton?: boolean) => {
    await dispatch(startOverFlow());
    resetTimer();
    trackGetQuoteButtonClick(eventDetail, closeButton);
  });

  const handleContinueQuote = useEvent(async (closeButton?: boolean) => {
    await dispatch(startOverFlow());
    resetTimer();
    await navigateToRetrievePage();
    trackContinueQuoteButtonClick(eventDetail, closeButton);
  });

  const handleActionButtonClick = useEvent(async () => {
    if (!isSessionExpired) {
      handleWarningActionClick();

      return;
    }

    if (!offerExist) {
      handleGetQuote();
    } else {
      handleContinueQuote();
    }
  });

  const handleCloseIconClick = useEvent(async () => {
    if (!isSessionExpired) {
      handleWarningActionClick(true);

      return;
    }

    if (!offerExist) {
      handleGetQuote(true);
    } else {
      handleContinueQuote(true);
    }
  });

  return (
    <Dialog
      open={showPrompt}
      titleText={titleText}
      onClose={handleCloseIconClick}
      actionButtonOnClick={handleActionButtonClick}
      actionButtonLabel={buttonText}
      trackingNameButton={trackingName}
      trackingLabelButton={trackingLabel}
      trackingNameCloseIcon={trackingNameCloseIcon}
      trackingLabelCloseIcon={trackingLabel}
    >
      {!isSessionExpired ? (
        <WarningPrompt remaining={remaining} />
      ) : (
        <ExpiredPrompt offerExist={offerExist} />
      )}
    </Dialog>
  );
};
