import { navigate } from '@ecp/utils/routing';

import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  getLineOfBusiness,
  getPrimaryFlowStep,
  PrimaryFlowStep,
  wrapThunkActionWithErrHandler,
} from '@ecp/features/sales/shared/store';
import { LineOfBusiness } from '@ecp/features/shared/product';

/**
 * EDSP-11130 - Resume flow for experiences coming with url and quoteid
 * On Post of inquiry DAL does do get offers and does check for other conditions and sends back below data as part of post inquiry
 * 1. PrimaryFlowStep value
 * 2. Offers
 * getNavigateToPrimaryFlowStepOnResume logic is to define where the user should be taken based on below conditions
 * 1. PRE_PRODUCT --> No Pre-fill Called (Go to landing page)
 * 2. PRODUCT--> Pre-fill called and OfferId doesn't exists (Go to first page of product)
 * 3. QUOTE --> OfferId exists and bindable === false (Go to quote page)
 * 4. POST_QUOTE --> OfferId exists and bindable === true (Go to delta page)
 * 5. RESUME_FAILED --> DNQ, 500 Error, something went wrong (Go to landing page)
 */
export const getNavigateToPrimaryFlowStepOnResume = wrapThunkActionWithErrHandler(
  () => async (dispatch, getState) => {
    const state = getState();
    const lineOfBusiness = getLineOfBusiness(state);
    const flowStep = getPrimaryFlowStep(state);

    const getPageRouteToResume = (): PagePath => {
      switch (lineOfBusiness) {
        case LineOfBusiness.AUTO: // Auto Monoline
        case LineOfBusiness.BUNDLE: // Auto Home Bundle
        case LineOfBusiness.BUNDLE_AUTO_RENTERS: // Auto Renters Bundle
          switch (flowStep) {
            case PrimaryFlowStep.PRODUCT:
              return PagePath.ADD_EDIT_DRIVERS;
            case PrimaryFlowStep.QUOTE:
              return PagePath.QUOTES;
            case PrimaryFlowStep.POST_QUOTE:
              return PagePath.AUTO_DELTA;
            case PrimaryFlowStep.RESUME_FAILED:
              return PagePath.QUOTE_RESUME_ERROR;
            default:
              return PagePath.LANDING;
          }

        case LineOfBusiness.HOME: // Home Monoline
          switch (flowStep) {
            case PrimaryFlowStep.PRODUCT:
              return PagePath.HOME_ELIGIBILITY;
            case PrimaryFlowStep.QUOTE:
              return PagePath.QUOTES;
            case PrimaryFlowStep.POST_QUOTE:
              return PagePath.HOME_DELTA;
            case PrimaryFlowStep.RESUME_FAILED:
              return PagePath.QUOTE_RESUME_ERROR;
            default:
              return PagePath.LANDING;
          }

        case LineOfBusiness.RENTERS: // Renters Monoline
          switch (flowStep) {
            case PrimaryFlowStep.PRODUCT:
              return PagePath.RENTERS_PROPERTY_DETAILS;
            case PrimaryFlowStep.QUOTE:
              return PagePath.QUOTES;
            case PrimaryFlowStep.POST_QUOTE:
              return PagePath.RENTERS_DELTA;
            case PrimaryFlowStep.RESUME_FAILED:
              return PagePath.QUOTE_RESUME_ERROR;
            default:
              return PagePath.LANDING;
          }

        default:
          return PagePath.LANDING;
      }
    };

    navigate(getPageRouteToResume());
  },
  'getNavigateToPrimaryFlowStepOnResume',
);
