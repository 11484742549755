import type { AllConfigs } from '../../types.js';
import { initialSummaryPageFlow as initialSummaryPageFlowBase } from './metadata.js';

export const initialSummaryPageFlow: AllConfigs = [
  ...initialSummaryPageFlowBase,
  {
    executionOrder: 30,
    startEffectiveDate: '01/01/2021',
    description: 'Quote Summary page for amfam advance experiences',
    criteria: {
      baseExp: ['Agent'],
      expId: ['2860'],
    },
    config: {
      menuItems: [
        {
          text: 'Quote Summary',
          pageId: 'quoteSummary',
          path: '/quote/quotes',
        },
      ],
      navigationOrder: ['quoteSummary'],
      byPageId: {
        quoteSummary: {
          path: '/quote/quotes',
          pageTitle: 'Quote Summary',
        },
      },
    },
  },
];
