import loadableComponent from '@loadable/component';
import type { Replace } from 'type-fest';

import { isTruthy } from '@ecp/utils/common';
import { datadogLog } from '@ecp/utils/logger';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import {
  getOpCoName,
  getOverrideException,
  getPartnerName,
  getProducts,
} from '@ecp/features/sales/shared/store';
import {
  CALLED_GET_STATE_IN_REDUCER,
  globalSelector,
  NO_GLOBAL_STORE_FOUND,
} from '@ecp/features/sales/shared/store/utils';
import type { Product } from '@ecp/features/shared/product';
import type { OpCoName, OverrideException, PartnerName } from '@ecp/partners';

/**
 * Returns override levels in the following priority:
 * partner name -> product names -> operating company name.
 */
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const getOverrideLevels = () => {
  let partnerName: PartnerName | undefined = undefined;
  let products: Replace<Product, '.', '-'>[] = [];
  let opCoName: OpCoName | undefined = undefined;
  let overrideException: OverrideException = undefined;

  // Global store would not be initialized during the modules import resolution initially
  try {
    partnerName = globalSelector(getPartnerName);
    products = globalSelector(getProducts)?.map(
      (product) => product.replace('.', '-') as Replace<Product, '.', '-'>,
    );
    opCoName = globalSelector(getOpCoName);
    overrideException = globalSelector(getOverrideException);
  } catch (error) {
    const e = error as TypeError;

    if (![NO_GLOBAL_STORE_FOUND, CALLED_GET_STATE_IN_REDUCER].includes(e?.message)) {
      datadogLog({
        logType: 'error',
        message: e?.message,
        context: {
          logOrigin: 'libs/features/sales/shared/loadable/src/loadable.ts',
          contextType: 'Module not resolved',
          functionOrigin: 'getOverrideLevels',
          message: e?.message,
        },
        error: e,
      });
      console.warn(e?.message);
    }

    // We want to throw error if we are calling store.getState() in reducer,
    // otherwise agent overrides aren't applied correctly and go unnoticed.
    if (e?.message === CALLED_GET_STATE_IN_REDUCER) throw error;

    // Skip error if it's due to no global store being found
  }

  return [overrideException, partnerName, ...products, opCoName].filter(isTruthy);
};

/** Dynamically loads appropriate metadata based on partner, product and opco overrides */
export const loadable = <Module extends object>(importer: (path: string) => Module): Module => {
  let _module: Module;
  const _moduleCache: Record<string, Module> = {};

  return new Proxy({} as Module, {
    get: (_, prop) => {
      /*
       Check if the Agent Moonshot application is still intializing and if so return the ocpo.
       getOverrideLevels > globalSelector > getState logs an error because loadable is called before initialization. 
      */
      const isAgentIntializing = env.static.isAgent && !sessionStorage.getItem('interactionId');
      let cacheKey = 'base';

      if (!isAgentIntializing) {
        const overrideLevels = getOverrideLevels();
        cacheKey = overrideLevels.join();

        if (_module && _module === _moduleCache[cacheKey]) {
          return Reflect.get(_moduleCache[cacheKey], prop);
        }

        // Traverse override levels, for each level try to load module and return it if it's found
        for (const overrideLevel of overrideLevels) {
          try {
            _module = importer(`.${overrideLevel}`);
            _moduleCache[cacheKey] = _module;

            return Reflect.get(_module, prop);
          } catch {
            // Skip error
          }
        }
      }

      // Last resort is to return the base level
      _module = importer('');
      _moduleCache[cacheKey] = _module;

      return Reflect.get(_module, prop);
    },
  });
};

/** Dynamically loads components base on partner, product and opco overrides */
export const loadComponent = <Module extends object, Component extends React.ComponentType>(
  importer: (path: string) => Promise<Module>,
  resolveComponent: (module: Module) => Component,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) => {
  let _module: Module;

  return loadableComponent(
    async () => {
      const overrideLevels = getOverrideLevels();

      // Traverse override levels, try to load module for each level and stop once a module is found
      for (const overrideLevel of overrideLevels) {
        try {
          _module = await importer(`.${overrideLevel}`);

          return _module;
        } catch {
          // Skip error
        }
      }

      // Last resort is to return the base level
      _module = await importer('');

      return _module;
    },
    {
      cacheKey: () => getOverrideLevels().toString(),
      resolveComponent,
    },
  );
};
