import { SECONDARY_INSURED_PERSON_REF } from '@ecp/features/sales/shared/constants';
import type { ThunkAction } from '@ecp/features/sales/shared/store/types';

import { getPersonRefForDriver } from '../../form/selectors';
import { getAnswer } from '../selectors';
import { deleteInquiryRef } from './deleteInquiryRef';
import { updateAnswers } from './updateAnswers';

// update the SECONDARY_INSURED_PERSON_REF to match the specified driver
// or remove that driver from being the SECONDARY_INSURED_PERSON_REF
export const updateSecondaryPolicyHolderByDriver =
  (driverRef: string, remove: boolean = false): ThunkAction<Promise<void>> =>
  async (dispatch, getState) => {
    const personRef = getPersonRefForDriver(driverRef)(getState());

    // FIXME: this should also handle the case that there is an existing
    // SNI, deleting the previous one

    if (remove) {
      const priorRefsKey = `${driverRef}.priorInsurance.ref`;
      const priorRefsValue = getAnswer(getState(), priorRefsKey) as Array<string>;
      // priorInsurance is only allowed on PNI or SNI
      // clear any priorInsurance that may have been set
      if (priorRefsValue[0]) {
        await dispatch(deleteInquiryRef(priorRefsValue[0]));
      }

      await dispatch(
        updateAnswers({
          answers: { [SECONDARY_INSURED_PERSON_REF]: null },
        }),
      );
    } else {
      await dispatch(
        updateAnswers({
          answers: { [SECONDARY_INSURED_PERSON_REF]: personRef },
        }),
      );
    }
  };
