import { useCallback, useEffect, useState } from 'react';

import { Grid } from '@mui/material';

import { trackClick, trackRender } from '@ecp/utils/analytics/tracking';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';

import { env } from '@ecp/env';
import { Button, SaveAndExit } from '@ecp/features/sales/shared/components';
import { PaymentPlan } from '@ecp/features/sales/shared/constants';
import { PagePath } from '@ecp/features/sales/shared/routing';
import {
  getAreSomeSelectedProductsIndicative,
  getOfferProductsSelectedByType,
  getSelectedPaymentPlan,
  setProcessingRecalc,
} from '@ecp/features/sales/shared/store';
import { getCurrentPage } from '@ecp/features/sales/shared/store';
import { useDispatch, useSelector } from '@ecp/features/sales/shared/store/utils';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import { useIsMobile } from '@ecp/themes/base';

import { getSelectedPaymentOptionForProduct } from '../../state/paymentoptions';
import type { CheckoutCardProps } from '../CheckoutCard';
import { IndicativeQuoteWarning } from '../IndicativeQuoteWarning';
import metadata from './metadata';
import { MobileMonthlyPaymentsBundleCheckoutView } from './MobileMonthlyPaymentsBundleCheckoutView';
import { MobileMonthlyPaymentsBundleCoverageView } from './MobileMonthlyPaymentsBundleCoverageView';
import { useStyles } from './MobileMonthlyPaymentsCheckoutCard.styles';
import { MobileMonthlyPaymentsMonolineCheckoutView } from './MobileMonthlyPaymentsMonolineCheckoutView';
import { MobileMonthlyPaymentsMonolineCoverageView } from './MobileMonthlyPaymentsMonolineCoverageView';

export const MobileMonthlyPaymentsCheckoutCard: React.FC<
  React.PropsWithChildren<CheckoutCardProps>
> = (props) => {
  const {
    buttonText,
    buttonDisabled,
    checkoutData,
    children,
    isMVRActive,
    isProcessing,
    onCheckout,
    isPatchingAnswers,
    recalculate,
    retrieveOffer,
    sapiAnalyticsSelectedOfferEventDetail,
    shouldRecalc,
    showError,
    showStateFees,
    isCheckout,
  } = props;
  const { classes, cx } = useStyles();
  const dispatch = useDispatch();
  const [showProcess, setShowProcess] = useState(false);
  const [recalculated, setRecalculated] = useState(false);
  const [isPricingDetailsHidden, setIsPricingDetailsHidden] = useState(true);

  const { auto: autoOfferProduct, property: propertyOfferProduct } = useSelector(
    getOfferProductsSelectedByType,
  );

  const autoPaymentOption = useSelector((state) =>
    getSelectedPaymentOptionForProduct(state, autoOfferProduct),
  );
  const propertyPaymentOption = useSelector((state) =>
    getSelectedPaymentOptionForProduct(state, propertyOfferProduct),
  );

  const premiumPlan = useSelector(getSelectedPaymentPlan);
  const areSomeSelectedProductsIndicative = useSelector(getAreSomeSelectedProductsIndicative);
  const ctaButtonVariant = areSomeSelectedProductsIndicative ? 'primary' : 'success';

  const currentPage = useSelector(getCurrentPage);
  // Variant B: Remove “Save & Exit” link from the entire Checkout page
  const isVariantB = flagValues[FeatureFlags.SAVE_AND_EXIT] === 'VARIANT_B';

  // Variant C: Add save&exit link and text to “Important Information” block at bottom of checkout page.
  // Important Information will display only if the corresponding state has fraud warning
  const isVariantC = flagValues[FeatureFlags.SAVE_AND_EXIT] === 'VARIANT_C';
  const removeSaveExit = currentPage === PagePath.CHECKOUT && (isVariantB || isVariantC);

  // This makes sure the updated values are sent correctly
  // TODO - These state changes in useEffects are troublesome & needs to be revisited
  useEffect(() => {
    if (recalculated) {
      setRecalculated(false);
      trackRender({
        action: 'RecalculatedCoverages',
        label: sapiAnalyticsSelectedOfferEventDetail,
      });
      trackSapiAnalyticsEvent({
        element: 'choice.policyCoveragePage.recalcButton',
        event: 'click',
        eventDetail: sapiAnalyticsSelectedOfferEventDetail,
      });
    }
    // hide current premium cost until recalc is complete
    if (recalculate || isPatchingAnswers) {
      setIsPricingDetailsHidden(false);
    }
  }, [
    sapiAnalyticsSelectedOfferEventDetail,
    dispatch,
    recalculated,
    recalculate,
    isPatchingAnswers,
  ]);

  const showHidePricingDetails = useCallback(() => {
    setIsPricingDetailsHidden((prev) => {
      const nextIsPricingDetailsHidden = !prev;
      const prefix = nextIsPricingDetailsHidden ? 'Hide' : 'Show';
      trackClick({
        action: `${prefix}PricingDetailsLink`,
        label: `${prefix}PricingDetails`,
      });

      return nextIsPricingDetailsHidden;
    });
  }, []);

  const recalculateClick = useCallback(async () => {
    if (shouldRecalc && retrieveOffer) {
      setShowProcess(true);
      // we need to dispatch this action to store the state of recalulation in process so our monthly payments card  on the top of coverages
      // can also display the loading/processing state.
      dispatch(setProcessingRecalc(true));
      await retrieveOffer();

      shouldRecalc(false);
      setShowProcess(false);
      setRecalculated(true);
      dispatch(setProcessingRecalc(false));
    }
  }, [shouldRecalc, retrieveOffer, dispatch]);

  const isBundle = !!(
    autoOfferProduct &&
    checkoutData.auto &&
    propertyOfferProduct &&
    checkoutData.property
  );
  const isAuto = !!(!isBundle && autoOfferProduct && checkoutData.auto);
  const isProperty = !!(!isBundle && propertyOfferProduct && checkoutData.property);
  const checkoutViewCommonProps = {
    isPricingDetailsHidden,
    premiumPlan,
    recalculate,
    showError,
    showProcess,
    showStateFees,
  };
  const isMobile = useIsMobile();
  const applyMobileVariantAStyle =
    isMobile && metadata.isVariantACoverageStyle && !env.static.isAgent;

  return (
    <div
      className={cx(
        classes.bottomCheckoutBarContainer,
        showError
          ? classes.bottomCheckoutBarContainerErrorBG
          : classes.bottomCheckoutBarContainerRegularBG,
      )}
    >
      {env.static.isAgent && <IndicativeQuoteWarning />}
      {showError ? (
        <Grid item xs={12} className={classes.bottomCheckoutBar}>
          <Grid className={classes.bottomCheckoutBarTextDiv} role='alert'>
            <p>Please review coverages and correct any errors</p>
          </Grid>
        </Grid>
      ) : (
        <Grid item md={12} sm={12} xs={12} container justifyContent='center' direction='column'>
          {(!applyMobileVariantAStyle || recalculate) && (
            <Grid container justifyContent='center'>
              <Grid item xs={12} className={classes.hideDetailsButton}>
                <Button onClick={showHidePricingDetails} variant='iconText'>
                  {!isPricingDetailsHidden ? 'Hide' : 'Review'} pricing details
                </Button>
              </Grid>
            </Grid>
          )}
          {!isPricingDetailsHidden && (
            <>
              {isBundle &&
                (isCheckout && autoPaymentOption && propertyPaymentOption ? (
                  <MobileMonthlyPaymentsBundleCheckoutView
                    {...checkoutViewCommonProps}
                    autoOfferProduct={autoOfferProduct}
                    propertyOfferProduct={propertyOfferProduct}
                    autoPaymentOption={autoPaymentOption}
                    propertyPaymentOption={propertyPaymentOption}
                    isMVRActive={isMVRActive}
                  />
                ) : (
                  <MobileMonthlyPaymentsBundleCoverageView
                    {...checkoutViewCommonProps}
                    autoOfferProduct={autoOfferProduct}
                    propertyOfferProduct={propertyOfferProduct}
                    isMVRActive={isMVRActive}
                  />
                ))}
              {isAuto &&
                (isCheckout && autoPaymentOption ? (
                  <MobileMonthlyPaymentsMonolineCheckoutView
                    {...checkoutViewCommonProps}
                    offerProduct={autoOfferProduct}
                    showEstimated={!isMVRActive}
                    paymentOption={autoPaymentOption}
                    isCheckout={isCheckout}
                  />
                ) : (
                  <MobileMonthlyPaymentsMonolineCoverageView
                    {...checkoutViewCommonProps}
                    offerProduct={autoOfferProduct}
                    showEstimated={!isMVRActive}
                  />
                ))}
              {isProperty &&
                (isCheckout && propertyPaymentOption ? (
                  <MobileMonthlyPaymentsMonolineCheckoutView
                    {...checkoutViewCommonProps}
                    offerProduct={propertyOfferProduct}
                    paymentOption={propertyPaymentOption}
                    isCheckout={isCheckout}
                  />
                ) : (
                  <MobileMonthlyPaymentsMonolineCoverageView
                    {...checkoutViewCommonProps}
                    offerProduct={propertyOfferProduct}
                  />
                ))}
            </>
          )}

          <Grid item xs={12}>
            <div className={classes.checkoutBarButton}>
              {!recalculate || isPatchingAnswers ? (
                !applyMobileVariantAStyle ? (
                  <div className={classes.carrierButtons}>
                    <Button
                      classes={{
                        root: cx(
                          classes.fullWidthCheckoutButtonRoot,
                          isProcessing && classes.spinnerOnly,
                        ),
                      }}
                      disabled={buttonDisabled}
                      data-testid='checkout'
                      variant={ctaButtonVariant}
                      onClick={onCheckout}
                      trackingName='BottomCheckout'
                      trackingLabel='BottomCheckout'
                      isProcessing={isProcessing}
                    >
                      {buttonText}
                    </Button>
                    {!env.static.isAgent && !removeSaveExit && <SaveAndExit />}
                  </div>
                ) : (
                  <div className={classes.variantACarrierButtons}>
                    <div>
                      <Button onClick={showHidePricingDetails} variant='iconText'>
                        {!isPricingDetailsHidden ? 'Hide' : 'Review'} pricing details
                      </Button>
                      <hr className={classes.divider} />
                      {!env.static.isAgent && !removeSaveExit && <SaveAndExit />}
                    </div>
                    <Button
                      classes={{
                        root: cx(
                          classes.fullWidthCheckoutButtonRoot,
                          isProcessing && classes.spinnerOnly,
                        ),
                      }}
                      data-testid='checkout'
                      variant={ctaButtonVariant}
                      onClick={onCheckout}
                      trackingName='BottomCheckout'
                      trackingLabel='BottomCheckout'
                      isProcessing={isProcessing}
                    >
                      {isCheckout ? buttonText : 'Continue'}
                    </Button>
                  </div>
                )
              ) : (
                <Button
                  variant='primary'
                  disabled={showProcess}
                  isProcessing={showProcess}
                  className={cx(classes.recalculateButton, {
                    [classes.recalcButtonBg]: !showProcess,
                  })}
                  trackingName='RecalculateButton'
                  trackingLabel={sapiAnalyticsSelectedOfferEventDetail}
                  onClick={recalculateClick}
                >
                  Calculate new rate
                </Button>
              )}
            </div>
            {premiumPlan === PaymentPlan.MONTHLY_PREMIUM &&
              !isPricingDetailsHidden &&
              !isCheckout &&
              !applyMobileVariantAStyle && (
                <Grid item xs={12}>
                  <p className={classes.installmentStatement}>
                    Premium does not include installment fees
                  </p>
                </Grid>
              )}
          </Grid>
          {children && (
            <Grid item xs={12}>
              {children}
            </Grid>
          )}
        </Grid>
      )}
    </div>
  );
};
