import type { AllConfigs } from '../types';

export const deltaPageFlow: AllConfigs = [
  {
    executionOrder: 100,
    startEffectiveDate: '01/01/2021',
    description: 'Auto - Long Form: Delta page',
    criteria: {
      flow: ['FLOW.LONG', 'FLOW.SHORT'],
      baseExp: ['Bundle', 'Agent'],
      product: ['connect.auto', 'amfam.auto', 'amfam-adv.auto'],
    },
    config: {
      menuItems: [
        {
          text: 'Additional information',
          pageId: 'delta',
          path: '/app/auto/delta',
        },
      ],
      navigationOrder: ['delta'],
      byPageId: {
        delta: {
          path: '/app/auto/delta',
          pageTitle: 'Additional Info',
        },
      },
    },
  },
  {
    executionOrder: 150,
    startEffectiveDate: '01/01/2021',
    description: 'Amfam Adv Renters: Delta page',
    criteria: {
      baseExp: ['Agent'],
      flow: ['FLOW.LONG', 'FLOW.SHORT'],
      product: ['amfam-adv.renters'],
    },
    config: {
      menuItems: [
        {
          text: 'Additional Info',
          pageId: 'delta',
          path: '/app/renters/delta',
        },
      ],
      navigationOrder: ['delta'],
      byPageId: {
        delta: {
          path: '/app/renters/delta',
          pageTitle: 'Additional Info',
        },
      },
    },
  },
  {
    executionOrder: 160,
    startEffectiveDate: '01/01/2021',
    description: 'Amfam Adv Home: Delta page',
    criteria: {
      baseExp: ['Agent'],
      flow: ['FLOW.LONG', 'FLOW.SHORT'],
      product: ['amfam-adv.home'],
    },
    config: {
      menuItems: [
        {
          text: 'Additional Info',
          pageId: 'delta',
          path: '/app/home/delta',
        },
      ],
      navigationOrder: ['delta'],
      byPageId: {
        delta: {
          path: '/app/home/delta',
          pageTitle: 'Additional Info',
        },
      },
    },
  },
];
