import {
  getOfferProductsSelected,
  getOfferProductsSelectedByType,
} from '@ecp/features/sales/shared/store';
import type { RootStore } from '@ecp/features/sales/shared/store/types';
import {
  getReducedProductNameFromProduct,
  type Product,
  type ReducedProductName,
} from '@ecp/features/shared/product';

import type { Acknowledgement, Signatures } from '../../types';
import type { AcknowledgementCategoryList } from './types';

export const getSignatureText = (state: RootStore): string | undefined =>
  state.acknowledgement.signatureText;

export const getPNISignatureText = (state: RootStore): string | undefined =>
  state.acknowledgement.pniSignatureText;

export const getSNISignatureText = (state: RootStore): string | undefined =>
  state.acknowledgement.sniSignatureText;

export const getAgreementResponse = (state: RootStore): boolean =>
  state.acknowledgement.agreementResponse;

export const getSignatureMethod = (state: RootStore): string | undefined =>
  state.acknowledgement.signatureMethod;

export const getSignatureMethodEmails = (state: RootStore): Record<string, string> =>
  state.acknowledgement.signatureMethodEmails;

export const getSignatureEnvelopeId = (state: RootStore): Record<string, string> =>
  state.acknowledgement.signatureEnvelopeId;

export const getSignatureRecipientId = (state: RootStore): Record<string, string> =>
  state.acknowledgement.signatureRecipientId;

export const getAcknowledgementList = (
  state: RootStore,
): { [productKey: string]: AcknowledgementCategoryList } =>
  state.acknowledgement.acknowledgementList;

export const getAcknowledgementListForProduct = (
  state: RootStore,
  product: Product,
): AcknowledgementCategoryList => {
  return getAcknowledgementList(state)[product];
};

export const getAcknowledgementsForCategory = (
  state: RootStore,
  productKey: Product,
  category: string,
): Acknowledgement[] | undefined => {
  const acknowledgementCategoryList = getAcknowledgementListForProduct(state, productKey);
  if (!acknowledgementCategoryList || !acknowledgementCategoryList.categories[category]) {
    return undefined;
  }

  return acknowledgementCategoryList.categories[category].acknowledgements;
};

export const getSignatures = (
  state: RootStore,
): Partial<Record<ReducedProductName, Acknowledgement[]>> => {
  const offerProducts = getOfferProductsSelectedByType(state);

  return Object.entries(offerProducts).reduce((acc, [reducedProductName, product]) => {
    if (product) {
      acc[reducedProductName] = getAcknowledgementsForCategory(state, product, 'Signature');
    }

    return acc;
  }, {} as Signatures);
};

export const getIAgreeAcknowledgements = (
  state: RootStore,
): Partial<Record<ReducedProductName, Acknowledgement[]>> => {
  const offerProducts = getOfferProductsSelectedByType(state);

  return Object.entries(offerProducts).reduce((acc, [reducedProductName, product]) => {
    if (product) {
      acc[reducedProductName] = getAcknowledgementsForCategory(state, product, 'IAgree');
    }

    return acc;
  }, {} as Signatures);
};

export const getAcknowledgementForKey = (
  state: RootStore,
  productKey: Product,
  category: string,
  acknowledgementKey: string,
): Acknowledgement | null => {
  const acknowledgements = getAcknowledgementsForCategory(state, productKey, category);
  if (!acknowledgements) {
    return null;
  }

  return acknowledgements.filter(
    (acknowledgement) => acknowledgement.key === acknowledgementKey,
  )[0];
};

export const getProductReminderKeys = (
  state: RootStore,
  product: Product,
  importanceCategory: 'Important' | 'Optional' | 'Reminder',
): string[] | null => {
  if (!state.acknowledgement || !state.acknowledgement.acknowledgementList[product]) {
    return null;
  }
  const reminders =
    state.acknowledgement.acknowledgementList[product].categories.Reminder?.acknowledgements;
  if (reminders) {
    if (reminders[0]?.rank) {
      const rankedReminders = reminders
        .filter((item) => item?.importance === importanceCategory)
        .sort((a, b) => {
          return Number(a.rank) - Number(b.rank);
        });

      return rankedReminders.map((acknowledgement) => acknowledgement.key);
    }
    if (importanceCategory === 'Important')
      return reminders.map((acknowledgement) => acknowledgement.key);
  }

  return [];
};

export const getAllProductReminderKeys = (
  state: RootStore,
  products: Product[],
  importanceCategory: 'Important' | 'Optional' | 'Reminder',
): string[] =>
  products.reduce((acc, product) => {
    const reminderKeys = getProductReminderKeys(state, product, importanceCategory);
    const result = reminderKeys?.length ? [...acc, ...reminderKeys] : acc;

    return result;
  }, [] as string[]);

type SignatureMethods = { label: string; value: string };

const signatureOptionsMetaData = [
  { value: 'REMOTE_CLICK_SIGN', label: 'Remote Click2sign' },
  { value: 'IN_PERSON_CLICK_SIGN', label: 'In-Person Click2sign' },
  { value: 'VOICE', label: 'Voice Signature' },
];

export const getSignatureMethodOptions = (
  state: RootStore,
  product: Product,
): SignatureMethods[] => {
  const signatureCategoryAcknowledgements =
    getAcknowledgementsForCategory(state, product, 'Signature') || [];

  const matchedData = signatureOptionsMetaData
    .map((metaDataItem) => {
      const finalItem = signatureCategoryAcknowledgements.find(
        (meta) => metaDataItem.value === meta.key,
      );
      if (finalItem) {
        return { value: metaDataItem.value, label: metaDataItem.label };
      } else {
        return null;
      }
    })
    .filter((item) => item !== null);

  return matchedData as SignatureMethods[];
};

export const getSignatureMethodsByPerson = (
  state: RootStore,
  product: Product,
  filterKey?: string,
): Record<string, Acknowledgement[]> => {
  const signatureCategoryAcknowledgements = getAcknowledgementsForCategory(
    state,
    product,
    'Signature',
  );

  return (
    signatureCategoryAcknowledgements?.reduce((acc, acknowledgement) => {
      const { personId, key } = acknowledgement;
      const isFilterMatch = filterKey ? key === filterKey : true;

      if (personId && isFilterMatch) {
        acc[personId] ??= [];
        acc[personId].push(acknowledgement);
      }

      return acc;
    }, {} as { [k: string]: Acknowledgement[] }) ?? {}
  );
};

// we can find the pni from any object within the Signature category array so picking the first object will suffice.
export const getPNISignatureLabel = (state: RootStore, product: Product): string | undefined => {
  const signatureCategoryAcknowledgements = getAcknowledgementsForCategory(
    state,
    product,
    'Signature',
  );
  const filteredSignatureAcknowledgementType = signatureCategoryAcknowledgements?.find((item) => {
    return item.acknowledgementType === 'Signature';
  });

  return filteredSignatureAcknowledgementType?.pniSignatureLabel;
};

// sni is not guaranteed to be in first object of Signature category array so we must do a find for it if it exists.
export const getSNISignatureLabel = (state: RootStore, product: Product): string | undefined => {
  const signatureCategoryAcknowledgements = getAcknowledgementsForCategory(
    state,
    product,
    'Signature',
  );
  const filteredSignatuerAcknowledgementType = signatureCategoryAcknowledgements?.filter((item) => {
    return item.acknowledgementType === 'Signature';
  });
  const signatures = filteredSignatuerAcknowledgementType?.find((acknowledgements) => {
    return acknowledgements.sniSignatureLabel;
  });

  return signatures?.sniSignatureLabel;
};

export const getAnyProductHasCheckboxAcknowledgements = (state: RootStore): boolean => {
  const acknowledgements = getIAgreeAcknowledgements(state);
  if (!acknowledgements) {
    return false;
  }
  const offerProductsSelected = getOfferProductsSelected(state);

  const checkboxAcknowledgement = offerProductsSelected.some((product) => {
    const productName = getReducedProductNameFromProduct(product);

    return acknowledgements[productName]?.some(
      (acknowledgement) => acknowledgement.acknowledgementType === 'Checkbox',
    );
  });

  return !!checkboxAcknowledgement;
};
