import { IconUICheck } from '@ecp/themes/base';

import { useStyles } from './StepIcon.styles';

export interface Props {
  active: boolean;
  completed: boolean;
  index?: number;
}

export const StepIcon: React.FC<Props> = (props) => {
  const { active, completed, index = 0 } = props;
  const { classes, cx } = useStyles();

  if (completed) return <IconUICheck className={cx(classes.circle, classes.completedCircle)} />;

  return (
    <p className={cx(classes.circle, active ? classes.incompleteCircle : classes.notVisitedCircle)}>
      {index + 1}
    </p>
  );
};
