import { makeStyles } from '@ecp/themes/base';

export const useStyles = makeStyles({ name: 'QuoteRetrieveCardContent' })((theme) => ({
  root: {
    border: `1px solid ${theme.palette.other.border}`,
    height: '100%',
    backgroundColor: theme.palette.grey[50],
    boxShadow: `0px 3px 5px ${theme.palette.shadow.primary}`,
  },
  cardContent: {
    display: 'flex',
    padding: 0,
    margin: '0px 20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
    },
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardColumn: {
    marginRight: 20,
    borderRight: `1px solid ${theme.palette.other.divider}`,
    [theme.breakpoints.down('md')]: {
      borderRight: 0,
      marginRight: 0,
      marginBottom: 10,
      paddingBottom: 10,
    },
    '& p': {
      marginBottom: 7,
    },
  },
  cardList: {
    listStyleType: 'none',
    marginBlockStart: '0.5em',
    marginBlockEnd: '0em',
    '& li': {
      marginLeft: -40,
      display: 'flex',
      justifyContent: 'space-between',
    },
    '& li:nth-last-child(1)': {
      borderBottom: 0,
    },
  },
  quoteDetailList: {
    margin: '10px 0px 5px',
    paddingBottom: 5,
    borderBottom: `1px solid ${theme.palette.other.divider}`,
    '& li:nth-child(last)': {
      display: 'none',
    },
  },
}));
