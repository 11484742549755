import * as interactionId from '@ecp/utils/analytics/interaction-id';
import { setDimension, TrackingDimensions } from '@ecp/utils/analytics/tracking';
import type { AgentAccessToken } from '@ecp/utils/auth';
import { FeatureFlags, flagValues } from '@ecp/utils/flags';
import type { LogParams } from '@ecp/utils/logger';
import { datadogLog } from '@ecp/utils/logger';
import { Headers, statusBadRequest, statusExpiredSession } from '@ecp/utils/network';
import { sessionStorage } from '@ecp/utils/storage';

import { env } from '@ecp/env';
import { setPaymentOptionsAndAcksRefetchRequired } from '@ecp/features/sales/checkout';
import {
  ErrorReason,
  OfferStatusCode,
  PARTNER_EXPERIENCE_ID,
  PaymentPlan,
  PRIMARY_INSURED_ADDRESS_REF,
  PRIMARY_INSURED_MAILING_ADDRESS_REF,
  PRIMARY_INSURED_PERSON_REF,
  SELECTED_PAYMENT_PLAN,
  USER_SELECTION,
} from '@ecp/features/sales/shared/constants';
import { RetreivalErrorReason } from '@ecp/features/sales/shared/constants';
import { getUpdatedPageflowsFromConfig, PagePath } from '@ecp/features/sales/shared/routing';
import type { FieldsDef, Person } from '@ecp/features/sales/shared/types';
import { trackSapiAnalyticsEvent } from '@ecp/features/sales/shared/utils/analytics';
import type { SalesResponse } from '@ecp/features/sales/shared/utils/network';
import { SalesRequestError } from '@ecp/features/sales/shared/utils/network';
import type { LineOfBusiness } from '@ecp/features/shared/product';
import { UserSelection } from '@ecp/features/shared/product';
import type { ExperienceId } from '@ecp/partners';

import type { SapiTarget } from '../api';
import { setSapiTarget } from '../api';
import { isAnyApiInProgress } from '../api/selectors';
import { fetchApi } from '../api/thunks';
import { updateGlobalCarrierErrors, updateGlobalError } from '../global/actions';
import type { AnswersResponse } from '../inquiry';
import { setAnswersApiGetSuccess, setInquiryOnOffersApiSuccess } from '../inquiry';
import {
  getAgentId,
  getAgentPartnerProducerId,
  getAnswers,
  getDalSessionId,
  getLineOfBusiness,
  getLineOfBusinessUserSelection,
  getProducts,
  getQuestion,
  getSelectedPaymentPlan,
  getUserSelection,
} from '../inquiry/selectors';
import {
  createInquiry,
  deleteAnswers,
  fetchInquiry,
  submitPolicyType,
  updateAnswers,
} from '../inquiry/thunks';
import { setNavPreviousPageChanged } from '../nav/actions';
import { makePreviousPagesValid } from '../nav/thunks';
import { wrapThunkActionWithErrHandler } from '../util/wrapThunkActionWithErrHandler';
import {
  deleteOffers,
  setDependenciesSnapshot,
  setOffersApiGetSuccess,
  setOffersApiPostSuccess,
  setOffersApiPutSuccess,
  setOffersApiRecallFail,
  setOffersApiRecallSuccess,
} from './actions';
import { getOffers, postDelta, postOffers, postRecallOffers, putOffers } from './api';
import metadata from './metadata';
import { makeQuoteRecallNavigation } from './recallNav';
import {
  CREATE_OFFERS_ID_PREFIX,
  FETCH_OFFERS_ID_PREFIX,
  getCreateOffersInProgress,
  getEligibleProductsFromSapi,
  getIsProductOfferPurchased,
  getOfferProductsSelected,
  getOfferSetId,
  getOffersForSelectedLob,
  getProductsForLOB,
  getShouldFetchNewOffers,
  getUpdateOffersInProgress,
  UPDATE_OFFERS_ID_PREFIX,
} from './selectors';
import type {
  OffersGetResponse,
  OffersPostResponse,
  OffersPutResponse,
  OffersRecallResponse,
  QuoteSummaryOffers,
} from './types';
import {
  getValidOffersForAnalytics,
  isFullPayOnly,
  isIndicativeQuoteRetrievalDenied,
  isOfferAutoSwitchRequote,
  isOfferOk,
  isOfferValid,
} from './util';

const { DefaultPaymentPlanMetadata, RetrievalErrors } = metadata;

function logOfferEligibility(
  response: OffersPostResponse | OffersGetResponse | OffersPutResponse | OffersRecallResponse,
  status: number,
  requestId: string | undefined,
  transactionId: string | undefined,
  token?: string | undefined,
  agentId?: string | undefined,
  agentProducerId?: string | undefined,
): void {
  const { offers, inquiryId } = response.data;

  Object.keys(offers).forEach((key) => {
    const {
      offerId,
      eligibility: { statusCode: offerEligibilityStatus, reason: offerEligibilityReasonText },
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    } = offers[key]!;

    let logType: LogParams['logType'] = 'info';
    let message = `Got a valid quote for ${key}`;
    sessionStorage.setItem('offerSetId', offerId, true);
    if (!isOfferValid(offers[key])) {
      logType = 'warn';
      message = `Could not get a valid quote for ${key}`;
    }

    /**
     * If, for some reason, SAPI sends a non-json parseable
     * format, JSON.parse will throw an error which could break
     * the application.
     *
     * To account for that and still maintain the log integrity
     * I want to throw this in a try/catch so we don't break the app
     * and we still get the data.
     */
    let offerEligibilityReason;
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      offerEligibilityReason = JSON.parse(offerEligibilityReasonText!);
    } catch (e) {
      offerEligibilityReason = offerEligibilityReasonText;
    }

    datadogLog({
      logType,
      message,
      context: {
        agentId,
        agentProducerId,
        token,
        inquiryId,
        logOrigin: 'libs/features/sales/shared/store/lib/src/offers/thunks.ts',
        functionOrigin: 'logOfferEligibility',
        contextType: 'Offers eligibility',
        offerEligibilityStatus,
        // the reason should be a strigified JSON object
        // parsing it will offer us greater details
        offerEligibilityReason,
        offerEligibilityReasonText,
        offerId,
        product: key,
        requestId,
        transactionId,
        responseStatus: status,
      },
    });
  });
}

export const createOffers = wrapThunkActionWithErrHandler<
  void,
  void | { requestId: string | undefined; transactionId: string | undefined }
>(
  () => async (dispatch, getState) => {
    const state = getState();
    const dalSessionId = getDalSessionId(state);
    const offerSetId = getOfferSetId(state);
    const offerInProgress = getCreateOffersInProgress(state);
    const agentId = env.static.isAgent ? getAgentId(getState()) : undefined;
    const agentProducerId = env.static.isAgent ? getAgentPartnerProducerId(getState()) : undefined;
    const agentAccessToken = sessionStorage.getItem('agentAuth.token') as unknown;
    const enableLogAgentToken = flagValues[FeatureFlags.ENABLE_LOG_AGENT_TOKEN];
    let token: string;
    if (agentAccessToken && enableLogAgentToken) {
      const { authToken } = agentAccessToken as AgentAccessToken;
      token = authToken;
    }

    if (!dalSessionId || offerSetId || offerInProgress) {
      return void 0;
    }
    let response: SalesResponse<OffersPostResponse> = {} as SalesResponse<OffersPostResponse>;
    await dispatch(
      fetchApi({
        fn: async () => {
          response = await postOffers({ dalSessionId });

          const hasSapiTarget = Headers.SAPI_TARGET in response.headers;
          if (!hasSapiTarget) {
            datadogLog({
              logType: 'error',
              message: 'sapi target not found in response headers for createOffers',
              context: {
                logOrigin: 'libs/features/sales/shared/store/lib/src/offers/thunks.ts',
                functionOrigin: 'createOffers',
              },
            });

            throw new Error('sapi target not found in response headers for createOffers');
          }
          const sapiTarget = response.headers[Headers.SAPI_TARGET] as SapiTarget;
          dispatch(setSapiTarget(sapiTarget));

          // Update questions and answers with latest values after offers creation
          dispatch(
            setInquiryOnOffersApiSuccess({
              dalSessionId,
              inquiryId: response.payload.data.inquiryId,
              answers: response.payload.data.answers,
              questions: response.payload.data.questions,
            }),
          );
          dispatch(setOffersApiPostSuccess(response.payload));

          const state = getState();
          const answers = getAnswers(state);
          const products = getEligibleProductsFromSapi(state);
          dispatch(setDependenciesSnapshot({ answers, products }));

          // Track valid offers for chase emails
          const offersForSelectedLob = getOffersForSelectedLob(state);
          const lineOfBusiness = getLineOfBusiness(state);
          const selectedLob = getLineOfBusinessUserSelection(state) || getLineOfBusiness(state);

          const validOfferDetails = getValidOffersForAnalytics(
            offersForSelectedLob,
            lineOfBusiness,
          );
          trackSapiAnalyticsEvent({
            element: 'choice.validOfferCheck',
            event: 'update',
            eventDetail: validOfferDetails,
          });

          // Set the payment term if only full pay is available
          await dispatch(
            handlePremiumPlanSelection({
              offers: offersForSelectedLob,
              selectedLob: selectedLob,
            }),
          );
          logOfferEligibility(
            response.payload,
            response.status,
            response.requestId,
            response.transactionId,
            token,
            agentId,
            agentProducerId,
          );
        },
        idPrefix: CREATE_OFFERS_ID_PREFIX,
      }),
    );

    // Returning request ID and transaction ID to quotes page to track CNQ/DNQ error to original request
    return { requestId: response.requestId, transactionId: response.transactionId };
  },
  'createOffers',
);

type FetchOffers = { dalSessionId?: string };
export const fetchOffers = wrapThunkActionWithErrHandler<FetchOffers, void>(
  ({ dalSessionId }) =>
    async (dispatch, getState) => {
      await dispatch(
        fetchApi({
          fn: async () => {
            const dalSessionIdValue = dalSessionId ?? getDalSessionId(getState());

            if (!dalSessionIdValue) {
              return;
            }

            const response: SalesResponse<OffersGetResponse> = await getOffers({
              dalSessionId: dalSessionIdValue,
            });

            // Adding the sapi target check here separately from fetchInquiry.
            // There might be a separate enhancement to return answers as part of
            // offers. Then, it is better to keep this check separate.
            const hasSapiTarget = Headers.SAPI_TARGET in response.headers;
            if (!hasSapiTarget) {
              datadogLog({
                logType: 'error',
                message: 'sapi target not found in response headers for fetchOffers',
                context: {
                  logOrigin: 'libs/features/sales/shared/store/lib/src/offers/thunks.ts',
                  functionOrigin: 'fetchOffers',
                },
              });

              throw new Error('sapi target not found in response headers for fetchOffers');
            }
            const sapiTarget = response.headers[Headers.SAPI_TARGET] as SapiTarget;
            dispatch(setSapiTarget(sapiTarget));

            // Update questions and answers with latest values since offers needs latest
            // Imp: fetchInquiry gets the SapiTarget as well.
            await dispatch(fetchInquiry({ dalSessionId: dalSessionIdValue }));
            dispatch(setOffersApiGetSuccess(response.payload));
            // Track valid offers for chase emails
            const agentAccessToken = sessionStorage.getItem('agentAuth.token') as unknown;
            const enableLogAgentToken = flagValues[FeatureFlags.ENABLE_LOG_AGENT_TOKEN];
            let token: string | undefined = undefined;
            if (agentAccessToken && enableLogAgentToken) {
              const { authToken } = agentAccessToken as AgentAccessToken;
              token = authToken;
            }
            const agentId = env.static.isAgent ? getAgentId(getState()) : undefined;
            const agentProducerId = env.static.isAgent
              ? getAgentPartnerProducerId(getState())
              : undefined;
            const offersForSelectedLob = getOffersForSelectedLob(getState());
            const lineOfBusiness = getLineOfBusiness(getState());
            const validOfferDetails = getValidOffersForAnalytics(
              offersForSelectedLob,
              lineOfBusiness,
            );
            trackSapiAnalyticsEvent({
              element: 'choice.validOfferCheck',
              event: 'update',
              eventDetail: validOfferDetails,
            });
            logOfferEligibility(
              response.payload,
              response.status,
              response.requestId,
              response.transactionId,
              token,
              agentId,
              agentProducerId,
            );
          },
          idPrefix: FETCH_OFFERS_ID_PREFIX,
        }),
      );
    },
  'fetchOffers',
);

type UpdateOffers = {
  clearUserSelection?: boolean;
  force?: boolean; // This prop forces put offers call even if there is no change in answers. This is needed for race & back nav conditions and only used during delta call.
};
export const updateOffers = wrapThunkActionWithErrHandler<
  UpdateOffers | void,
  void | { requestId: string | undefined; transactionId: string | undefined }
>(
  (args) => async (dispatch, getState) => {
    const { clearUserSelection, force } = args || {};
    const products = getProducts(getState());
    const agentId = env.static.isAgent ? getAgentId(getState()) : undefined;
    const agentProducerId = env.static.isAgent ? getAgentPartnerProducerId(getState()) : undefined;
    const agentAccessToken = sessionStorage.getItem('agentAuth.token') as unknown;
    const enableLogAgentToken = flagValues[FeatureFlags.ENABLE_LOG_AGENT_TOKEN];
    let token: string;
    if (agentAccessToken && enableLogAgentToken) {
      const { authToken } = agentAccessToken as AgentAccessToken;
      token = authToken;
    }

    if (clearUserSelection && products.length > 1) {
      // User selection is cleared to get all offer.premiums including bundle when we back navigate from coverage page to quotes page
      // Unfortunately it is a requirement from SAPI which we are discussing with them
      await dispatch(deleteAnswers({ keys: [USER_SELECTION] }));
    }

    const checkCreateInProgress = async (resolve: (value?: unknown) => void): Promise<void> => {
      if (getCreateOffersInProgress(getState())) {
        setTimeout(() => checkCreateInProgress(resolve), 200);
      } else {
        resolve();
      }
    };
    const checkUpdateInProgress = async (resolve: (value?: unknown) => void): Promise<void> => {
      if (getUpdateOffersInProgress(getState())) {
        setTimeout(() => checkUpdateInProgress(resolve), 200);
      } else {
        resolve();
      }
    };
    const checkIsAnyApiInProgress = async (resolve: (value?: unknown) => void): Promise<void> => {
      if (isAnyApiInProgress(getState())) {
        setTimeout(() => checkIsAnyApiInProgress(resolve), 200);
      } else {
        resolve();
      }
    };
    // !TODO Looks like a perfect candidate for waitForCondition usage
    await Promise.all([
      new Promise(checkCreateInProgress),
      new Promise(checkUpdateInProgress),
      new Promise(checkIsAnyApiInProgress),
    ]);

    let response: SalesResponse<OffersPutResponse> = {} as SalesResponse<OffersPutResponse>;

    await dispatch(
      fetchApi({
        fn: async () => {
          const state = getState();
          const offerSetId = getOfferSetId(state);
          const dalSessionId = getDalSessionId(state);
          if (!dalSessionId || !offerSetId) {
            return;
          }
          const offerProductsSelected = getOfferProductsSelected(state);
          const eligibleProductsFromSapi = getEligibleProductsFromSapi(state);
          const userSelection = getUserSelection(state);
          const products =
            userSelection && offerProductsSelected.length > 0
              ? offerProductsSelected
              : eligibleProductsFromSapi;
          if (!products.length) {
            // only could happen on quote summary since don't pass in products -> show no quotes page instead of error page
            datadogLog({
              logType: 'error',
              message: 'No valid products for existing offers. Cannot update or show offers',
              context: {
                logOrigin: 'libs/features/sales/shared/store/lib/src/offers/thunks.ts',
                functionOrigin: 'updateOffers/fetchApi',
                offerSetId,
              },
            });
            dispatch(deleteOffers());

            return;
          }
          // Dont allow recalc for purchased products
          const productsForCheckout = products.filter(
            (product) => !getIsProductOfferPurchased(state, product),
          );
          if (!productsForCheckout.length) {
            return;
          }

          const shouldFetchNewOffers = getShouldFetchNewOffers(state, productsForCheckout);
          if (!shouldFetchNewOffers && !force) return;

          response = await putOffers({ dalSessionId, products: productsForCheckout });

          const { offers } = response.payload.data;

          const hasSapiTarget = Headers.SAPI_TARGET in response.headers;
          if (!hasSapiTarget) {
            datadogLog({
              logType: 'error',
              message: 'sapi target not found in response headers for updateOffers',
              context: {
                logOrigin: 'libs/features/sales/shared/store/lib/src/offers/thunks.ts',
                functionOrigin: 'updateOffers',
              },
            });

            throw new Error('sapi target not found in response headers for updateOffers');
          }
          const sapiTarget = response.headers[Headers.SAPI_TARGET] as SapiTarget;
          dispatch(setSapiTarget(sapiTarget));

          // Update questions and answers with latest values after offers update
          dispatch(
            setInquiryOnOffersApiSuccess({
              dalSessionId,
              inquiryId: response.payload.data.inquiryId,
              answers: response.payload.data.answers,
              questions: response.payload.data.questions,
            }),
          );
          dispatch(setOffersApiPutSuccess(response.payload));

          const stateAfterRequest = getState();
          const answers = getAnswers(stateAfterRequest);
          dispatch(setDependenciesSnapshot({ answers, products: productsForCheckout }));

          // Track valid offers for chase emails
          const offersForSelectedLob = getOffersForSelectedLob(stateAfterRequest);
          const lineOfBusiness = getLineOfBusiness(stateAfterRequest);
          const selectedLob =
            getLineOfBusinessUserSelection(stateAfterRequest) ||
            getLineOfBusiness(stateAfterRequest);

          const validOfferDetails = getValidOffersForAnalytics(
            offersForSelectedLob,
            lineOfBusiness,
          );
          trackSapiAnalyticsEvent({
            element: 'choice.validOfferCheck',
            event: 'update',
            eventDetail: validOfferDetails,
          });
          dispatch(setPaymentOptionsAndAcksRefetchRequired(true));

          // Set the payment term if only full pay is available
          await dispatch(
            handlePremiumPlanSelection({
              offers: offersForSelectedLob,
              selectedLob: selectedLob,
            }),
          );

          // TODO - currently even if one product fails after quote summary page we go to oops page. This behavior should be changed and so working with UX & PO for it
          if (userSelection && offerProductsSelected.length > 0) {
            // This is needed because SAPI includes all products in offers response even after specific products are selected.
            const erroredOffers = Object.values(offers).filter(
              (offer) => offerProductsSelected.includes(offer.product) && !isOfferOk(offer),
            );

            erroredOffers.forEach((erroredOffer) => {
              if (erroredOffer) {
                datadogLog({
                  logType: 'warn',
                  message: `Errored offer for ${erroredOffer.product}`,
                  context: {
                    logOrigin: 'libs/features/sales/shared/store/lib/src/offers/thunks.ts',
                    functionOrigin: 'updateOffers/fetchApi',
                    offerSetId,
                  },
                });
              }
            });

            // ECP-3849: only if all offers are errored
            const allDeclined = erroredOffers.length === offerProductsSelected.length;

            if (allDeclined) {
              erroredOffers.forEach((erroredOffer) => {
                if (erroredOffer) {
                  // set previous page to policy start just in case quotes page also fails
                  // Policy start page is always visited also there is not much chance of changing personal details
                  dispatch(setNavPreviousPageChanged(PagePath.PERSON));

                  // 'RVP' is not a statusCode that can be returned from SAPI.
                  // Because we want to continue using the structure laid out in ErrorPageMetaData.tsx to generate the correct UI,
                  // we need to manually set the 'errorReason' here when the offer eligibility meets certain conditions.

                  // In v4, DAL doesn't give the "reason" node so we have to check in the "reasons" object
                  const includesRVPReasons = erroredOffer.eligibility.reasons?.some(
                    (e) => e.code === 'PSPRVPH' || e.code === 'PSPRVPM',
                  );

                  const offerErrorReason =
                    erroredOffer.eligibility.statusCode === OfferStatusCode.QNB &&
                    (includesRVPReasons ||
                      erroredOffer.eligibility.reason === 'PSPRVPH' ||
                      erroredOffer.eligibility.reason === 'PSPRVPM')
                      ? ErrorReason.RVP
                      : erroredOffer.eligibility.statusCode;

                  const errorBase = {
                    hasError: true,
                    requestId: response.requestId || '',
                    transactionId: response.transactionId || '',
                    errorReason: offerErrorReason,
                    errorReasonMessage: erroredOffer.eligibility.reason,
                    name: '',
                    text: '',
                  };

                  dispatch(updateGlobalError(errorBase));
                }
              });
            }
          }
          logOfferEligibility(
            response.payload,
            response.status,
            response.requestId,
            response.transactionId,
            token,
            agentId,
            agentProducerId,
          );
        },
        idPrefix: UPDATE_OFFERS_ID_PREFIX,
      }),
    );

    // Returning request ID and transaction ID to quotes page to track CNQ/DNQ error to original request
    return { requestId: response.requestId, transactionId: response.transactionId };
  },
  'updateOffers',
);

export const getRetrieveErrorReasonFromErrorBody = (
  errorBody: string | undefined,
): RetreivalErrorReason => {
  if (!errorBody) {
    return RetreivalErrorReason.QUOTE_NOT_FOUND;
  }

  let errorReason;

  if (errorBody.includes('zipcode_mismatch')) {
    errorReason = RetreivalErrorReason.ZIPCODE_MISMATCH;
  } else if (errorBody.includes('dob_or_email_mismatch')) {
    errorReason = RetreivalErrorReason.DOB_OR_EMAIL_MISMATCH;
  } else if (errorBody.includes('product_mismatch')) {
    errorReason = RetreivalErrorReason.PRODUCT_MISMATCH;
  } else if (errorBody.includes('last_name_mismatch')) {
    errorReason = RetreivalErrorReason.LAST_NAME_MISMATCH;
  } else if (errorBody.includes('quote_not_found')) {
    errorReason = RetreivalErrorReason.QUOTE_NOT_FOUND;
  } else {
    errorReason = RetreivalErrorReason.QUOTE_NOT_FOUND;
  }

  return errorReason;
};

// Set initialRecallRequest = true only on the first recallOffers request
// to ensure offers are updated after any change (edit vehicle) to get updated offers
export const recallOffers = wrapThunkActionWithErrHandler<
  {
    person: FieldsDef<Pick<Person, 'lastName' | 'dateOfBirth' | 'email'>>;
    zipcode: string;
    initialRecallRequest: boolean;
    experienceId?: string;
    pniRef?: string;
    primaryAddressRef?: string;
  },
  void
>(
  ({ person, zipcode, initialRecallRequest, experienceId, pniRef, primaryAddressRef }) =>
    async (dispatch, getState) => {
      // This is to make sure list of products is always available, even for v4.
      await dispatch(submitPolicyType());

      const enableLogAgentToken = flagValues[FeatureFlags.ENABLE_LOG_AGENT_TOKEN];
      const agentId = env.static.isAgent ? getAgentId(getState()) : undefined;
      const agentProducerId = env.static.isAgent
        ? getAgentPartnerProducerId(getState())
        : undefined;
      const agentAccessToken = env.static.isAgent
        ? (sessionStorage.getItem('agentAuth.token') as unknown)
        : undefined;
      let token: string | undefined = undefined;
      if (agentAccessToken && enableLogAgentToken) {
        const { authToken } = agentAccessToken as AgentAccessToken;
        token = authToken;
      }
      const { dateOfBirth, lastName, email } = person;
      const personObj = {
        dob: dateOfBirth.props.value,
        lastname: lastName.props.value,
        email: email.props.value,
      };
      const products = getProductsForLOB(getState());
      const recallPayload = {
        person: personObj,
        products,
        zipcode,
        experienceId,
      };
      let response: SalesResponse<OffersRecallResponse>;
      const agentCrossAcountRecallEnabled = flagValues[FeatureFlags.AGENT_CROSS_ACCOUNT_RECALL];
      try {
        dispatch(setOffersApiRecallFail(''));
        response = await postRecallOffers(recallPayload);

        if (
          agentCrossAcountRecallEnabled &&
          response.payload.errorCode === ErrorReason.CROSS_ACCOUNT_RETRIEVE &&
          response.payload.errorData
        ) {
          const { crossAccountExperienceId } = response.payload.errorData;
          sessionStorage.setItem(PARTNER_EXPERIENCE_ID, crossAccountExperienceId);
          interactionId.reset();
          await dispatch(
            createInquiry({
              answers: {
                [PRIMARY_INSURED_PERSON_REF]: pniRef,
                [PRIMARY_INSURED_ADDRESS_REF]: primaryAddressRef,
                [PRIMARY_INSURED_MAILING_ADDRESS_REF]: primaryAddressRef,
                [PARTNER_EXPERIENCE_ID]: crossAccountExperienceId as ExperienceId,
              },
            }),
          );
          response = await postRecallOffers({
            ...recallPayload,
            experienceId: crossAccountExperienceId,
          });
        }

        const hasSapiTarget = Headers.SAPI_TARGET in response.headers;
        if (!hasSapiTarget) {
          datadogLog({
            logType: 'error',
            message: 'sapi target not found in response headers for recallOffers',
            context: {
              logOrigin: 'libs/features/sales/shared/store/lib/src/offers/thunks.ts',
              functionOrigin: 'recallOffers',
            },
          });

          throw new Error('sapi target not found in response headers for recallOffers');
        }
        const sapiTarget = response.headers[Headers.SAPI_TARGET] as SapiTarget;
        dispatch(setSapiTarget(sapiTarget));

        const { dalSessionId } = response.payload;
        if (response.payload.data.offerSetId !== undefined) {
          setDimension(TrackingDimensions.RETREIVE_FLAG, true);
        }

        if (isIndicativeQuoteRetrievalDenied(response.payload)) {
          dispatch(setOffersApiRecallFail(metadata.DisplayErrorRecallMessage.recallErrorMessage));

          return;
        }
        // Need to check if the offer is auto_switch_re_quote before updating the offers
        const isAutoSwitchRequote = isOfferAutoSwitchRequote(response.payload);
        if (isAutoSwitchRequote) {
          dispatch(
            setOffersApiRecallFail(
              'Your auto quote has expired. Please start a new quote by clicking the link below.',
            ),
          );
          datadogLog({
            logType: 'warn',
            message:
              'AUTO_SWITCH_RE_QUOTE - Your auto quote has expired. Please start a new quote by clicking the link below.',
            context: {
              logOrigin: 'libs/features/sales/shared/store/lib/src/offers/thunks.ts',
              functionOrigin: 'recallOffers',
            },
          });

          return;
        }

        const { inquiryId, answers, questions } = response.payload.data;
        // Answers and questions are returned on retrieve offers response
        dispatch(
          setInquiryOnOffersApiSuccess({
            dalSessionId,
            inquiryId,
            answers,
            questions,
          }),
        );
        dispatch(setOffersApiRecallSuccess(response.payload));

        // Track valid offers for chase emails
        const state = getState();
        const offersForSelectedLob = getOffersForSelectedLob(state);
        const lineOfBusiness = getLineOfBusiness(state);
        const selectedLob = getLineOfBusinessUserSelection(state) || getLineOfBusiness(state);
        const validOfferDetails = getValidOffersForAnalytics(offersForSelectedLob, lineOfBusiness);
        trackSapiAnalyticsEvent({
          element: 'choice.validOfferCheck',
          event: 'update',
          eventDetail: validOfferDetails,
        });

        // Always set premium plan answer based on newest sapi offers
        await dispatch(
          handlePremiumPlanSelection({
            offers: offersForSelectedLob,
            selectedLob: selectedLob,
          }),
        );

        setDimension(TrackingDimensions.ZIP, zipcode);

        const nextPage = env.static.isAgent ? '/agent/dashboard' : '/quote/quotes?loading';

        // Get page flows from config for the line of business
        await dispatch(
          getUpdatedPageflowsFromConfig({
            modifiedLOB: lineOfBusiness,
          }),
        );
        // Set page status all to valid, since they have to be valid to get an offer and then recall
        dispatch(makePreviousPagesValid(nextPage));
        await dispatch(makeQuoteRecallNavigation({ nextPage, initialRecallRequest }));
      } catch (e) {
        const error = e as Error | SalesRequestError;

        datadogLog({
          logType:
            error instanceof SalesRequestError &&
            (statusExpiredSession(error.response?.status) ||
              statusBadRequest(error.response?.status))
              ? 'warn'
              : 'error',
          message: `Error Recalling Quote - ${error?.message}`,
          context: {
            logOrigin: 'libs/features/sales/shared/store/lib/src/offers/thunks.ts',
            functionOrigin: 'recallOffers',
            ...(error instanceof SalesRequestError && { errorData: error.errorData }),
          },
          error,
        });

        if (error instanceof SalesRequestError) {
          const { response: errorResponse, errorBody } = error;
          const errorReason = getRetrieveErrorReasonFromErrorBody(errorBody);

          // for 400 and 404, show error alert on the Retrieve Form
          if (
            errorResponse &&
            (statusExpiredSession(errorResponse.status) || statusBadRequest(errorResponse.status))
          ) {
            // reset interaction Id when recall does not return any offers only on 404
            if (!statusBadRequest(errorResponse.status)) interactionId.reset();

            dispatch(
              setOffersApiRecallFail(
                RetrievalErrors[errorReason as RetreivalErrorReason]?.displayErrorMessage ||
                  metadata.DisplayErrorRecallMessage.recallErrorMessage,
              ),
            );

            return;
          }
        }

        // for all the other errors, let the thunk wrapper handle the logging and showing the SessionErrorStopPage
        throw error;
      }
      logOfferEligibility(
        response.payload,
        response.status,
        response.requestId,
        response.transactionId,
        token,
        agentId,
        agentProducerId,
      );
    },
  'recallOffers',
);

// After any change to product selection, patch the type of product(Bundled/Unbundled) to inquiries and update the selected offer
export const patchUserSelectionAndUpdateOffer = wrapThunkActionWithErrHandler<void>(
  () => async (dispatch, getState) => {
    const offerProductsSelected = getOfferProductsSelected(getState());
    const products = getProducts(getState());
    const bundleTypeOfSelectedProduct =
      products.length > 1 && offerProductsSelected.length > 1
        ? UserSelection.BUNDLED
        : UserSelection.UNBUNDLED;
    await dispatch(updateAnswers({ answers: { [USER_SELECTION]: bundleTypeOfSelectedProduct } }));
    await dispatch(updateOffers());
    dispatch(setPaymentOptionsAndAcksRefetchRequired(true));
  },
  'patchUserSelectionAndUpdateOffer',
);

export const handlePremiumPlanSelection = wrapThunkActionWithErrHandler<
  {
    offers: QuoteSummaryOffers | null;
    selectedLob: LineOfBusiness;
  },
  void
>(
  ({ offers, selectedLob }) =>
    async (dispatch, getState) => {
      const isFullPay = isFullPayOnly(offers, selectedLob);
      const paymentPlan = getSelectedPaymentPlan(getState());

      // If only full pay is available, set to full pay
      if (isFullPay && paymentPlan !== PaymentPlan.FULL_PREMIUM) {
        await dispatch(
          updateAnswers({ answers: { [SELECTED_PAYMENT_PLAN]: PaymentPlan.FULL_PREMIUM } }),
        );
      }
      // Otherwise only set to default if it hasn't been set yet
      else if (!paymentPlan) {
        if (DefaultPaymentPlanMetadata[selectedLob])
          await dispatch(
            updateAnswers({
              answers: { [SELECTED_PAYMENT_PLAN]: DefaultPaymentPlanMetadata[selectedLob] },
            }),
          );
        else {
          const paymentPlanQuestion = getQuestion(SELECTED_PAYMENT_PLAN)(getState());
          await dispatch(
            updateAnswers({
              answers: { [SELECTED_PAYMENT_PLAN]: paymentPlanQuestion.defaultValue },
            }),
          );
        }
      }
    },
  'handlePremiumPlanSelection',
);

// TODO: Deprecate this once every one moves to submitDelta usage
export const applyDelta = wrapThunkActionWithErrHandler<
  {
    policyTypes?: string[];
  },
  SalesResponse<AnswersResponse> | void
>(
  ({ policyTypes }) =>
    async (dispatch, getState) => {
      const dalSessionId = getDalSessionId(getState());

      if (!dalSessionId) {
        return undefined;
      }

      const response = await postDelta({ dalSessionId, policyTypes });
      dispatch(setAnswersApiGetSuccess(response.payload));

      return response;
    },
  'applyDelta',
);

export const submitDelta = wrapThunkActionWithErrHandler<{
  policyTypes?: string[];
}>(
  ({ policyTypes }) =>
    async (dispatch, getState) => {
      const dalSessionId = getDalSessionId(getState());
      const redesignedErrorHandling = flagValues[FeatureFlags.ERROR_HANDLING_REDESIGN];

      if (!dalSessionId) {
        return undefined;
      }

      try {
        const response = await postDelta({ dalSessionId, policyTypes });
        dispatch(setAnswersApiGetSuccess(response.payload));
      } catch (e) {
        const error = e as Error | SalesRequestError;

        // EDSP-13850 - Update errors to global state to display on the oops page
        if (redesignedErrorHandling) {
          const errors = error instanceof SalesRequestError ? error.errors : [];
          if (errors && errors.length > 0) {
            dispatch(updateGlobalCarrierErrors(errors));
          }
        }

        datadogLog({
          logType: 'error',
          message: `Error submitting delta - ${error?.message}`,
          context: {
            logOrigin: 'libs/features/sales/shared/store/lib/src/offers/thunks.ts',
            functionOrigin: 'submitDelta',
            ...(error instanceof SalesRequestError && { errorData: error.errorData }),
          },
          error,
        });

        throw e;
      }
    },
  'submitDelta',
);
